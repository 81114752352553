/**
 * This class is shown when the user tries to login or open a new tab and there are no available user licenses.
 */

import { Image, Label, Layout } from "@mcleod/components";
import {
    AuthType, ClientRegistration, DisplayType, HorizontalAlignment, StringUtil, VerticalAlignment
} from "@mcleod/core";

export class NoLicensesAvailable extends Layout {
    constructor(props) {
        super({
            auth: AuthType.UNAUTH,
            allowDecoratorOverride: false,
            title: "No Licenses Available",
            needsServerLayout: false,
            align: HorizontalAlignment.CENTER,
            fillRow: true,
            verticalAlign: VerticalAlignment.CENTER,
            ...props
        });
        let errorMessage = props?.error;
        try {
            if (errorMessage == null)
                errorMessage = localStorage.getItem(ClientRegistration.getLicenseErrorStorageKey());
            if (errorMessage == null)
                errorMessage = "All user licenses are currently in use.";
        }
        finally {
            localStorage.removeItem(ClientRegistration.getLicenseErrorStorageKey());
        }
        this.add(new Image({ name: "x", height: 140, width: 388, color: "primary", rowBreak: true, marginTop: 64, marginBottom: 48 }));
        this.add(new Label({ caption: errorMessage, color: "error", fontSize: "xxlarge" }));

        try {
            const contactName = localStorage.getItem(ClientRegistration.getLicenseContactNameStorageKey());
            let firstLabelPrinted = false;
            if (StringUtil.isEmptyString(contactName) !== true) {
                this.add(new Label({ caption: "Name:", fontSize: "large", fontBold: true, rowBreak: false, marginTop: this.getContactLabelMarginTop(firstLabelPrinted) }));
                this.add(new Label({ caption: contactName, fontSize: "large", marginTop: this.getContactLabelMarginTop(firstLabelPrinted) }));
                firstLabelPrinted = true;
            }
            const contactEmail = localStorage.getItem(ClientRegistration.getLicenseContactEmailStorageKey());
            if (StringUtil.isEmptyString(contactEmail) !== true) {
                this.add(new Label({ caption: "Email:", fontSize: "large", fontBold: true, rowBreak: false, marginTop: this.getContactLabelMarginTop(firstLabelPrinted) }));
                this.add(new Label({ caption: contactEmail, fontSize: "large", displayType: DisplayType.EMAIL, marginTop: this.getContactLabelMarginTop(firstLabelPrinted) }));
                firstLabelPrinted = true;
            }
            const contactPhone = localStorage.getItem(ClientRegistration.getLicenseContactPhoneStorageKey());
            if (StringUtil.isEmptyString(contactPhone) !== true) {
                this.add(new Label({ caption: "Phone:", fontSize: "large", fontBold: true, rowBreak: false, marginTop: this.getContactLabelMarginTop(firstLabelPrinted) }));
                this.add(new Label({ caption: contactPhone, fontSize: "large", displayType: DisplayType.PHONE, marginTop: this.getContactLabelMarginTop(firstLabelPrinted) }));
                firstLabelPrinted = true;
            }
        }
        finally {
            localStorage.removeItem(ClientRegistration.getLicenseContactEmailStorageKey());
            localStorage.removeItem(ClientRegistration.getLicenseContactNameStorageKey());
            localStorage.removeItem(ClientRegistration.getLicenseContactPhoneStorageKey());
        }
        this.add(new Label({ caption: "Return to Sign In", link: "common/Login", linkHardRefresh: true, fontSize: "large", marginTop: 15 }));
    }

    private getContactLabelMarginTop(value: boolean) {
        return value == false ? 15 : 0;
    }
}
