import { Button, ButtonProps, ButtonVariant, Label, Layout, LayoutProps, Panel, SlideoutDecorator } from "@mcleod/components";
import { Alignment, AuthType, CompanySettings, HorizontalAlignment, StringUtil, WindowTitle } from "@mcleod/core";
import { CommonDialogs } from "../CommonDialogs";
import { McLeodMainPage } from "../McLeodMainPage";
import { ModelUserHomePage, RowUserHomePage } from "../models/ModelUserHomePage";
import { HomePageEditor } from "./HomePageEditor";
import { WidgetGrid } from "./WidgetGrid";

const buttonProps: Partial<ButtonProps> = { variant: ButtonVariant.round, color: "subtle.dark", imageHeight: 24, imageWidth: 24, rowBreak: false }

export class Home extends Layout {
    buttonConfig: Button;
    layout: Layout;
    panelTools: Panel;

    constructor(props: LayoutProps) {
        super({
            auth: AuthType.ANY,
            needsServerLayout: false,
            title: "PowerBroker Home",
            ...props });
    }

    override onLoad() {
        this.loadUserHomePage();
    }

    private loadUserHomePage() {
        new ModelUserHomePage().searchSingle().then((row: RowUserHomePage) => {
            const homePage = row?.get("home_page");
            const def = row?.get("definition")
            if (homePage != null)
                this.showHomePage(homePage);
            else if (def != null)
                this.showWidgetLayout(row);
            else
                McLeodMainPage.getInstance().header.showMenu();
        });
    }

    private showHomePage(page: string) {
        const layout = Layout.getLayout(page);
        layout.addLayoutLoadListener(() => {
            if (layout.title != null) {
                const settings = CompanySettings.get();
                let title = layout.title;
                if (!StringUtil.isEmptyString(settings?.name))
                    title += " - " + settings.name;
                WindowTitle.set(title);
            }
        })
        this.padding = 24;
        this.add(layout);
    }

    private showWidgetLayout(row: RowUserHomePage) {
        const def = row?.get("definition");
        if (def != null) {
            this.removeAll();
            this.layout = new Layout({ align: HorizontalAlignment.CENTER, paddingTop: 12 });
            this.panelTools = new Panel({ height: 40, align: HorizontalAlignment.RIGHT });
            this.add(this.layout);
            const widgetGrid = new WidgetGrid();
            this.layout.add(this.panelTools);
            this.layout.add(widgetGrid);
            widgetGrid.style.visibility = "hidden";
            widgetGrid.displayLayout(def).then(() => {
                // Adjust the grid and toolbar widths after the layouts load so they are horizontally centered
                widgetGrid.width = widgetGrid.getExplicitGridWidth() + 4;
                this.panelTools.width = widgetGrid.bounds.width;
                this.addToolbarButtons(row);
                widgetGrid.style.visibility = "visible";
            });
        }
    }

    private addToolbarButtons(row: RowUserHomePage) {
        this.buttonConfig = new Button({
            ...buttonProps,
            imageName: "settings",
            tooltip: "Choose what appears on your home page"
        });
        this.buttonConfig.addClickListener(() => {
            const editor = new HomePageEditor({ rowUserHomePage: row });
            editor.addLayoutLoadListener(() => {
                new SlideoutDecorator({
                    layout: editor,
                    transitionOptions: { direction: Alignment.BOTTOM },
                    title: "Edit Home Page",
                    fillVerticalSpace: true,
                    fillHorizontalSpace: true,
                    backgroundColor: "defaultBackground",
                    addlComponents: [editor.buttonSave],
                    onClose: (cancelled: boolean) => editor.slideOut(),
                    doAfterSlideOut: (decorator: SlideoutDecorator) => {
                        if (editor.savedRow != null)
                            this.showWidgetLayout(editor.savedRow);
                    }
                });
            });
        });

        if (!row.isNull("id")) {
            const buttonReset = new Button({
                ...buttonProps,
                imageName: "refresh",
                tooltip: "Reset back to the default homepage",
                onClick: async () => {
                    if (await CommonDialogs.showYesNo("Are you sure you want to reset back to the default widgets?", "Reset Widgets")) {
                        await row.delete();
                        this.loadUserHomePage()
                    }
                }, style: {
                    transform: "scaleX(-1)"
                }
            });
            this.panelTools.add(buttonReset);
        }

        this.panelTools.add(this.buttonConfig);
    }

    private showGetStarted() {
        const panel = new Panel({ fillRow: true, align: HorizontalAlignment.CENTER });
        const label = new Label({
            caption: "You haven't set up anything to be displayed on your home page.  Click the gear icon to get started.",
            color: "subtle.darker",
            align: HorizontalAlignment.CENTER,
            width: 300
        });
        panel.add(label);
        this.layout.add(panel);
        this.buttonConfig.showTooltip("Click here to get started!", { position: Alignment.LEFT, shaking: true, timeout: 5000 });
    }

    getRouterProps() {
        return { padding: 0 };
    }
}
