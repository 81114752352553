import { Collection, SettingsManager, StringUtil, setLogConfig } from "..";
import { BroadcastMessageHandler } from "../messaging/BroadcastMessageHandler";
import { AbstractSettings, AbstractSettingsDef } from "./AbstractSettings";

export interface GeneralSettingsDef extends AbstractSettingsDef {
    base_url_offset?: string;
    environment_message?: string;
    experiments:Collection<boolean>;
    log_config?: any;
    menu: any;
    menu_favorites: any[];
    menu_toolbox_items: any[];
    messaging_passcode?: string;
    messaging_url?: string;
    onPremise?: boolean;
    permissions: any;
    running_in_ide?: boolean;
    server_tz_id?: string;
    server_tz_offset?: number;
    support_email_address?: string;
    tenant_settings: {
        addresses: {},
        allow_switching_tenants: boolean
    };
    use_sso: boolean;
}

export class GeneralSettings extends AbstractSettings {
    constructor() {
        super("general_settings");
    }

    public static getSingleton(): GeneralSettings {
        return SettingsManager.getSingleton("general_settings") as GeneralSettings;
    }

    public static get(): GeneralSettingsDef {
        return GeneralSettings.getSingleton().values as GeneralSettingsDef;
    }

    public override get values(): GeneralSettingsDef {
        return super.values as GeneralSettingsDef;
    }

    protected getValuesInternal(): GeneralSettingsDef {
        return this._values as GeneralSettingsDef;
    }

    _doAfterSet(newValues: any, isAuthSet: boolean) {
        super._doAfterSet(newValues, isAuthSet);
        if (isAuthSet === true) {
            if (newValues != null)
                this.getValuesInternal().menu = newValues.menu?.menu;
            BroadcastMessageHandler.init();
        }
        else {
            if (newValues.log_config != null) {
                setLogConfig(newValues.log_config);
                delete this.getValuesInternal().log_config;
            }
        }
    }

    public isMessagingEnabled(): boolean {
        return this.getValuesInternal()?.messaging_url != null;
    }

    public isRunningInIDE(): boolean {
        return this.getValuesInternal()?.running_in_ide === true;
    }

    public getServiceAddress(serviceName: string): string {
        return this.values?.tenant_settings?.addresses[serviceName];
    }

    public static hasEarlyAdoptersAccess(): boolean {
        return GeneralSettings.isExperimentActiveOrUndefined("early_adopters_24_2");
    }

    public static isExperimentActiveOrUndefined(name: string): boolean {
        return StringUtil.isEmptyString(name) || (GeneralSettings.get()?.experiments?.[name.toLowerCase()] ?? true);
    }
}
