import { ProcessInfo } from "../../ProcessInfo";
import { ArrayUtil } from "../ArrayUtil";
import { AuthToken } from "../AuthToken";
import { setForwardDestination } from "../Forward";
import { InstanceIdentifier } from "../InstanceIdentifier";
import { getLogger } from "../Logger";
import { Navigation } from "../Navigation";
import { ServiceAddresses } from "../ServiceAddresses";
import { UUID } from "../UUID";
import { SettingsManager } from "../settings/SettingsManager";

const log = getLogger("core.ClientRegistration");
const _sessionIdStorageKey = "cr.session.id";
const _licenseErrorStorageKey = "cr.license.error";
const _licenseContactEmailStorageKey = "cr.license.contact_email";
const _licenseContactNameStorageKey = "cr.license.contact_name";
const _licenseContactPhoneStorageKey = "cr.license.contact_phone";

export class ClientRegistration {

    static getAuthSettingsBody() {
        return {
            id: InstanceIdentifier.get(),
            session_id: ClientRegistration._getSessionId(),
            program_display_name: new ProcessInfo().programName
        };
    }

    /**
     * Generates a UUID to serve the equivalent function of the windows session key that is generated on a windows pc
     * in the swing client.  This session id is stored in local storage so that it is effective for all of the user's tabs
     * on the current host/pc.
     * @returns a string containing the simulated session id
     */
    static _getSessionId(): string {
        let result = localStorage.getItem(_sessionIdStorageKey);
        if (result == null) {
            result = UUID.randomUUID();
            localStorage.setItem(_sessionIdStorageKey, result);
        }
        return result;
    }

    /**
     * After registering the web client, check to see if the registration call returned an error.
     * If an error was returned, the licensing check failed, and the user should be shows the 'no
     * licenses available' screen/message.
     *
     * @param response
     * @returns void
     */
    static handleLicensingResult(response): boolean {
        let error = ArrayUtil.getFirstElement(response.data);
        let userLicenseContactEmail = "";
        let userLicenseContactName = "";
        let userLicenseContactPhone = "";
        if (error != null) {
            error = error["user_license_error_text"];
            userLicenseContactEmail = error["user_license_contact_email"];
            userLicenseContactName = error["user_license_contact_name"];
            userLicenseContactPhone = error["user_license_contact_phone"];
        }
        if (error == null) {
            error = response["user_license_error_text"];
            userLicenseContactEmail = response["user_license_contact_email"];
            userLicenseContactName = response["user_license_contact_name"];
            userLicenseContactPhone = response["user_license_contact_phone"];
        }
        if (error == null) {
            SettingsManager.setSettings("general_settings", response, true);
            return true;
        }
        localStorage.setItem(ClientRegistration.getLicenseErrorStorageKey(), error);
        if (userLicenseContactEmail != null)
            localStorage.setItem(ClientRegistration.getLicenseContactEmailStorageKey(), userLicenseContactEmail);
        if (userLicenseContactName != null)
            localStorage.setItem(ClientRegistration.getLicenseContactNameStorageKey(), userLicenseContactName);
        if (userLicenseContactPhone != null)
            localStorage.setItem(ClientRegistration.getLicenseContactPhoneStorageKey(), userLicenseContactPhone);

        //if we got an error message back from the server, registration failed
        //show user a screen similar to the expired session page
        log.info("Web client registration failed, no licenses available", error);
        AuthToken.clear();
        setForwardDestination(window.location.pathname + window.location.search);
        Navigation.navigateTo("common/NoLicensesAvailable", { hardRefresh: true });
        return false;
    }

    public static addUnregisterBeforeUnloadListener() {
        window.addEventListener("beforeunload", event => {
            log.info("Notifying other sessions that this session is ending.");
            ClientRegistration.unregister(InstanceIdentifier.get());
        });
    }

    /**
     * Unregisters the web client.
     *
     * @param instanceIdentifier a string value that is the ID of the client that is specific to the browser's tab
     */
    static unregister(instanceIdentifier: string) {
        const body = { id: instanceIdentifier };
        //can't just send a message because HTTP connections can be dropped when a browser tab is closed
        navigator.sendBeacon(ServiceAddresses.ON_PREM_URL + "lib/unregister-client", JSON.stringify(body));
    }

    public static getLicenseErrorStorageKey(): string {
        return _licenseErrorStorageKey;
    }

    public static getLicenseContactEmailStorageKey(): string {
        return _licenseContactEmailStorageKey;
    }

    public static getLicenseContactNameStorageKey(): string {
        return _licenseContactNameStorageKey;
    }

    public static getLicenseContactPhoneStorageKey(): string {
        return _licenseContactPhoneStorageKey;
    }
}
