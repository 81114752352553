import { UserSettings } from "./settings/UserSettings";

export enum CompanyType {
    ASSET = "A",
    BROKER = "B",
    BOTH = "L"
}

export enum LtlType {
    ANY = "L",
    ASSET = "A",
    BROKERAGE = "B",
    NON_LTL = "N"
}

export class Company {
    public static testCompanyId(companyIds: string[]): boolean {
        const currentCompanyId = UserSettings.getSingleton().getCompanyId();
        if (currentCompanyId == null)
            return false;
        return companyIds?.includes(currentCompanyId);
    }

    public static getCompanyTypeDropdownItems(): any[] {
        return [
            { caption: "Asset", value: CompanyType.ASSET },
            { caption: "Brokerage", value: CompanyType.BROKER },
            { caption: "Both", value: CompanyType.BOTH }
        ]
    }

    public static getLtlTypeDropdownItems(): any[] {
        return [
            { caption: "Asset", value: LtlType.ASSET },
            { caption: "Brokerage", value: LtlType.BROKERAGE },
            { caption: "Both", value: LtlType.ANY },
            { caption: "Non-LTL", value: LtlType.NON_LTL }
        ]
    }
}
