import { Api, DynamicLoader, getThemeColor } from "@mcleod/core";
import { DialogProps } from "../page/Dialog";
import { ReflectiveDialogs } from "./ReflectiveDialogs";

export interface SelectedColor {
    colorName?: string;
    hexColor?: string;
    numericColor?: string;
}

export class ColorSelectorUtil {

    public static async promptForColor(dialogProps?: Partial<DialogProps>): Promise<string> {
        const cls = DynamicLoader.getClassForPath("designer/ui/ColorSelector");
        const colorSelector = new cls();
        const props = { height: 600, width: 620, caption: colorSelector.caption, ...colorSelector.dialogProps, ...dialogProps };
        const dialog = await ReflectiveDialogs.showDialog(colorSelector, props);

        if (dialog.wasCancelled)
            return null;

        return colorSelector.getValue() ?? null;
    }

    public static async promptForHexColor(dialogProps?: Partial<DialogProps>): Promise<SelectedColor> {
        const result: SelectedColor = {};
        result.colorName = await this.promptForColor(dialogProps);
        if (result.colorName != null)
            result.hexColor = getThemeColor(result.colorName);
        return result;
    }

    public static async promptForNumericColor(dialogProps?: Partial<DialogProps>): Promise<SelectedColor> {
        const result: SelectedColor = await this.promptForHexColor(dialogProps);
        if (result.hexColor != null) {
            const response = await Api.post("lme/general/color-code-conversion", {"hex_color_code": result.hexColor});
            result.numericColor = response.data[0]?.numeric_color_code;
        }
        return result;
    }
}
