import { DisplayValue, DynamicLoader, StringUtil, getLogger } from "@mcleod/core";
import { DataSource } from "../databinding/DataSource";
import { Textbox } from "../components/textbox/Textbox";

const log = getLogger("components.page.TailorExtension");

export enum AllowManualInput {
    NO = "N",
    WHEN_BLANK = "B",
    ALWAYS = "A"
}

export class TailorExtensionParam {
    private _id: string;
    private descr: string;
    private value: string; //this is the value of the param as defined in the layout (could be static or a dynamic reference, like {field_name})
    private evaluatedValue: string; //this is the evaluated value of the param (could be a value from a field, an accessible setting value, etc)
    private required: boolean;
    private allowManualInput: AllowManualInput;
    inputComponent: Textbox;

    constructor(id: string, value: string) {
        this._id = id;
        this.value = value;        
    }

    public get id(): string {
        return this._id;
    }

    createInputComponent() {
        //invoke TailorExtensionParamInputCreator via DynamicLoader to avoid circular reference
        const inputCreator = DynamicLoader.getModuleByName("components/page/TailorExtensionParamInputCreator");
        this.inputComponent = inputCreator.TailorExtensionParamInputCreator.create(this.id, this.descr, this.required, this.allowManualInput);
        this.inputComponent.text = this.evaluatedValue;
    }

    updateFromInputComponent() {
        this.evaluatedValue = this.inputComponent.text;
    }

    updateFromMetadata(paramMetadata: any) {
        this.descr = paramMetadata.descr;
        this.required = paramMetadata.required;
        this.allowManualInput = paramMetadata.allow_manual_input;
    }

    evaluateValue(dataSource: DataSource) {
        this.evaluatedValue = null;
        log.debug("Evaluating value for Tailor Extension Param %s with value: %o", this.id, this.value);
        this.evaluatedValue = DisplayValue.getFormattedDataString(this.value, dataSource?.activeRow);
        log.debug("Evaluated value: %o", this.evaluatedValue);
    }

    buildRequestData(): any {
        return {
            id: this.id,
            value: this.evaluatedValue
        };
    }

    needsManualInput(): boolean {
        const result = this.allowManualInput === AllowManualInput.ALWAYS ||
            (this.allowManualInput === AllowManualInput.WHEN_BLANK &&
                StringUtil.isEmptyString(this.evaluatedValue) === true);
        log.debug("Tailor Extension Param %s needs manual input: %o", this.id, result);
        return result;
    }

    public toJSON(): any {
        return {
            id: this.id,
            value: this.value
        };
    }
}
