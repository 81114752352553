import { ClickEvent, DataSource, DataSourceExecutionEvent, DataSourceMode } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { ShareOrder } from "./ShareOrder";
import { AutogenLayoutAdditionalDetailsFTL } from "./autogen/AutogenLayoutAdditionalDetailsFTL";

export class AdditionalDetailsFTL extends AutogenLayoutAdditionalDetailsFTL {

    private orderId: string;

    /** This is an event handler for the onClick event of buttonShare.  */
    buttonShareOnClick(event: ClickEvent) {
        ShareOrder.onClick(event, this.orderId)
    }

    /** This is an event handler for the afterExecution event of sourceCustomerOrderDetail.  */
    sourceCustomerOrderDetailAfterExecution(event: DataSourceExecutionEvent) {
        const email = event.dataSource.activeRow?.get("email_address");
        this.orderId = event.dataSource.activeRow?.get("orders.id");
        if (email != null) {
            const subject = `Question about Order ${this.orderId}`;
            this.labelOrderEmail.link = `mailto:${email}?subject=${subject}`;
        }
        else {
            this.labelOrderEmail.imageColor = "strokeSecondary";
            this.labelOrderEmail.tooltip = "No email address setup"
        }
        const row = event.dataSource.activeRow as ModelRow;
        this.populateDataSource(row.get("reference_numbers"), this.sourceOrderReferenceNumbers);
    }

    private populateDataSource(data: [], dataSource: DataSource) {
        dataSource.setRowsAndMode(DataSourceMode.NONE, data?.map(data => new ModelRow(dataSource.url, false, data)) ?? []);
    }
}
