import { makeStyles } from "@mcleod/core";

export const ImageStyles = makeStyles("img", () => {
    return {
        imageBase: {
            fill: "currentcolor",
            height: "1.5em",
            width: "1.5em",
            userSelect: "none"
        },
        rotate: {
            animation: "rotation 4s infinite linear"
        }
    }
});
