import { ArrayUtil } from "./ArrayUtil";
import { ModuleLogger, getLogger } from "./Logger";
import { UrlUtil } from "./UrlUtil";
import { WindowTitle } from "./WindowTitle";
import { GeneralSettings } from "./settings/GeneralSettings";

let log: ModuleLogger;
let currentHistIndex = (history.state && history.state.index) || 0;
const backFunctions: (() => void)[] = [];
const originalGetState = Object.getOwnPropertyDescriptor(History.prototype, "state").get;
const originalPushState = history.pushState;
const originalReplaceState = history.replaceState;

/** This is called after the Navigation is initialized (at the end of this module) */
function initHistory() {
    if (!history.state || !("index" in history.state))
        history.replaceState({ index: currentHistIndex, state: history.state }, WindowTitle.get());
    history.pushState = createModifiedStateFunction(originalPushState, 1);
    history.replaceState = createModifiedStateFunction(originalReplaceState, 0);
    window.addEventListener("popstate", Navigation.onPopstate);
    window.addEventListener("beforeunload", Navigation.beforeUnloadHandler);
}

function getLog() {
    if (log == null)
        log = getLogger("core/Navigation");
    return log;
}

export interface NavigationListener {
    onNavigate: (event: NavigationEvent) => void,
    persistAcrossPages?: boolean
}

export interface NavigationHandler {
    displayRoute: (path: string, props: any) => void;
    getRoute(): string;
}

export interface PseudoNavigateState {
    slideoutComponent: any;
}

export class Navigation {
    private static listeners: NavigationListener[] = [];
    private static navigationHandler: NavigationHandler;

    public static addNavigationListener(listener: NavigationListener) {
        Navigation.listeners.push(listener);
    }

    public static removeNavigationListener(listener: NavigationListener) {
        ArrayUtil.removeFromArray(Navigation.listeners, listener);
    }

    public static removeAllNavigationListeners() {
        Navigation.listeners = Navigation.listeners.filter(listener => listener.persistAcrossPages === true);
    }

    static listenersPreventHardNavigation(navOptions?: Partial<NavOptions>, cancelable?: boolean): boolean {
        if (navOptions?.newTab === true)
            return false;
        const event = new NavigationEvent(cancelable);
        event.navOptions = navOptions;
        for (const listener of Navigation.listeners) {
            listener.onNavigate(event);
            if (event.defaultPrevented)
                return true;
        }
        return false;
    }


    static async listenersPreventNavigation(navOptions?: Partial<NavOptions>, cancelable?: boolean): Promise<boolean> {
        if (navOptions?.newTab === true)
            return false;
        const event = new NavigationEvent(cancelable);
        event.navOptions = navOptions;
        for (const listener of Navigation.listeners) {
            await listener.onNavigate(event);
            if (event.defaultPrevented)
                return true;
        }
        return false;
    }

    static setNavigationHandler(value: NavigationHandler) {
        Navigation.navigationHandler = value;
    }

    /**
     * This will change the content that is displayed in the main body of the page.  In most cases, it just causes the
     * main page's {@link Router} to display another Layout but navOptions can be used to specify how the path is displayed. *
     *
     * @param {string} path The URL suffix of the path that you wish to navigate to
     * @param {Object} navOptions Options used for controlling the navigation.
     * @param {bool} navOptions.hardRefresh Specifies that the browser should refresh the whole page instead of just letting the {@link Router} render the page
     * @param {bool} navOptions.newTab Specifies that you wish to navigate to the new page in a new tab
     * @param {bool} navOptions.resizable Specifies that you wish the new tab to not be resizable (requires newTab to be true)
     * @param {bool} navOptions.windowDecorators Specifies that you wish the new tab to not have the standard browser decorators (toolbar, menu bar, and status bar)  (requires newTab to be true)
     * @param {bool} navOptions.width Specifies the width of the new tab (requires newTab to be true)
     * @param {bool} navOptions.height Specifies the height of the new tab (requires newTab to be true)
     * @param {bool} navOptions.left Specifies the left position of the new tab (requires newTab to be true)
     * @param {bool} navOptions.top Specifies the top position of the new tab (requires newTab to be true)
     * @param {Object} props Props that you wish to pass to the new page
     */
    static async navigateTo(path: string, navOptions?: Partial<NavOptions>, props?: any): Promise<Window> {
        if (await Navigation.listenersPreventNavigation(navOptions, true) === true)
            return;
        Navigation.removeAllNavigationListeners();
        const prefix = Navigation.getUrlPrefix(path);
        if (Navigation.navigationHandler == null)
            getLog().info("Attempted to navigate to " + path + " but a navigation handler has not been set.");
        else if (navOptions?.newTab === true) {
            let options = "";
            if (navOptions.resizable === false)
                options += "resizable=no,";
            if (navOptions.windowDecorators === false)
                options += "toolbar=no,menubar=no,status=no,";
            if (navOptions.width != null)
                options += "width=" + navOptions.width + ",";
            if (navOptions.height != null)
                options += "height=" + navOptions.height + ",";
            if (navOptions.left != null)
                options += "left=" + navOptions.left + "px,";
            if (navOptions.top != null)
                options += "top=" + navOptions.top + "px,";
            if (options.endsWith(","))
                options = options.substring(0, options.length - 1);
            const title = navOptions?.title == null ? "_blank" : navOptions?.title;
            const origin = UrlUtil.isAbsoluteUrl(path) ? "" : window.location.origin;
            return window.open(origin + prefix + path, title, options);
        }
        else if (navOptions?.hardRefresh === true) {
            if (UrlUtil.isAbsoluteUrl(path))
                window.location.replace(prefix + path);
            else
                window.location.pathname = prefix + path;
        }
        else {
            window.history.pushState(props, "McLeod", prefix + path);
            Navigation.navigationHandler.displayRoute(prefix + path, props);
        }
        return window;
    }

    private static getUrlPrefix(path: string): string {
        if (UrlUtil.isAbsoluteUrl(path))
            return "";
        let prefix = "";
        const base = GeneralSettings.get().base_url_offset;
        if (base == null) {
            if (!path.startsWith("/"))
                prefix += "/";
        } else {
            if (!base.startsWith("/"))
                prefix += "/";
            prefix += base;
            if (!path.startsWith("/"))
                prefix += "/";
        }
        return prefix;
    }

    /**
     * This function will update the browser's URL without actually navigating.  The calling application is responsible for doing whatever action is
     * necessary to make it appear that the browser has navigated (sliding in a panel, for example).  It is also up to the calling application
     * to provide a function to execute when the user goes back (close the slide-out panel, for example).
     *
     * @param path
     * @param backFunction
     * @param state
     */
    static async pseudoNavigateTo(path: string, backFunction: () => void, state?: any): Promise<boolean> {
        if (await Navigation.listenersPreventNavigation({}, true) === true)
            return false;
        const prefix = Navigation.getUrlPrefix(path);
        history.pushState({ ...state, backFunction: backFunction }, "McLeod", prefix + path);
        return true;
    }

    static async navigateBack(): Promise<boolean> {
        if (await Navigation.listenersPreventNavigation({}, true) === true)
            return false;
        window.history.back();
        return true;
    }

    static async reloadCurrentPage(reloadAuthSettings: boolean = false): Promise<boolean> {
        if (await Navigation.listenersPreventNavigation({}, true) === true)
            return false;
        if (reloadAuthSettings)
            GeneralSettings.getSingleton().clearAuthSettings();
        const props = UrlUtil.getPropsFromUrl(window.location.search);
        Navigation.navigationHandler.displayRoute(window.location.pathname, props);
        return true;
    }

    private static async handleNav(backFunction: () => void): Promise<boolean> {
        if (Navigation.navigationHandler == null)
            return true;
        if (await Navigation.listenersPreventNavigation(null, true) === true) {
            const route = Navigation.navigationHandler.getRoute();
            if (route != null)
                window.history.pushState(null, '', "/" + route); // the state has already been popped - need to push it back
            return false;
        }
        if (backFunction == null) {
            if (document.location.pathname != null && Navigation.navigationHandler != null)
                Navigation.navigationHandler.displayRoute(document.location.pathname, UrlUtil.getPropsFromUrl(document.location.search));
        } else
            backFunction();
        return true;
    }

    static async onPopstate(event: PopStateEvent) {
        const state = originalGetState.call(history);
        if (state == null)
            originalReplaceState.call(history, { index: currentHistIndex + 1 }, WindowTitle.get());
        const index = state == null ? currentHistIndex + 1 : state.index;
        const isBack = index <= currentHistIndex;
        getLog().debug("onPopState", isBack, state, event);
        const backFunction = isBack ? backFunctions[currentHistIndex] : null;
        if (await Navigation.handleNav(backFunction) === false)
            event.preventDefault();
        else {
            delete backFunctions[currentHistIndex]; // we can't assume that we can re-execute the back function again, so delete it once we have popped that state
            currentHistIndex = index;
        }
    }

    static beforeUnloadHandler(event: BeforeUnloadEvent) {
        if (Navigation.listenersPreventHardNavigation({ hardRefresh: true }, true) === true) {
            event.preventDefault();
            event.returnValue = "";
        }
    }
}

/**
 * This is a method to inject how we want to handle page navigation.
 *
 * Since code at a @mcleod/core and @mcleod/component level needs to navigate to pages, but the actual page navigation is
 * handled by the Router class in @mcleod/common, we use this pattern to allow core code to say that it wants to navigate.
*/
export class NavigationEvent extends Event {
    navOptions: Partial<NavOptions>;

    constructor(cancelable: boolean) {
        super(null, { cancelable: cancelable })
    }
}

export interface NavOptions {
    hardRefresh: boolean,
    newTab: boolean,
    resizable: boolean,
    windowDecorators: boolean,
    width: number,
    height: number,
    left: number,
    top: number,
    title: string;
}

function createModifiedStateFunction(originalStateFunction, increment: number) {
    return (state: any, ...args) => {
        let backFunction: () => void;
        if (state != null && "backFunction" in state) {
            backFunction = state.backFunction;
            delete state.backFunction; // can't pass a function to the basic history pushState function because state has to be serializable
        }
        const histIndex = currentHistIndex + increment;
        if (backFunction != null) {
            getLog().debug("Adding back function", backFunction, backFunctions, histIndex);
            backFunctions[histIndex] = backFunction; // put the backFunction in memory instead of trying to serialize it
        }
        originalStateFunction.call(history, { index: histIndex, state: state }, ...args);
        currentHistIndex += increment;
    };
}

initHistory();
