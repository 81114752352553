import { DataSourceMode, Snackbar, Toast } from "@mcleod/components";
import { Api, getLogger, Navigation, ServerError, StringUtil, UrlUtil } from "@mcleod/core";
import { ErrorHandlerFunction } from "@mcleod/core/src/ErrorHandler";
import { AutogenLayoutCounter } from "./autogen/AutogenLayoutCounter";

const log = getLogger("portal-carrier/Counter");

export class Counter extends AutogenLayoutCounter {

    override async onLoad() {
        const props = UrlUtil.getPropsFromUrl(document.location.search);
        if (!StringUtil.isEmptyString(props.token)) {
            this.mainDataSource.search({ type: "pending", token: props.token }, this._errorHandler).then(response => {
                if (response?.modelRows?.length > 0) {
                    this.panelAvailable.visible = true;
                }
                else {
                    this.panelNotAvailable.visible = true;
                    this.panelAvailable.visible = false;
                }
            });
        } else {
            this.panelNotAvailable.visible = true;
            this.panelAvailable.visible = false;
        }

        this.mainDataSource.mode = DataSourceMode.NONE;
    }

    confirmCounter() {
        const counterAmount = this.activeRow.getCurrency("counter_amount").getAmount();
        const currencyCode = this.activeRow.getCurrency("rate_currency")?.getCurrencyCode();
        if (this.textboxCounterAmount.validateSimple(true, true) && counterAmount > 0) {
            const props = UrlUtil.getPropsFromUrl(document.location.search);
            if (!StringUtil.isEmptyString(props.token)) {
                Api.post("portal/carrier/carrier-counter-offer", {
                    token: props.token,
                    counter_amount: counterAmount,
                    currency_code: currencyCode
                }).then(response => {
                    if (!StringUtil.isEmptyString(response.data[0].success)) {
                        Toast.showSuccessToast(response.data[0].success);
                        this.panelAvailable.visible = false;
                        this.panelSuccess.visible = true;
                    }
                }).catch((err: ServerError) => {
                    log.error(err.messages.join(", "));
                    Snackbar.showWarningSnackbar(err.messages[0]);
                });
            }
        } else {
            Snackbar.showWarningSnackbar("Counter amount must be a valid amount greater than 0.");
        }
    }

    _errorHandler: ErrorHandlerFunction = (error) => {
        Navigation.navigateTo("");
    }

}
