import { Component } from "@mcleod/components";
import { AbstractUIDesigner } from "../AbstractUIDesigner";
import { DesignerAction } from "./DesignerAction";
import { DesignerActionResult } from "./DesignerActionResult";
import { getPropDisplayValue } from "../UIDesignerUtil";

export class ActionChangeComponentProperty implements DesignerAction {
    private _component: Component;
    private propName: string;
    private oldValue: any; // No longer optional so we can keep track of modified components
    private newValue: any;
    private designer: AbstractUIDesigner;
    private redisplayProp = false;

    constructor(component: Component, propName: string, newValue: any, oldValue: any, redisplayProp: boolean = false) {
        this._component = component;
        this.propName = propName;
        this.newValue = newValue;
        this.oldValue = oldValue;
        this.redisplayProp = redisplayProp
    }

    public get component(): Component {
        return this._component;
    }

    execute(): DesignerActionResult {
        if (this.oldValue == this.newValue)
            return { success: false };
        return this.updateProp(this.oldValue, this.newValue);
    }


    undo() {
        this.updateProp(this.newValue, this.oldValue);
    }

    updateProp(oldVal: any, newVal: any): DesignerActionResult {
        this.designer.doBeforePropChanged(this.component, this.propName);
        this.component[this.propName] = newVal;
        this.designer.doAfterPropChanged(this.component, this.propName, oldVal, newVal, this.redisplayProp);
        this.redisplayProp = true;
        if ("id" === this.propName)
            this.designer.getActiveTab().validateComponentId(this.component);
        return { success: true };
    }

    toString() {
        const compText = this.component.id ? this.component.id : this.component.constructor.name.toLocaleLowerCase();
        const oldVal = getPropDisplayValue(this.designer, this.component, this.propName, this.oldValue);
        const newVal = getPropDisplayValue(this.designer, this.component, this.propName, this.newValue);
        return `Change ${compText} ${this.propName} property from '${oldVal}' to '${newVal}'`
    }
}
