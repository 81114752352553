import { Button, ButtonVariant, DataDisplayEvent, Label, TableRowDisplayEvent, TableRowExpansionEvent } from "@mcleod/components";
import { DateUtil, ModelRow, ModelSearchResult, Navigation, StringUtil } from "@mcleod/core";
import { CommonDialogs } from "../CommonDialogs";
import { RequestDetail } from "./RequestDetail";
import { AutogenLayoutRequestList } from "./autogen/AutogenLayoutRequestList";

export class RequestList extends AutogenLayoutRequestList {
    logFileName: string;
    logFileContents: string;
    requestWithError: string;
    highlightText: string;

    onLoad() {
        this.tableRequestList.addTool(new Button({
            imageName: "detail",
            color: "primary",
            variant: ButtonVariant.round,
            onClick: () => this.popout()
        }));
        if (window["passedLogReaderData"] != null)
            this.displayRows(window["passedLogReaderData"]);
    }

    public set filter(value: any) {
        this.addLayoutLoadListener(() => {
            this.tableRequestList.busy = true;
            this.sourceLogReader.search({ ...value, log_name: this.logFileName, log_contents: this.logFileContents, detail_level: "request" }).then((response: ModelSearchResult) => {
                this.tableRequestList.busy = false;
                this.requestData = response.modelRows[0].get("detail");
            }).catch(error => {
                this.tableRequestList.busy = false;
                CommonDialogs.showError(error);
            });
        });
    }

    set requestData(value: any) {
        const rows: ModelRow[] = [];
        for (const item of value)
            rows.push(new ModelRow("", true, item));
        this.displayRows(rows);
        if (this.requestWithError != null) {
            for (const row of this.tableRequestList.rows) {
                if (row.data.get("name") === this.requestWithError) {
                    // I'm not sure why a timeout is needed here, but without it, scrollIntoView() doesn't work the 
                    // first time we search on this page.  It works without the timeout after that.  It's also strange
                    // that both the timeout and the direct call are needed.  It might have to do with Table virtualization.
                    // I assume there is a listener that I could add to make it so that only one call is needed.  
                    // If you're reading this and know how to do that, please fix it.  Thanks!
                    setTimeout(() => row._element.scrollIntoView({behavior: "smooth", block: "center"}), 100);
                    row._element.scrollIntoView({behavior: "smooth", block: "center"});
                    break;
                }

            }
        }
    }

    private displayRows(rows: ModelRow[]) {
        this.sourceLogReader.data = rows;
        this.tableRequestList.displayData(rows[0], rows, 0);
    }

    async popout() {
        const screen = window.screen as any;
        const left = screen.availLeft + (screen.availWidth / 2) - 500;
        const top = screen.availTop + (screen.availHeight / 2) - 350;
        const openedWindow = await Navigation.navigateTo("common/logreader/RequestList", { newTab: true, left, top, height: 700, width: 1000, windowDecorators: false });
        openedWindow["passedLogReaderData"] = this.sourceLogReader.data;
    }

    /** This is an event handler for the onDataDisplay event of labelEndpoint.  */
    labelEndpointOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        label.caption = StringUtil.stringAfter(label.caption, "/api/");
    }

    /** This is an event handler for the onRowExpand event of table.  */
    tableOnRowExpand(event: TableRowExpansionEvent) {
        const detail = event.expandComponents[0] as RequestDetail;
        detail.maxDetailHeight = 400;
        const data: ModelRow = event.getTableRow().data;
        detail.highlightText = this.highlightText;
        if (!StringUtil.isEmptyString(data.get("name"))) {
            detail.logFileName = this.logFileName;
            detail.logFileContents = this.logFileContents;
            detail.searchRequest(data.get("name"), data.get("requestStart"));
        }
    }

    /** This is an event handler for the onRowDisplay event of table.  */
    tableOnRowDisplay(event: TableRowDisplayEvent) {
        const rowData = event.getTableRow().data;
        if (this.requestWithError != null && rowData.get("name") === this.requestWithError)
            event.getTableRow().expanded = true;
    }

    /** This is an event handler for the onDataDisplay event of labelRequestTime.  */
    labelRequestStartTimeOnDataDisplay(event: DataDisplayEvent) {
        const millis = event.rowData.get("requestStart");
        if (millis > 0)
            (event.target as Label).caption = DateUtil.formatDateTime(new Date(millis), "MM-dd HH:mm:ss.SSS");
    }

    public removeDBStatColumns() {
        this.tableRequestList.removeColumn(6);
        this.tableRequestList.removeColumn(5);
    }
}
