import { AutogenLayoutTenant } from "./autogen/AutogenLayoutTenant";
import {  ClickEvent } from "@mcleod/components";

export class Tenant extends AutogenLayoutTenant {

  /** This is an event handler for the onClick event of buttonInviteUser.  */
  buttonInviteUserOnClick(event: ClickEvent) {

  }
}
