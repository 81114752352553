import { Component } from "./Component";
import { DesignableObjectPropDefinition } from "./DesignableObjectProps";

export interface McLeodTailorComponentsProps {
    baseComponent?: boolean;
    customComponent?: boolean;
}

export class McLeodTailorPropertyEvaluator {
    /**
     * Determines if a component proerty should be visible in the current designer.
     * When not specified, the default is 'false'.
     * If a McLeodTailorComponentsProps object is supplied as the value for the 'editableInMcLeodTailor' attribute,
     * any missing value is also assumed to be 'false'.
     * @param props
     * @param component
     * @returns a boolean indicating if the property should be visible in the current designer
     */
    public static isVisible(props: DesignableObjectPropDefinition, component: Component): boolean {
        if (props?.visibleInMcLeodTailor == null)
            return false;
        if (typeof props.visibleInMcLeodTailor === "boolean")
            return props.visibleInMcLeodTailor;
        if (typeof props.visibleInMcLeodTailor === "function")
            return props.visibleInMcLeodTailor();
        if (component.isCustom !== true)
            return props.visibleInMcLeodTailor.baseComponent === true;
        return props.visibleInMcLeodTailor.customComponent === true;
    }

    /**
     * Determines if a component proerty should be editable in the current designer.
     * When not specified, the default is 'true' (meaning any visible property is editable).
     * If a McLeodTailorComponentsProps object is supplied as the value for the 'editableInMcLeodTailor' attribute,
     * any missing value is also assumed to be 'true'.
     * For example this object would result in the property being editable for both base and custom components:
     * { baseComponent: true }
     * @param props
     * @param component
     * @returns a boolean indicating if the property should be editable in the current designer
     */
    public static isEditable(props: DesignableObjectPropDefinition, component: Component): boolean {
        if (props?.editableInMcLeodTailor == null)
            return true;
        if (typeof props.editableInMcLeodTailor === "boolean")
            return props.editableInMcLeodTailor;
        if (component.isCustom !== true)
            return props.editableInMcLeodTailor.baseComponent !== false;
        return props.editableInMcLeodTailor.customComponent !== false;
    }
}
