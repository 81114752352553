import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenLayoutEndpoint = "layout";

export class AutogenModelLayout<RowType extends ModelRow> extends Endpoint<RowType, RowLayoutFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenLayoutEndpoint, ctor);
    }
}

export class AutogenRowLayout extends ModelRow<RowLayoutFields> {
    constructor() {
        super(AutogenLayoutEndpoint);
    }
}

export interface RowLayoutFields {
    apply_field_level_company_type: boolean;
    apply_field_level_licensing: boolean;
    apply_field_level_ltl_type: boolean;
    apply_field_level_perms: boolean;
    apply_field_level_experiment: boolean;
    apply_field_level_multi_currency: boolean;
    removed_by_server: boolean;
    definition: string;
    id: string;
    require_base_version: boolean;
    path: string;
    response: string;
}
