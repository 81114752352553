import { DataSource, HorizontalSpacer, Label, Layout, Panel, SaveButton, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutSendUserInvitation extends Layout {
    buttonSendInvite: SaveButton;
    horizontalspacer1: HorizontalSpacer;
    labelLabel1: Label;
    panelButtons: Panel;
    panelContent: Panel;
    sourceUserInvite: DataSource;
    textboxEmailAddress: Textbox;
    textboxUserId: Textbox;
}
