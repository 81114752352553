import {
    ApiMetadata, ArrayUtil, Collection, Company, ErrorHandler, FieldUpdateEvent, GeneralSettings, getApiMetadata,
    getApiMetadataFromCache, getLogger, handleError, LogicResponsibilityFiltering, MessageSubscriber, Model, ModelRow,
    ModelSearchResult, ObjectUtil, OrderByInfo, RowConstructor, StringUtil
} from "@mcleod/core";
import { DataDisplayEvent, DataDisplayListener, EventListener, McLeodMainPageUtil, Table } from "..";
import { Component } from "../base/Component";
import { ComponentTypes } from "../base/ComponentTypes";
import { DesignableObject } from "../base/DesignableObject";
import { DesignableObjectPropDefinition, DesignableObjectPropsCollection } from "../base/DesignableObjectProps";
import { ListenerListDef } from "../base/ListenerListDef";
import { ValidationResult } from "../base/ValidationResult";
import { Layout } from "../components/layout/Layout";
import { DataSourceExecutionEvent, DataSourceExecutionListener } from "../events/DataSourceExecutionEvent";
import { DataSourceModeChangeEvent, DataSourceModeChangeListener } from "../events/DataSourceModeChangeEvent";
import {
    DataSourceRemoteDataChangeEvent, DataSourceRemoteDataChangeListener
} from "../events/DataSourceRemoteDataChangeEvent";
import {
    DataSourceSubscriberErrorEvent, DataSourceSubscriberErrorListener
} from "../events/DataSourceSubscriberErrorEvent";
import { DataSourceValidationListener } from "../events/DataSourceValidationEvent";
import { ComponentCreationCallback } from "../serializer/ComponentDeserializer";
import { AllowedToSearch } from "./AllowedToSearch";
import { DataSourcePropDefinitions, DataSourceProps, ParentSearchMode } from "./DataSourceProps";
import { BoundComponentValidator } from "./BoundComponentValidator";
import { MountUtil } from "../base/MountListener";

const log = getLogger("components.databinding.DataSource");
const _beforeModeChangeListenerDef: ListenerListDef = { listName: "_beforeModeChangeListeners" };
const _afterModeChangeListenerDef: ListenerListDef = { listName: "_afterModeChangeListeners" };
const _beforeExecutionListenerDef: ListenerListDef = { listName: "_beforeExecutionListeners" };
const _afterExecutionListenerDef: ListenerListDef = { listName: "_afterExecutionListeners" };
const _afterSearchPlusChildrenListenerDef: ListenerListDef = { listName: "_afterSearchPlusChildrenListeners" };
const _displayListenerDef: ListenerListDef = { listName: "_displayListeners" };
const _remoteDataChangeListenerDef: ListenerListDef = { listName: "_remoteDataChangeListeners" };
const _subscriberErrorListenerDef: ListenerListDef = { listName: "_subscriberErrorListeners" };
const _validationListenerDef: ListenerListDef = { listName: "_validationListeners" };

export enum DataSourceMode {
    NONE = "none",
    SEARCH = "search",
    ADD = "add",
    UPDATE = "update"
}

export enum DataSourceAction {
    SEARCH = "search",
    ADD = "add",
    UPDATE = "update",
    DELETE = "delete"
}

export enum ModelDataChangeType {
    ADD = "ADD",
    UPDATE = "UPDATE",
    DELETE = "DELETE"
}

export enum ModelDataChangeFilterType {
    COMPANY = "COMPANY",
    RESPONSIBILITY = "RESPONSIBILITY"
}

export interface ModelDataChange {
    type: ModelDataChangeType;
    data: ModelRow;
    filters?: ModelDataChangeFilter[];
}

export interface ModelDataChangeFilter {
    type?: ModelDataChangeFilterType;
    values?: any[];
}

/**
 * The DataSource class is response for binding server-side data models to client-side UI Components.
 * It supports CRUD operations against that data model using its various properties and methods.
 *
 * DataSource specifies a url of the data model that it will operate on.
 *
 * Components specify a DataSource to which they will be bound and will typically specify a single field
 * from the DataSource's model that the Component will be responsible for.  Some Components, such as Attachment
 * and CityState, are bound to multiple fields.  Other Components, such as Table, may be bound to multiple records
 * from the model. *
 *
 * DataSource supports CRUD operations against its data models with its different modes.  Typically, the DataSource is
 * placed into one of these modes and then the execute() method is called to make a server-side API call to perform
 * the CRUD operation associated with the mode.
 *
 * DataSourceMode.ADD - Data entered into the bound Components will be used to populate a new record that will be inserted into the model.
 * DataSourceMode.SEARCH - Data entered in the bound Components will be used to construct a filter against the model.
 * DataSourceMode.NONE - After record(s) are searched or added, the DataSource's mode will be NONE.  The bound Components display the data from the model in this mode.
 * DataSourceMode.UPDATE - when a record is displayed, the DataSource can enter updated mode.  In this mode, data entered into bound Components will update the record being displayed
 */
export class DataSource<RowType extends ModelRow = ModelRow> extends DesignableObject implements DataSourceProps {
    private _actionInProgress: DataSourceAction;
    private _boundComponents: Component[];
    private _busyComponents: Component[];
    private _dataDisplayHandlerComponents: Component[];
    private _data: RowType[];
    private _deletedData: RowType[];
    private _originalRowCount: number;
    private _boundSearchComponents: Component[];
    private _hasChangedComponents: Component[];
    private _subscribeToChanges: boolean;
    private _searchRow: RowType;
    private _designer: any;
    private _designerTab: any;
    private _maxResults: number;
    private _url: string;
    private metadata: ApiMetadata;
    private _mode: DataSourceMode = DataSourceMode.NONE;
    private _rowIndex: number;
    lastSearch: any;
    private _autoSearch: boolean = false;
    public layoutName: string;
    private _explicitOrderBy: OrderByInfo[];
    private _searchOrderBy: OrderByInfo[];
    actualRowCount: number;
    summaryData: ModelRow;
    private componentsByField: Collection<Component[]>;
    private _parentDataSource: DataSource;
    private _childDataSources: DataSource[];
    parentSearchMode: ParentSearchMode;
    private _boundComponentObserver: IntersectionObserver;
    private _preventChangeNotifications: boolean = false;
    private subscriber: MessageSubscriber;
    private _trackingLayout: Layout;
    private _topLevelDataSource: DataSource;
    private _dataSourcesSearching: DataSource[];
    private _searchesComplete: boolean;
    private _componentValidationFailedCallback: (result: ValidationResult) => void;
    private _preventFocusFirstField: boolean;
    private _afterGetDataboundValues: (row: ModelRow, dataSource: DataSource) => void;
    private _doBeforeDataSourcePost: () => Promise<boolean>;
    private _componentValidator: BoundComponentValidator;
    public onTransformSearchResults: (results: ModelSearchResult) => ModelSearchResult
    private ownerUnmountListener: () => void = null;

    constructor(props?: Partial<DataSourceProps>, owner?: Layout, designer?: any, designerTab?: any) {
        super();
        this.owner = owner;
        this._designerTab = designerTab;
        //populate ID first to mimic Component logic
        if (props?.id != null)
            this.id = props.id;
        this.setDesigner(designer);
        this.setProps(props);
        this._boundComponents = [];
        this._boundSearchComponents = [];
        this.data = [];
        this._rowIndex = -1;
        this.mode = DataSourceMode.NONE;
        this._componentValidator = new BoundComponentValidator(this, _validationListenerDef);
    }

    set id(value: string) {
        if (this._designerTab != null)
            this._designerTab.dataSourceIdUpdated(this, this._id, value);
        this._id = value;
    }

    get id(): string {
        return this._id;
    }

    get rowCount(): number {
        return this.data != null ? this.data.length : 0;
    }

    get data(): RowType[] {
        return this._data;
    }

    set data(value: RowType[]) {
        if (this._data != null)
            log.debug(() => ["set data - _data length", this._data.length, "value length", value.length, this]);
        this._data = value;
        this.updateOriginalRowCount();
        if (value != null)
            for (const row of value) {
                this._addFieldUpdateListenerToRow(row);
            }
    }

    getDataCopy(): ModelRow[] {
        const result: ModelRow[] = [];
        for (const row of this.data) {
            result.push(row.createBasicCopy());
        }
        return result;
    }

    private _addFieldUpdateListenerToRow(row: ModelRow) {
        if (row["_dsListener"] == null) { // keep a reference in each row to the listener to prevent adding multiple listeners if the same row is used somehow
            row["_dsListener"] = (event: FieldUpdateEvent) => this.fieldUpdated(event);
            row.addAfterFieldUpdateListener(row["_dsListener"]);
        }
    }

    /**
     * Dynamically add a ModelRow to the existing data array in this DataSource.
     */
    public addRow(row: RowType, index?: number, redisplay?: boolean): void {
        if (row == null) { //sometimes the row is null if we just tried to post an update to a row that had no changes; ModelRow.post() returns null in that case
            log.debug("Not adding row to DataSource %o; row is null", this.id);
            return;
        }
        if (index == null || index > this.data.length)
            index = this.data.length;
        if (index < 0)
            index = 0;
        this.data.splice(index, 1, row);
        this._addFieldUpdateListenerToRow(row);
        // this.addDataToMultiRowBoundComponents(row, index);
        if (redisplay !== false)
            this.redisplayRow(index, row, ModelDataChangeType.ADD);
    }

    // /**
    //  * Since bound components that can display multiple rows (Tables) might keep their
    //  * own data arrays, we need to notify them that a new row had been added so they can update
    //  * those arrays.
    //  *
    //  * Side note: it might be nice if those components didn't maintain their own arrays
    //  *
    //  * @param row
    //  * @param index
    //  */
    // private addDataToMultiRowBoundComponents(row: RowType, index: number) {
    //   for (const component of this._boundComponents) {
    //     if ("redisplaySingleRow" in component)
    //       component["redisplaySingleRow"](index);
    //     else if (index === this.rowIndex)
    //       this.displayDataInBoundComponent(component);
    //   }
    // }

    /**
     * Replaces a single row in the DataSource and updates the UI for that row.  If
     * the row is the DataSource's active row, all the bound components will redisplay the updated row.
     * If the row is not the DataSource's active row, only Components that are capable of displaying
     * multiple records (e.g. Tables) will redisplay data.
     *
     * @param row either the index of the row to be replaced or the ModelRow itself.  If a ModelRow
     * is passed, this method will locate the ModelRow within its data.  If that row doesn't exist in the data,
     * this method will throw an error.  Currently, if a ModelRow is passed, it must be the same object that
     * is in the DataSource's data (i.e. it won't try to match rows' primary keys or anything like that).
     * @param replaceWith The new ModelRow that will be contained in the DataSource's data.
     */
    public replaceRow(row: number | RowType, replaceWith: RowType): void {
        if (row instanceof ModelRow) {
            row = this.data.indexOf(row);
            if (row < 0) {
                const message = "The specified row could not be found in the DataSource's data.";
                log.debug(() => [message, row, this]); // print a log message that includes the values from the row we couldn't find
                throw new Error(message);
            }
        }
        this.data[row] = replaceWith;
        this.redisplayRow(row, this.data[row], ModelDataChangeType.UPDATE);
    }

    /**
     * This causes the DataSource to redisplay a row from its data array.  If
     * the row is the DataSource's active row, all the bound components will redisplay the row.
     * If the row is not the DataSource's active row, only Components that are capable of displaying
     * multiple records (e.g. Tables) will redisplay data.
     * @param index The index of the element in the data array to redisplay
     */
    public redisplayRow(index: number, row: RowType, changeType: ModelDataChangeType): void {
        for (const component of this._boundComponents) {
            if (changeType === ModelDataChangeType.DELETE && component instanceof Table)
                component.removeRow(index);
            else if (component instanceof Table)
                component.redisplaySingleRow(index, row, changeType === ModelDataChangeType.ADD, false);
            else if (index === this.rowIndex)
                this.displayDataInBoundComponent(component);
        }
    }

    updateOriginalRowCount() {
        if (this._data == null)
            return;
        this._originalRowCount = this._data.length;
    }

    private _rowCountChanged(): boolean {
        return this._originalRowCount != null && this._originalRowCount !== this._data.length;
    }

    fieldUpdated(event: FieldUpdateEvent) {
        const eventRowChanged = event.row.hasChanged();
        this.notifyHasChangedComponents(eventRowChanged);
        this._rebuildComponentsByField();
        const components = this.componentsByField[event.fieldName];
        if (components != null)
            for (const comp of components)
                if (comp != event.originator)
                    this.displayDataInBoundComponent(comp);
        if (this._dataDisplayHandlerComponents != null) {
            for (const dataDisplayHandler of this._dataDisplayHandlerComponents) {
                dataDisplayHandler["handleDataDisplay"](event, this.data[this.rowIndex], this.data, this.rowIndex);
            }
        }
    }

    addHasChangedComponent(component: Component) {
        if (this._hasChangedComponents == null)
            this._hasChangedComponents = [];
        if (this._hasChangedComponents.includes(component) === false)
            this._hasChangedComponents.push(component);
    }

    removeHasChangedComponent(component: Component) {
        if (this._hasChangedComponents == null)
            return;
        ArrayUtil.removeFromArray(this._hasChangedComponents, component);
        if (this._hasChangedComponents.length === 0)
            this._hasChangedComponents = null;
    }

    /**
     * Notify components that are interested in whether or not the data in this DataSource (or any of its child DataSources) has changed.
     * Typically these components will be things like the Save button.
     *
     * Calling this method for a child DataSource results in the method also be called for parent DataSources.  This allows a child
     * DataSource that is changed to notify a component (like a Save button) that is tied to the parent DataSource.  However, when the
     * parent evaluates this method, it will test its own data (including any of its child DataSources, including the child that originated the action).
     *
     * @param overrideValue When true, indicates that the calling function already knows the data has changed.  Providing 'false' for this param has no real effect.
     * @returns void
     */
    notifyHasChangedComponents(overrideValue: boolean = false) {
        if (this.preventChangeNotifications)
            return;
        log.debug(() => ["DataSource", this.id, "either a row or child row has changed = ", this.hasChanged(true), this]);
        if (this._hasChangedComponents != null) {
            const dataSourceChanged = overrideValue === true || this.hasChanged(true);
            for (const component of this._hasChangedComponents) {
                if (component.doOnDataSourceChanged != null)
                    component.doOnDataSourceChanged(this, dataSourceChanged);
            }
        }
        this.parentDataSource?.notifyHasChangedComponents(overrideValue);
    }

    override get owner(): Layout {
        return super.owner;
    }

    override set owner(value: Layout) {
        super.owner = value;
        this.syncOwnerUnmountListener();
    }

    get subscribeToChanges(): boolean {
        return this._subscribeToChanges;
    }

    set subscribeToChanges(value: boolean) {
        this._subscribeToChanges = value;
        if (value !== true)
            this.unsubscribeFromChanges();
        this.syncOwnerUnmountListener();
    }

    private syncOwnerUnmountListener() {
        if (this.owner == null || !(this.owner instanceof Layout))
            return;
        if (this._subscribeToChanges !== true && this.ownerUnmountListener != null) {
            MountUtil.removeUnmountListener(this.owner._element, this.ownerUnmountListener);
            this.ownerUnmountListener = null;
        }
        if (this._subscribeToChanges === true && this.ownerUnmountListener == null) {
            this.ownerUnmountListener = () => this.unsubscribeFromChanges();
            MountUtil.addUnmountListener(this.owner._element, this.ownerUnmountListener);
        }
    }

    get autoSearch() {
        return this._autoSearch;
    }

    set autoSearch(value: boolean) {
        const oldAutoSearch = this._autoSearch;
        this._autoSearch = value;
        if (this._designer == null && oldAutoSearch !== this._autoSearch) {
            this.executeAutoSearch();
        }
    }

    private calcNameFromUrl(value: string): string {
        const path = StringUtil.stringBefore(value, "?");
        const lastPath = StringUtil.stringAfterLast(path, "/");
        return StringUtil.toLowerCamelCase("source_" + lastPath);
    }

    private _hasDefaultDesignerId(oldValue: string): boolean {
        if (this.id == null)
            return true;
        if (!this.id.startsWith("source"))
            return false;
        if (this.id === oldValue)
            return true;
        const afterType = this.id.substring(6)
        return afterType.length === 0 || !isNaN(Number(afterType))
    }

    get maxResults(): number {
        return this._maxResults;
    }

    set maxResults(value: number) {
        this._maxResults = value;
    }

    get url(): string {
        return this._url;
    }

    set url(value: string) {
        const oldUrl = this._url;
        let oldNameValue: string;
        if (this._designer != null && this._url != null)
            oldNameValue = this.calcNameFromUrl(this._url)
        this._url = value;
        this.metadata = null;
        if (value != null && this._designer != null && this._hasDefaultDesignerId(oldNameValue)) {
            this.id = this.calcNameFromUrl(value);
            this._designer.redisplayProp("id", this.id);
        }
        if (this._designer == null && oldUrl !== this._url) {
            this.executeAutoSearch();
        }
    }

    public isAddingOrUpdating() {
        return this.mode === DataSourceMode.ADD || this.mode === DataSourceMode.UPDATE;
    }

    private executeAutoSearch() {
        if (this._autoSearch === true && this._mode === DataSourceMode.SEARCH && this._url != null) {
            this.execute();
        }
    }

    cancel() {
        if (this.mode === DataSourceMode.SEARCH || this.mode === DataSourceMode.ADD) {
            this.data = [];
            this.rowIndex = -1;
        }
        this.mode = DataSourceMode.NONE;
    }

    get mode(): DataSourceMode {
        return this._mode;
    }

    set mode(value: DataSourceMode) {
        log.debug(() => ["set mode", value, this]);
        if (this._mode === value || (this._designer != null && value !== DataSourceMode.NONE))
            return;

        if (value === DataSourceMode.ADD)
            this.createBlankRow().then(row => this._internalSetMode(value, row as RowType));
        else if (value === DataSourceMode.SEARCH) {
            this._searchRow = null;
            this._internalSetMode(value, this.searchRow as RowType);
        }
        else
            this._internalSetMode(value);
    }

    public async createBlankRow(): Promise<RowType> {
        let result: RowType;
        if (this.rowConstructor == null) {
            if (StringUtil.isEmptyString(this.url) === false)
                result = new ModelRow(this.url, true) as RowType;
            else
                result = new ModelRow("", true) as RowType;
        }
        else
            result = new this.rowConstructor() as RowType;
        await result.populateDefaultValues(this.layoutName);
        return result;
    }

    private _internalSetMode(value: DataSourceMode, row?: RowType) {
        if (this._mode === value)
            return;
        const oldMode = this._mode;
        const beforeChangeEvent = new DataSourceModeChangeEvent(this, oldMode, value, true);
        this.fireListeners(_beforeModeChangeListenerDef, beforeChangeEvent);
        if (beforeChangeEvent.defaultPrevented) // probably need to think about what things that set the mode expect to have happened
            return;
        this._mode = value;
        if (value === DataSourceMode.SEARCH || (value === DataSourceMode.ADD && this.parentDataSource == null)) {
            this.data = [row];
            this._rowIndex = 0;
        }

        for (const component of this._boundComponents)
            component.dataSourceModeChanged(value);

        if (this._designer == null && oldMode !== this._mode) {
            this.executeAutoSearch();
        }
        if (value !== DataSourceMode.NONE)
            this.focusFirstField();
        this.displayDataInBoundComponents();
        if (value === DataSourceMode.UPDATE || value === DataSourceMode.NONE)
            this.newRowDisplayed();
        this.fireListeners(_afterModeChangeListenerDef, new DataSourceModeChangeEvent(this, oldMode, value, false));
    }

    /**
     * This method allows for setting both the mode and populating the data with a single ModelRow
     * @param mode The DataSourceMode that should be used
     * @param rows The ModelRow(s) to put in the data array
     * @param displayDataCallback A callback function that allows for control of how components in the layout display data after it is set in the DataSource.
     * @returns void
     */
    public setRowsAndMode(mode: DataSourceMode, rows: RowType[], displayDataCallback?: () => void) {
        const oldMode = this._mode;
        if (oldMode !== mode) {
            const beforeChangeEvent = new DataSourceModeChangeEvent(this, oldMode, mode, true);
            this.fireListeners(_beforeModeChangeListenerDef, beforeChangeEvent);
            if (beforeChangeEvent.defaultPrevented) // probably need to think about what things that set the mode expect to have happened
                return;
        }
        this.data = rows;
        this._mode = mode;
        this._rowIndex = 0;
        if (oldMode !== mode) {
            for (const component of this._boundComponents)
                component.dataSourceModeChanged(mode);
        }
        if (this._designer == null && oldMode !== this._mode)
            this.executeAutoSearch();
        if (mode !== DataSourceMode.NONE)
            this.focusFirstField();
        if (displayDataCallback == null)
            this.displayDataInBoundComponents();
        else
            displayDataCallback();
        if (oldMode !== mode) {
            if (mode === DataSourceMode.UPDATE || mode === DataSourceMode.NONE)
                this.newRowDisplayed();
            this.fireListeners(_afterModeChangeListenerDef, new DataSourceModeChangeEvent(this, oldMode, mode, false));
        }
    }

    public set preventFocusFirstField(value: boolean) {
        this._preventFocusFirstField = value;
    }

    focusFirstField() {
        if (this._preventFocusFirstField !== true && this._boundComponents.length > 0)
            this._boundComponents[0].focus();
    }

    setRowIndexWithoutUpdate(value: number) {
        if (value < this.data.length)
            this._rowIndex = value;
    }

    get activeRow(): RowType {
        return this.data[this.rowIndex];
    }

    set rowIndex(value: number) {
        if (value < this.data.length && value !== this._rowIndex) {
            this._rowIndex = value;
            this.displayDataInBoundComponents();
            this.newRowDisplayed();
        }
    }

    get rowIndex(): number {
        return this._rowIndex;
    }

    next() {
        if (this.rowIndex < this.data.length - 1)
            this.rowIndex++;
    }

    previous() {
        if (this.rowIndex > 0)
            this.rowIndex--;
    }

    newRowDisplayed() {
        log.debug(() => ["New row displayed", this.id, this, this.activeRow]);
        this.unobserveBoundComponents();
        if (this._childDataSources != null) {
            for (const child of this._childDataSources) {
                child.clear();
                child.displayDataInBoundComponents();
                child.unobserveBoundComponents();
                if (this.activeRow != null) {
                    if (child.parentSearchMode === ParentSearchMode.onParentDisplay)
                        child.search();
                    else if (child.parentSearchMode === ParentSearchMode.onlyWhenVisible) {
                        log.debug(() => ["Observing child datasource", child.id, "from", this.id, this, child]);
                        child.observeBoundComponents(() => {
                            log.debug(() => ["Child observed", this, child]);
                            if (this._mode !== DataSourceMode.SEARCH && this._mode !== DataSourceMode.ADD)
                                child.search();
                        });
                    }
                }
            }
        }
    }

    private observeBoundComponents(callback: () => void) {
        this._boundComponentObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
            for (const entry of entries)
                if (entry.isIntersecting) {
                    //if we have specified a single component as the only one whose DataSource(s) can search, ensure that component is the one that's visible
                    if (AllowedToSearch.isAllowed(entry.target) !== true)
                        continue;
                    //the component that is allowed to search is now visible, so let it load its data
                    //also clear that component from AllowedToSearch, so that other DataSources can search going forward
                    AllowedToSearch.clear();
                    this.unobserveBoundComponents();
                    callback();
                    break;
                }
        });
        log.debug(() => ["Observing bound components", this.id, this._boundComponents, this]);
        for (const comp of this._boundComponents)
            this._boundComponentObserver.observe(comp._element)
    }

    private unobserveBoundComponents() {
        log.debug(() => ["Unobserving bound components", this.id, this._boundComponents, this]);
        if (this._boundComponentObserver != null) {
            this._boundComponentObserver.disconnect();
            this._boundComponentObserver = null;
        }
    }

    async execute(errorHandler?: ErrorHandler): Promise<ModelRow> {
        if (this.mode === DataSourceMode.SEARCH)
            return this.search(this.getDataboundValues(this.mode, true), errorHandler);
        else if ([DataSourceMode.ADD, DataSourceMode.UPDATE].includes(this.mode))
            return this.post(errorHandler);
        else
            log.info("Cannot execute() DataSource in mode " + this.mode);
    }

    fillLinkedValues(parentRow: ModelRow, overrideMode?: DataSourceMode) {
        parentRow.clearLinkedModels();
        if (this._childDataSources == null) {
            log.debug(() => ["fillLinkedValues: _childDataSources is null", this]);
            return;
        }

        for (const child of this._childDataSources) {
            if (overrideMode !== DataSourceMode.SEARCH) {
                if (child.data.length == 0)
                    log.debug(() => ["fillLinkedValues: child.data.length is zero", this]);
                else
                    log.debug(() => ["fillLinkedValues: child.data[0].data", this, child.data[0].data]);
                parentRow.addLinkedModel({ model: child.url, rows: child.data, /*forceInclusion: child._rowCountChanged() === true*/ deletedRows: child._deletedData });
            }
            else {
                const childValues = child.getDataboundValues(overrideMode, true, null, true);
                if (childValues != null && !childValues.isEmpty()) {
                    log.debug(() => ["fillLinkedValues: childValues ", this, childValues]);
                    parentRow.addLinkedModel({ model: child.url, rows: [childValues] });
                }
                else if (childValues == null)
                    log.debug(() => ["fillLinkedValues: childValues is null"], this);
                else
                    log.debug(() => ["fillLinkedValues: childValues is empty"], this);
            }
        }
    }

    getDataboundValues(overrideMode?: DataSourceMode, includeNested?: boolean, useBoundSearchComponents?: boolean, fillingLinkedValues?: boolean): RowType {
        const result = new ModelRow(this.url);
        const components = this.getRelevantBoundComponents(useBoundSearchComponents);
        this._updateRowFromBoundComponents(result, components, overrideMode, fillingLinkedValues);
        if (includeNested === true)
            this.fillLinkedValues(result, overrideMode);
        if (this._afterGetDataboundValues != null)
            this._afterGetDataboundValues(result, this);
        return result as RowType
    }

    public getRelevantBoundComponents(useBoundSearchComponents?: boolean): Component[] {
        if (useBoundSearchComponents === true && this._boundSearchComponents.length > 0)
            return this._boundSearchComponents;
        return this._boundComponents;
    }

    public set afterGetDataboundValues(value: (row: ModelRow, dataSource: DataSource) => void) {
        this._afterGetDataboundValues = value;
    }

    public get doBeforeDataSourcePost(): () => Promise<boolean> {
        return this._doBeforeDataSourcePost;
    }
    public set doBeforeDataSourcePost(value: () => Promise<boolean>) {
        this._doBeforeDataSourcePost = value;
    }

    private _updateRowFromBoundComponents(row: ModelRow, components: Component[] = this._boundComponents, overrideMode?: DataSourceMode, fillingLinkedValues?: boolean) {
        for (const component of components) {
            component.updateBoundData(row, overrideMode || this.mode, fillingLinkedValues);
            log.debug(() => ["Updated row data", row.data]);
        }
    }

    public getMetadataFromCache(): ApiMetadata {
        if (this.metadata != null)
            return this.metadata;
        else
            return getApiMetadataFromCache(this.url);
    }


    async getMetadata(): Promise<ApiMetadata> {
        if (this.metadata != null)
            return Promise.resolve(this.metadata);
        else
            return getApiMetadata(this.url).then(metadata => this.metadata = metadata);
    }

    hasChanged(includeChildren: boolean = false): boolean {
        if (this._rowCountChanged()) {
            return true;
        }
        for (const row of this.data) {
            if (row.hasChanged())
                return true;
        }
        if (includeChildren === true && ArrayUtil.isEmptyArray(this._childDataSources) !== true) {
            for (const childDataSource of this._childDataSources) {
                if (childDataSource.hasChanged(true) === true)
                    return true;
            }
        }
        return false;
    }

    clear() {
        this.mode = DataSourceMode.NONE;
        this.data = [];
    }

    public isEmpty(): boolean {
        return ArrayUtil.isEmptyArray(this.data);
    }

    public addBusyComponent(component: Component) {
        if (this._busyComponents == null)
            this._busyComponents = [];
        this._busyComponents.push(component);
    }

    public removeBusyComponent(component: Component) {
        ArrayUtil.removeFromArray(this._busyComponents, component);
        if (ArrayUtil.isEmptyArray(this._busyComponents) === true)
            this._busyComponents = null;
    }

    setComponentsBusy(value: boolean) {
        for (const component of this._boundComponents) {
            this.setComponentBusy(component, value);
        }
        if (ArrayUtil.isEmptyArray(this._busyComponents) !== true) {
            for (const component of this._busyComponents) {
                this.setComponentBusy(component, value);
            }
        }
    }

    private setComponentBusy(component: Component, value: boolean) {
        if (component["busyWhenDataSourceBusy"] === true && "busy" in Object.getPrototypeOf(component))
            component["busy"] = value;
    }

    public get rowConstructor(): RowConstructor<RowType> {
        return null; // Clark is going to make the Typescript version of TableMap and we can return something good here
    }

    /**
     * The tracking layout is present to allow a link back to the layout that is using this DataSource
     * (for use with data load listeners)
     */
    get trackingLayout(): Layout {
        return this._trackingLayout;
    }

    set trackingLayout(value: Layout) {
        this._trackingLayout = value;
    }

    getSearchFilter(includeNested?: boolean): any {
        return this.getDataboundValues(DataSourceMode.SEARCH, includeNested, true);
    }

    async executeValidatedSearch(includeNested?: boolean): Promise<any> {
        const filter = this.getSearchFilter(includeNested);
        if (this.validate(true)) {
            return this.search(filter.data, null, null);
        } else {
            return Promise.resolve();
        }
    }

    async search(filter?: any, errorHandler?: ErrorHandler, fieldList?: string | object): Promise<any> {
        this._actionInProgress = DataSourceAction.SEARCH;
        this._addSearchingDataSource(this._topLevelDataSource);
        const event = new DataSourceExecutionEvent(this, DataSourceAction.SEARCH, true, filter);
        this.fireListeners(_beforeExecutionListenerDef, event);
        if (event.defaultPrevented) {
            this._actionInProgress = null;
            return;
        }
        this.data = [];
        this.displayDataInBoundComponents();
        if (this.parentDataSource != null) {
            const parentFilter = this.getParentFilter();
            if (parentFilter == null) {
                this._actionInProgress = null;
                return;
            }
            filter = { ...filter, _parent_link: parentFilter };
        }
        if (this.subscribeToChanges) {
            filter = { ...filter, _subscribe_to_changes: true };
            this.setupSubscriberIfNeeded();
        }
        if (this.maxResults != null && this.maxResults > 0) {
            filter._max_search_results = this.maxResults;
        }
        this.lastSearch = filter;
        this.setComponentsBusy(true);
        const fields = fieldList == null ? this.layoutName : fieldList;
        return Model.search(this.url, filter, fields, false, this.rowConstructor).then(result => {
            if (this.onTransformSearchResults != null)
                result = this.onTransformSearchResults(result);
            this.setComponentsBusy(false);
            if (filter != this.lastSearch) {
                log.info("Ignoring results from a previous search that were returned after the DataSource has been searched again.  Lagging search", filter, "Last search", this.lastSearch, "Lagging result", result);
                return;
            }
            this.data = result.modelRows as RowType[];
            this._searchOrderBy = result.orderBy;
            this.actualRowCount = result.actualRowCount;
            this.summaryData = result.summaryData;
            log.debug(() => ["Search results", this.data, this]);
            if (this.data.length > 0)
                this._rowIndex = 0;

            // setting the mode will call displayDataInBoundsComponents() automatically
            // but we don't want to set the mode if we are already in NONE mode, or if we are in UPDATE mode
            // (if we are already in UPDATE mode, we may be searching a child datasource because its component became visible, so we need to stay in UPDATE mode)
            if (this.mode === DataSourceMode.NONE || this.mode === DataSourceMode.UPDATE)
                this.displayDataInBoundComponents();
            else
                this.mode = DataSourceMode.NONE;

            this.newRowDisplayed();
            this.fireListeners(_afterExecutionListenerDef, new DataSourceExecutionEvent(this, DataSourceAction.SEARCH, false));
            this._actionInProgress = null;
            return result;
        }).catch(err => {
            this._actionInProgress = null;
            this.setComponentsBusy(false);
            log.error("An error occurred while searching for data from url %o with filter %o: %o", this.url, filter, err);
            handleError(err, errorHandler);
        });
    }

    get actionInProgress(): DataSourceAction {
        return this._actionInProgress;
    }

    get isSearching(): boolean {
        return this._actionInProgress === DataSourceAction.SEARCH;
    }

    /**
     * Returns true if any child DataSource is currently searching.  The method is recursive and checks children of children.
     *
     * @returns boolean
     */
    get areChildrenSearching(): boolean {
        if (ArrayUtil.isEmptyArray(this._childDataSources) !== true) {
            for (const childDataSource of this._childDataSources) {
                if (childDataSource.isSearching === true || childDataSource.areChildrenSearching === true)
                    return true;
            }
        }
        return false;
    }

    get isSearchingOrChildrenSearching(): boolean {
        if (this.isSearching === true)
            return true;
        return this.areChildrenSearching;
    }

    get isAdding(): boolean {
        return this._actionInProgress === DataSourceAction.ADD;
    }

    get isUpdating(): boolean {
        return this._actionInProgress === DataSourceAction.UPDATE;
    }

    get isDeleting(): boolean {
        return this._actionInProgress === DataSourceAction.DELETE;
    }

    public validateSimple(useBoundSearchComponents?: boolean): boolean {
        return this.componentValidator.validate(useBoundSearchComponents)?.length === 0;
    }

    public validate(useBoundSearchComponents?: boolean): boolean {
        return this.componentValidator.validate(useBoundSearchComponents, true)?.length === 0;
    }

    public get componentValidator(): BoundComponentValidator {
        return this._componentValidator;
    }

    public get componentValidationFailedCallback(): (result: ValidationResult) => void {
        return this._componentValidationFailedCallback;
    }
    public set componentValidationFailedCallback(value: (result: ValidationResult) => void) {
        this._componentValidationFailedCallback = value;
    }

    getParentFilter() {
        const filter = { model: this.parentDataSource.url}
        const parentRow = this.parentDataSource.activeRow;
        const parentFields = this.parentDataSource.getMetadataFromCache().getLinkedModelKeyFields(this.url);
        for (const parentField of parentFields) {
            if (parentRow.isNull(parentField)) {
                log.debug("Unable to search for child data source because parent field %s is null", parentField);
                return null;
            }
            filter[parentField] = parentRow.get(parentField);
        }
        return filter;
    }

    async post(errorHandler?: ErrorHandler): Promise<ModelRow> {
        if (this.activeRow == null) {
            log.error("No active row to post");
            return;
        }
        log.debug(() => ["DataSource post()", this]);
        const action = this.mode === DataSourceMode.UPDATE ? DataSourceAction.UPDATE : DataSourceAction.ADD;
        this._actionInProgress = action;
        const event = new DataSourceExecutionEvent(this, action, true);
        this.fireListeners(_beforeExecutionListenerDef, event);
        if (event.defaultPrevented) {
            this._actionInProgress = null;
            return;
        }
        if (await this.doBeforeDataSourcePost?.() === false) {
            McLeodMainPageUtil.doNotReportNextError(true);
            return Promise.reject();
        }
        if (!this.validate()) {
            return Promise.reject({ message: "Invalid data", displayed: true });
        }
        this.preventChangeNotifications = true;
        this._updateRowFromBoundComponents(this.activeRow);
        this.fillLinkedValues(this.activeRow);
        this.setComponentsBusy(true);
        return this.activeRow.post(false).then(row => {
            this.setComponentsBusy(false);
            if (DataSourceMode.ADD === this.mode)
                this.mode = DataSourceMode.UPDATE;
            else {
                this.newRowDisplayed();
            }
            this.updateOriginalRowCount();
            this._updateChildRowsAfterPost();
            this.displayDataInBoundComponents();
            this.fireListeners(_afterExecutionListenerDef, new DataSourceExecutionEvent(this, action, false));
            this._actionInProgress = null;
            return row;
        }).catch(err => {
            this._actionInProgress = null;
            this.setComponentsBusy(false);
            if (typeof err == "function") {
                err();
            } else {
                log.error("An error occurred while posting with values %o: %o", this.activeRow, err);
                handleError(err, errorHandler);
            }
            McLeodMainPageUtil.doNotReportNextError(true);
            return Promise.reject(err);
        }).finally(() => {
            this.preventChangeNotifications = false;
            this.notifyHasChangedComponents();
        })
    }

    private _updateChildRowsAfterPost() {
        if (this._childDataSources != null)
            for (const child of this._childDataSources) {
                child._deletedData = null;
                if (child.data == null)
                    continue;
                for (const row of child.data) {
                    row._appending = false;
                    row.resetOriginalData();
                }
            }
    }

    delete(errorHandler?: ErrorHandler) {
        this._actionInProgress = DataSourceAction.DELETE;
        const event = new DataSourceExecutionEvent(this, DataSourceAction.DELETE, true)
        this.fireListeners(_beforeExecutionListenerDef, event);
        if (event.defaultPrevented) {
            this._actionInProgress = null;
            return;
        }
        this.activeRow.delete(false).then(response => {
            this.data.splice(this.rowIndex, 1);
            if (this.rowIndex >= this.data.length)
                this.rowIndex--;
            this.updateOriginalRowCount();
            this.displayDataInBoundComponents();
            this.mode = DataSourceMode.NONE;
            this.fireListeners(_afterExecutionListenerDef, new DataSourceExecutionEvent(this, DataSourceAction.DELETE, false));
            this._actionInProgress = null;
        }).catch(err => {
            this._actionInProgress = null;
            log.error("An error occurred while deleting data with values %o: %o", this.activeRow, err);
            handleError(err, errorHandler);
        });
    }

    displayDataInBoundComponents() {
        log.debug(() => ["displayDataInBoundComponents", this.id, this, this._boundComponents]);
        this.fireListeners(_displayListenerDef, () => new DataDisplayEvent(this, this.activeRow, this.data, this.rowIndex));
        for (const component of this._boundComponents)
            this.displayDataInBoundComponent(component);
    }

    displayDataInBoundComponent(component: Component) {
        component.displayData(this.data[this.rowIndex], this.data, this.rowIndex);
        component.syncCaptionIfPossible();
    }

    rebindComponentsTo(otherDataSource: DataSource) {
        const temporaryList: Component[] = [];
        for (const component of this._boundComponents) {
            temporaryList.push(component);
        }
        for (const component of temporaryList) {
            component.dataSource = otherDataSource;
        }
    }

    private _rebuildComponentsByField(forceRebuild: boolean = false) {
        if (this.componentsByField != null && forceRebuild !== true)
            return;

        const dataDisplayHandlerComponents: Component[] = [];
        const result: Collection<Component[]> = {};
        for (const component of this._boundComponents) {
            const fields = component.getFieldNames();
            if (fields != null) {
                for (const field of fields) {
                    const compsForField = result[field];
                    if (compsForField == null)
                        result[field] = [component];
                    else
                        compsForField.push(component);
                }
            }
            else if (component["handleDataDisplay"] != null)
                dataDisplayHandlerComponents.push(component);
        }
        this._dataDisplayHandlerComponents = dataDisplayHandlerComponents;
        this.componentsByField = result;
    }

    public updateComponentsByField(component: Component, oldField: string) {
        const field = component.field;
        if (oldField != null)
            this._removeFromComponentsByField(component, oldField);
        this._addToComponentsByField(component);
    }

    private _addToComponentsByField(component: Component) {
        const field = component.field;
        if (field == null)
            return;
        if (this.componentsByField == null) {
            this._rebuildComponentsByField();
            return;
        }
        let compsForField = this.componentsByField[field];
        if (compsForField == null) {
            compsForField = [];
            this.componentsByField[field] = compsForField;
        }
        if (!compsForField.includes(component))
            compsForField.push(component);
    }

    private _removeFromComponentsByField(component: Component, oldField?: string) {
        const field = oldField || component.field;
        if (field == null)
            return;
        if (this.componentsByField == null) {
            return;
        }
        const compsForField = this.componentsByField[field];
        if (compsForField == null || compsForField.length === 0) {
            return;
        }
        if (compsForField.includes(component))
            compsForField.splice(compsForField.indexOf(component), 1);
    }

    addBoundComponent(component: Component, oldSearchOnlyValue?: boolean) {
        if (oldSearchOnlyValue !== true && component.searchOnly === true) {
            component.boundRow = this.searchRow;
            this._removeBoundComponent(component);
            this._addBoundSearchComponent(component);
        }
        else {
            component.boundRow = null;
            this._removeBoundSearchComponent(component);
            this._addBoundComponent(component);
        }
    }

    removeBoundComponent(component: Component) {
        this._removeBoundComponent(component);
        this._removeBoundSearchComponent(component);
    }

    private _addBoundComponent(component: Component) {
        log.debug(() => ["addBoundComponent", component.getFieldNames(), this, component]);
        if (!this._boundComponents.includes(component))
            this._boundComponents.push(component);
        this._addToComponentsByField(component);
    }

    private _removeBoundComponent(component: Component) {
        log.debug(() => ["removeBoundComponent", this, component]);
        const index = this._boundComponents.indexOf(component);
        if (index >= 0)
            this._boundComponents.splice(index, 1);
        this._removeFromComponentsByField(component);
    }

    private _addBoundSearchComponent(component: Component) {
        log.debug(() => ["_addBoundSearchComponent", component.getFieldNames(), this, component]);
        if (!this._boundSearchComponents.includes(component))
            this._boundSearchComponents.push(component);
    }

    private _removeBoundSearchComponent(component: Component) {
        log.debug(() => ["_removeBoundSearchComponent", this, component]);
        const index = this._boundSearchComponents.indexOf(component);
        if (index >= 0)
            this._boundSearchComponents.splice(index, 1);
    }

    public getComponentsByField(field: string): Component[] {
        return this.componentsByField[field];
    }

    override getPropertyDefinitions(): DesignableObjectPropsCollection {
        return DataSourcePropDefinitions.getDefinitions();
    }

    getPropertyDefaultValue(prop: DesignableObjectPropDefinition): any {
        return prop.defaultValue;
    }

    public deleteTableRow(rowIndex: number, addToDeletedData: boolean = true, field?: string) {
        if (field == null) {
            const row = this.data[rowIndex];
            if (row._appending !== true && addToDeletedData === true) {
                if (this._deletedData == null)
                    this._deletedData = [];
                this._deletedData.push(row);
            }
            this.data.splice(rowIndex, 1);
        }
        else {
            const row = this.data[rowIndex];
            if (row != null) {
                const fieldData = row[field];
                if (fieldData != null) {
                    fieldData.splice(rowIndex, 1);
                }
            }
        }
        this.notifyHasChangedComponents();
        this.displayDataInBoundComponents();
    }

    public getDesigner() {
        return this._designer;
    }

    public setDesigner(designer: any) {
        this._designer = designer;
    }

    addBeforeExecutionListener(value: DataSourceExecutionListener) {
        this.addEventListener(_beforeExecutionListenerDef, value);
    }

    removeBeforeExecutionListener(value: DataSourceExecutionListener) {
        this.removeEventListener(_beforeExecutionListenerDef, value);
    }

    addAfterExecutionListener(value: DataSourceExecutionListener) {
        this.addEventListener(_afterExecutionListenerDef, value);
    }

    removeAfterExecutionListener(value: DataSourceExecutionListener) {
        this.removeEventListener(_afterExecutionListenerDef, value);
    }

    /**
     * The afterSearchPlusChildren type listener is intended to fire when both the DataSource
     * and any of its (recursive) chilren have finished searching.
     *
     * Notes:
     *  -> Listeners are only allowed to run once.
     *  -> Any listener added after all sources have finished searching will be evaluated immediately
     *
     * @param value The listener method to invoke when the even occurs.
     * @returns void
     */
    addAfterSearchPlusChildrenListener(value: DataSourceExecutionListener) {
        value.runsOnce = true;
        this.addEventListener(_afterSearchPlusChildrenListenerDef, value);
        if (this._searchesComplete === true) {
            this._fireAfterSearchPlusChildrenListeners();
            return;
        }
        this._addRecursiveSearchingDataSources(this);
    }

    private _addRecursiveSearchingDataSources(topLevelDataSource: DataSource) {
        this._topLevelDataSource = topLevelDataSource;
        if (this.isSearching === true)
            this._addSearchingDataSource(topLevelDataSource);
        if (ArrayUtil.isEmptyArray(this._childDataSources) !== true) {
            for (const childDataSource of this._childDataSources) {
                childDataSource._addRecursiveSearchingDataSources(topLevelDataSource);
            }
        }
    }

    private _addSearchingDataSource(topLevelDataSource: DataSource) {
        //if the DataSource has a specified topLevelDataSource, add this DataSource to that layout's list of DataSources that are searching
        //it will be removed when it finishes searching (via the afterExecutionListener)
        if (topLevelDataSource != null) {
            if (topLevelDataSource._searchesComplete === true)
                return;
            if (topLevelDataSource._dataSourcesSearching == null)
                topLevelDataSource._dataSourcesSearching = [];
            const added = ArrayUtil.addNoDuplicates(topLevelDataSource._dataSourcesSearching, this);
            if (added === true) {
                const callback: EventListener = (event: DataSourceExecutionEvent) => topLevelDataSource._removeSearchingDataSource(event.dataSource);
                callback.runsOnce = true;
                this.addAfterExecutionListener(callback);
            }
        }
        else {
            //otherwise, get the top-most parent DataSource and add this DataSource to that layout's list of sources that are searching
            //this DataSource will be removed form the list when it finishes searching
            const topMostParentDataSource = this._getTopMostParent();
            if (topMostParentDataSource?.trackingLayout != null)
                topMostParentDataSource.trackingLayout.addDataLoadDataSource(topMostParentDataSource);
        }
    }

    private _getTopMostParent(): DataSource {
        if (this.parentDataSource == null)
            return this;
        while (true) {
            if (this.parentDataSource.parentDataSource == null)
                return this.parentDataSource;
        }
    }

    private _removeSearchingDataSource(dataSource: DataSource) {
        ArrayUtil.removeFromArray(this._dataSourcesSearching, dataSource);
        if (ArrayUtil.isEmptyArray(this._dataSourcesSearching) === true) {
            this._searchesComplete = true;
            this._fireAfterSearchPlusChildrenListeners();
        }
    }

    private _fireAfterSearchPlusChildrenListeners() {
        this.fireListeners(_afterSearchPlusChildrenListenerDef, new DataSourceExecutionEvent(this, DataSourceAction.SEARCH, false));
    }

    removeAfterSearchPlusChildrenListener(value: DataSourceExecutionListener) {
        this.removeEventListener(_afterSearchPlusChildrenListenerDef, value);
    }

    addBeforeModeChangeListener(value: DataSourceModeChangeListener) {
        this.addEventListener(_beforeModeChangeListenerDef, value);
    }

    removeBeforeModeChangeListener(value: DataSourceModeChangeListener) {
        this.removeEventListener(_beforeModeChangeListenerDef, value);
    }

    addAfterModeChangeListener(value: DataSourceModeChangeListener) {
        this.addEventListener(_afterModeChangeListenerDef, value);
    }

    removeAfterModeChangeListener(value: DataSourceModeChangeListener) {
        this.removeEventListener(_afterModeChangeListenerDef, value);
    }

    addDisplayListener(value: DataDisplayListener) {
        this.addEventListener(_displayListenerDef, value);
    }

    removeDisplayListener(value: DataDisplayListener) {
        this.removeEventListener(_displayListenerDef, value);
    }

    addRemoteDataChangeListener(value: DataSourceRemoteDataChangeListener) {
        this.addEventListener(_remoteDataChangeListenerDef, value)
    }

    removeRemoteDataChangeListener(value: DataSourceRemoteDataChangeListener) {
        this.removeEventListener(_remoteDataChangeListenerDef, value)
    }

    addSubscriberErrorListener(value: DataSourceSubscriberErrorListener) {
        this.addEventListener(_subscriberErrorListenerDef, value);
    }

    removeSubscriberErrorListener(value: DataSourceSubscriberErrorListener) {
        this.removeEventListener(_subscriberErrorListenerDef, value);
    }

    addValidationListener(value: DataSourceValidationListener) {
        this.addEventListener(_validationListenerDef, value);
    }

    removeValidationListener(value: DataSourceValidationListener) {
        this.removeEventListener(_validationListenerDef, value);
    }

    public attachListeners(otherDataSource: DataSource) {
        Object.values(this.getListenerDefs()).forEach(listenerDef => {
            const listenerList = otherDataSource[listenerDef.listName]?.listeners;
            if (listenerList != null)
                listenerList.forEach((event: EventListener) => this.addEventListener(listenerDef, event));
        })
    }

    public get parentDataSource(): DataSource {
        return this._parentDataSource;
    }

    public set parentDataSource(value: DataSource) {
        if (this._parentDataSource instanceof DataSource)
            ArrayUtil.removeFromArray(this._parentDataSource._childDataSources, this);
        this._parentDataSource = value;
        if (value instanceof DataSource && this._designer == null) {
            if (value._childDataSources == null)
                value._childDataSources = [];
            value._childDataSources.push(this);
        }
    }


    get boundSearchComponents(): Component[]{
        return this._boundSearchComponents;
    }

    get boundComponents() {
        return this._boundComponents;
    }

    public isBoundComponent(component: Component): boolean {
        return this._boundComponents?.includes(component);
    }

    public getChildDataSourceIds(): string[] {
        const resut = [];
        if (this._childDataSources != null) {
            for (const child of this._childDataSources)
                resut.push(child.id);
        }
        return resut;
    }

    public get childDataSources(): DataSource[] {
        return this._childDataSources;
    }

    _serializeNonProps(): string {
        let result = "";
        if (this.parentDataSource == null) {
            let id: string;
            if (this.parentDataSource instanceof DataSource)
                id = this.parentDataSource.id;
            else
                id = this.parentDataSource;
            result += "\"parentDataSource\": \"" + id + "\",\n";
        }
        return result;
    }

    _deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, componentCreationCallback: ComponentCreationCallback): string[] {
        if (compDef.parentDataSource != null && dataSources != null)
            this.parentDataSource = dataSources[compDef.dataSource];
        return ["parentDataSource"];
    }

    private _initializeSearchRow() {
        if (this._searchRow != null)
            return;
        this._searchRow = new ModelRow(this.url) as RowType;
    }

    public get searchRow(): ModelRow {
        this._initializeSearchRow();
        return this._searchRow;
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "beforeModeChange": { ..._beforeModeChangeListenerDef },
            "afterModeChange": { ..._afterModeChangeListenerDef },
            "beforeExecution": { ..._beforeExecutionListenerDef },
            "afterExecution": { ..._afterExecutionListenerDef },
            "display": { ..._displayListenerDef },
        };
    }

    public get originalRowCount() {
        return this._originalRowCount;
    }

    public get preventChangeNotifications(): boolean {
        return this._preventChangeNotifications;
    }

    public set preventChangeNotifications(value: boolean) {
        this._preventChangeNotifications = value;
    }

    public get deletedData(): RowType[] {
        return this._deletedData;
    }

    private setupSubscriberIfNeeded() {
        if (this.subscriber == null && GeneralSettings.getSingleton().isMessagingEnabled()) {
            this.subscriber = new MessageSubscriber("model-change-" + this.url, false, (message: any) => {
                let updateType = message.type;
                if (this.isMessageForMe(message) !== true) {
                    log.debug("Message %o is not relevant for this user, treating this as a removal", message);
                    updateType = ModelDataChangeType.DELETE;
                }
                const row = new ModelRow(this.url, false, message.data) as RowType;
                const change = { type: updateType, data: row, filters: message.filters };
                this.handleDataChange(change);
            });
            this.subscriber.errorHandler = error => {
                if (this.getListeners(_subscriberErrorListenerDef)?.length > 0) {
                    const event = new DataSourceSubscriberErrorEvent(this, error);
                    this.fireListeners(_subscriberErrorListenerDef, event);
                } else
                    log.error("Error on subscriber", error);
            };
        }
    }

    private unsubscribeFromChanges() {
        if (this.subscriber != null) {
            this.subscriber.unsubscribe();
            this.subscriber = null;
        }
    }

    private isMessageForMe(message: any) {
        log.debug("Evaluating if real-time data change is applicable for user.  Filters: %o", message.filters);
        if (ArrayUtil.isEmptyArray(message.filters))
            return true;
        for (const filter of message.filters) {
            if (this.evaluateSingleDataChangeFilter(filter) === false)
                return false;
        }
        return true;
    }

    private evaluateSingleDataChangeFilter(filter: ModelDataChangeFilter): boolean {
        if (filter.type == null || filter.values == null)
            return true;
        switch (filter.type) {
            case ModelDataChangeFilterType.COMPANY:
                return Company.testCompanyId(filter.values);
            case ModelDataChangeFilterType.RESPONSIBILITY:
                return LogicResponsibilityFiltering.userHasResponsibility(filter.values);
            default:
                break;
        }
        return true; //we were sent a filter type we don't care about
    }

    public handleDataChange(change: ModelDataChange) {
        const row = change.data as RowType;
        const changeEvent = new DataSourceRemoteDataChangeEvent(this, { type: change.type, data: row });
        this.fireListeners(_remoteDataChangeListenerDef, changeEvent);
        if (changeEvent.defaultPrevented)
            return;
        change.type = changeEvent.change.type; // allow the listener to change the type
        const keyData = row.getKeyData();
        log.debug("Data change", change, "Key data", keyData, "Current data", this.data);
        if (change.type === ModelDataChangeType.ADD) {
            // this.data.push(row);
            this.redisplayRow(this.data.length - 1, row, change.type);
        } else if (change.type == ModelDataChangeType.UPDATE) {
            const index = this.findRowInData(keyData);
            if (index >= 0) {
                this.data[index] = row;
                log.debug("Updated row %o at index %d", row, index);
                this.redisplayRow(index, row, change.type);
            } else {
                log.debug("Row being updated was not in data.  Changing type to ADD", row);
                this.redisplayRow(this.data.length - 1, row, ModelDataChangeType.ADD);
            }
        } else if (change.type == ModelDataChangeType.DELETE) {
            const index = this.findRowInData(row.getKeyData());
            if (index >= 0) {
                this.data.splice(index, 1);
                log.debug("Deleted row %o at index %d", row, index, "All rows", this.data);
                this.updateOriginalRowCount();
                this.redisplayRow(index, row, change.type);
            }
        }
        log.debug("Finished handling change.  New data", this.data);
    }

    public findRowInData(keyData): number {
        for (let i = 0; this.data != null && i < this.data.length; i++)
            if (ObjectUtil.deepEqual(keyData, this.data[i].getKeyData()))
                return i;
        return -1;
    }

    get orderBy(): OrderByInfo[] {
        if (this._explicitOrderBy != null)
            return this._explicitOrderBy;
        return this._searchOrderBy;
    }

    set orderBy(value: OrderByInfo[]) {
        this._explicitOrderBy = value;
    }

    /**
     * Provided an array of DataSources, return a different array containing any DataSource that is not a child of another DataSource
     *
     * @param dataSources the array of DataSources to be evaluated
     * @returns DataSource[]
     */
    public static getTopLevelDataSources(dataSources: DataSource[]): DataSource[] {
        const result = [];
        if (ArrayUtil.isEmptyArray(dataSources) === true)
            return result;
        for (const ds of dataSources) {
            if (ds.parentDataSource == null)
                result.push(ds);
        }
        return result;
    }
}

export function createDataSourcesFromDef(sources: any[], owner: Layout, layoutName: string): DataSource[] {
    const result = [];
    if (sources != null) {
        const dataSourcesById: Collection<DataSource> = {};
        const parents: Collection<string> = {};
        for (const def of sources) {
            const props = { ...def };
            if (owner._designer != null)
                props._designer = owner._designer;
            if (def.parentDataSource != null)
                parents[def.id] = def.parentDataSource;
            delete props.parentDataSource;
            const dataSource = new DataSource(props, owner);
            dataSource.layoutName = layoutName;
            result.push(dataSource);
            dataSourcesById[dataSource.id] = dataSource;
        }
        for (const childId in parents) {
            const parentId = parents[childId];
            dataSourcesById[childId].parentDataSource = dataSourcesById[parentId];
        }
    }
    return result;
}

ComponentTypes.registerComponentType("datasource", DataSource.prototype.constructor);
