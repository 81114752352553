import { Button, Component, DataDisplayEvent, Label, Panel, TableRow, TableRowDisplayEvent, TableRowExpansionEvent, Toast } from "@mcleod/components";
import { Api, ModelRow, ModelSearchResult, StringUtil } from "@mcleod/core";
import { DriverChoice } from "./DriverChoice";
import { DriverChoiceDetails } from "./DriverChoiceDetails";
import { AutogenLayoutDriverChoiceTable } from "./autogen/AutogenLayoutDriverChoiceTable";

export class DriverChoiceTable extends AutogenLayoutDriverChoiceTable {

    private beforeSearch = true;

    override onLoad() {
    }

    labelLabel4OnDataDisplay(event: DataDisplayEvent) {
        const orderId = event.rowData.get("order_id");
        if (this.tableDriverChoice.rows.length == 1) {
            this.labelDetailForOrder.caption = "Detail for order " + orderId;
        }
        else {
            const cityState = this.tableDriverChoice.rows[0].data.get("shipper_city_state");
            this.labelDetailForOrder.caption = "Orders in " + cityState;
        }
    }

    public tableDriverChoiceOnRowExpand(expansionEvent: TableRowExpansionEvent) {
        const modelRow = expansionEvent.getTableRow()?.data as ModelRow;
        const expandPanel = expansionEvent.expandComponentParent as Panel;
        const driverChoiceDetailsLayout = expandPanel.components[0] as DriverChoiceDetails;
        driverChoiceDetailsLayout.addLayoutLoadListener(event => {
            const tableDriverChoiceDetails = driverChoiceDetailsLayout.tableDriverChoiceDetails;

            const row = new ModelRow(null, false, {
                loaded_miles: modelRow?.get("loaded_miles"),
                dest_early_date: modelRow?.get("dest_early_date"),
                additional_pickups: modelRow?.get("additional_pickups"),
                additional_deliveries: modelRow?.get("additional_deliveries"),
                preloaded: modelRow?.get("preloaded"),
                weight: modelRow?.get("weight"),
                consignee_ref_num: modelRow?.get("consignee_ref_num"),
                commodity: modelRow?.get("commodity"),
                comments: modelRow?.get("comments")
            });

            tableDriverChoiceDetails.addRow(row, null, { display: true, addToData: true });
        });
    }

    tableDriverChoiceOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        tableRow.forEveryChildComponent((component: Component) => this.setNotAvailable(component, tableRow));
    }

    setNotAvailable(component: Component, tableRow: TableRow) {
        if (component instanceof Label && component.field != null) {
            if (StringUtil.isEmptyString(tableRow.data?.get(component.field))) {
                component.caption = "--";
                component.color = "default";
            }
        }
    }

    handleRequest(event) {
        const target = event.target.row as TableRow;
        const movementId = target.data.get("movement_id");
        const sessionId = target.data.get("session_id");
        const milesToOrigin = target.data.get("miles_to_origin")
        Api.post("portal/driver/driver-choice-request", { move_id: movementId, session_id: sessionId, miles_to_origin: milesToOrigin })
            .then(response => {
                if (response.data[0].successful && !StringUtil.isEmptyString(response.data[0].response)) {
                    const responseArray: String[] = response.data[0].response as String[];
                    let responseString = "";
                    responseArray.forEach(r => {
                        responseString = responseString + r + "\n";
                    });
                    Toast.showSuccessToast(responseString);
                    target.table.deleteRow(target.index);
                }
                else if (!StringUtil.isEmptyString(response.data[0].response)) {
                    Toast.showSuccessToast(response.data[0].response);
                }

            });
    }

    handleDecline(event) {
        const target = event.target.row as TableRow;
        const movementId = target.data.get("movement_id");
        const sessionId = target.data.get("session_id");
        const btn = event.target as Button;
        btn.busy = true;
        Api.post("portal/driver/driver-choice-decline", { move_id: movementId, session_id: sessionId })
            .then(response => {
                btn.busy = true;
                if (!StringUtil.isEmptyString(response.data[0].success)) {
                    Toast.showSuccessToast(response.data[0].success);
                    const driverChoiceLayout = this.parent as DriverChoice;
                    driverChoiceLayout.beforeQuery();
                    driverChoiceLayout.mainDataSource.search({session_id: sessionId }).then((result: ModelSearchResult) => {
                        driverChoiceLayout.afterQuery(result?.modelRows);
                    });
                }
            });
    }

}
