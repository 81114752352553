import {
    ChangeEvent, ClickEvent, DataSourceExecutionEvent,
    Layout,
    TableRowCreationEvent, TableRowDisplayEvent
} from "@mcleod/components";
import { CustomVersionInterface, CustomVersionUtil } from "../common/CustomVersionUtil";
import { AbstractUIDesigner } from "./AbstractUIDesigner";
import { AutogenLayoutUILayoutVersions } from "./autogen/AutogenLayoutUILayoutVersions";

export class UILayoutVersions extends AutogenLayoutUILayoutVersions implements CustomVersionInterface {
    public switchListenersActive = false;
    public enclosingDesigner: AbstractUIDesigner;
    public positionedBaseComponentsFromRow = false;

    override onLoad(): void | Promise<void> {
        CustomVersionUtil.onLoad(this);
    }

    /** This is an event handler for the onChange event of switchIsActive.  */
    switchIsActiveOnChange(event: ChangeEvent) {
        CustomVersionUtil.switchIsActiveOnChange(event, this);
    }

    /** This is an event handler for the afterExecution event of sourceCustomUiLayout.  */
    sourceCustomUiLayoutAfterExecution(event: DataSourceExecutionEvent) {
        CustomVersionUtil.doAfterExecute(event, this);
    }

    /** This is an event handler for the onClick event of imageDelete.  */
    async imageDeleteOnClick(event: ClickEvent) {
        CustomVersionUtil.imageDeleteOnClick(event, this);
    }

    /** This is an event handler for the onRowDisplay event of tableVersions.  */
    tableVersionsOnRowDisplay(event: TableRowDisplayEvent) {
        CustomVersionUtil.tableVersionsOnRowDisplay(event, this);
    }

    /** This is an event handler for the onRowCreate event of tableVersions.  */
    tableVersionsOnRowCreate(event: TableRowCreationEvent) {
        this.switchListenersActive = false;
    }

    /** This is an event handler for the onClick event of imageEdit.  */
    imageEditOnClick(event: ClickEvent) {
       CustomVersionUtil.imageEditOnClick(event, this);
    }

    /** This is an event handler for the onClick event of buttonOpenBaseVersion.  */
    buttonOpenBaseVersionOnClick(event: ClickEvent) {
        CustomVersionUtil.buttonOpenBaseVersionOnClick(event, this);
    }

    /** This is an event handler for the onChange event of switchBaseVersionActive.  */
    switchBaseVersionActiveOnChange(event: ChangeEvent) {
        CustomVersionUtil.switchBaseVersionActiveOnChange(event, this);
    }

    /** This is an event handler for the onClick event of imageUpgradeResults. */
    async imageUpgradeResultsOnClick(event: ClickEvent) {
        CustomVersionUtil.imageUpgradeResultsOnClick(event, "designer/ui/UILayoutUpgradeResults");
    }

    public static showSlideout(designer: AbstractUIDesigner) {
        CustomVersionUtil.showSlideout(designer, Layout.getLayout("designer/ui/UILayoutVersions") as UILayoutVersions);
    }
}
