import { CommonDialogs } from "@mcleod/common";
import { Button, ButtonVariant, ClickEvent, DropdownItem, ResourceFileProps, Snackbar, Tabset } from "@mcleod/components";
import { Api, ArrayUtil, ErrorHandler, Navigation, StringUtil, handleError } from "@mcleod/core";
import { CodeEditor } from "../common/CodeEditor";
import { DesignerProps, ResourceDesigner } from "../common/ResourceDesigner";
import { PanelOpenModel } from "../ui/PanelOpenModel";
import { ModelDesignerTab } from "./ModelDesignerTab";
import { ModelDefinition, ModelTableDefinition } from "./ModelDesignerTypes";

export abstract class AbstractModelDesigner extends ResourceDesigner<ModelDesignerTab> {
    tabset: Tabset;
    buttonViewCode: Button;
    buttonViewJson: Button;
    buttonTestModel: Button;
    buttonOpen: Button;
    buttonSave: Button;
    buttonNew: Button;
    tableNames: string[];
    recordTypes: DropdownItem[];
    respFilteringControlTypes: DropdownItem[];
    respFilteringContactTypes: DropdownItem[];
    open: string;

    constructor(props: Partial<DesignerProps>) {
        super({
            ...props,
            localStorageManager: props.localStorageManager,
            endpointPath: "metadata/model"
        });
        this.tabset = new Tabset({ fillRow: true, fillHeight: true, allowStyleChange: false });
        this.addTabsetToolbar();
        this.setProps({ title: "Model Designer", fillRow: true, fillHeight: true, padding: 0 });
        this.add(this.tabset);
    }

    override createTab(props: ResourceFileProps): ModelDesignerTab {
        return new ModelDesignerTab(this, props);
    }

    override doAfterTabDefintionLoaded(tab: ModelDesignerTab, definition: any) {
        tab.designerPanel.def ??= definition;
    }

    override doAfterTabAdded(tab: ModelDesignerTab) {
        tab.designerPanel.panelModelProps.focus();
    }

    addTabsetToolbar() {
        this.buttonViewCode = this.createTabButton("View code for this model", "curlyBrackets", () => this.showCode());
        this.buttonViewJson = this.createTabButton("View raw JSON of this model", "codeTags", () => this.showJson());
        this.buttonTestModel = this.createTabButton("Test this model", "run", () => this.testModel());
        this.buttonOpen = this.createTabButton("Open a model for editing", "folder", () => this.showOpen());
        this.buttonSave = this.createTabButton("Save this model", "disk", () => this.showSave());
        this.buttonNew = this.createTabButton("Create a new model", "add", () => this.addNewTab());
        this.tabset.tools = this.tabsetTools;
    }

    protected createTabButton(tooltip: string, imageName: string, onClick: (event: ClickEvent) => void): Button {
        return new Button({ variant: ButtonVariant.round, color: "subtle.darker", tooltip, imageName, onClick});
    }

    // function called by router that can affect the properties of the router
    getRouterProps() {
        return { padding: 0 };
    }

    override async onLoad() {
        super.onLoad();
        this.selectedComponents = [];
        this.tabset.addAfterTabSelectionListener(event => this.tabChanged(event.newSelection));
        this.tabset.addBeforeTabCloseListener(event => this.beforeTabClosed(event.tab as ModelDesignerTab));
        this.tabset.addAfterTabCloseListener(event => this.afterTabClosed());
        this.loadTableList();
        this.loadRespFilteringValues();
    }

    tabChanged(tab: ModelDesignerTab) { }
    beforeTabClosed(tab: ModelDesignerTab) { }

    afterTabClosed() {
        if (this.tabset.getComponentCount() === 0)
            this.addNewTab();
    }

    async showCode() {
        let fileName = this.getActiveTab().designerPanel.def.file;
        let contents: string;
        if (fileName == null) {
            const path: string[] = this.getActiveTab().path.split("/");
            const last = path[path.length - 1];
            path.splice(path.length - 1);
            const className = StringUtil.toLowerCamelCase("Model_" + last);
            fileName = path.join("/") + "/" + className + ".java";

            if (await CommonDialogs.showYesNo("There is no associated Java file for this model.  Do you want to create and open " + fileName + "?") === false)
                return;// we probably want to ask the java api to create this file instead of specifying the contents here
            contents = `package com.tms.model.${path.join(".")};

import com.tms.api.lib.metadata.Path;
import com.tms.api.lib.model.QueryModelEndpoint;

@Path("${this.getActiveTab().path}")
public class ${className} extends QueryModelEndpoint {

}
`
        }
        fileName = "../mcleod-services/portal-api/src/main/java/com/tms/model/" + fileName;
        CodeEditor.openCodeEditor(fileName, { contentsIfEmpty: contents });
    }

    showJson() {
        const path = "../mcleod-services/portal-api/src/main/resources/models/" + this.getActiveTab().path + ".model";
        CodeEditor.openCodeEditor(path);
    }

    testModel() {
        Navigation.navigateTo("designer/model/ModelTester?model=" + encodeURI(this.getActiveTab().path), { newTab: true });
    }

    loadTableList() {
        Api.search("metadata/tables").then(response => {
            this.tableNames = [];
            for (const data of response.data)
                this.tableNames.push(data.table_name);
        });
    }

    private loadRespFilteringValues() {
        Api.search("metadata/resp-filtering").then(response => {
            this.recordTypes = [];
            this.respFilteringControlTypes = [];
            this.respFilteringContactTypes = [];
            const data = response?.data?.[0];
            if (data != null) {
                for (const recordType of data.record_types) {
                    this.recordTypes.push({ caption: recordType.caption, value: recordType.value })
                }
                for (const controlType of data.control_types) {
                    this.respFilteringControlTypes.push({ caption: controlType.caption, value: controlType.value })
                }
                for (const contactType of data.contact_types) {
                    this.respFilteringContactTypes.push({ caption: contactType.caption, value: contactType.value })
                }
            }
        });
    }

    getRecordType(value: string): DropdownItem {
        return this.getDropdownItemFromArray(value, this.recordTypes);
    }

    getRespFilteringControlType(value: string): DropdownItem {
        return this.getDropdownItemFromArray(value, this.respFilteringControlTypes);
    }

    getRespFilteringContactType(value: string): DropdownItem {
        return this.getDropdownItemFromArray(value, this.respFilteringContactTypes);
    }

    private getDropdownItemFromArray(value: string, array: DropdownItem[]): DropdownItem {
        if (StringUtil.isEmptyString(value) || ArrayUtil.isEmptyArray(array))
            return null;
        for (const item of array) {
            if (item.value === value)
                return item;
        }
        return null;
    }

    showOpen() {
        this.showOpenDialog(new PanelOpenModel());
    }

    showSave() {
        const tab = this.getActiveTab();
        if (tab.path == null) {
            tab.promptForResourceName("metadata/models").then((fullPath) => {
                if (fullPath != null) {
                    //service project will be at the front of the path, we don't need it
                    tab.path = fullPath == null ? null : StringUtil.stringAfter(fullPath, "/");
                    this.saveActiveTab();
                }
            });
        }
        else
            this.saveActiveTab();
    }

    saveActiveTab(body: any = this.getSaveRequestBody(this.getActiveTab()), errorHandler?: ErrorHandler) {
        const tab = this.getActiveTab();
        Api.update("metadata/model", body).then((response) => {
            this.doAfterActiveTabSave(tab, response.data[0]);
        }).catch(error => {
            if (errorHandler != null)
                handleError(error);
            else
                CommonDialogs.showError(error);
        });
    }

    doAfterActiveTabSave(tab: ModelDesignerTab, responseData: any) {
        tab.modified = false;
        tab.updateFromServerResponse(responseData);
        Snackbar.showSnackbar(responseData.message);
    }

    getSaveRequestBody(tab: ModelDesignerTab): any {
        return {
            require_base_version: tab.baseVersion,
            path: tab.path,
            definition: JSON.stringify(this.getActiveTabDefToPost())
        };
    }

    getActiveTabDefToPost() : ModelDefinition{
        const tab = this.getActiveTab();
        const def = tab.designerPanel.panelModelProps.def;
        if (def.orderBy == null || def.orderBy.length === 0)
            delete def.orderBy;
        this.removeDefDefaultValues(def);
        return def;
    }

    /**
     * This removes extraneous data from the model definitions.  This extraneous data includes empty arrays and other default values.
     * @param def
     */
    removeDefDefaultValues(def: Partial<ModelTableDefinition>) {
        if (def.joinConditions?.length == 0)
            delete def.joinConditions;
        if (def.fields?.length === 0)
            delete def.fields;
        if (def.joins?.length === 0)
            delete def.joins;
        if (def.qualifyFields === false)
            delete def.qualifyFields;
        if (def.joins?.length > 0)
            for (const join of def.joins)
                this.removeDefDefaultValues(join);
    }

    modified() {
        this.getActiveTab().modified = true;
    }
}
