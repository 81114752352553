import { Component, Container, Panel } from "@mcleod/components";
import { AbstractUIDesigner } from "../AbstractUIDesigner";
import { DesignerTool } from "../DesignerTool";
import { DesignerAction } from "./DesignerAction";
import { DesignerActionResult } from "./DesignerActionResult";

interface ComponentInfo {
    component: Component;
    parent: Container;
    index: number;
}

export class ActionInsertIntoNewPanel implements DesignerAction {
    components: Component[];
    componentsToMove: ComponentInfo[] = [];
    parentContainer: Container;
    panelAdded: Panel;
    designer: AbstractUIDesigner;

    constructor(components: Component[]) {
        this.components = components;
        this.parentContainer = components[0]?.parent;
    }

    execute(): DesignerActionResult {
        for (const comp of this.components)
            this.componentsToMove.push({ component: comp, parent: comp.parent, index: comp.parent.indexOf(comp) });
        this.wrapComponentsInPanel();
        return { success: true };
    }

    wrapComponentsInPanel() {
        this.componentsToMove.sort((a, b) => a.index - b.index);
        this.componentsToMove.forEach(compToMove => this.parentContainer.remove(compToMove.component));
        this.designer.notifyActionHistory = false;
        this.panelAdded = this.designer.addToolToContainer(new DesignerTool(this.designer, "panel", "Panel"), this.parentContainer, this.componentsToMove[0].index) as Panel;
        this.designer.notifyActionHistory = true;
        this.componentsToMove.forEach(compInfo => this.panelAdded.add(compInfo.component));
    }

    undo() {
        this.parentContainer.remove(this.panelAdded);
        for (const compInfo of this.componentsToMove)
            compInfo.parent.insert(compInfo.component, compInfo.index);
    }

    toString() {
        return "Insert into new " + this.panelAdded.constructor.name;
    }
}
