import { AutogenModelUserRecognizedDevice, AutogenRowUserRecognizedDevice } from "./autogen/AutogenModelUserRecognizedDevice";

export class ModelUserRecognizedDevice extends AutogenModelUserRecognizedDevice<RowUserRecognizedDevice> {
    constructor() {
        super(RowUserRecognizedDevice);
    }
}

export class RowUserRecognizedDevice extends AutogenRowUserRecognizedDevice {
}
