/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelUserHomePage.ts                  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "common/user-home-page";

export class AutogenModelUserHomePage<RowType extends ModelRow> extends Endpoint<RowType, RowUserHomePageFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowUserHomePage extends ModelRow<RowUserHomePageFields> {
	constructor(values?: Partial<RowUserHomePageFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowUserHomePageFields {
	company_id: string;
	definition: string;
	home_page: string;
	id: string;
	user_or_group_id: string;
	get_default_home_page: boolean;
}

