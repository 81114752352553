import { Event, LookupModelSelectionEvent } from "@mcleod/components";
import { AutogenLayoutCarrierCallins } from "./autogen/AutogenLayoutCarrierCallins";

export class CarrierCallins extends AutogenLayoutCarrierCallins {

    override onLoad() {
        this.addLayoutLoadListener((event: Event) => {
            this.citystate1.textCombined.addLookupModelSelectionListener((event: LookupModelSelectionEvent) => {
                this.citystate1.textCombined.tooltipCallback = null;
            });
        });
    }
}


