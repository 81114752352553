import { CommonDialogs } from "@mcleod/common";
import { Button, ChangeEvent, ClickEvent, Component, CrudDecorator, DataHeader, DataSourceExecutionEvent, DataSourceMode, DataSourceModeChangeEvent, DropdownItem, HorizontalSpacer, Label, Layout, LookupModelSearchEvent, Panel, Snackbar, Step, StepEvent, Table, TableAddRowResult, TableRow, TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { DataSource, DataSourceAction } from "@mcleod/components/src/databinding/DataSource";
import { Alignment, Api, Block, DateUtil, DisplayType, FieldUpdateEvent, HorizontalAlignment, ModelRow, ModelRowType, Navigation, ServerError, StringUtil, getLogger } from "@mcleod/core";
import { AddQuoteStop } from "./AddQuoteStop";
import { FtlQuoteDetails } from "./FtlQuoteDetails";
import { showCreditSnackbar, userCanEnterFtlQuote, validateRevenuCode } from "./OrderValidation";
import { AutogenLayoutFtlQuote } from "./autogen/AutogenLayoutFtlQuote";
import { PortalCustomerSettings } from "../../customer/src/settings/PortalCustomerSettings";
import { PortalCompanySettings } from "../../portal-common/src/settings/PortalCompanySettings";

const log = getLogger("portal-customer.FtlQuote");

const executeButtonProps = { fontSize: "medium", rowBreak: false, backgroundColor: "primary", color: "primary.reverse", width: 250, busyWhenDataSourceBusy: true }
const navigationButtonPros = { fontSize: "medium", width: 125, rowBreak: false, color: "primary" }

export class FtlQuote extends AutogenLayoutFtlQuote {
    private changingStep: boolean = false;
    private clearRates: boolean = false;
    private panelFooter: Panel;
    private selectedRate: ModelRow<any>;
    private buttonGetQuote = new Button({ ...executeButtonProps, caption: "Get Quote", onClick: () => this.postFirstStep() });
    private buttonBookOrder = new Button({ ...executeButtonProps, visible: false, caption: "Book Order", onClick: () => this.postLastStep() });
    private buttonCancel = new Button({ ...navigationButtonPros, caption: "Cancel", onClick: () => Navigation.navigateTo("/") });
    public doBeforeCommoditySearch: (filter: any) => void;
    private buttonPrevious = new Button({
        ...navigationButtonPros,
        caption: "Previous", visible: false,
        onClick: () => {
            this.buttonPreviousOnClick();
        }
    });
    private _shipper: AddQuoteStop;
    public get shipper(): AddQuoteStop {
        return this._shipper;
    }

    private errorHandler = (error: any) => {
        if (error instanceof ServerError) {
            const labels: Component[] = convertStringsToLabels(error.messages);
            const panel: Panel = new Panel({ padding: 0, width: 450 });
            for (const label of labels)
                panel.add(label);
            Snackbar.showWarningSnackbar(panel, { persist: true });
        }
        else
            Snackbar.showWarningSnackbar(error, { persist: true });
        delete this.activeRow.data["convert_to_order"];
        delete this.activeRow.data["selected_bfg"];
        this.buttonPrevious.enabled = true;
        // defect exists where posting fails but linkedModels remain,
        // causing duplicates to be sent to server
        this.activeRow["linkedModels"] = null;
    }

    override async onLoad() {
        if (userCanEnterFtlQuote()) {
            this.mainDataSource.addAfterModeChangeListener((event: DataSourceModeChangeEvent) => {
                if (event.newMode === DataSourceMode.ADD)
                    this.validateCreditAndRevenueCode();
            })
            this.dataHeader = new DataHeader({ visible: false });
            this.mainDataSource.preventFocusFirstField = true;
            this.setupStepper();
            this.addTableListeners(this.sourceQuoteStops, this.tableStops);
            this.labelWelcomeMsg.caption = PortalCustomerSettings.get().order_welcome_message;
            this.sourceQuoteStops.addAfterExecutionListener((event: DataSourceExecutionEvent) => {
                if (event.getAction() === DataSourceAction.SEARCH) {
                    for (let x = 0; x < this.sourceQuoteStops.data.length; x++) {
                        const pickupDate = this.sourceQuoteStops.data[x].get("sched_arrive_early");
                        if (pickupDate != null) {
                            this.sourceQuoteStops.data[x].set('sched_arrive_date', pickupDate);
                            this.sourceQuoteStops.data[x].set('sched_arrive_early_time', DateUtil.parseTime(pickupDate));
                        }
                    }
                    const quoteDetailsLayout = Layout.getLayout("portal-customer/FtlQuoteDetails") as FtlQuoteDetails;
                    quoteDetailsLayout.addLayoutDataLoadListener(() => {
                        const trailerTypeTextbox = quoteDetailsLayout.textboxEquipmentTypeId as Textbox
                        if (!StringUtil.isEmptyString(this.sourceFtlQuoteOrder.activeRow.get("equipment_type_id", ""))) {
                            Api.search(trailerTypeTextbox.lookupModel, { id: this.sourceFtlQuoteOrder.activeRow.get("equipment_type_id") }).then(response => {
                                if (response != null && response.data[0] != null) {
                                    const row: ModelRow = this.sourceFtlQuoteOrder.activeRow;
                                    const lmModelRow = new ModelRow(trailerTypeTextbox.lookupModel, false, { id: response.data[0].id, descr: response.data[0].descr });
                                    lmModelRow.type = ModelRowType.LOOKUP_MODEL_DATA;
                                    row.setLookupModelData("equipment_type_id", lmModelRow);
                                    row.set("equipment_type_id", response.data[0].id);
                                    trailerTypeTextbox.displayData(row, null, 0);
                                }
                            });
                        }
                        const commodityDescrTextBox = quoteDetailsLayout.textboxCommodityId as Textbox
                        if (!StringUtil.isEmptyString(this.sourceFtlQuoteOrder.activeRow.get("commodity_id", ""))) {
                            const row: ModelRow = this.sourceFtlQuoteOrder.activeRow;
                            const lmModelRow = new ModelRow(commodityDescrTextBox.lookupModel, false, { id: row.get("commodity_id", ""), descr: row.get("commodity", "") });
                            lmModelRow.type = ModelRowType.LOOKUP_MODEL_DATA;
                            row.setLookupModelData("commodity_id", lmModelRow);
                            row.set("commodity_id", row.get("commodity_id", ""));
                            commodityDescrTextBox.displayData(row, null, 0);
                        }
                    });
                }
            });
            this.doBeforeCommoditySearch = (filter: any) => {
                filter.product_book_location = this.sourceQuoteStops?.data?.[0].get("location_id");
            };
        } else {
            Navigation.navigateTo("/");
        }
    }

    buttonPreviousOnClick() {
        if (this.activeRow.data["quote_status"] == "P" || this.activeRow.data["quote_status"] == "A") {
            const companySettings = PortalCompanySettings.get();
            const contactName = companySettings.order_contact_name;
            const contactNumber = companySettings.order_contact_phone;
            const contactEmail = PortalCustomerSettings.get().order_email_list;
            const contactMessage = `Please contact ${contactName} at ${contactNumber} or ${contactEmail} for assistance.`;
            const quoteStatus = this.activeRow.data["quote_status"] == "A" ? "approved" : "pending approval";
            Snackbar.showWarningSnackbar({ caption: `This quote is ${quoteStatus} and you cannot update information about the quote.  ` + contactMessage, padding: 0, width: 450 });
            return;
        } else {
            this.searchIfNeeded().then(() => {
                this.changingStep = true;
                this.stepper.selectedIndex -= 1
            })
        }
    }

    private searchIfNeeded(): Promise<any> {
        // if navigating back to first step, search mainDataSource
        // in case user changed data on current step
        if (this.stepper.selectedIndex == 1)
            return this.mainDataSource.search({ id: this.activeRow.get("id") });
        return Promise.resolve();
    }


    setupStepper() {
        this.mainDataSource.addBusyComponent(this.buttonGetQuote);
        this.mainDataSource.addBusyComponent(this.buttonBookOrder);
        this.stepper["panelHeader"].forEveryChildComponent(comp => comp._element.style.cursor = null);
        this.panelFooter = this.stepper["panelFooter"];
        this.panelFooter.removeAll();
        this.panelFooter.align = HorizontalAlignment.LEFT;
        this.panelFooter.add(this.buttonPrevious, new HorizontalSpacer(), this.buttonCancel, this.buttonGetQuote, this.buttonBookOrder);
    }

    addTableListeners(dataSource: DataSource, table: Table) {
        this.sourceFtlQuoteOrder.addBeforeExecutionListener((event: DataSourceExecutionEvent) => {
            if (event.getAction() == DataSourceAction.SEARCH && !table.busy)
                table.busy = true;
        });
        dataSource.addAfterExecutionListener((event: DataSourceExecutionEvent) => {
            if (event.getAction() == DataSourceAction.SEARCH && table.busy == true)
                table.busy = false;
        });
    }

    async sourceQuoteStopsAfterModeChange(event: DataSourceModeChangeEvent) {
        if (event.newMode == DataSourceMode.ADD) {
            await this.addStop().then(row => row?.data?.set("stop_type", "PU"));
            await this.addStop().then(row => row?.data?.set("stop_type", "SO"))
        }
    }

    async addStop(): Promise<TableRow> {
        return await this.tableStops.dataSource.createBlankRow().then(row => {
            const addRowResult: TableAddRowResult = this.tableStops.addRow(row, { mode: TableRowMode.ADD }, { display: true, addToData: true, save: false });
            return addRowResult.row;
        });
    }

    textPickupDateOnChange(event: ChangeEvent) {
        if (event.userInitiatedChange) {
            const textbox = event.target as Textbox;
            this.sourceFtlQuoteOrder.activeRow.set("ship_date", textbox.selectedItem?.value);
            this.sourceQuoteStops?.data?.forEach(data => {
                data.set("ship_date", textbox.selectedItem?.value);
                data.set("sched_arrive_early", textbox.selectedItem?.value);
                data.set("sched_arrive_late", textbox.selectedItem?.value);
            })
        }
    }

    beforeStepListener(event: StepEvent) {
        const currentStep = this.stepper?.components?.[event.oldIndex] as Step;
        const nextStep = this.stepper?.components?.[event.newIndex] as Step;
        if (!this.changingStep) {
            event.preventDefault();
            return;
        }
        if (event.newIndex > event.oldIndex) {
            if ("stepDetails" == nextStep.id) {
                this.layoutQuoteDetails.doBeforeDisplay(null);
            }
        }
        const readyToBook: boolean = this.activeRow.data["ready_for_booking"];
        this.buttonBookOrder.visible = "stepDetails" == nextStep.id && readyToBook;
        this.buttonPrevious.visible = event.newIndex != 0;
        this.buttonGetQuote.visible = event.newIndex == 0;
        this.layoutQuoteDetails.labelTermsTitle.visible = readyToBook;
        this.layoutQuoteDetails.checkAgree.visible = readyToBook;
        if (!readyToBook) {
            this.labelWelcomeMsg.caption = "";
            this.layoutQuoteDetails.labelTerms.caption = PortalCustomerSettings.get().pending_booking_message ?? "";
        }
        this.changingStep = false;
    }

    tableStopsOnRowDisplay(event: TableRowDisplayEvent) {
        const row = event.getTableRow();
        const stopData = row.data;
        (row.findComponentById("labelSequence") as Label).caption = (row.index + 1).toString();
        const stopLayout = row.findComponentById((comp) => comp instanceof AddQuoteStop) as AddQuoteStop;
        stopLayout.addLayoutLoadListener(() => {
            stopLayout.textPickupDate.displayType = DisplayType.DATE;
            stopLayout.isFtlOrder = true;
            stopLayout.initialize(stopData, row.index == 0);
            if (row.index == 0) {
                stopLayout.buttonDelete.visible = false;
                this._shipper = stopLayout;
            } else {
                stopLayout.buttonDelete.visible = true;
                stopLayout.buttonDelete.addClickListener(() => row.table.deleteRow(row.index));
            }
            this.setSelectedItem(stopLayout.textboxEarlyPickup);
        });
    }

    setSelectedItem(textbox: Textbox) {
        const items = textbox.items as DropdownItem[];
        if (!StringUtil.isEmptyString(textbox.getDataValue()) && items != null) {
            items.forEach(item => {
                if (item.value == textbox.getDataValue()) {
                    textbox.selectedItem = item;
                    return;
                }
            })
        }
    }

    stopFieldUpdated(event: FieldUpdateEvent) {
        if (event.oldValue != event.newValue && "location_id" == event.fieldName) {
            this.updateOpenCloseTimesFromLocation(event.row);
        }
    }

    async updateOpenCloseTimesFromLocation(row: ModelRow<any>) {
        row.set({ "contact_name": null, "phone": null, "appt_required": "N" });
        if (!row.isNull("location_id")) {
            await Api.search("portal/customer/dispatch/location-auto-fill", {
                id: row.get("location_id"),
                ltl: false
            }).then(response => {
                const data = response?.data[0];
                row.set({ "contact_name": data.name, "phone": data.phone, "appt_required": data.appt_required });
            }).catch(error => {
                row.set({ "contact_name": null, "phone": null, "appt_required": "N" });
            });
        }
    }

    async postFirstStep() {
        const step1 = this.stepper.components[0] as Step;
        await this.shipper.validatePickupDates();
        if (this.shipper.hasValidPickupTimes === true && this.validateStopsInOrder() && this.validatePanel(step1)) {
            await this.checkCommidtyField();
            this.mainDataSource.post(this.errorHandler).then(() => {
                this.clearRates = true;
                this.changingStep = true;
                this.stepper.selectedIndex = 1;
            });
        }
    }
    async checkCommidtyField() {
        const commodityId = this.mainDataSource.activeRow?.get("commodity_id");
        if (!StringUtil.isEmptyString(commodityId))
            await Api.search("portal/common/ftl-commodity", { search: commodityId }).then(response => {
                if (response.data == null || response.data.length == 0) {
                    this.mainDataSource.activeRow.setValues({
                        "commodity_id": null,
                        "commodity": commodityId
                    });
                    this.mainDataSource.activeRow.setLookupModelData("commodity_id", { id: null, descr: commodityId });
                    this.textboxCommodityId.displayData(this.mainDataSource.activeRow, null, 0);
                }
            });
    }

    async postLastStep() {
        const quoteDetailsValidated: boolean = await this.layoutQuoteDetails.validateBeforePost();
        if (quoteDetailsValidated === true && this.validateStopsInOrder() && (this.validatePanel(this.layoutQuoteDetails))) {
            this.buttonPrevious.enabled = true;
            this.activeRow.set("selected_bfg", this.selectedRate?.get("bfg_uid"));
            this.activeRow.set("convert_to_order", true);
            await this.mainDataSource.post(this.errorHandler);
            const orderId = this.activeRow.get("order_id");
            if (orderId != null)
                Navigation.navigateTo("portal-customer/FtlOrderConfirmation?key=" + orderId);
        }
    }

    validatePanel(panel: Panel): boolean {
        const valid = panel.validateSimple();
        if (!valid)
            CommonDialogs.showError("Unable to save this record because data is missing or invalid.");
        else {
            if(this.sourceQuoteStops.data.length == 0) {
                Snackbar.showWarningSnackbar({ caption: "Please specify at least one pickup and one delivery.", padding: 0, width: 450 }, { id: "stopTypes", persist: true });
                return false;
            }
            const lastStop = this.sourceQuoteStops.data[this.sourceQuoteStops.data.length - 1];
            if (lastStop.get("stop_type") != "SO") {
                Snackbar.showWarningSnackbar({ caption: "The last stop must be a delivery", padding: 0, width: 450 }, { id: "stopTypes", persist: false });
                return false;
            }
        }
        return valid;
    }

    tableStopOverviewOnRowDisplay(event: TableRowDisplayEvent) {
        const row = event.getTableRow()
        const labelIndex = row.findComponentById("labelIndex") as Label;
        const labelStopType = row.findComponentById("labelStopType") as Label;
        labelIndex.caption = (row.index + 1).toString();
        if (row.index == 0) {
            labelStopType.caption = "Pickup";
        }
        if (row.index == 1) {
            labelStopType.caption = "Delivery";
            row.findComponentById("panelSpacer").borderLeftWidth = 0;
        }
    }

    sourceFtlQuoteOrderAfterExecution(event: DataSourceExecutionEvent) {
        if (this.stepper.selectedIndex == 0 && (event.getAction() == DataSourceAction.ADD || event.getAction() == DataSourceAction.UPDATE)) {
            this.sourceFtlQuoteOrder.mode = DataSourceMode.UPDATE;
        }
    }

    validateCreditAndRevenueCode() {
        if (this.activeRow != null) {
            validateRevenuCode(this.activeRow.get("revenue_code_id"), false);
            showCreditSnackbar(this.activeRow.getBoolean("credit_status_valid", false), false);
        }
    }

    buttonAddStopOnClick(event: ClickEvent) {
        this.addStop().then(row => {
            if (this.sourceQuoteStops.data.length <= 1)
                row.data?.set("stop_type", "PU");
            else
                row.data?.set("stop_type", "SO");
            row.data?.set("order_sequence", this.sourceQuoteStops.data.length);
            row.scrollIntoView({ block: Block.CENTER });
        });
    }

    textCommodityBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.search_product_book = true;
        if (this.doBeforeCommoditySearch)
            this.doBeforeCommoditySearch(event.filter);
    }

    static slideInCrudDecorator(key: string): Promise<FtlQuote> {
        return new Promise(async (resolve) => {
            const layout = Layout.getLayout("portal-customer/FtlQuote", { paddingLeft: 12, paddingRight: 12 }) as FtlQuote;
            layout.addLayoutLoadListener(async () => {
                layout.layoutQuoteDetails.validateShipperAfterSearch = false;
                if (await Navigation.pseudoNavigateTo(`/${layout.layoutName}?mode=update&key=${key}`, () => Navigation.navigateTo("/"))) {
                    const quotePanel = new CrudDecorator({
                        layout: layout, mode: DataSourceMode.UPDATE, key: key,
                        headerProps: { showClose: false, showExecute: false, title: null },
                        doAfterSlideIn: () => {
                            layout.validateCreditAndRevenueCode();
                            layout.layoutQuoteDetails.validateShipperAfterSearch = true;
                            layout.layoutQuoteDetails.validateShipper();
                        }
                    });
                    layout.mainDataSource.addAfterSearchPlusChildrenListener(() => {
                        layout.changingStep = true;
                        layout.stepper.selectedIndex = 1;
                        quotePanel.slideIn({ speed: 200 }).then(() => resolve(layout));
                    });
                }
            });
        })
    }

    validateStopsInOrder(): boolean {
        let prevDateTime = null;
        for (const row of this.sourceQuoteStops.data) {
            if (!row.isNull("sched_arrive_early")) {
                const earlyDateTime = DateUtil.parseDateTime(row.get("sched_arrive_early"));
                if (prevDateTime !== null && prevDateTime > earlyDateTime) {
                    Snackbar.showWarningSnackbar("Scheduled stop date/times must be in order. Please update.", { id: "stopDates", persist: false });
                    return false;
                }
                prevDateTime = earlyDateTime;
            }
        }
        return true;
    }

    /** This is an event handler for the onChange event of textboxCommodityId.  */
    textboxCommodityIdOnChange(event: ChangeEvent) {
        // The bound field is commodity_id which is lenght 30, but if freeform text, it's descr
        // but max length isn't checked anyway since this has a lookupmodel (textbox._checkMaxLength)
        if (event.newValue.length > 80) {
            this.textboxCommodityId.showTooltip("You have exceeded the 80 character limit.", { position: Alignment.RIGHT, shaking: true, timeout: 5000 });
            this.textboxCommodityId.text = event.oldValue;
        }
    }
}

function convertStringsToLabels(content: string | string[]): Label[] {
    if (content == null)
        return null;
    if (!Array.isArray(content))
        content = [content];
    const labels: Label[] = [];
    for (const line of content)
        labels.push(new Label({ caption: appendPeriod(line), fontBold: true, fillRow: true }));
    return labels;
}
function appendPeriod(message: string): string {
    if (message == null || message.endsWith("."))
        return message;
    return message + ".";
}
