import { Button, DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCounter extends Layout {
    buttonConfirmCounter: Button;
    labelAdditionalDetails: Label;
    labelCounterRate: Label;
    labelDeliveryDate: Label;
    labelDeliveryDateTZ: Label;
    labelDistance: Label;
    labelDistanceCaption: Label;
    labelDistanceUnits: Label;
    labelEmailConfirmation: Label;
    labelPickupDate: Label;
    labelPickupDateTZ: Label;
    labelSuccess: Label;
    labelThisLoadIsNoLongerAvailable: Label;
    panelAdditionalDetails: Panel;
    panelAvailable: Panel;
    panelCommodityAndEquipment: Panel;
    panelDelivery: Panel;
    panelNotAvailable: Panel;
    panelPickup: Panel;
    panelSuccess: Panel;
    sourceCarrierAvailableOrders: DataSource;
    textboxCommodity: Textbox;
    textboxCounterAmount: Textbox;
    textboxDestinationLocation: Textbox;
    textboxEquipmentDescr: Textbox;
    textboxEquipmentLength: Textbox;
    textboxLTL: Textbox;
    textboxOrderId: Textbox;
    textboxOriginLocation: Textbox;
    textboxRateCurrency: Textbox;
    textboxWeight: Textbox;
}
