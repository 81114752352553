import { ObjectUtil } from "../../ObjectUtil";
import { ThemeCommonComponents } from "../common/ThemeCommonComponents";
import { ThemeCommonFont } from "../common/ThemeCommonFont";
import { ThemeCommonPage } from "../common/ThemeCommonPage";
import { ThemeDefaultPalette } from "./ThemeDefaultPalette";

/**
 * First, we establish the colors that are available for this theme (light/dark/etc) to use and then we use those
 * colors in higher level components
*/
export const ThemeDefaults = {
    // first add the default palette (the standard colors that have darkest, darker, dark)
    ...ThemeDefaultPalette,

    // then add other colors that don't have the standard darkest -> lightest members
    defaultColor: "palette.default.base",
    defaultBackground: "#fff",
    backgroundSubtle: "#f9f9f9",
    background2: "#f6f9f0",  // used by main menu (and presumably other things later where we need the background to stand apart from the main background)
    "background2-reverse": "#111",
    background3: "#777", // used by tooltips
    background4: "#dcefff66", //used by component demo
    background5: "#e5f5f5", //used by tooltips
    background6: "#f6f6f6", //used by some panels
    headerBackground: "#ddd",
    headerColor: "#000",
    buttonHoverColor: "#ababab",
    buttonFocusColor: "#ddd",
    strokePrimary: "#979797",
    strokeSecondary: "#cdcdcd",
    McLeodPrimary: "#595959",
    McLeodSecondary: "#000000",
    McLeodTertiary: "#9E9E9E",
    McLeodDisable: "#BFBFBF",
    McLeodWhite: "#FFFFFF",
    McLeodWhiteDisabled: "#808080",
    McLeodLinkSpecial: "#137CDD",
    McLeodCTASpecial: "#458719",
    McLeodAlert: "#B60909",
    McLeodCaution: "#F7B500",
    McLeodWarning: "#EF7200",


    // members defined in component.palette are used by specific components in ThemeCommonComponents
    component: {
        palette: {
            checkbox: {
                color: "primary",
                hover: {
                    backgroundColor: "#DEDEDE"
                }
            },
            clock: {
                backgroundColor: "#00000012",
                hand: {
                    backgroundColor: "primary.light",
                },
                selection: {
                    backgroundColor: "primary.light"
                },
                numbers: {
                    color: "default"
                }
            },
            overlay: {
                backgroundColor: "#00000044",
            },
            snackbar: {
                color: "McLeodWhite",
                backgroundColor: "default.darker",
            },
            table: {
                editRow: {
                    textbox: {
                        backgroundColor: "primary.reverse",
                    }
                },
                headingRow: {
                    color: "McLeodSecondary",
                    backgroundColor: "backgroundSubtle",
                },
                hover: {
                    backgroundColor: "primary.slight"
                },
                selection: {
                    backgroundColor: "primary.lightest",
                }
            },
            textbox: {
                button: {
                    color: "subtle.light"
                },
                caption: {
                    color: "subtle.darker"
                },
                disabled: {
                    color: null,
                    backgroundColor: "subtle.lightest"
                },
                enabled: {
                    color: "background2-reverse",
                    backgroundColor: "McLeodWhite"
                }
            }
        }
    },

    // Now that those colors are defined, they are usable in higher level components
    ...ObjectUtil.deepCopy(ThemeCommonFont),
    ...ObjectUtil.deepCopy(ThemeCommonComponents),
    ...ObjectUtil.deepCopy(ThemeCommonPage)
}
