import { Api, ArrayUtil } from ".";
import { ExternalLink } from "./ExternalLink";

export class HelpLink extends ExternalLink {
    //note that the 'url' member of a HelpLinks can be a url or a video item id
    //if a video item id, finalized url is resolved by calling video/create-url below

    protected override async resolveURL(): Promise<void> {
        if (this.urlIsValid() === true)
            return;
        //helpLink is assumed to be a video ID, ask the server for a current URL to access that video
        const response = await Api.post("video/create-url", { id: this.url });
        const data = ArrayUtil.isEmptyArray(response?.data) !== true ? response.data[0] : null;
        this.url = data?.url;
    }
}
