import { Button, ButtonVariant, ClickEvent, Component, Dialog, Layout, LayoutProps, ResourceFileProps, Tabset } from "@mcleod/components";
import { ReflectiveDialogs } from "@mcleod/components/src/base/ReflectiveDialogs";
import { AuthType, StringUtil } from "@mcleod/core";
import { AbstractPanelOpenTree } from "../ui/AbstractPanelOpenTree";
import { DesignerTabDescriptor } from "../ui/DesignerTabDescriptor";
import { LocalStorageManager } from "./LocalStorageManager";
import { ResourceDesignerTab } from "./ResourceDesignerTab";

export interface DesignerProps extends Partial<LayoutProps> {
    localStorageManager: LocalStorageManager;
    endpointPath: string;
    open?: string;
}

export abstract class ResourceDesigner<T extends ResourceDesignerTab> extends Layout {
    selectedComponents: Component[];
    localStorageManager: LocalStorageManager;
    open: string; // passed as props from URL
    openBase: string; // passed as props from URL
    finishedLoading = false;
    private _endpointPath: string;

    abstract tabset: Tabset;
    abstract createTab(props: ResourceFileProps): T;
    abstract tabsetTools: Button[];
    abstract doAfterTabDefintionLoaded(tab: T, definition: any): void;
    abstract doAfterTabAdded(tab: T): void;


    constructor(props: DesignerProps) {
        super({ auth: AuthType.LME, fillHeight: true, scrollX: true, needsServerLayout: false, ...props });
        this.selectedComponents = [];
    }

    override async onLoad() {
        this.loadInitialTabs();
    }

    // function called by router that can affect the properties of the router
    getRouterProps() {
        return { padding: 0 };
    }

    protected createTabButton(tooltip: string, imageName: string, onClick: (event: ClickEvent) => void): Button {
        return new Button({ variant: ButtonVariant.round, color: "subtle.darker", tooltip, imageName, onClick});
    }

    async loadInitialTabs() {
        await this.loadFromLocalStorage();
        if (this.open != null) {
            await this.openTab({path: this.open, baseVersion: this.openBase === "true"}, true);
        }

        if (this.tabset.isEmpty()) {
            this.addNewTab();
        }
    }

    async loadFromLocalStorage(){
        let tabToSelect = null;
        if (this.localStorageManager != null) {
            const open = this.localStorageManager?.getLastOpen();
            if (open.length > 0)  {
                const lastSel = this.localStorageManager.getLastSelected();
                for (let i = 0; i < open.length; i++) {
                    const tab = await this.openTab(open[i], false);
                    // only select the from local storage if a path wasn't passed as props from the URL
                    if (this.open == null && open[i].equals(lastSel))
                        tabToSelect = tab;
                }
            }
        }

        this.finishedLoading = true;
        if (tabToSelect != null)
            tabToSelect.select();
    }

    get firstSelected(): Component {
        if (this.selectedComponents.length === 0)
            return null;
        return this.selectedComponents[0];
    }

    public get endpointPath(): string {
        return this._endpointPath;
    }

    public set endpointPath(value: string) {
        this._endpointPath = value;
    }

    getActiveTab(): T {
        return this.tabset.getActiveTab() as T;
    }

    findOpenTab(predicate: (tab: T) => boolean): T {
        return [...this.tabset].find(predicate);
    }

    findOpenTabs(predicate: (tab: T) => boolean): T[] {
        return [...this.tabset].filter(predicate);
    }

    modified() {
        this.getActiveTab().modified = true;
    }

    isModified(): boolean {
        return [...this.tabset].some(tab => tab.modified);
    }

    findOpenTabByDescriptor(descriptor: DesignerTabDescriptor, select: boolean = false): T {
        if (descriptor?.path == null)
            return null;
        const openTab = this.findOpenTab(openTab => descriptor.equals(openTab.descriptor));
        if (select && openTab != null)
            openTab.select();
        return openTab;
    }

    addNewTab(): T {
        return this.addTab(this.createTab({ path: null }), true);
    }

    async openTab(props: ResourceFileProps, selectTab: boolean = true): Promise<T> {
        props ??= { path: null };
        if (props.path == null) {
            return this.addNewTab();
        }
        const tab = this.createTab(props);
        const definition = await tab.initilaizeAndGetDefinition();
        const existingTab = this.findOpenTabByDescriptor(tab.descriptor, selectTab);
        if (existingTab != null) {
            if (selectTab === true)
                existingTab.select();
            return existingTab;
        }
        this.addTab(tab, selectTab);
        if (tab.definition != null)
            this.doAfterTabDefintionLoaded(tab, definition);
        return tab;
    }

    private addTab(tab: T, selectTab: boolean = true): T {
        this.tabset.add(tab);
        if (this.doAfterTabAdded != null)
            this.doAfterTabAdded(tab);
        if (selectTab === true)
            this.tabset.selectedIndex = this.tabset.getComponentCount() - 1;
        return tab;
    }

    showOpenDialog(pnl: AbstractPanelOpenTree) {
        ReflectiveDialogs.showDialog(pnl, { title: "Open Model", height: 600, width: 500 }).then((dialog: Dialog) => {
            if (dialog.wasCancelled === true || pnl.tree?.selectedNode?.path == null)
                return;
            let path = null;
            const selectedNode = pnl.tree.selectedNode;
            selectedNode.path.forEach((node) => {
                if (path == null) {
                    path = node.caption;
                } else {
                    path += "/" + node.caption;
                }
            });
            path = StringUtil.stringAfter(path, "/"); //service project will be at the front of the path, we don't need it

            if (selectedNode.hasChildren() === true) //user tried to select a directory entry in the tree
                return; //returning here is better than an error, but is still weak sauce.  ideally the OK button in the dialog would not be available in this situation.

            const baseVersion = selectedNode.data?.base_version ?? true;

            this.openTab({path, baseVersion}, true);
        });
    }
}
