import { Button, ButtonVariant, ClickEvent, DomEventListener, Image, ImageType, KeyModifiers, Label, LabelProps, Layout, Panel, PanelProps, ScreenStack } from "@mcleod/components";
import { Alignment, ArrayUtil, AuthToken, CompanySettings, GeneralSettings, Keys, Navigation, ShiftedNumbers, StringUtil, UserSettings, VerticalAlignment } from "@mcleod/core";
import { CommonDialogs } from "./CommonDialogs";
import { DiagnosticDialog } from "./DiagnosticDialog";
import { ExternalLinkLauncher } from "./ExternalLinkLauncher";
import { Login } from "./Login";
import { MainMenu } from "./MainMenu";
import { Router } from "./Router";
import { PageHeaderProvider } from "./PageHeaderProvider";
import { SupportEmailProvider } from "./SupportEmailProvider";

export class PageHeader extends Panel {
    keyListener: DomEventListener;
    panelLogo: Panel;
    filler: Panel;
    panelNotifications: Panel;
    panelUser: Panel;
    panelSupportEmail: Panel;
    menu: MainMenu;
    buttonMenu: Button;
    buttonHelp: Button;
    labelEnvironmentType: Label;

    constructor(props?: PanelProps) {
        super({ id: "pageHeader", padding: 0, fillRow: true, verticalAlign: VerticalAlignment.CENTER, ...props });
        this.keyListener = (event) => this.handleKey(event as KeyboardEvent);
        document.addEventListener("keydown", this.keyListener);
        this.panelLogo = new Panel({ id: "headerLogoContainer", padding: 0, height: 60, rowBreak: false });
        this.filler = new Panel({ fillRow: true, rowBreak: false });
        this.panelNotifications = new Panel({ id: "notificationContainer", rowBreak: false });
        this.panelUser = new Panel({ id: "userContainer", fillHeight: true, verticalAlign: VerticalAlignment.CENTER });
        this.panelSupportEmail = new Panel({ id: "supportEmailPanel", verticalAlign: VerticalAlignment.CENTER, rowBreak: false, padding: 0 });
        this.createHelpButton();
        this.add(this.panelLogo, this.filler, this.buttonHelp, this.panelNotifications, this.panelUser);
        this.applyUnauthSettings();
    }

    private createHelpButton() {
        this.buttonHelp = new Button({
            id: "buttonHelp", tooltip: "Click here to open the eLearning Portal for this page.",
            imageHeight: 32, imageWidth: 32, height: 50, width: 50,
            backgroundColor: CompanySettings.get()?.header_bg_color,
            variant: ButtonVariant.round, imageName: "lightBulb", visible: false, rowBreak: false
        });
        let currentLayouts = []; // contains all the layouts from a single container with the highest z-index
        this.buttonHelp.addClickListener(() => ExternalLinkLauncher.showLinks(currentLayouts, this.buttonHelp));
        ScreenStack.addCurrentLayoutsListener((panel: Panel, layouts: Layout[]) => {
            if (layouts?.length > 0)
                currentLayouts = layouts;
            this.evaluateExternalLinkVisibility(panel, currentLayouts);
        });
    }

    public evaluateExternalLinkVisibility(topMostPanel: Panel, currentLayouts: Layout[]) {
        // Only show the help button if the top-most layout contains a helplink, or 
        // if some additional external link is available.
        // If the current panel / overlay doesn't contain any Layouts (Main Menu, Dialogs), then
        // set visiblity based on the top-most layout.
        this.buttonHelp.visible =
            ArrayUtil.isEmptyArray(ExternalLinkLauncher.discoverExternalLinks(currentLayouts)) === false;
        if (this.buttonHelp.visible === true)
            this.buttonHelp.zIndex = (topMostPanel.getEffectiveZIndex() ?? 0) + 1;
    }

    private applyUnauthSettings() {
        const settings = CompanySettings.get();
        if (settings != null) {
            if (settings.text_color != null)
                this.color = settings.text_color;
            if (settings.header_bg_color != null)
                this.backgroundColor = settings.header_bg_color;
            if (settings.header_logo != null)
                this.panelLogo.add(new Image({ imageType: ImageType.IMG, imageBytes: settings.header_logo, height: 60, width: "auto" }));
            const environmentMessage = GeneralSettings.get()?.environment_message;
            if (environmentMessage != null) {
                this.labelEnvironmentType = new Label({
                    caption: environmentMessage,
                    fontBold: true,
                    fontSize: "xxlarge",
                    marginLeft: 64,
                    fillHeight: true,
                    verticalAlign: VerticalAlignment.CENTER
                });
                this.filler.add(this.labelEnvironmentType)
            }
        }
        else
            this.removeAll();
        GeneralSettings.getSingleton().addSettingsChangeListener((newConfig: any, oldConfig: any, isAuthSet: boolean) => {
            this.displayUserInfo();
            this.displaySupportEmailLink();
        });
        this.displayUserInfo();
        this.displaySupportEmailLink();
    }

    handleKey(event: KeyboardEvent) {
        const ctrlKeyOnly: KeyModifiers = { ctrlKey: true, shiftKey: false, altKey: false };
        if (event.key === "m" && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true) {
            this.showMenu();
            event.preventDefault();
        }
        else if (event.key >= "0" && event.key <= "9" && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true) {
            this.getMenu().showToolbox(event);
            event.stopPropagation();
            event.preventDefault();
        }
        else if (KeyModifiers.hasModifiers({ ctrlKey: true, shiftKey: true, altKey: false }, event) &&
            ((event.key >= "0" && event.key <= "9") || ShiftedNumbers[event.key] != null)) {
            let numberKey: string;
            if (ShiftedNumbers[event.key] != null)
                numberKey = ShiftedNumbers[event.key];
            else
                numberKey = event.key;
            this.getMenu().showFavorite(event, numberKey);
            event.stopPropagation();
            event.preventDefault();
        }
        else if (event.key === "h" && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true) {
            Navigation.navigateTo("");
            event.preventDefault();
        }
        else if (event.key === Keys.E.toLowerCase() && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true) {
            this.showMenu();
            this.getMenu().selectFirstToolboxItem();
            event.preventDefault();
        }
        else if (event.key === Keys.D.toLowerCase() && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true) {
            this.showMenu();
            this.getMenu().selectFirstFavorite();
            event.preventDefault();
        }
        else if (event.key === "F10" && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true)
            new DiagnosticDialog().show();
        else if (event.key === "F11" && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true)
            this.openInModelDesigner();
        else if (event.key === "F12" && KeyModifiers.hasModifiers(ctrlKeyOnly, event) === true)
            this.openInUIDesigner();
    }

    openInModelDesigner() {
        if (GeneralSettings.getSingleton().isRunningInIDE()) {
            const layout = ScreenStack.getCurrentLayout();
            if (layout == null)
                CommonDialogs.showMessage("Could not discover current layout.");
            else {
                const url = layout.mainDataSource?.url;
                if (url == null)
                    CommonDialogs.showMessage("The layout doesn't have a model.");
                else
                    Navigation.navigateTo("designer/model/ModelDesigner?open=" + url, { newTab: true });
            }
        }
    }

    openInUIDesigner() {
        const layout = ScreenStack.getCurrentLayout();
        if (layout == null)
            CommonDialogs.showMessage("Could not discover current layout.");
        else {
            let path = "designer/ui/custom/McLeodTailor";
            if (GeneralSettings.getSingleton().isRunningInIDE())
                path = "designer/ui/UIDesigner";
            Navigation.navigateTo(`${path}?open=${layout.layoutName}`, { newTab: true });
        }
    }

    displayUserInfo() {
        this.panelUser.removeAll();
        const userSettings = UserSettings.get();
        if (AuthToken.isAuthenticated() && userSettings != null) {
            const panelInfo = new Panel({ fillRow: true, rowBreak: false, padding: 0, verticalAlign: VerticalAlignment.CENTER, marginRight: 8 });
            this.panelUser.add(panelInfo);
            const panelDropdown = new Panel({ verticalAlign: VerticalAlignment.CENTER, fillHeight: true, padding: 0 });
            this.panelUser.add(panelDropdown);
            const dropdown = this.createUserDropdown();
            panelDropdown.add(new Button({ imageName: "chevron", imageWidth: 36, imageHeight: 36, variant: ButtonVariant.round, marginLeft: 0, marginRight: 14, dropdownItems: dropdown, dropdownProps: { align: Alignment.RIGHT } }));
            panelInfo.add(new Label({ fontSize: "large", fillHeight: true, caption: userSettings.user_name }));
            if (!StringUtil.isEmptyString(userSettings.user_company_name))
                panelInfo.add(new Label({ fontSize: "small", fillHeight: true, caption: userSettings.user_company_name }));
            if (this.buttonMenu == null) {
                this.buttonMenu = new Button({ imageName: "hamburger", variant: ButtonVariant.round, marginLeft: 14, marginRight: 14, imageWidth: 28, imageHeight: 28, rowBreak: false });
                this.buttonMenu.addClickListener(event => this.showMenu());
            }
            this.insert(this.buttonMenu, 0);
        } else {
            this.remove(this.buttonMenu);
            this.panelUser.add(new Label({ caption: "Sign in", link: Router.loginRoute, fillHeight: true, marginRight: 8 }));
        }
    }

    private displaySupportEmailLink() {
        this.panelSupportEmail.removeAll();
        if (SupportEmailProvider.get()?.shouldDisplayPageHeaderSupportEmailLink() === true) {
            this.panelSupportEmail.add(new Image({
                name: "mail",
                onClick: (event: ClickEvent) => SupportEmailProvider.get()?.doOnSupportEmailClick(),
                fillHeight: true,
                marginRight: 8,
                tooltip: "Let us know how we can help!"
            }));
            if (this.indexOf(this.panelSupportEmail) < 0)
                this.insert(this.panelSupportEmail, this.indexOf(this.panelUser));
        }
        else
            this.remove(this.panelSupportEmail);
    }

    createUserDropdown() {
        const allProps = { paddingRight: 28, paddingLeft: 8, paddingBottom: 8, paddingTop: 8, fontSize: "large", color: "subtle.darker" }

        const labelSignOut = new Label({ caption: "Sign out", imageName: "exit", ...allProps, borderTopWidth: 1, borderTopColor: "strokeSecondary" });
        labelSignOut.addClickListener((event: ClickEvent) => {
            Login.logout();
        });
        const result = [];
        if (UserSettings.get().user_settings_page_access) {
            const labelSettings = new Label({ caption: "Settings", imageName: "settings", ...allProps });
            labelSettings.addClickListener((event: ClickEvent) => {
                Navigation.navigateTo("common/UserSettings");
            });
            result.push(labelSettings);
        }
        const addlDropdownItems: Label[] = this.getAdditionalUserDropdownItems(allProps);
        if (ArrayUtil.isEmptyArray(addlDropdownItems) === false)
            result.push(...addlDropdownItems);
        result.push(labelSignOut);
        return result;
    }

    private getAdditionalUserDropdownItems(sharedProps: Partial<LabelProps>): Label[] {
        return PageHeaderProvider.get()?.getUserDropdownMenuItems(sharedProps);
    }

    getMenu() {
        if (this.menu == null)
            this.menu = new MainMenu({ width: 300, height: 0, borderTopRightRadius: 8, borderBottomRightRadius: 8, borderBottomLeftRadius: 8, padding: 0 });
        return this.menu;
    }

    showMenu() {
        this.getMenu().show(this.buttonMenu);
    }
}
