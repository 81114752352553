import { CommonDialogs } from "@mcleod/common";
import { BlurEvent, Button, ClickEvent, DataSource, DataSourceAction, DataSourceExecutionEvent, LookupModelSearchEvent, LookupModelSelectionEvent, Panel, PrintableEvent, PrintableListener, RowModeControlType, Snackbar, TableContentsChangedEvent, TableRow, TableRowBeforeSaveEvent, TableRowCreationEvent, TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { Api, Block, FieldUpdateEvent, HorizontalAlignment, ModelRow, NumberUtil } from "@mcleod/core";
import { PortalCustomerSettings } from "../../../customer/src/settings/PortalCustomerSettings";
import { PortalCompanySettings } from "../../../portal-common/src/settings/PortalCompanySettings";
import { LtlUtil } from "./LtlUtil";
import { AutogenLayoutFreightItemTable } from "./autogen/AutogenLayoutFreightItemTable";

const fgiCommodityFields = ["nmfc_class_code", "_lookup_nmfc_class_code", "fgi_packaging_type_code", "_lookup_fgi_packaging_type_code",
    "length", "height", "width", "description", "nmfc_code", "_lookup_nmfc_code",
    "weight", "weight_uom_type_code", "req_spots", "handling_units",
    "hazmat", "hazmat_number", "hazmat_class_code", "_lookup_hazmat_class_code",
    "hazmat_packing_group", "lookup_hazmat_packing_group", "hazmat_proper_shipname", "hazmat_erg_number"];

const dimensionFields = ["length", "width", "height"];
const requiredFields = ["pieces", "weight", "req_spots", "fgi_packaging_type_code", "nmfc_class_code", "handling_units"];

const requiredHazmatFields = ["hazmat_unna_nbr", "hazmat_proper_shipname", "hazmat_class_code", "hazmat_packing_group"];

interface FgiData {
    row: TableRow,
    textLength: Textbox,
    textWidth: Textbox,
    textHeight: Textbox,
    textHandlingUnits: Textbox,
    textWeight: Textbox,
    textboxLinearUnits: Textbox,
    textboxCubicUnits: Textbox
}

export class FreightItemTable extends AutogenLayoutFreightItemTable {
    private fgiMap = new Map<number, FgiData>();
    private _sourceQuote: DataSource;
    public doBeforeCommoditySearch: (filter: any) => void;
    private maxWeight = PortalCompanySettings.get().ltl_max_weight;
    private unitOfMeasure = PortalCompanySettings.get().ltl_freight_dimension_uom;
    private _showDetails: boolean = false;
    private printableListenter: PrintableListener;

    private _tableDataSource: DataSource;

    override onLoad() {
        this.printableListenter = (event: PrintableEvent) => this.setPrintableLabelProps(event.target as Textbox);
        this.tableFreightItems.addRowBeforeSaveListener((event: TableRowBeforeSaveEvent) => {
            this.tableFreightItemsBeforeSave(event);
        });
    }

    async tableFreightItemsBeforeSave(event: TableRowBeforeSaveEvent) {
        const data = event.getTableRow().data;
        const suggestedClass = data.get("suggested_nmfc_class");
        data.set("suggested_nmfc_class", null);

        if (suggestedClass != null && data.get("nmfc_class_code") != suggestedClass) {
            if (await CommonDialogs.showYesNo(`Density does not match freight class.  Would you like to update to class ${suggestedClass} ?`))
                data.set("nmfc_class_code", suggestedClass);
        }
    }

    public get sourceQuote(): DataSource {
        return this._sourceQuote;
    }


    public get tableDataSource(): DataSource {
        return this._tableDataSource;
    }
    public set tableDataSource(value: DataSource) {
        this._tableDataSource = value;
        this.tableFreightItems.dataSource = value;
        this.tableDataSource.addAfterExecutionListener((event) => this.sourceFreightGroupItemAfterExecution(event));
    }

    sourceFreightGroupItemAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.SEARCH) {
            this.setFgiTotals();
        }
    }

    tableFreightItemsOnContentsChanged(event: TableContentsChangedEvent) {
        this.setFgiTotals();
        const editingRows = this.getRowsInAddOrUpdateMode();
        this.tableFreightItems.rows.forEach(row => {
            if (!editingRows.includes(row))
                this.setTableRowBorder(row, "subtle.light")
        });
    }

    buttonAddItemOnClick(event: ClickEvent) {
        if (this.validateFreightItemsMode()) {
            this.addFreightItemToTable().then((tableRow: TableRow) => {
                const textCommodity = tableRow.findComponentById("textCommodity") as Textbox;
                tableRow.scrollIntoView({ block: Block.CENTER });
                setTimeout(() => { textCommodity.focus() }, 500);
            });
        }
    }

    async addFreightItemToTable(): Promise<TableRow> {
        return new Promise((resolve) => {
            this.tableFreightItems._createNewRowData().then(row => {
                const addRowResult = this.tableFreightItems.addRow(row, { mode: TableRowMode.ADD }, { display: true, addToData: true, save: false });
                resolve(addRowResult.row);
            });
        });
    }

    tableFreightItemsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.target as TableRow;
        const modelRow = tableRow.data;
        modelRow.addAfterFieldUpdateListener((event: FieldUpdateEvent) => {
            if ((event.fieldName == "length" || event.fieldName == "width" || event.fieldName == "height") && event.newValue == null) {
                modelRow.setValues({ cubic_units: null, linear_units: null, density: null, suggested_nmfc_class: null })
            }
        });

        const fgiData: FgiData = {
            row: tableRow,
            textLength: tableRow.findComponentById("textLength") as Textbox,
            textWidth: tableRow.findComponentById("textWidth") as Textbox,
            textHeight: tableRow.findComponentById("textHeight") as Textbox,
            textHandlingUnits: tableRow.findComponentById("textHandlingUnits") as Textbox,
            textWeight: tableRow.findComponentById("textWeight") as Textbox,
            textboxLinearUnits: tableRow.findComponentById("textboxLinearUnits") as Textbox,
            textboxCubicUnits: tableRow.findComponentById("textboxCubicUnits") as Textbox
        }
        this.fgiMap.set(tableRow.index, fgiData);
        this.addDenistyListeners(fgiData);
        this.setTableRowBorder(tableRow, "subtle.light");
        tableRow.forEveryChildComponent((comp) => {
            if (dimensionFields.includes(comp.field)) {
                if (comp instanceof Textbox && this.unitOfMeasure != null)
                    comp.caption = `${comp.caption} (${this.unitOfMeasure})`;

                comp.required = PortalCompanySettings.getSingleton().isBrokerage();
            } else {
                comp.required = requiredFields.includes(comp.field)
            }
            tableRow.forEveryChildComponent((comp) => {
                if (comp instanceof Textbox) {
                    comp.addPrintableListener(this.printableListenter);
                    this.setPrintableLabelProps(comp);
                }
            });
        });
        const textboxHazmatUnnaNbr = tableRow.findComponentById("textboxHazmatUnnaNbr") as Textbox;
        textboxHazmatUnnaNbr.addLookupModelSelectionListener((event: LookupModelSelectionEvent) => this.textboxHazmatUnnaNbrOnLookupModelSelection(event));
    }



    private addDenistyListeners(fgiData: FgiData) {

        const blurListener = (event: BlurEvent) => {
            if (event.changedWhileFocused)
                this.calculateCubeAndDensity(fgiData, (event.target as Textbox).field);
        };

        Object.values(fgiData).forEach(obj => {
            if (obj instanceof Textbox)
                obj.addBlurListener(blurListener);
        });
    }

    calculateCubeAndDensity(fgiData: FgiData, changedField?: string) {
        const data = fgiData.row.data;

        let body: any = {
            weight: data.get("weight"),
            length: data.get("length"),
            width: data.get("width"),
            height: data.get("height"),
            handling_units: data.get("handling_units"),
            nmfc_class_code: data.get("nmfc_class_code")
        }

        if (Object.values(body).every(o => o != null)) {
            body = {
                linear_units: data.get("linear_units"),
                cubic_units: data.get("cubic_units"),
                changed_field: changedField,
                ...body
            }
            data.set("suggested_nmfc_class", null);
            this.enableTableRowButtons(fgiData.row, false);
            Api.post("portal/customer/dispatch/fgi-calculations", body).then(result => {
                const apiData = result.data[0];
                if (apiData) {
                    data.set("cubic_units", apiData.cubic_units);
                    data.set("linear_units", apiData.linear_units);
                    data.set("density", apiData.density);
                    data.set("suggested_nmfc_class", apiData.nmfc_class_code)
                }
            }).finally(() => {
                this.enableTableRowButtons(fgiData.row, true);
            })
        }
    }

    enableTableRowButtons(tableRow: TableRow, enable: boolean) {
        tableRow.forEveryChildComponent(comp => {
            if (comp instanceof Button) {
                comp.enabled = enable;
            }
        })
    }

    private setTableRowBorder(tableRow: TableRow, borderColor: string) {
        tableRow.setProps({ borderWidth: 1, borderRadius: 4, borderColor: borderColor, paddingTop: 12, paddingBottom: 20, marginTop: 15 });
    }

    tableFreightItemsOnRowCreate(event: TableRowCreationEvent) {
        const tableRow = event.target as TableRow;
        tableRow.data?.set("lme_order_id", this.sourceQuote?.activeRow?.get("id"));
    }

    setPrintableLabelProps(textbox: Textbox) {
        if (textbox.printableLabel) {
            if (textbox.required)
                textbox.printableLabel.paddingLeft = 5;
            textbox.printableLabel.setProps({ width: undefined, margin: undefined, marginRight: undefined, align: HorizontalAlignment.LEFT });
        }
    }

    buttonDeleteFgiOnClick(event: ClickEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Button);
        tableRow.deleteRow();
    }

    textFreightClassBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.field_table_alias = "FGI";
        event.filter.field_name = "nmfc_class_code";
    }

    textPackagingTypeBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.field_table_alias = "FGI";
        event.filter.field_name = "fgi_packaging_type_code";
    }

    textCommodityBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.search_product_book = true;
        if (this.doBeforeCommoditySearch)
            this.doBeforeCommoditySearch(event.filter);
        const textCommodity = event.target as Textbox;
        textCommodity.addLookupModelSelectionListener( (event: LookupModelSelectionEvent) => {
            this.commoditySelected(event.target, event.selectedRow);
        });
    }

    commoditySelected(textCommodity: Textbox, selectedRow: ModelRow<any>) {
        if (selectedRow != null) {
            const row = textCommodity.getRelevantModelRow();
            const tableRow = TableRow.getContainingTableRow(textCommodity);
            fgiCommodityFields.forEach(field => row.set(field, null));
            let pieces = null;
            if (selectedRow.getBoolean("weight_is_per_piece") ||
                selectedRow.getBoolean("spots_is_per_piece") ||
                selectedRow.getBoolean("handling_units_is_per_piece")) {
                pieces = 1;
            }
            row.set("pieces", pieces);
            const fgiData = selectedRow.get("fgi_data");
            if (fgiData != null)
                row.set({ ...fgiData });

            delete selectedRow.data.fgi_data;
            this.piecesUpdated(textCommodity);
            tableRow.cells[0].displayData(row, null, 0);
            this.calculateCubeAndDensity(this.fgiMap.get(tableRow.index))
        }
    }

    textPiecesOnBlur(event: BlurEvent) {
        if (event.changedWhileFocused)
            this.piecesUpdated(event.target as Textbox)
    }

    piecesUpdated(textbox: Textbox) {
        const tableRow = TableRow.getContainingTableRow(textbox);
        const commodity = tableRow.data.getFirstLookupModelData("commodity_id");
        const weight = tableRow.data.get("weight");
        const handlingUnits = tableRow.data.get("handling_units");
        if (commodity != null) {
            this.setPerPieceValues(tableRow, "weight", this.getCommodityPerPiece(commodity, "weight"));
            this.setPerPieceValues(tableRow, "req_spots", this.getCommodityPerPiece(commodity, "req_spots", "spots_is_per_piece"));
            this.setPerPieceValues(tableRow, "handling_units", this.getCommodityPerPiece(commodity, "handling_units"));
        }

        const weightUpdated = weight != tableRow.data.get("weight");
        const handlingUnitsUpdated = handlingUnits != tableRow.data.get("handling_units");
        if (weightUpdated || handlingUnitsUpdated) {
            this.calculateCubeAndDensity(this.fgiMap.get(tableRow.index), handlingUnitsUpdated ? "handling_units" : "weight")
        }

    }

    private getCommodityPerPiece(commodity: ModelRow, field: string, perPieceField: string = field + "_is_per_piece"): number {
        let perPiece = null;
        if (!commodity.isNull(field) && commodity.getBoolean(perPieceField, false))
            perPiece = commodity.get(field);
        return perPiece
    }

    private setPerPieceValues(tableRow: TableRow, field: string, perPiece: number) {
        const data = tableRow.data;
        const pieces = parseInt(data.get("pieces", 0));
        if (!isNaN(pieces) && perPiece != null) {
            let value = perPiece * pieces;
            if ("req_spots" == field)
                value = Math.round(value);
            data.set(field, value);
        }
    }

    setFgiTotals() {
        const totalWeight = LtlUtil.getFgiTotal(this.tableFreightItems.dataSource, "weight");
        const totalHu = LtlUtil.getFgiTotal(this.tableFreightItems.dataSource, "handling_units");
        this.setTotalCaptions(totalWeight, totalHu);
        this.validateWeight(totalWeight);
    }

    setTotalCaptions(weight: number, handlingUnits: number) {
        this.labelTotalWeight.caption = NumberUtil.formatDecimal(weight ?? 0, "#,###.#") + " lbs";
        this.labelTotalUnits.caption = (handlingUnits ?? 0).toString();
    }


    validateWeight(totalWeight: number): boolean {
        if (totalWeight > this.maxWeight) {
            Snackbar.showWarningSnackbar(this.getWeightMaxMsg(), { id: "maxWeight", persist: false, targetPanel: this });
            return false;
        }
        return true;
    }

    getWeightMaxMsg(): string {
        const companySettings = PortalCustomerSettings.get();
        const contactName = companySettings.order_contact_name;
        const contactPhone = companySettings.order_contact_phone;
        if (contactName != null && contactPhone != null)
            return `Contact ${contactName} at ${contactPhone} for shipments weighing more than ${this.maxWeight} lbs.`;
        return `Shipments cannot weigh more than ${this.maxWeight} lbs.`;
    }

    validateFreightItems(): boolean {
        if (this.tableFreightItems?.dataSource?.data?.length == 0) {
            Snackbar.showWarningSnackbar("Please enter at least one commodity.", { id: "fgiMin", persist: false });
            return false;
        }
        return true;
    }

    validateBeforePost(): boolean {
        return this.validateFreightItemsMode() && this.validateWeight(LtlUtil.getFgiTotal(this.tableFreightItems.dataSource, "weight")) && this.validateFreightItems();
    }

    validateFreightItemsMode(): boolean {
        const addOrUpdateRows = this.getRowsInAddOrUpdateMode();
        if (addOrUpdateRows?.length > 0) {
            Snackbar.showWarningSnackbar("Please save your commodity before continuing.", { id: "fgiMode", persist: false });
            addOrUpdateRows.forEach(tableRow => this.setTableRowBorder(tableRow, "red"))
            addOrUpdateRows[0].scrollIntoView({ block: Block.CENTER });
            return false;
        }
        return true;
    }

    getRowsInAddOrUpdateMode(): TableRow[] {
        const rows = [];
        for (const row of this.tableFreightItems.rows)
            if (row.mode != TableRowMode.NONE)
                rows.push(row);
        return rows;
    }

    public get showDetails(): boolean {
        return this._showDetails;
    }

    public set showDetails(value: boolean) {
        this._showDetails = value;
        if (value == true) {
            this.tableFreightItems.rowModeControlType = RowModeControlType.ALWAYS_EDIT;
            this.tableFreightItems.addRowDisplayListener((event: TableRowDisplayEvent) => {
                const row = event.getTableRow();
                row.canBeDeleted = false;
                row.draggable = false;
                if (row.data.getBoolean("hazmat")) {
                    this.displayHazmatPanel(row, true);
                }
                const panel = row.findComponentById("panelNotHazmat") as Panel;
                panel.forEveryChildComponent(comp => {
                    if (comp.field != null) {
                        if (comp.id == "switchHazmat") {
                            comp.marginRight = 24;
                            comp.rowBreak = false;
                        }
                        if (comp.field != "description") {
                            comp["printable"] = true;
                        }
                    }
                });
            });
            this.buttonAddItem.visible = false;
        }
    }

    displayHazmatPanel(row: TableRow, showHazmat: boolean) {
        const panelHazmat = row.findComponentById("panelHazmat") as Panel;
        panelHazmat.visible = showHazmat;
        requiredHazmatFields.forEach(field => {
            panelHazmat.findComponentByField(field).forEach(comp => {
                comp.required = showHazmat;
            })
        })
    }

    private textboxHazmatUnnaNbrOnLookupModelSelection(event: LookupModelSelectionEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target);
        const tableRowData = tableRow.data;
        const selectedRow = event.selectedRow;
        tableRowData.set("hazmat_proper_shipname", selectedRow.get("hazmat_proper_shipname"));
        tableRowData.set("hazmat_class_code", selectedRow.get("hazmat_class_code"));
        tableRowData.set("hazmat_erg_number", selectedRow.get("hazmat_erg_number"));
        tableRowData.set("hazmat_packing_group", selectedRow.get("hazmat_packing_group"));
    }
}
