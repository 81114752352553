import { ChangeEvent, Checkbox, DataSourceExecutionEvent, Layout, Snackbar } from "@mcleod/components";
import { DataSource, DataSourceAction } from "@mcleod/components/src/databinding/DataSource";
import { ModelRow } from "@mcleod/core";
import { showCreditSnackbar, validateShipper } from "./../OrderValidation";
import { LtlQuote } from "./LtlQuote";
import { LtlUtil } from "./LtlUtil";
import { AutogenLayoutLtlQuoteDetails } from "./autogen/AutogenLayoutLtlQuoteDetails";
import { PortalCustomerSettings } from "../../../customer/src/settings/PortalCustomerSettings";
import { PortalCompanySettings } from "../../../portal-common/src/settings/PortalCompanySettings";

const snackbarPops = { padding: 0, width: 450 };
export class LtlQuoteDetails extends AutogenLayoutLtlQuoteDetails {

    public validateShipperAfterSearch: boolean = true;

    override onLoad() {
        const isAssetLtl = PortalCompanySettings.getSingleton().isAssetLtl();
        this.textboxShipperRef.visible = isAssetLtl;
        const customerSettings = PortalCustomerSettings.get();
        this.labelTerms.caption = customerSettings.order_terms_conditions ?? "";
        this.labelOrderSubmitMsg.caption = customerSettings.order_submit_ok ?? "";
        this.layoutFreightItems.showDetails = true;
        if (this.owner instanceof LtlQuote) {
            const fgiSource = this.owner.layoutFreightItems.sourceQuoteFreightGroupItem;
            replaceLayoutDataSource(this.layoutOverview, this.layoutOverview.sourceQuoteFreightGroupItem, fgiSource);
            replaceLayoutDataSource(this.layoutFreightItems, this.layoutFreightItems.sourceQuoteFreightGroupItem, fgiSource);
            replaceLayoutDataSource(this.layoutQuoteStops, this.layoutQuoteStops.sourceQuoteStops, this.owner.sourceQuoteStops);

            this.owner.sourceHdr.addAfterExecutionListener((event: DataSourceExecutionEvent) => {
                this.layoutHdr.populateHdrs(this.owner.sourceHdr.data, true)
            });

            this.layoutQuoteStops.sourceQuoteStops.addAfterExecutionListener((event: DataSourceExecutionEvent) => {
                if (event.getAction() == DataSourceAction.SEARCH) {
                    const index = isAssetLtl ? 1 : 2;
                    if (this.validateShipperAfterSearch == true && this.owner.stepper.selectedIndex == index)
                        this.validateShipper();
                }
            })
            if (isAssetLtl && customerSettings.quote_sheet_template !== 0) {
                this.layoutOverview.buttonQuoteSheet.visible = true;
                this.layoutOverview.buttonQuoteSheet.addClickListener((event) => LtlUtil.downloadQuoteSheet(this.activeRow.get("id")));
            }
        }
    }

    public doBeforeDisplay(selectedRate: ModelRow<any>) {
        this.layoutHdr.enabled = false;
        this.checkAgree.checked = false;
        if (this.owner instanceof LtlQuote) {
            this.layoutOverview.setup(this.owner.mainDataSource, selectedRate);
        }
    }

    validateShipper(): boolean {
        const shipperLoc = this.layoutQuoteStops.sourceQuoteStops.data[0]?.get("location_id");
        return validateShipper(shipperLoc, this.activeRow.get("id"));
    }

    async validateBeforePost(): Promise<boolean> {
        let result = true;

        await this.layoutQuoteStops.validatePickupTimesBeforePost();

        const validation: (() => boolean)[] = [
            () => this.termsAccepted(),
            () => this.validateShipper(),
            () => this.layoutQuoteStops.hasValidPickupTimes,
            () => showCreditSnackbar(this.activeRow?.getBoolean("credit_status_valid", false), false)
        ]
        for (const isValid of validation)
            result = isValid() && result;
        return result;
    }

    checkAgreeOnChange(event: ChangeEvent) {
        const checkbox = event.target as Checkbox;
        checkbox.color = event.newValue ? "success" : "error";
        checkbox.checkColor = checkbox.color;
    }

    private termsAccepted(): boolean {
        if (this.checkAgree.checked == false)
            Snackbar.showWarningSnackbar({ ...snackbarPops, caption: "Please accept Terms and Conditions before proceeding." }, { id: "terms", persist: true });
        return this.checkAgree.checked;
    }
}

function replaceLayoutDataSource(layout: Layout, thisDataSource: DataSource, newDataSource: DataSource) {
    thisDataSource.rebindComponentsTo(newDataSource);
    newDataSource.attachListeners(thisDataSource);
    layout[thisDataSource.id] = newDataSource;
}

