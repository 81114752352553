import { ClickEvent, MapPin, Textbox } from "@mcleod/components";
import { PinPlotEvent } from "@mcleod/components/src/components/map/PinPlotEvent";
import { Api, CityUtil, MapSettings, ModelRow, ModelRowType, StringUtil, getThemeColor } from "@mcleod/core";
import { ShareOrder } from "./ShareOrder";
import { AutogenLayoutFtlOrderConfirmation } from "./autogen/AutogenLayoutFtlOrderConfirmation";

export class FtlOrderConfirmation extends AutogenLayoutFtlOrderConfirmation {
    private key: string;

    override async onLoad() {

        if (this.__designer == null && this.key != null) {
            this.panelRight.visible = false;
            await this.mainDataSource.search({ id: this.key }).then(() => {
                this.layoutHeader.displayHoldPanel(this.activeRow.get("hold_warning"));
            })
            this.panelRight.visible = true;
            if (MapSettings.getSingleton().noDistanceCalcVendor() === true)
                this.mapStops.visible = false;
        }

        this.layoutOverView.labelFreightCharge.displayData(this.activeRow, null, 0);
        this.layoutOverView.labelOtherchargetotal.displayData(this.activeRow, null, 0);
        this.layoutOverView.labelTotalCharge.displayData(this.activeRow, null, 0);
        this.layoutOverView.labelWeight.displayData(this.activeRow, null, 0);
        this.layoutOverView.labelBillDistance.displayData(this.activeRow, null, 0);
        this.layoutOverView.panelOrderNumber.visible = true;
        this.layoutOverView.layoutBOL.orderId = this.activeRow.get("id");
        this.layoutOverView.layoutBOL.buttonBOL.visible = false;

        this.mapStops.addPinPlotListener((event: PinPlotEvent) => {
            const pin: MapPin = event.pin;
            pin.caption = "" + pin.data.get("order_sequence");
            pin.clusterable = false;
            pin.image = "map-pin-white-dot-large";
            pin.tooltip = this.getMapPinToolTip(pin.data);
            pin.captionColor = getThemeColor("primary");
        });

        this.addLayoutDataLoadListener(() => {
            const trailerTypeTextbox = this.textboxEquipmentTypeId as Textbox
            if (!StringUtil.isEmptyString(this.activeRow.get("equipment_type_id", ""))) {
                Api.search(trailerTypeTextbox.lookupModel, { id: this.activeRow.get("equipment_type_id") }).then(response => {
                    if (response != null) {
                        const row: ModelRow = this.activeRow;
                        const lmModelRow = new ModelRow(trailerTypeTextbox.lookupModel, false, { id: response.data[0].id, descr: response.data[0].descr });
                        lmModelRow.type = ModelRowType.LOOKUP_MODEL_DATA;
                        row.setLookupModelData("equipment_type_id", lmModelRow);
                        row.set("equipment_type_id", response.data[0].id);
                        trailerTypeTextbox.displayData(row, null, 0);
                    }
                });
            }
        });
    }

    private getMapPinToolTip(data: ModelRow): string {
        const cityStateZip = CityUtil.formatCityStateZip(data.get("city_name"), data.get("state"), data.get("zip_code"));
        return `Stop ${data.get("order_sequence")}: ${cityStateZip}`;
    }

    buttonShareOnClick(event: ClickEvent) {
        ShareOrder.onClick(event, this.activeRow.get("id"));
    }
}
