import { StringUtil } from "@mcleod/core";
import { Component } from "../base/Component";
import { ComponentTypes } from "../base/ComponentTypes";

export type ComponentCreationCallback = (componentType: string, props: any) => Component;

export function deserializeComponents(componentOwner, def, designer, defaultPropValues, dataSources, componentCreationCallback: ComponentCreationCallback) {
    if (componentCreationCallback == null)
        componentCreationCallback = ComponentTypes.createComponentOfType;
    const result = [];
    if (def == null)
        return result;
    if (!Array.isArray(def))
        def = [def];
    for (let i = 0; i < def.length; i++) {
        const compDef = def[i];
        let props = { _deserializing: true, ...getConstructorProps(compDef), ...defaultPropValues};
        if (designer?.setPropsForDeserialization != null)
            props = designer.setPropsForDeserialization(compDef.type, props);
        const comp = componentCreationCallback(compDef.type, props);
        comp.setDesigner(designer);
        comp.owner = componentOwner;
        if (componentOwner != null)
            componentOwner[compDef.id] = comp;

        const handledProps = [];
        if (comp._deserializeSpecialProps != null)
            handledProps.push(...comp._deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, componentCreationCallback));

        deserializeBaseVersionProps(comp, compDef);

        const defKeys = Object.keys(compDef);
        const compProps = comp.getPropertyDefinitions();
        for (let i = defKeys.length - 1; i >= 0; i--) {
            const key = defKeys[i];
            if (compProps[key] == null || handledProps.includes(key))
                defKeys.splice(i, 1);
        }

        for (const key of defKeys) {
            comp.setPropUsingPropsCollection(compDef[key], key, compProps);
        }

        if (defaultPropValues != null)
            for (const key in defaultPropValues)
                if (compDef[key] === undefined)
                    comp[key] = defaultPropValues[key];

        comp.clearDeserializingFlag();
        if (comp["_afterDeserialize"] != null)
            comp["_afterDeserialize"]();

        comp.deserialized = true;
        result.push(comp);
    }
    return result;
}

function getConstructorProps(compDef: any): any {
    const result = { id: compDef.id };
    const propNames = ComponentTypes.getComponentType(compDef.type)?.constructorProps;
    if (propNames != null)
        for (const propName of propNames)
            result[propName] = compDef[propName];
    return result;
}

function deserializeBaseVersionProps(comp: Component, compDef: any) {
    const baseVersionProps = compDef.baseVersionProps;
    if (baseVersionProps != null) {
        for (const key in baseVersionProps) {
            // null is serialized as an empty string because null values aren't serialized on the server
            if (StringUtil.isEmptyString(baseVersionProps[key]))
                baseVersionProps[key] = null;
        }
        comp.baseVersionProps = baseVersionProps;
    }
}

