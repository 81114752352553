import { Container } from "../..";
import { Component } from "../../base/Component";
import { DesignerInterface } from "../../base/DesignerInterface";
import { Panel } from "../panel/Panel";

export class CompoundComponent extends Panel {
    add(...components: Component[]): Component {
        if (this._designer != null)
            for (const component of components)
                component._interactionEnabled = false;
        return super.add(...components);
    }

    get _designer(): DesignerInterface {
        return super._designer;
    }

    set _designer(value: DesignerInterface) {
        super._designer = value;
        if (value != null)
            this.disableChildInteraction(this);
    }

    private disableChildInteraction(container: Container) {
        for (const comp of container.components) {
            comp._interactionEnabled = false;
            comp.style.pointerEvents = "none";
            if (comp instanceof Container)
                this.disableChildInteraction(comp);
        }
    }

    public forEveryChildComponent(callback: (component: Component) => void) {
        //do nothing here so that we are forced to define the behavior in an overidden method in each CompoundComponent
    }

    override getSortFieldNames(): string[] {
        return this.filterSortFields(this.getFieldNames());
    }

    public override discoverIncludedComponents(): Component[] {
        //return null here so that the components that make up a compound component aren't included
        return null;
    }
}
