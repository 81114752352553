import { AbstractSettings, AbstractSettingsDef } from "./AbstractSettings";
import { SettingsManager } from "./SettingsManager";

export interface LicenseSettingsDef extends AbstractSettingsDef {
    licensed_packages: string[];
    licensed_classes: string[];
    excluded_classes: string[];
}

export class LicenseSettings extends AbstractSettings {
    constructor() {
        super("license_settings");
    }

    public static getSingleton(): LicenseSettings {
        return SettingsManager.getSingleton("license_settings") as LicenseSettings;
    }

    public static get(): LicenseSettingsDef {
        return LicenseSettings.getSingleton().values as LicenseSettingsDef;
    }

    public override get values(): LicenseSettingsDef {
        return super.values as LicenseSettingsDef;
    }

    protected getValuesInternal(): LicenseSettingsDef {
        return this._values as LicenseSettingsDef;
    }

    public getLicensedPackages(): string[] {
        return [ ...this.getValuesInternal()?.licensed_packages ];
    }

    public getLicensedClasses(): string[] {
        return [ ...this.getValuesInternal()?.licensed_classes ];
    }

    public getExcludedClasses(): string[] {
        return [ ...this.getValuesInternal()?.excluded_classes ];
    }
}
