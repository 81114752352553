import { AbstractSettings, AbstractSettingsDef, SettingsManager } from "@mcleod/core";

export interface PortalCarrierSettingsDef extends AbstractSettingsDef {
    loadboard_active: boolean;
    loadboard_show_b4_search: boolean;
}

export class PortalCarrierSettings extends AbstractSettings {
    constructor() {
        super("carrier_settings");
    }

    public static getSingleton(): PortalCarrierSettings {
        return SettingsManager.getSingleton("carrier_settings") as PortalCarrierSettings;
    }

    public static get(): PortalCarrierSettingsDef {
        return PortalCarrierSettings.getSingleton().values as PortalCarrierSettingsDef;
    }

    public override get values(): PortalCarrierSettingsDef {
        return super.values as PortalCarrierSettingsDef;
    }

    protected getValuesInternal(): PortalCarrierSettingsDef {
        return this._values as PortalCarrierSettingsDef;
    }
}
