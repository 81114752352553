import { ArrayUtil, JSUtil, ModelRow, StringUtil } from "@mcleod/core";
import { DataSourceMode, Layout, serializeComponents } from "..";
import { DomEvent } from "../events/DomEvent";
import { Component } from "./Component";
import { ComponentProps } from "./ComponentProps";
import { Printable } from "./PrintableComponent";
import { ValidationResult } from "./ValidationResult";

export class Container extends Component {
    protected _components: Component[] = [];

    constructor(type: string, props?: Partial<ComponentProps>) {
        super(type, props);
    }

    [Symbol.iterator]() {
        return ArrayUtil.arrayIterator(this._components);
    }

    public override isEmpty(): boolean {
        return this.getComponentCount() == 0;
    }

    public getComponentCount(): number {
        return this._components?.length ?? 0;
    }

    public getComponent(index: number) {
        return this._components[index];
    }

    get components(): Component[] {
        return this._components;
    }

    set components(value: Component[]) {
        throw new Error("The " + this.constructor.name + " class must implement a setter for its components.")
    }

    add(child: Component) {
        throw new Error("The " + this.constructor.name + " class must implement add().")
    }

    removeAt(index: number, domEvent?: DomEvent) {
        throw new Error("The " + this.constructor.name + " class must implement removeAt().")
    }

    remove(child: Component, errorIfNotAChild: boolean = false) {
        const index = this.indexOf(child);
        if (index < 0) {
            if (errorIfNotAChild !== false)
                throw new Error("The specified component is not a child of this container.");
        } else
            this.removeAt(index);
    }

    removeAll() {
        for (let i = this.components.length - 1; i >= 0; i--) {
            this.removeAt(i);
        }
    }

    indexOf(child: Component) {
        return this._components.indexOf(child);
    }

    get printable(): boolean {
        return this["_mixin-Printable-printable"];
    }

    set printable(value: boolean) {
        if (this["printableValueMatches"](value) === true) {
            return;
        }
        this["_mixin-Printable-printable"] = value;
        const isThisPrintable = this.printable;
        if (this.components != null)
            for (const comp of this.components)
                if (comp["printable"] != null)
                    comp["printable"] = isThisPrintable;
    }

    set insideTableCell(value: boolean) {
        super.insideTableCell = value;
        for (const component of this.components) {
            component.insideTableCell = this.insideTableCell;
        }
    }

    public addListenersToAll(listeners: any) {
        if (listeners != null) {
            for (const component of this.components) {
                component.addListenersToAll(listeners);
            }
        }
    }

    getSearchValues(): string[] {
        const result = [];
        for (const component of this.components) {
            result.push(...component.getSearchValues());
        }
        return result;
    }

    childBreakChanged(child: Component) { // do nothing in a basic container
    }

    tieComponentsToOwner(components: Component[], owner: any) {
        for (const comp of components) {
            comp.owner = owner;
            if (comp.id != null)
                owner[comp.id] = comp;
            if (comp instanceof Container)
                this.tieComponentsToOwner(comp.components, owner);
            if ((comp as any).tools != null) // yuck - this is specific to Tabset.... make this more generic
                this.tieComponentsToOwner((comp as any).tools, owner);
        }
    }

    getRecursiveChildren(collectNestedLayoutChildren: boolean = true): Component[] {
        const result: Component[] = [];
        this._addRecursiveChildren(this, result, collectNestedLayoutChildren);
        return result;
    }

    getRecursiveChildrenById(collectNestedLayoutChildren: boolean = true) {
        const children = this.getRecursiveChildren(collectNestedLayoutChildren);
        return children.filter(child => !StringUtil.isEmptyString(child.id)).map(child => child.id);
    }

    private _addRecursiveChildren(container: Container, result: Component[], collectNestedLayoutChildren: boolean) {
        for (const comp of container) {
            result.push(comp);
            if (comp instanceof Container) {
                if (!collectNestedLayoutChildren && comp instanceof Layout && comp.isNested)
                    continue;
                comp._addRecursiveChildren(comp, result, collectNestedLayoutChildren);
            }
        }
    }

    override displayData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        super.displayData(data, allData, rowIndex);
        if (this._designer != null)
            return;
        if (data != null && this.field != null)
            data = data.get(this.field);
        for (const component of this.components)
            component.displayData(data, allData, rowIndex);
    }

    override validate(checkRequired: boolean, showErrors: boolean = true): ValidationResult[] {
        let result: ValidationResult[] = null;
        for (const comp of this.components) {
            const thisResult: ValidationResult[] = comp.validate(checkRequired, showErrors);
            if (thisResult !== null) {
                if (result == null)
                    result = thisResult;
                else
                    result = result.concat(thisResult);
            }
        }
        return result;
    }

    override resetValidation() {
        for (const comp of this.components)
            comp.resetValidation();
    }

    _designerDrop(component: Component) {
        this.add(component);
    }

    _serializeNonProps(): string {
        if (this.components != null && this.components.length > 0)
            return "\"components\": " + serializeComponents(this.components, null,) + ",\n";
        return "";
    }

    replace(component: Component, withComponent: Component) {
        const index = this.indexOf(component);
        if (index >= 0) {
            this.components[index] = withComponent;
            this.reLayout();
        }
    }

    insert(component: Component, index: number): Component {
        this.components.splice(index, 0, component);
        this.reLayout();
        return component;
    }

    insertBefore(component: Component, existingComponent: Component): Component {
        let index = existingComponent != null ? this.indexOf(existingComponent) : -1;
        if (index < 0) {
            index = this.components.length;
        }
        return this.insert(component, index);
    }

    reLayout() {
    }

    private findFocusableComponent(): Component | undefined {
        for (const comp of this.components)
            if (comp.isFocusable())
                return comp;
        return undefined;
    }

    isFocusable() {
        return this.findFocusableComponent() != null;
    }

    focus() {
        this.findFocusableComponent()?.focus();
    }

    public getFirstFocasableChild(): Component {
        for (const component of this?.components) {
            if (component.isFocusable())
                return component;
            if (component instanceof Container) {
                const focusableComp = component.getFirstFocasableChild();
                if (focusableComp != null)
                    return focusableComp;
            }
        }
    }

    public setPropOnChildren(propName: string, value: any, setOnlyIfExists: boolean = true) {
        for (const component of this.components) {
            if (component instanceof Container) {
                component.setPropOnChildren(propName, value);
            }
            else {
                if (setOnlyIfExists === false || (setOnlyIfExists === true && component[propName] != null)) {
                    component.setProp(value, propName, null);
                }
            }
        }
    }

    findComponentByType<T extends Component>(type: new (...args: any[]) => T): T | null {
        return this.findComponentsByType(type)[0] ?? null;
    }

    findComponentsByType<T extends Component>(type: new (...args: any[]) => T): T[] {
        return this.getRecursiveChildren().filter(c => c instanceof type) as T[];
    }

    public findComponentById(id: String | Function): Component {
        let result: Component;
        this.forEveryChildComponent(c => {
            if ((id instanceof Function && id(c)) || c.id == id)
                result = c;
        });
        return result;
    }

    public findComponentByField(field: string): Component[] {
        const result: Component[] = [];
        if (StringUtil.isEmptyString(field))
            return result;
        for (const component of this.components) {
            if (component.field === field)
                result.push(component);
            else if (component instanceof Container) {
                const innerComps = component.findComponentByField(field);
                for (const innerComp of innerComps) {
                    result.push(innerComp);
                }
            }
        }
        return result;
    }

    public forEveryChildComponent(callback: (component: Component) => void) {
        for (const component of this.components) {
            callback(component);
            if (component instanceof Container)
                component.forEveryChildComponent(callback);
        }
    }

    public forEveryTopLevelChildComponent(callback: (component: Component) => void) {
        for (const component of this.components) {
            callback(component);
        }
    }

    override updateBoundData(row: ModelRow, mode: DataSourceMode) {
        for (const component of this.components) {
            component.updateBoundData(row, mode);
        }
    }

    growChildComponent(height: number) {
        if (this.components?.length !== 0)
            return;
        this._components[0].growToContainerHeight(height);
    }

    public override doBeforeComponentEnlarge(componentsToEnlarge: Component[]) {
        this["_tempRemovedComponents"] = [...this.components];
        this.removeAll();
        componentsToEnlarge.forEach((component: Component) => this.add(component));
    }

    public override doAfterComponentsShrink() {
        for (const removedComp of this["_tempRemovedComponents"]) {
            this.add(removedComp);
        }
        delete this["_tempRemovedComponents"];
    }

    public get enlarged(): boolean {
        return super.enlarged;
    }

    public set enlarged(value: boolean) {
        if (this.enlarged === value)
            return;
        super.enlarged = value;
        if (this.enlarged === false) {
            for (const component of this.components) {
                component.enlarged = value;
            }
        }
    }

    public override discoverIncludedComponents(): Component[] {
        return this.components;
    }

    public setLastComponentRowBreak(value: boolean) {
        const lastComponent = ArrayUtil.getLastElement(this.components);
        if (lastComponent != null)
            lastComponent.rowBreak = true;
    }
}

JSUtil.applyMixins(Container, [Printable]);
