import { Button, Image, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutResetPassword extends Layout {
	buttonCancel: Button;
	buttonSave: Button;
	imgRequirements: Image;
	labelAllowedSpecials: Label;
	labelAsterisk: Label;
	labelAsteriskTwo: Label;
	labelConfirmNewPassword: Label;
	labelMinimumLength: Label;
	labelMinimumNumeric: Label;
	labelMinimumSpecials: Label;
	labelMinimumUppers: Label;
	labelNewPassword: Label;
	labelPasswordCriteria: Label;
	panelContent: Panel;
	panelHeader: Panel;
	panelLogo: Panel;
	panelMain: Panel;
	panelPage: Panel;
	textConfirmPassword: Textbox;
	textPassword: Textbox;
}
