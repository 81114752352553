import { DataDisplayEvent, Label, Layout, Panel, TableRowExpansionEvent, Textbox } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { OrderStatus } from "./CustomerPortal";
import { OrderDetails } from "./OrderDetails";
import { OrderImages } from "./OrderImages";
import { OrderMapView } from "./OrderMapView";
import { TableCustomerOrdersMap } from "./TableCustomerOrdersMap";
import { TableStops } from "./TableStops";
import { AutogenLayoutTableCustomerOrders } from "./autogen/AutogenLayoutTableCustomerOrders";
import { PortalCompanySettings } from "../../portal-common/src/settings/PortalCompanySettings";
import { PortalCustomerSettings } from "../../customer/src/settings/PortalCustomerSettings";

export class TableCustomerOrders extends AutogenLayoutTableCustomerOrders {
    private _orderStatus: OrderStatus;
    private _mapOrders: TableCustomerOrdersMap;
    private _mapVisible: boolean = false;
    private isAssetLTLCompany = PortalCompanySettings.getSingleton().isAssetLtl();
    private isBrokerageLTLCompany = PortalCompanySettings.getSingleton().isBrokerageLtl();
    private displayBrkStatus = PortalCustomerSettings.get().display_brk_status;

    private isAllOrdersOrAdvanceSearch(): boolean {
        return this.orderStatus == OrderStatus.ALL || this.orderStatus == null;
    }

    override onLoad() {
        this.addLayoutDataLoadListener(event => {
            if (this._mapOrders == null)
                this._mapOrders = new TableCustomerOrdersMap(this);
        })
    }

    set mapVisible(value: boolean) {
        if (this._mapVisible != value) {
            this._mapVisible = value;
            this.syncLayout();
        }
    }

    get mapVisible(): boolean {
        return this._mapVisible;
    }

    private syncLayout() {
        this.remove(this.tableOrders);
        this.remove(this.mapOrders);
        if (this.mapVisible) {
            this.add(this.mapOrders);
            this.mapOrders.displayData(this.mainDataSource.data?.[0], this.mainDataSource.data, null);
        }
        else {
            this.add(this.tableOrders);
        }
    }

    private get mapOrders(): TableCustomerOrdersMap {
        if (this.mapVisible && this._mapOrders == null)
            this._mapOrders = new TableCustomerOrdersMap(this);
        return this._mapOrders;
    }

    public set orderStatus(value: OrderStatus) {
        if (this._orderStatus !== value) {
            this._orderStatus = value;
            this.configureTable();
        }
    }

    public get orderStatus(): OrderStatus {
        return this._orderStatus;
    }

    public orderExpanded(event: TableRowExpansionEvent) {
        const expandPanel = event.expandComponentParent as Panel;
        const placeholder = expandPanel.findComponentById("labelPlaceholder");
        if (placeholder != null)
            expandPanel.remove(placeholder);
        const orderDetailLayout = Layout.getLayout("portal-customer/OrderDetails") as OrderDetails;
        const orderModelRow = event.getTableRow()?.data as ModelRow;
        const orderId = orderModelRow?.get("id");
        if (orderId != null) {
            orderDetailLayout.addLayoutLoadListener(event => {
                const orderMapViewLayout = orderDetailLayout.tabMapView.findComponentById("layoutOrderMapView") as OrderMapView;
                orderDetailLayout.tabsetOrderDetails.addAfterTabSelectionListener(event => {
                    if (event.newSelection === orderDetailLayout.tabMapView) {
                        orderMapViewLayout.map1.fitPins();
                    }
                });
                orderDetailLayout.mainDataSource.search({ id: orderId }).then(response => {
                    orderMapViewLayout.setUpMap(response);
                    const row = response?.modelRows[0] as ModelRow;
                    const stops = row.data["stops"];
                    const modelRowsStops = [];
                    stops.forEach(stop => {
                        modelRowsStops.push(new ModelRow((orderDetailLayout.layoutStops as TableStops).sourceOrderStops.url, false, stop));

                    });
                    modelRowsStops.forEach(modelRow => {
                        (orderDetailLayout.layoutStops as TableStops).tableStops.displayData(modelRow, modelRowsStops, 0);
                    });

                    if (row.data["imaging_permitted"])
                        (orderDetailLayout.layoutImageTab as OrderImages).mainDataSource.search({ order_id: orderId });
                    else
                        orderDetailLayout.tabsetOrderDetails.remove(orderDetailLayout.tabImages);

                    expandPanel.add(orderDetailLayout);
                });
            });
        }
    }

    labelScheduledOnDataDisplay(event: DataDisplayEvent) {
        const status = event.rowData.get("orders.status");
        const labelProps = { imageColor: "primary", caption: event.rowData.get("status_descr") }
        if (status === OrderStatus.AVAILABLE) {
            (event.target as Label).setProps({ ...labelProps, imageName: "circleEmpty" });
        }
        else if (status === OrderStatus.PROGRESS) {
            (event.target as Label).setProps({ ...labelProps, imageName: "circleFilled" });
        }
        else if (status === OrderStatus.DELIVERED) {
            (event.target as Label).setProps({ ...labelProps, imageName: "circleCheck" });
        }
    }

    public configureTable() {
        for (let i = this.tableOrders.columns.length - 1; i >= 0; i--) {
            const column = this.tableOrders.columns[i];
            const cellId = column?.cellDef?.def?.id;
            if (!this.shouldDisplayColumn(cellId))
                this.tableOrders.removeColumn(i);
            else if (cellId === "cellTracking") {
                column.cellDef.def.components = column.cellDef.def.components.filter(comp => this.getTrackingComps().includes(comp.id));
                this.tableOrders.resetColumnSorting();
            }
        }
    }

    private getTrackingComps(): any[] {
        const trackingComps = ["textboxOrderId", "textboxConsigneeRefno"];
        if (this.isAssetLTLCompany)
            trackingComps.push("textboxFreightGroupProNbr");
        if (this.isBrokerageLTLCompany)
            trackingComps.push("textboxCarrierPro", "textboxShipperPO");
        return trackingComps;
    }

    shouldDisplayColumn(cellId: string): boolean {
        switch (cellId) {
            case "cellETA":
                return this.shouldDisplayEta();
            case "cellStatus":
                return this.isAllOrdersOrAdvanceSearch();
            case "cellBrokerageStatus":
                return this.displayBrkStatus;
            default: {
                return true;
            }
        }
    }

    private shouldDisplayEta(): boolean {
        if (this.isAllOrdersOrAdvanceSearch())
            return false;
        return PortalCustomerSettings.get().display_eta !== "N";
    }

    textboxConsigneeRefnoDisplay(event: DataDisplayEvent) {
        (event.target as Textbox).visible = !event.rowData.getBoolean("is_asset_ltl") && !event.rowData.getBoolean("is_br_ltl");
    }

    textboxFreightBroupProNbrDisplay(event: DataDisplayEvent) {
        (event.target as Textbox).visible = event.rowData.getBoolean("is_asset_ltl");
    }

    textboxShipperMoveProNbrDisplay(event: DataDisplayEvent) {
        (event.target as Textbox).visible = event.rowData.getBoolean("is_br_ltl");
    }

    textboxPONumOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Textbox).visible = event.rowData.getBoolean("is_br_ltl");
    }

    textboxEtaDateTimeOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Textbox).caption = event.rowData.get("eta_label");
    }
}
