import { ModelRow } from "./ModelRow";

export class ArrayUtil {
    public static removeFromArray(array: any[], item: any): any[] {
        if (array == null)
            return array;
        if (!Array.isArray(item))
            item = [item];
        for (const i of item) {
            const index = array.indexOf(i);
            if (index >= 0)
                array.splice(index, 1);
        }
        return array;
    }

    public static removeAllFromArray(array: any[], ...items: any[]): any[] {
        if (items == null)
            return array;
        for (const item of items) {
            ArrayUtil.removeFromArray(array, item);
        }
        return array;
    }

    public static switchArrayElements(array: any[], index1: number, index2: number): void {
        const e1 = array[index1];
        array[index1] = array[index2];
        array[index2] = e1;
    }

    public static moveArrayElement(array: any[], oldIndex: number, newIndex: number) {
        //stole this from https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
        //if newIndex is outside array bounds, pad array with undefined until we have the right length
        if (newIndex >= array.length) {
            let k = newIndex - array.length + 1;
            while (k--) {
                array.push(undefined);
            }
        }
        //remove element from the old index with the inner splice, then add it at the new index with the outer splice
        array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    }

    public static arrayIterator(arr: any[]) {
        let index = -1;
        return {
            next: () => ({ value: arr[++index], done: !(index in arr) })
        };
    }

    public static isEmptyArray(array: any[]): boolean {
        return array == null || array.length === 0;
    }

    /**
     * Method that can safely provide the length of an array.  It returns zero if the provided array is null/undefined.
     * @param array Array for which the length will be returned.
     */
    public static getLength(array: any[]): number {
        return array != null ? array.length : 0;
    }

    public static arrayIncludesObjectWithValue(array: any[], attr: string, value: any): boolean {
        for (const object of array) {
            if (typeof object !== "object")
                continue;
            if (object instanceof ModelRow) {
                if (object.get(attr) === value)
                    return true;
            }
            else if (object[attr] === value)
                return true;
        }
        return false;
    }

    public static joinWithOptions(array: any[], delimiter: any, beforeLast?: any, delimiterBeforeLast?: boolean): string {
        if (array == null || array.length === 0)
            return "";
        let result = "";
        const delimiterString = delimiter as string;
        for (let x = 0; x < array.length; x++) {
            if (x > 0) {
                if (x < array.length - 1 || (x === array.length - 1 && beforeLast != null && delimiterBeforeLast !== false))
                    result += delimiterString;
            }
            if (x === array.length - 1 && beforeLast != null)
                result += beforeLast as string;
            result += array[x] as string;
        }
        return result;
    }

    public static getFirstElement(array: any[]): any {
        if (array != null && array.length > 0)
            return array[0];
        return null;
    }

    public static getLastElement(array: any[]): any {
        if (array != null && array.length > 0)
            return array[array.length - 1];
        return null;
    }

    public static getAsArray(value: any | any[]): any[] {
        if (value == null || Array.isArray(value))
            return value;
        return [value];
    }

    public static copy(array: any[]): any[] {
        //why are you using this method?  you could probably use the spread operator instead.
        //maybe you are safeguarding against the possibility that your input array is null?
        const result = [];
        if (ArrayUtil.isEmptyArray(array) === true)
            return result;
        for (const item of array)
            result.push(item);
        return result;
    }

    public static copyNoDuplicates(array: any[]): any[] {
        if (ArrayUtil.isEmptyArray(array) === true)
            return;
        const noDups = [];
        for (const item of array) {
            if (noDups.includes(item) !== true)
                noDups.push(item);
        }
        return noDups;
    }

    public static addNoDuplicates(array: any[], item: any): boolean {
        if (array == null || item == null || array.includes(item))
            return false;
        array.push(item);
        return true;
    }

    public static addAllNoDuplicates(array1: any[], array2: any[]) {
        if (array1 == null || this.isEmptyArray(array2) === true)
            return;
        for (const array2Value of array2) {
            this.addNoDuplicates(array1, array2Value);
        }
    }

    public static equals(array1: any[], array2: any[]): boolean {
        if (array1 == null && array2 == null)
            return true;
        if (array1 == null || array2 == null)
            return false;
        if (array1.length !== array2.length)
            return false;
        for (let x = 0; x < array1.length; x++) {
            if (array1[x] !== array2[x])
                return false;
        }
        return true;
    }
}