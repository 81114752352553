import { DataDisplayEvent, Label, Panel, Textbox } from "@mcleod/components";
import { CommonTooltips } from "../CommonTooltips";
import { KeyValuePair } from "../KeyValuePair";
import { AutogenLayoutPermissionsSearch } from "./autogen/AutogenLayoutPermissionsSearch";

export class PermissionsSearch extends AutogenLayoutPermissionsSearch {

    /** This is an event handler for the onChange event of switchUserOrGroup. */
    switchUserOrGroupOnChange(event) {
        this.setTextboxActive(this.textboxUser, !event.newValue);
        this.setTextboxActive(this.textboxGroup, event.newValue);
    }

    setTextboxActive(textbox: Textbox, active:boolean) {
        textbox.validationWarning = null;
        textbox.clear();
        textbox.required = active;
        textbox.visible = active;
        if (active) {
            textbox.focus();
        }
    }

    /** This is an event handler for the onDataDisplay event of labelMenuCaption. */
    labelMenuCaptionOnDataDisplay(event: DataDisplayEvent) {
        const layoutReferences = event.rowData.get("layout_references");
        if (layoutReferences != null) {
            const label = event.target as Label;
            label.tooltipCallback = () => {
                return label.showTooltip(this.createMenuTooltip(layoutReferences), null, { themeKey: "quickInfo", color: null });
            };
        }
    }

  createMenuTooltip(layoutReferences: string[]): Panel {
      const keyValuePairs: KeyValuePair[] = [];
      layoutReferences?.forEach((value: string) => {
          const caption = value.split(":");
          keyValuePairs.push(new KeyValuePair(caption[0].trim(), caption[1].trim()));
      });
      return CommonTooltips.createKeyValueTooltip(keyValuePairs);
  }
}
