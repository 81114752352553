export class Base64Util {
    public static toArrayBuffer(base64String: string): ArrayBufferLike {
        return this.toUint8Array(base64String).buffer;
    }

    public static toUint8Array(base64String: string): Uint8Array {
        const binaryString = window.atob(base64String);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }
}
