import { Alignment } from "@mcleod/core";
import { Component } from "../../base/Component";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions } from "../../base/ComponentProps";
import { ContainerProps } from "../../base/ContainerProps";
import { PropType } from "../../base/PropType";

export interface TabsetProps extends ContainerProps {
    allowStyleChange: boolean;
    tabAlignment: Alignment;
    tabStyle: TabStyle;
    tools: Component[];
}

export enum TabStyle {
    OUTLINED = "outlined",
    UNDERLINED = "underlined",
    ACCORDION = "accordion",
    SCROLL = "scroll"
}

export enum AccordionDefaultExpand {
    ALL = "all",
    FIRST = "first",
    NONE = "none"
}

let tabsetPropDefs: ComponentPropDefinitions;

export class TabsetPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (tabsetPropDefs == null) {
            tabsetPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                tabAlignment: { type: PropType.string, dropdownProps: {items: Object.values(Alignment)}, defaultValue: Alignment.TOP },
                tabStyle: { type: PropType.string, dropdownProps: {items: Object.values(TabStyle)}, defaultValue: TabStyle.UNDERLINED },
                allowStyleChange: { type: PropType.bool, description: "This determines whether or not the user is allowed to change the tab's style.", defaultValue: true },
                beforeTabSelection: { type: PropType.event, category: "Events", addListenerMethod: "addBeforeTabSelectionListener", removeListenerMethod: "removeBeforeTabSelectionListener", description: "This event is fired before the Tabset's active tab is changed.  It passes the tab that we are attempting to make active.  In your event handler, you can call event.preventDefault() if you wish to stop the active tab from being changed." },
                afterTabSelection: { type: PropType.event, category: "Events", addListenerMethod: "addAfterTabSelectionListener", removeListenerMethod: "removeAfterTabSelectionListener", description: "This event is fired after the Tabset's active tab is changed by the user or programatically." },
                beforeTabClose: { type: PropType.event, category: "Events", addListenerMethod: "addBeforeTabCloseListener", removeListenerMethod: "removeBeforeTabCloseListener", description: "This event is fired before a tab is closed/removed from the Tabset." },
                afterTabClose: { type: PropType.event, category: "Events", addListenerMethod: "addAfterTabCloseListener", removeListenerMethod: "removeAfterTabCloseListener", description: "This event is fired after a tab is closed/removed from the Tabset." },
                accordionDefaultExpand: { type: PropType.string, dropdownProps: {items: Object.values(AccordionDefaultExpand)}, defaultValue: AccordionDefaultExpand.FIRST, description: "When using the accordion tab style, this property specifies which tab will be expanded by a default." }
            }
            ComponentPropDefinitionUtil.populateComponentPropNames(tabsetPropDefs);
        }
        return tabsetPropDefs;
    }
}
