
import { CommonDialogs } from "@mcleod/common";
import { ClickEvent, DataSourceMode, Label, Panel, TableAddRowResult, TableRow, TableRowCreationEvent, TableRowDisplayEvent, TableRowMode } from "@mcleod/components";
import { ModelDataChangeType } from "@mcleod/components/src/databinding/DataSource";
import { FieldUpdateEvent, ModelRow, Navigation } from "@mcleod/core";
import { LOOKUP_MODEL_PREFIX } from "@mcleod/core/src/ModelRow";
import { AddStop } from ".././AddStop";
import { showCreditSnackbar, userCanEnterLtlOrder, validateBeforeAddOrder, validateLocationsPresent } from "./../OrderValidation";
import { AutogenLayoutLtlOrder } from "./autogen/AutogenLayoutLtlOrder";
import { PortalCustomerSettings } from "../../../customer/src/settings/PortalCustomerSettings";

export class LtlOrder extends AutogenLayoutLtlOrder {
    private _shipper: AddStop;
    private errorHandler = (error: any) => {
        CommonDialogs.showError(error);
        // defect exists where posting fails but linkedModels remain,
        // causing duplicates to be sent to server
        this.activeRow["linkedModels"] = null;
    }

    override async onLoad(): Promise<void> {
        if (await userCanEnterLtlOrder()) {
            this.labelWelcome.caption = PortalCustomerSettings.get().order_welcome_message;
            this.mainDataSource.setRowsAndMode(DataSourceMode.ADD, [await this.mainDataSource.createBlankRow()]);
            this.layoutFGIs.tableFreightItems.addRowCreateListener((event: TableRowCreationEvent) => this.fgiRowAdded(event));
            this.sourceFreightItem.mode = DataSourceMode.ADD;
            this.sourceStop.mode = DataSourceMode.ADD;
            this.layoutFGIs.tableDataSource = this.sourceFreightItem;
            await this.addStops();
            await this.layoutFGIs.addFreightItemToTable();
            await this.layoutHdrs.populateTable();
            this.layoutHdrs.hdrCallback = (row: ModelRow<any>, type: ModelDataChangeType) => this.hdrChanged(row, type);
            this.buttonCancel.addClickListener(() => Navigation.navigateTo("/"));
            this.layoutFGIs.doBeforeCommoditySearch = (filter: any) => {
                filter.product_book_location = this.sourceStop?.data?.[0].get("location_id");
            };
            validateLocationsPresent();
            this.addLayoutLoadListener(() => {
                validateBeforeAddOrder(this);
            })
        } else {
            Navigation.navigateTo("/");
        }
        return Promise.resolve();
    }

    fgiRowAdded(event: TableRowCreationEvent) {
        const tableRow = event.getTableRow();
        const data = tableRow.data as ModelRow;
        data.addAfterFieldUpdateListener((event: FieldUpdateEvent) => {
            if ("hazmat" == event.fieldName) {
                this.layoutFGIs.displayHazmatPanel(tableRow, data.getBoolean("hazmat"));
            }
        })
    }

    clearHazmatDataIfNotHazmat() {
        this.layoutFGIs.tableFreightItems.rows.forEach(tableRow => {
            const data = tableRow.data as ModelRow;
            if (!data.getBoolean("hazmat")) {
                const panelHazmat = tableRow.findComponentById("panelHazmat") as Panel;
                panelHazmat.forEveryChildComponent(comp => {
                    if (comp.field) {
                        comp.required = false;
                        data.set(comp.field, null);
                        data.set(LOOKUP_MODEL_PREFIX + comp.field, null);
                    }
                });
                panelHazmat.displayData(data, null, 0);
            }
        });
    }

    public get shipper(): AddStop {
        return this._shipper;
    }

    async addStops() {
        await this.addStop().then(row => {
            row?.data?.set("stop_type", "PU");
            const defaultLocationData = this.activeRow.get("default_shipper_data");
            if (defaultLocationData != null)
                row?.data?.setValues({ ...defaultLocationData });
        });
        await this.addStop().then(row => row?.data?.set("stop_type", "SO"));
    }

    async addStop(): Promise<TableRow> {
        return await this.tableStops.dataSource.createBlankRow().then(row => {
            const addRowResult: TableAddRowResult = this.tableStops.addRow(row, { mode: TableRowMode.ADD }, { display: true, addToData: true, save: false });
            return addRowResult.row;
        });
    }

    tableStopsOnRowDisplay(event: TableRowDisplayEvent) {
        const row = event.getTableRow();
        const stopData = row.data;
        (row.findComponentById("labelSequence") as Label).caption = (row.index + 1).toString();
        const stopLayout = row.findComponentById((comp) => comp instanceof AddStop) as AddStop;
        stopLayout.addLayoutLoadListener(() => {
            stopLayout.initialize(stopData, row.index == 0);
            if (row.index == 0) {
                this._shipper = stopLayout;
            }
        });
    }

    hdrChanged(hdr: ModelRow<any>, type: ModelDataChangeType) {
        if (type == ModelDataChangeType.ADD) {
            hdr.set("fgp_uid", this.activeRow.get("fgp_uid"));
            this.sourceHandlingReq.addRow(hdr, this.sourceHandlingReq.data.length, false);
        } else if (type == ModelDataChangeType.DELETE) {
            this.sourceHandlingReq.data.forEach((row, index) => {
                if (row == hdr) {
                    this.sourceHandlingReq.deleteTableRow(index, true);
                    return;
                }
            });
        }
    }

    buttonSubmitOrderOnClick(event: ClickEvent) {
        this.validateBeforePost().then(valid => {
            if (valid == true) {
                this.clearHazmatDataIfNotHazmat();
                this.mainDataSource.post(this.errorHandler).then(row => {
                    const orderId = row.get("id");
                    if (orderId != null)
                        Navigation.navigateTo("portal-customer/ltl/LtlOrderConfirmation?key=" + orderId);
                });
            }
        })
    }

    async validateBeforePost(): Promise<boolean> {
        let result = true;
        await this.shipper.validatePickupDates();

        const validation: (() => boolean)[] = [
            () => this.layoutFGIs.validateBeforePost(),
            () => this.layoutOrderTerms.userAgreed(),
            () => this.shipper.hasValidPickupTimes,
            () => showCreditSnackbar(this.activeRow?.getBoolean("credit_status_valid", false), false)
        ]
        for (const isValid of validation)
            result = isValid() && result;

        if (result == true && !this.validateSimple()) {
            CommonDialogs.showError("Unable to save this record because data is missing or invalid.");
            return false;
        }
        return result;
    }
}
