import {
    Alignment, ApiMetadata, ArrayUtil, Collection, Color, CompanyType, createClass, DOMUtil, DynamicLoader, getLogger,
    getTheme, getThemeColor, getThemeFontSize, getThemeForKey, HorizontalAlignment, JSUtil, Keys, LocalUserSettings,
    LtlType, makeStyles, MetadataField, ModelRow, ScrollOptions, Size, StringUtil
} from "@mcleod/core";
import {
    BlurListener, DataSource, DataSourceMode, DomEventListener, FocusListener, Layout, PanelProps, TooltipOptions
} from "..";
import { LabelStyles } from "../components/label/LabelStyles";
import { ClickEvent, ClickListener } from "../events/ClickEvent";
import { DataDisplayEvent, DataDisplayListener } from "../events/DataDisplayEvent";
import { DomEvent, DomMouseEvent } from "../events/DomEvent";
import { DragListener } from "../events/DragEvent";
import { Event } from "../events/Event";
import { KeyListener } from "../events/KeyEvent";
import { KeyHandler } from "../events/KeyHandler";
import { ModifierKeys } from "../events/KeyModifiers";
import { MouseEvent, MouseListener } from "../events/MouseEvent";
import { ResizeEvent, ResizeListener } from "../events/ResizeEvent";
import { Overlay } from "../page/Overlay";
import { OverlayProps } from "../page/OverlayProps";
import {
    getComponentFromStringOrPropsOrComponent, StringOrPropsOrComponent
} from "../page/getComponentFromStringOrPropsOrComponent";
import { ComponentCreationCallback } from "../serializer/ComponentDeserializer";
import { BorderType } from "./BorderType";
import { ComponentListenerDefs } from "./ComponentListenerDefs";
import { ComponentPropDefinition, ComponentProps, IdProps, TooltipCallback } from "./ComponentProps";
import { Container } from "./Container";
import { Cursor } from "./Cursor";
import { DesignableObject } from "./DesignableObject";
import { DesignerInterface } from "./DesignerInterface";
import { ListenerListDef } from "./ListenerListDef";
import { McLeodMainPageUtil } from "./McLeodMainPageUtil";
import { MountUtil } from "./MountListener";
import { PermissionsDefinition } from "./PermissionsDefinition";
import { SlideoutSizeManager } from "./SlideoutSizeManager";
import { TransitionOptions } from "./TransitionOptions";
import { TransitionsUtil } from "./TransitionsUtil";
import { ValidationResult } from "./ValidationResult";

createClass("@keyframes rippleEffect", "to { transform: scale(4); opacity: 0; }", true);
const classes = makeStyles("cmp", {
    base: { padding: "4px", position: "relative" },
    permsEditor: { width: "100%", display: "flex", justifyContent: "flex-end", position: "absolute", paddingRight: "8px" },
    hidden: { display: "none !important" },
    hiddenNoCollapse: { visibility: "hidden" },
    borderShadow: { boxShadow: "0px 5px 5px 0px rgb(0 0 0 / 20%)" },
    ripple: {
        position: "absolute",
        transform: "scale(0)",
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%"
    },
    rippleWrapper: {
        position: "absolute",
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    fill: {
        flex: "1",
        maxWidth: "100%",
    },
    designerCustomComponent: { boxShadow: "inset 0px 0px 2px 2px #ffbb58" },
    designerModifiedBaseComponent: { boxShadow: "inset 0px 0px 2px 2px #ad12b0" }
});
const _dataDisplayListenerDef: ListenerListDef = { listName: "_dataDisplayListeners" };

const log = getLogger("components/Component");

const DOUBLE_CLICK_TIME = 200;

export interface RippleOptions {
    event: Event | DomEvent;
    speed: number;
}

/**
 * Component is the base class for all UI elements.  It defines the properties, methods, events, and other behavior that is common
 * to every subclass.
 *
 * Components have a root _element that is HTMLElement.  This is what is added to the DOM when the Component is added to a Container.
 *
 * It has several methods that are meant to be overridden by subclasses.
 */
export class Component extends DesignableObject implements ComponentProps {
    protected __designer: DesignerInterface;
    public _element: HTMLElement; // make it public, but still give it private naming so folks know they shouldn't use it
    private _parent: Container;
    protected _boundField: MetadataField;
    private _tooltipMouseEnterListener: any;
    private _tooltipMouseLeaveListener: any;
    private _mouseOver: boolean;
    protected _align: HorizontalAlignment;
    private _keyHandlers: KeyHandler[];
    private _backgroundColor: Color;
    private _borderRadius: string | number;
    private _borderBottomLeftRadius: string | number;
    private _borderBottomRightRadius: string | number;
    private _borderTopLeftRadius: string | number;
    private _borderTopRightRadius: string | number;
    private _borderColor: Color;
    private _borderType: BorderType;
    private _borderShadow: boolean;
    private _borderWidth: number;
    private _borderBottomColor: Color;
    private _borderBottomType: BorderType;
    private _borderBottomWidth: number;
    private _borderLeftColor: Color;
    private _borderLeftType: BorderType;
    private _borderLeftWidth: number;
    private _borderRightColor: Color;
    private _borderRightType: BorderType;
    private _borderRightWidth: number;
    private _borderTopColor: Color;
    private _borderTopType: BorderType;
    private _borderTopWidth: number;
    private _bottom: string | number;
    private _boundRow: any; //should be a ModelRow, except the PropertiesTable still deals in plain old objects
    private _color: Color;
    private _companyType: CompanyType;
    private _ltlType: LtlType;
    private _cursor: Cursor;
    private _dataSource: DataSource;
    protected _displayLabel: string;
    protected _draggable: boolean;
    private _enabled: boolean;
    private _enabledDuringAdd: boolean;
    private _enabledDuringUpdate: boolean;
    private _enabledDuringSearch: boolean;
    private _deserialized: boolean;
    private _disabledByServer: boolean;
    private _disabledTooltip: StringOrPropsOrComponent;
    private _enlarged: boolean;
    private _enlargeScope: Component;
    protected _fillRow: boolean;
    protected _fontBold: boolean;
    private _fontFamily: string;
    private _fontSize: string | number;
    private _fillHeight: boolean;
    private _field: string;
    private _height: string | number;
    private _insideTableCell: boolean;
    private _isCustom: boolean;
    private _isRow: boolean;
    private _left: string | number;
    private _license: string;
    private _margin: number | string;
    private _marginTop: number | string;
    private _marginBottom: number | string;
    private _marginLeft: number | string;
    private _marginRight: number | string;
    private _minHeight: number | string;
    private _maxHeight: number | string;
    private _minWidth: number | string;
    private _maxWidth: number | string;
    private _padding: number;
    private _paddingTop: number;
    private _paddingBottom: number;
    private _paddingLeft: number;
    private _paddingRight: number;
    private _preFocusValue: any;
    private _rememberUserChoice: boolean;
    private _right: string | number;
    public _explicitRequired: boolean;
    private _requiredDuringAdd: boolean;
    public _requiredDuringUpdate: boolean;
    public _requiredDuringSearch: boolean;
    private resizeObserver: ResizeObserver;
    _rowBreak: boolean;
    private _searchOnly: boolean;
    private _slideOutRemovalOptions: TransitionOptions;
    private _slideOutOverlay: Overlay;
    private _sortField: string;
    private _excludeFromSortFields: string[];
    protected _tooltip: StringOrPropsOrComponent;
    public tooltipPosition: Alignment;
    private _hideTooltipOnClick: boolean;
    private static _preOverlayMouseOverComponent: Component;
    private _top: string | number;
    private _visible: boolean;
    private _visibleDuringAdd: boolean;
    private _visibleDuringUpdate: boolean;
    private _visibleDuringSearch: boolean;
    private _preventCollapse: boolean;
    private _width: number | string;
    private _widthFillWeight: number;
    private _zIndex: number;
    private _tooltipMouseMoveListener: DomEventListener;
    private _lastMouseOverEvent: DomMouseEvent;
    private _tooltipInstance: Component;
    private _tooltipDisplayTimeout: any;
    private _tooltipHideTimeout: any;
    private _suppressTooltip: boolean;
    private _tooltipCallback: TooltipCallback;
    private _setPreFocusValueRef = (event: Event) => this._setPreFocusValue(event);
    private dblClickReceived: boolean;
    private _sortDescendingByDefault: boolean;
    private _sortNullsAtEnd: boolean;
    private _nextFocusable: string;
    private _tabKeyHandler: KeyHandler;
    private _hiddenUnlessMouseOver: Component;
    private _hiddenChildComponentMouseEnterListener: MouseListener;
    private _hiddenChildComponentMouseLeaveListener: MouseListener;
    private _doOnDataSourceChanged: (dataSource: DataSource, changesPresent: boolean) => void;
    private _removedByServer: boolean;

    constructor(type: string, props?: IdProps) {
        super();
        this._element = document.createElement(type);
        this._element["_mcleodComponent"] = this;
        this._initElement(props);
    }

    protected _initElement(props: IdProps) {
        this._element.classList.add(classes.base);
        if (props != null && props.id != null)
            this.id = props.id;
    }

    get id(): string {
        return this._element.id;
    }

    set id(value: string) {
        if (this.__designer?.canModifyProp == null || this.__designer.canModifyProp("id", this)) {
            this._element.id = value;
        }
    }

    public get deserialized(): boolean {
        return this._deserialized;
    }
    public set deserialized(value: boolean) {
        this._deserialized = value;
        if (this._designer != null && this._designer.addDragAndDropListeners != null)
            this._designer.addDragAndDropListeners(this);
    }

    public get isCustom(): boolean {
        return this._isCustom;
    }

    public set isCustom(value: boolean) {
        this._isCustom = value;
        if (value === true && this._designer != null)
            this.setClassIncluded(classes.designerCustomComponent);
    }

    public get defaultDataValue(): any {
        return null;
    }

    get fillRow(): boolean {
        return this._fillRow;
    }

    set fillRow(value: boolean) {
        this._fillRow = value;
        this._calcFill();
    }

    get widthFillWeight(): number {
        return this._widthFillWeight;
    }

    set widthFillWeight(value: number) {
        this._widthFillWeight = value;
        this._calcFill();
    }

    get suppressTooltip(): boolean {
        return this._suppressTooltip;
    }

    set suppressTooltip(value: boolean) {
        this._suppressTooltip = value;
    }

    protected _calcFill() {
        if (this.widthFillWeight != null) {
            this._element.style.flex = this.widthFillWeight.toString();
            if (this._maxWidth == undefined)
                this._element.style.maxWidth = "100%";
        }
        else if (this.fillRow === true) {
            this.setClassIncluded(classes.fill);
        }
        else {
            this.removeClass(classes.fill);
        }
    }

    private setElementHeight() {
        if (this.height == null && (this.fillHeight == null || this.fillHeight === false))
            this._element.style.height = "";
        else if (this.height == null && this.fillHeight === true)
            this._element.style.height = "100%"; // setting flex:1 if the parent container is flex and the flex-direction is row would be better
        else if (this.height != null)
            this._element.style.height = DOMUtil.getSizeSpecifier(this.height);
    }

    get fillHeight(): boolean {
        return this._fillHeight;
    }

    set fillHeight(value: boolean) {
        this._fillHeight = value;
        this.setElementHeight();
        if (value && this._element.parentElement?.className === "pnl-panelRow")
            this._element.parentElement.className += " pnl-rowFillHeight";
        else if (!value && this._element.parentElement?.classList.contains("pnl-rowFillHeight"))
            this._element.parentElement.classList.remove("pnl-rowFillHeight");
    }

    public scrollIntoView(options?: Partial<ScrollOptions>, startingElement?: HTMLElement) {
        const seedElement = startingElement == null ? this._element : startingElement;
        const parent = DOMUtil.findScrollableParent(seedElement);
        DOMUtil.scrollElementIntoView(this._element, options, parent);
    }

    public setClassIncluded(className, included = true) {
        if (included === true)
            this._element.classList.add(className);
        else
            this._element.classList.remove(className);
    }

    /**
     * Call this method when you want a simple true/false answer about whether a Component passes its validation.
     *
     * @param checkRequired Whether blank required fields should be considered a validation error
     * @returns
     */
    validateSimple(checkRequired: boolean = true, showErrors: boolean = true): boolean {
        if (this._designer != null)
            return true;
        const results: ValidationResult[] = this.validate(checkRequired, showErrors);
        if (results != null)
            for (const result of results)
                if (!result.isValid)
                    return false;
        return true;
    }

    /**
     * This method is meant to be overriden by subclasses to validate the user's input.
     *
     * @param checkRequired Whether blank required fields should be considered a validation error
     * @param showErrors
     * @returns void
     */

    validate(checkRequired: boolean = true, showErrors: boolean = true): ValidationResult[] {
        return null;
    }

    /**
     * This method is meant to be overriden by subclasses to remove validation warnings.  It will be called when the component's related DataSource changes modes.
     *
     * @returns void
     */

    resetValidation() { }

    /**
     * This will calculate the size of the component if it were allowed to be whatever size it wants to be (height="auto").
     * Note: to calculate the preferred size, we clone the node in an invisible area of the DOM, so this method should be called
     * with that in mind.
     * @returns {object} Object containing width, height
     */
    getPreferredSize(): Size {
        const clone = this._element.cloneNode(true) as HTMLElement;
        clone.style.cssText = "position:fixed; top:-9999px; opacity:0; height: auto";
        document.body.appendChild(clone);
        const result = { width: clone.clientWidth, height: clone.clientHeight };
        clone.parentNode.removeChild(clone);
        return result;
    }

    get className(): string {
        return this._element.className;
    }

    set className(value: string) {
        this._element.className = value;
    }

    addClass(value: string) {
        this._element.classList.add(value);
    }

    removeClass(value: string) {
        this._element.classList.remove(value);
    }

    get insideTableCell(): boolean {
        if (this._insideTableCell != null)
            return this._insideTableCell;
        return this.findParentOfType("cell") != null;
    }

    set insideTableCell(value: boolean) {
        this._insideTableCell = value;
    }

    clicked(event?: Event) {
        if (this.removedByServer !== true) {
            this.fireListeners(ComponentListenerDefs.click, new ClickEvent(this, event?.domEvent))
        }
    }

    public addClickListener(value: ClickListener, fireEvenOnDblClick: boolean = false): Component {
        const wrapper = (event: Event) => {
            log.debug("Clicked", this);
            // if this component also has double click listeners, we want to delay firing the click event to make sure
            // that this click isn't the first click of a double click..... I know.  I thought the same thing you're thinking.
            if (!fireEvenOnDblClick && this.hasListeners(ComponentListenerDefs.dblclick)) {
                this.dblClickReceived = false;  // reset the flag that checks for the second click of a double click
                setTimeout(() => {
                    if (this.dblClickReceived !== true)
                        value(event);
                }, DOUBLE_CLICK_TIME);
            } else // if there are no double-click listeners, fire the click listener immediately
                value(event);
        };
        wrapper.wrapped = value; // pure witchcraft
        this.addEventListener(ComponentListenerDefs.click, wrapper);
        if (this.hasListeners(ComponentListenerDefs.click) && this.cursor === undefined)
            this._element.style.cursor = "pointer";
        return this;
    }

    public removeClickListener(value: ClickListener): Component {
        this.removeEventListener(ComponentListenerDefs.click, value) as Component;
        if (!this.hasListeners(ComponentListenerDefs.click) && this.cursor === undefined)
            this._element.style.cursor = null;
        return this;
    }

    public addDblClickListener(value: ClickListener): Component {
        const wrapper = (event: Event) => {
            this.dblClickReceived = true; // this notifies the click listeners to stand down
            value(event);
        };
        wrapper.wrapped = value;
        this.addEventListener(ComponentListenerDefs.dblclick, wrapper);
        if (this.hasListeners(ComponentListenerDefs.dblclick) && this.cursor === undefined)
            this._element.style.cursor = "pointer";
        return this;
    }

    public removeDblClickListener(value: ClickListener): Component {
        this.removeEventListener(ComponentListenerDefs.dblclick, value) as Component;
        if (this.hasListeners(ComponentListenerDefs.dblclick) && this.cursor === undefined)
            this._element.style.cursor = null;
        return this;
    }

    public addMouseDownListener(value: MouseListener): Component {
        return this.addEventListener(ComponentListenerDefs.mouseDown, value) as Component;
    }

    public removeMouseDownListener(value: MouseListener): Component {
        return this.removeEventListener(ComponentListenerDefs.mouseDown, value) as Component;
    }

    public addMouseUpListener(value: MouseListener): Component {
        return this.addEventListener(ComponentListenerDefs.mouseUp, value) as Component;
    }

    public removeMouseUpListener(value: MouseListener): Component {
        return this.removeEventListener(ComponentListenerDefs.mouseDown, value) as Component;
    }

    public addMouseEnterListener(value: MouseListener): Component {
        return this.addEventListener(ComponentListenerDefs.mouseEnter, value) as Component;
    }

    public removeMouseEnterListener(value: MouseListener): Component {
        return this.removeEventListener(ComponentListenerDefs.mouseEnter, value) as Component;
    }

    public addMouseLeaveListener(value: MouseListener): Component {
        return this.addEventListener(ComponentListenerDefs.mouseLeave, value) as Component;
    }

    public removeMouseLeaveListener(value: MouseListener): Component {
        return this.removeEventListener(ComponentListenerDefs.mouseLeave, value) as Component;
    }

    public addMouseMoveListener(value: MouseListener): Component {
        return this.addEventListener(ComponentListenerDefs.mouseMove, value) as Component;
    }

    public removeMouseMoveListener(value: MouseListener): Component {
        return this.removeEventListener(ComponentListenerDefs.mouseMove, value) as Component;
    }

    public addKeyDownListener(value: KeyListener): Component {
        return this.addEventListener(ComponentListenerDefs.keyDown, value) as Component;
    }

    public removeKeyDownListener(value: KeyListener): Component {
        return this.removeEventListener(ComponentListenerDefs.keyDown, value) as Component;
    }

    public addKeyUpListener(value: KeyListener): Component {
        return this.addEventListener(ComponentListenerDefs.keyUp, value) as Component;
    }

    public removeKeyUpListener(value: KeyListener): Component {
        return this.removeEventListener(ComponentListenerDefs.keyUp, value) as Component;
    }

    public addDragStartListener(value: DragListener): Component {
        return this.addEventListener(ComponentListenerDefs.dragStart, value) as Component;
    }

    public removeDragStartListener(value: DragListener): Component {
        return this.removeEventListener(ComponentListenerDefs.dragStart, value) as Component;
    }

    public addDragEndListener(value: DragListener): Component {
        return this.addEventListener(ComponentListenerDefs.dragEnd, value) as Component;
    }

    public removeDragEndListener(value: DragListener): Component {
        return this.removeEventListener(ComponentListenerDefs.dragEnd, value) as Component;
    }

    public addDragEnterListener(value: DragListener): Component {
        return this.addEventListener(ComponentListenerDefs.dragEnter, value) as Component;
    }

    public removeDragEnterListener(value: DragListener): Component {
        return this.removeEventListener(ComponentListenerDefs.dragEnter, value) as Component;
    }

    public addDragLeaveListener(value: DragListener): Component {
        return this.addEventListener(ComponentListenerDefs.dragLeave, value) as Component;
    }

    public removeDragLeaveListener(value: DragListener): Component {
        return this.removeEventListener(ComponentListenerDefs.dragLeave, value) as Component;
    }

    public addDragOverListener(value: DragListener): Component {
        return this.addEventListener(ComponentListenerDefs.dragOver, value) as Component;
    }

    public removeDragOverListener(value: DragListener): Component {
        return this.removeEventListener(ComponentListenerDefs.dragOver, value) as Component;
    }

    public addDropListener(value: DragListener): Component {
        return this.addEventListener(ComponentListenerDefs.dragDrop, value) as Component;
    }

    public removeDropListener(value: DragListener): Component {
        return this.removeEventListener(ComponentListenerDefs.dragDrop, value) as Component;
    }

    public addFocusListener(value: FocusListener): Component {
        return this.addEventListener(ComponentListenerDefs.focus, value) as Component;
    }

    public insertFocusListener(value: FocusListener, index: number): Component {
        return this.insertEventListener(ComponentListenerDefs.focus, value, index) as Component;
    }

    public removeFocusListener(value: FocusListener): Component {
        return this.removeEventListener(ComponentListenerDefs.focus, value) as Component;
    }

    public addBlurListener(value: BlurListener): Component {
        this.addEventListener(ComponentListenerDefs.focus, this._setPreFocusValueRef);
        return this.addEventListener(ComponentListenerDefs.blur, value) as Component;
    }

    public removeBlurListener(value: BlurListener): Component {
        return this.removeEventListener(ComponentListenerDefs.blur, value) as Component;
    }

    addDataDisplayListener(value: DataDisplayListener): Component {
        return this.addEventListener(_dataDisplayListenerDef, value) as Component;
    }

    removeDataDisplayListener(value: DataDisplayListener): Component {
        return this.removeEventListener(_dataDisplayListenerDef, value) as Component;
    }

    public focus() {
        this.getFocusTarget()?.focus();
    }

    protected getFocusTarget(): HTMLElement {
        return this._element;
    }

    isFocusable() {
        return this.getFocusTarget() != null;
    }

    public hasFocus(): boolean {
        return DOMUtil.isActiveElement(this.getFocusTarget());
    }

    public getEventTarget(): HTMLElement {
        return this._element;
    }

    public get style(): CSSStyleDeclaration {
        return this._element.style;
    }

    public set style(value: CSSStyleDeclaration) {
        if (value != null)
            for (const key in value)
                this._element.style[key] = value[key];
    }

    public get fontBold(): boolean {
        return this._fontBold;
    }

    public set fontBold(value: boolean) {
        this._fontBold = value;
        if (this.getFontTarget() == null) {
            return;
        }
        if (value)
            this.getFontTarget().style.fontWeight = "500";
        else
            this.getFontTarget().style.fontWeight = "";
    }

    getDragTarget(): HTMLElement {
        return this._element;
    }

    /**
     * Meant to be overridden for components that wish font properties to affect something other than the root element.
     * @returns
     */
    protected getFontTarget(): HTMLElement {
        return this._element;
    }

    public get color(): Color {
        return this._color;
    }

    public set color(value: Color) {
        this._color = value;
        this._element.style.color = value == null ? "" : getThemeColor(value);
    }

    public get backgroundColor(): Color {
        return this._backgroundColor;
    }

    public set backgroundColor(value: Color) {
        this._backgroundColor = value;
        this._element.style.backgroundColor = value == null ? "" : getThemeColor(value);
    }

    public get align(): HorizontalAlignment {
        return this._align;
    }

    public set align(value: HorizontalAlignment) {
        this._align = value;
        if (value === HorizontalAlignment.RIGHT) {
            this._element.style.justifyContent = "flex-end";
            this._element.style.textAlign = "right";
        }
        else if (value === HorizontalAlignment.CENTER) {
            this._element.style.justifyContent = "center";
            this._element.style.textAlign = "center";
        }
        else {
            this._element.style.justifyContent = "flex-start";
            this._element.style.textAlign = "left";
        }
    }

    public get fontFamily(): string {
        return this._fontFamily;
    }

    public set fontFamily(value: string) {
        this._fontFamily = value;
        this.getFontTarget().style.fontFamily = value; // should make some theming (an enum) for this
    }

    public get fontSize(): string | number {
        return this._fontSize;
    }

    public set fontSize(value: string | number) {
        this._fontSize = value;
        this.getFontTarget().style.fontSize = getThemeFontSize(value);
    }

    public get _designer(): DesignerInterface {
        return this.__designer;
    }

    public set _designer(value: DesignerInterface) {
        this.__designer = value;
        if (value != null) {
            this._element.onclick = (event) => this._clickedInDesigner(new MouseEvent(this, event));
            this._interactionEnabled = false;
        }
    }

    /**
     * This is called when the Component is initially added to the UI Designer.  Components can override
     * this to establish initial state for the component.  Table and Tabset use this to add an initial column and
     * tab, respectively.
     */
    protected _initialDropInDesigner() {
    }

    private _clickedInDesigner(event: MouseEvent) {
        if (this._designer == null)
            return;
        const nestedContainer = this._getNestedContainer(this);
        if (nestedContainer == null)
            this._designer.selectComponent(this, event.ctrlKey);
        else
            this._designer.selectComponent(nestedContainer, event.ctrlKey);
        event.stopPropagation();
    }

    /**
     * Meant to be overridden by any component that has a "default" event type.  This is used to add the code skeleton for the event handler when the user double clicks the component in the designer.
     *
     * @returns The name of the event that is considered the default event for this component type.
     */
    protected _getDefaultEventProp(): string {
        return null;
    }

    private _getNestedContainer(comp: Component) {
        // this totally doesn't work and will need to be fixed when we start working with Layouts that are nested
        while (comp != null) {
            const proto = Object.getPrototypeOf(comp);
            if (proto != null && proto.constructor != null && (comp.isNestedDesignerContainer() === true || proto.constructor.name === "UsedToBeNestedLayoutButDon'tMakeItLayout")) // don't add instanceof here so we don't import that module
                return comp;
            comp = comp._parent;
        }
        return null;
    }

    public isNestedDesignerContainer(): boolean {
        return false;
    }

    public get companyType(): CompanyType {
        return this._companyType == null ? this.getPropertyDefinitions().companyType.defaultValue : this._companyType;
    }

    public set companyType(value: CompanyType) {
        this._companyType = value;
    }

    public get ltlType(): LtlType {
        return this._ltlType;
    }

    public set ltlType(value: LtlType) {
        this._ltlType = value;
    }

    public get cursor(): Cursor {
        return this._cursor;
    }

    public set cursor(value: Cursor) {
        this._cursor = value;
        this._element.style.cursor = value;
    }

    public get draggable(): boolean {
        return this._draggable;
    }

    public set draggable(value: boolean) {
        const target = this.getDragTarget();
        if (target != null) {
            this._draggable = value;
            if (this._designer == null)
                target.draggable = value;
        }
    }

    public get left(): string | number {
        return this._left;
    }

    public set left(value: string | number) {
        this._left = value;
        this._element.style.left = DOMUtil.getSizeSpecifier(value);
    }

    public get top(): string | number {
        return this._top;
    }

    public set top(value: string | number) {
        this._top = value;
        this._element.style.top = DOMUtil.getSizeSpecifier(value);
    }

    public get right(): string | number {
        return this._right;
    }

    public set right(value: string | number) {
        this._right = value;
        this._element.style.right = DOMUtil.getSizeSpecifier(value);
    }

    public get bottom(): string | number {
        return this._bottom;
    }

    public set bottom(value: string | number) {
        this._bottom = value;
        this._element.style.bottom = DOMUtil.getSizeSpecifier(value);
    }

    public get width(): string | number {
        return this._width;
    }

    public set width(value: string | number) {
        this._width = value;
        this._element.style.width = DOMUtil.getSizeSpecifier(value);
    }

    public get minWidth(): string | number {
        return this._minWidth;
    }

    public set minWidth(value: string | number) {
        this._minWidth = value;
        this._element.style.minWidth = DOMUtil.getSizeSpecifier(value);
    }

    public get maxWidth(): string | number {
        return this._maxWidth;
    }

    public set maxWidth(value: string | number) {
        this._maxWidth = value;
        this._element.style.maxWidth = DOMUtil.getSizeSpecifier(value);
    }

    public get height(): string | number {
        return this._height;
    }

    public set height(value: string | number) {
        this._height = value;
        this.setElementHeight();
    }

    public get minHeight(): string | number {
        return this._minHeight;
    }

    public set minHeight(value: string | number) {
        this._minHeight = value;
        this._element.style.minHeight = DOMUtil.getSizeSpecifier(value);
    }

    public get maxHeight(): string | number {
        return this._maxHeight;
    }

    public set maxHeight(value: string | number) {
        this._maxHeight = value;
        this._element.style.maxHeight = DOMUtil.getSizeSpecifier(value);
    }

    public get borderShadow(): boolean {
        return this._borderShadow;
    }

    public set borderShadow(value: boolean) {
        this._borderShadow = value;
        this.setClassIncluded(classes.borderShadow, value === true);
    }

    private setElementBorder(which: string) {
        const element = this._getBorderPropTarget();
        let color = this["_border" + which + "Color"];
        if (color != null)
            color = getThemeColor(color);
        element.style["border" + which] = this.getBorderString(this["_border" + which + "Width"], this["_border" + which + "Type"], color);
    }

    protected _getBorderPropTarget(): HTMLElement {
        return this._element;
    }

    public get borderRadius(): string | number {
        return this._borderRadius;
    }

    public set borderRadius(value: string | number) {
        this._borderRadius = value;
        this._element.style.borderRadius = DOMUtil.getSizeSpecifier(value);
    }

    public get borderBottomLeftRadius(): string | number {
        return this._borderBottomLeftRadius;
    }

    public set borderBottomLeftRadius(value: string | number) {
        this._borderBottomLeftRadius = value;
        this._element.style.borderBottomLeftRadius = DOMUtil.getSizeSpecifier(value);
    }

    public get borderBottomRightRadius(): string | number {
        return this._borderBottomRightRadius;
    }

    public set borderBottomRightRadius(value: string | number) {
        this._borderBottomRightRadius = value;
        this._element.style.borderBottomRightRadius = DOMUtil.getSizeSpecifier(value);
    }

    public get borderTopLeftRadius(): string | number {
        return this._borderTopLeftRadius;
    }

    public set borderTopLeftRadius(value: string | number) {
        this._borderTopLeftRadius = value;
        this._element.style.borderTopLeftRadius = DOMUtil.getSizeSpecifier(value);
    }

    public get borderTopRightRadius(): string | number {
        return this._borderTopRightRadius;
    }

    public set borderTopRightRadius(value: string | number) {
        this._borderTopRightRadius = value;
        this._element.style.borderTopRightRadius = DOMUtil.getSizeSpecifier(value);
    }

    private getBorderString(width, type, color: Color) {
        if (width === undefined)
            return "";
        if (width === null)
            return "unset";
        if (color === undefined)
            color = "currentcolor";
        color = getThemeColor(color);
        if (type === undefined)
            type = "solid";
        return width + "px " + type + " " + color;
    }

    public get borderWidth(): number {
        return this._borderWidth;
    }

    public set borderWidth(value: number) {
        this._borderWidth = value;
        this.setElementBorder("");
    }

    public get borderType(): BorderType {
        return this._borderType;
    }

    public set borderType(value: BorderType) {
        this._borderType = value;
        this.setElementBorder("");
    }

    public get borderColor(): Color {
        return this._borderColor;
    }

    public set borderColor(value: Color) {
        this._borderColor = value;
        this.setElementBorder("");
    }

    public get borderBottomWidth(): number {
        return this._borderBottomWidth;
    }

    public set borderBottomWidth(value: number) {
        this._borderBottomWidth = value;
        this.setElementBorder("Bottom");
    }

    public get borderBottomType(): BorderType {
        return this._borderBottomType;
    }

    public set borderBottomType(value: BorderType) {
        this._borderBottomType = value;
        this.setElementBorder("Bottom");
    }

    public get borderBottomColor(): Color {
        return this._borderBottomColor;
    }

    public set borderBottomColor(value: Color) {
        this._borderBottomColor = value;
        this.setElementBorder("Bottom");
    }

    public get borderLeftWidth(): number {
        return this._borderLeftWidth;
    }

    public set borderLeftWidth(value: number) {
        this._borderLeftWidth = value;
        this.setElementBorder("Left");
    }

    public get borderLeftType(): BorderType {
        return this._borderLeftType;
    }

    public set borderLeftType(value: BorderType) {
        this._borderLeftType = value;
        this.setElementBorder("Left");
    }

    public get borderLeftColor(): Color {
        return this._borderLeftColor;
    }

    public set borderLeftColor(value: Color) {
        this._borderLeftColor = value;
        this.setElementBorder("Left");
    }

    public get borderRightWidth(): number {
        return this._borderRightWidth;
    }

    public set borderRightWidth(value: number) {
        this._borderRightWidth = value;
        this.setElementBorder("Right");
    }

    public get borderRightType(): BorderType {
        return this._borderRightType;
    }

    public set borderRightType(value: BorderType) {
        this._borderRightType = value;
        this.setElementBorder("Right");
    }

    public get borderRightColor(): Color {
        return this._borderRightColor;
    }

    public set borderRightColor(value: Color) {
        this._borderRightColor = value;
        this.setElementBorder("Right");
    }

    public get borderTopWidth(): number {
        return this._borderTopWidth;
    }

    public set borderTopWidth(value: number) {
        this._borderTopWidth = value;
        this.setElementBorder("Top");
    }

    public get borderTopType(): BorderType {
        return this._borderTopType;
    }

    public set borderTopType(value: BorderType) {
        this._borderTopType = value;
        this.setElementBorder("Top");
    }

    public get borderTopColor(): Color {
        return this._borderTopColor;
    }

    public set borderTopColor(value: Color) {
        this._borderTopColor = value;
        this.setElementBorder("Top");
    }

    public get margin(): number | string {
        return this._margin;
    }

    public set margin(value: number | string) {
        this._margin = value;
        this._element.style.margin = DOMUtil.getSizeSpecifier(value);
    }

    public get marginTop(): number | string {
        return this._marginTop;
    }

    public set marginTop(value: number | string) {
        this._marginTop = value;
        this._element.style.marginTop = DOMUtil.getSizeSpecifier(value);
    }

    public get marginBottom(): number | string {
        return this._marginBottom;
    }

    public set marginBottom(value: number | string) {
        this._marginBottom = value;
        this._element.style.marginBottom = DOMUtil.getSizeSpecifier(value);
    }

    public get marginLeft(): number | string {
        return this._marginLeft;
    }

    public set marginLeft(value: number | string) {
        this._marginLeft = value;
        this._element.style.marginLeft = DOMUtil.getSizeSpecifier(value);
    }

    public get marginRight(): number | string {
        return this._marginRight;
    }

    public set marginRight(value: number | string) {
        this._marginRight = value;
        this._element.style.marginRight = DOMUtil.getSizeSpecifier(value);
    }

    public get padding(): number {
        return this._padding;
    }

    public set padding(value: number) {
        this._padding = value;
        this._element.style.padding = DOMUtil.getSizeSpecifier(value);
    }

    public get paddingTop(): number {
        return this._paddingTop;
    }

    public set paddingTop(value: number) {
        this._paddingTop = value;
        this._element.style.paddingTop = DOMUtil.getSizeSpecifier(value);
    }

    public get paddingBottom(): number {
        return this._paddingBottom;
    }

    public set paddingBottom(value: number) {
        this._paddingBottom = value;
        this._element.style.paddingBottom = DOMUtil.getSizeSpecifier(value);
    }


    public get paddingLeft(): number {
        return this._paddingLeft;
    }

    public set paddingLeft(value: number) {
        this._paddingLeft = value;
        this._element.style.paddingLeft = DOMUtil.getSizeSpecifier(value);
    }

    public get paddingRight(): number {
        return this._paddingRight;
    }

    public set paddingRight(value: number) {
        this._paddingRight = value;
        this._element.style.paddingRight = DOMUtil.getSizeSpecifier(value);
    }

    public get rowBreak(): boolean {
        return this._rowBreak == null ? true : this._rowBreak;
    }

    public set rowBreak(value: boolean) {
        this._rowBreak = value;
        if (this._parent != null)
            this._parent.childBreakChanged(this);
    }

    public get disabledTooltip(): StringOrPropsOrComponent {
        return this._disabledTooltip;
    }

    public set disabledTooltip(value: StringOrPropsOrComponent) {
        this._disabledTooltip = value;
        this._manageHoverEvents();
    }

    public get enabledDuringAdd(): boolean {
        if (this._enabledDuringAdd != null)
            return this._enabledDuringAdd;
        else if (this._boundField?.allowAdd === false)
            return false;
        return true;
    }

    public set enabledDuringAdd(value: boolean) {
        this._enabledDuringAdd = value;
        this._syncEnabled();
    }

    public get enabledDuringUpdate(): boolean {
        if (this._enabledDuringUpdate != null)
            return this._enabledDuringUpdate;
        else if (this._boundField?.allowUpdate === false)
            return false;
        return true;
    }

    public set enabledDuringUpdate(value: boolean) {
        this._enabledDuringUpdate = value;
        this._syncEnabled();
    }

    public get enabledDuringSearch(): boolean {
        if (this._enabledDuringSearch != null)
            return this._enabledDuringSearch;
        else if (this._boundField?.allowSearch === false)
            return false;
        return true;
    }

    public set enabledDuringSearch(value: boolean) {
        this._enabledDuringSearch = value;
        this._syncEnabled();
    }

    public get disabledByServer(): boolean {
        return this._disabledByServer;
    }

    private set disabledByServer(value: boolean) {
        this._disabledByServer = value;
        this._syncEnabled();
    }

    private get defaultEnabled(): boolean {
        if (this.disabledByServer === true)
            return false;
        const mode = this.getCurrentDataSourceMode();
        if ((mode === DataSourceMode.ADD && !this.enabledDuringAdd)
            || (mode === DataSourceMode.UPDATE && !this.enabledDuringUpdate)
            || (mode === DataSourceMode.SEARCH && !this.enabledDuringSearch))
            return false;
        if (this.parent != null)
            return this.parent.enabled; // note that this will be recursive through the parent hierarchy
        return true;
    }

    public get enabled(): boolean {
        return this.defaultEnabled && this._enabled !== false;
    }

    public set enabled(value: boolean) {
        this._enabled = value;
        this._syncEnabled();
    }

    public get license(): string {
        return this._license;
    }

    public set license(value: string) {
        this._license = value;
    }

    get nextFocusable(): string {
        return this._nextFocusable;
    }

    set nextFocusable(value: string) {
        this._nextFocusable = value;
        this.syncTabKeyHandler(value);
    }

    private syncTabKeyHandler(value: string) {
        if (this._nextFocusable == null) {
            this.removeKeyHandler(this._tabKeyHandler);
            this._tabKeyHandler = null;
        } else if (this._tabKeyHandler == null) {
            this._tabKeyHandler = {
                key: Keys.TAB,
                listener: (event) => {
                    const container = this.getRootLayout() as Container;
                    const nextFocusableComp = container?.findComponentById(value) as Component;
                    nextFocusableComp?.focus();
                },
                element: this._element,
                scope: this._element
            }
            this.addKeyHandler(this._tabKeyHandler);
        }
    }

    public get visible(): boolean {
        const mode = this.getCurrentDataSourceMode();
        return this._visible !== false &&
            (mode == null
                || mode === DataSourceMode.NONE
                || (mode === DataSourceMode.ADD && this.visibleDuringAdd)
                || (mode === DataSourceMode.SEARCH && this.visibleDuringSearch)
                || (mode === DataSourceMode.UPDATE && this.visibleDuringUpdate));
    }

    public set visible(value: boolean) {
        this._visible = value;
        this._syncVisible();
    }

    public get visibleDuringAdd(): boolean {
        return this._visibleDuringAdd == null ? true : this._visibleDuringAdd;
    }

    public set visibleDuringAdd(value: boolean) {
        this._visibleDuringAdd = value;
        this._syncVisible();
    }

    public get visibleDuringSearch(): boolean {
        return this._visibleDuringSearch == null ? true : this._visibleDuringSearch;
    }

    public set visibleDuringSearch(value: boolean) {
        this._visibleDuringSearch = value;
        this._syncVisible();
    }

    public get visibleDuringUpdate(): boolean {
        return this._visibleDuringUpdate == null ? true : this._visibleDuringUpdate;
    }

    public set visibleDuringUpdate(value: boolean) {
        this._visibleDuringUpdate = value;
        this._syncVisible();
    }

    public get preventCollapse(): boolean {
        return this._preventCollapse == null ? false : this._preventCollapse;
    }

    public set preventCollapse(value: boolean) {
        this._preventCollapse = value;
        this._syncVisible();
    }

    protected _syncVisible() {
        this.setClassIncluded(this._preventCollapse ? classes.hiddenNoCollapse : classes.hidden, !this.visible && this.__designer == null);
    }

    public get hiddenUnlessMouseOver(): Component {
        return this._hiddenUnlessMouseOver;
    }

    public set hiddenUnlessMouseOver(value: Component) {
        if (this._hiddenUnlessMouseOver != null && this._hiddenChildComponentMouseEnterListener != null) {
            this._hiddenUnlessMouseOver.removeMouseEnterListener(this._hiddenChildComponentMouseEnterListener);
            this._hiddenUnlessMouseOver.removeMouseLeaveListener(this._hiddenChildComponentMouseLeaveListener);
        }
        this._hiddenUnlessMouseOver = value;
        if (value != null) {
            this.style.opacity = "0";
            this._hiddenChildComponentMouseEnterListener = () => this.style.opacity = "1";
            value.addMouseEnterListener(this._hiddenChildComponentMouseEnterListener);
            this._hiddenChildComponentMouseLeaveListener = () => this.style.opacity = "0";
            value.addMouseLeaveListener(this._hiddenChildComponentMouseLeaveListener);
        }
    }

    public get zIndex(): number {
        return this._zIndex;
    }

    public set zIndex(value: number) {
        this._zIndex = value;
        if (value == null)
            this._element.style.zIndex = "";
        else
            this._element.style.zIndex = value.toString();
    }

    public getEffectiveZIndex(): number {
        let result = this.zIndex;
        if (result == null) {
            const ancestorValue = DOMUtil.getStyleValueFromElementOrAncestor("z-index", this._element);
            if (typeof ancestorValue === "string")
                result = DOMUtil.convertStyleAttrToNumber(ancestorValue);
            else
                result = ancestorValue;
        }
        return result;
    }

    public get isRow(): boolean {
        return this._isRow;
    }

    public set isRow(value: boolean) {
        this._isRow = value;
    }

    public toggleCollapsed() {
        const rect = this._element.getBoundingClientRect();
        rect.height > 10 ? this.collapse() : this.expand();
    }

    public collapse(options?: TransitionOptions) {
        return TransitionsUtil.collapseComponent(this, options);
    }

    public collapseNow() {
        TransitionsUtil.collapseComponentNow(this);
    }

    public expand(options?: TransitionOptions) {
        return TransitionsUtil.expandComponent(this, options);
    }

    public expandNow() {
        TransitionsUtil.expandComponentNow(this);
    }

    public slideInAndFill(fillWidth: boolean = true, fillHeight: boolean = true, options?: TransitionOptions, displayOverlay?: boolean, overlayProps?: Partial<OverlayProps>): Promise<any> {
        //if we are displaying the slideout inside an overlay that already takes the height of the page header into account, we don't need to adjust
        //for the page header height a second time.
        const topAdjust = ((displayOverlay === true && overlayProps?.coverPageHeader === false) ? McLeodMainPageUtil.getPageHeaderHeight() : 0);
        this.fillWindowHeightAndWidth(fillWidth, fillHeight, topAdjust);
        return this.slideIn(options, displayOverlay, overlayProps);
    }

    public fillWindowHeightAndWidth(fillWidth: boolean = true, fillHeight: boolean = true, topAdjust: number = 0) {
        if (fillWidth === true)
            this.fillWindowWidth();
        if (fillHeight === true)
            this.fillWindowHeight(topAdjust);
    }

    public fillWindowWidth() {
        this.width = "100%";
    }

    public fillWindowHeight(topAdjust: number = 0) {
        this.height = "calc(100% - " + (McLeodMainPageUtil.getPageHeaderHeight() - topAdjust) + "px)";
    }

    public slideIn(options?: TransitionOptions, displayOverlay?: boolean, overlayProps?: Partial<OverlayProps>): Promise<any> {
        this._slideOutOverlay = null;
        this._slideOutRemovalOptions = { ...options };
        if (displayOverlay === true) {
            if (overlayProps == null)
                overlayProps = {};
            if (overlayProps.closeHandler == null) //beware...if you provide this you must also handle closing the slideout
                overlayProps.closeHandler = () => this.slideOut(options, overlayProps?.componentToFocusOnClose);
            this._slideOutOverlay = DynamicLoader.getModuleByName("components/page/Overlay").Overlay.showInOverlay(this, overlayProps);
        }

        new SlideoutSizeManager(this, options?.direction);
        return TransitionsUtil.slideInComponent(this, options);
    }

    public slideOut(options?: TransitionOptions, componentToFocusOnClose?: Component): Promise<any> {
        const optionsToUse = options == null ? this._slideOutRemovalOptions : options;
        const result = TransitionsUtil.slideOutComponent(this, optionsToUse).then(() => {
            if (this._slideOutOverlay != null) {
                DynamicLoader.getModuleByName("components/page/Overlay").Overlay.hideOverlay(this._slideOutOverlay, componentToFocusOnClose);
                this._slideOutOverlay = null;
            }
            this._slideOutRemovalOptions = null;
        });
        return result;
    }

    public get field(): string {
        return this._field;
    }

    public set field(value: string) {
        const oldValue = this._field;
        this._field = value;
        this._matchIdToValue(oldValue, value);
        this._syncDatabinding();
        this._dataSource?.updateComponentsByField(this, oldValue);
    }

    /**
     * When a component is bound to multiple fields, override this method to return the list of fields this component is bound to.
     * This is currently (10/2021) used in DataSource fieldUpdateListener to dynamically update fields that are displayed more than
     * once on a page (i.e. when one use of a field is updated, all components that display that field are re-displayed).
    */
    getFieldNames(): string[] {
        const field = this.field;
        if (field == null)
            return null;
        else
            return [field];
    }

    public get sortField(): string {
        return this._sortField != null ? this._sortField : this.field;
    }

    public set sortField(value: string) {
        this._sortField = value;
    }

    public get excludeFromSortFields(): string[] {
        return this._excludeFromSortFields;
    }

    public set excludeFromSortFields(value: string[]) {
        this._excludeFromSortFields = value;
    }

    /**
     * When a component is bound to multiple fields, override this method to return the list of fields that should be considered within Table sorting
     *
     * @returns string[]
    */
    getSortFieldNames(): string[] {
        const field = this.sortField;
        if (field == null)
            return null;
        else
            return this.filterSortFields([field]);
    }

    get sortDescendingByDefault(): boolean {
        return this._sortDescendingByDefault || false;
    }

    set sortDescendingByDefault(value: boolean) {
        this._sortDescendingByDefault = value;
    }

    get sortNullsAtEnd(): boolean {
        return this._sortNullsAtEnd || false;
    }

    set sortNullsAtEnd(value: boolean) {
        this._sortNullsAtEnd = value;
    }

    filterSortFields(sortFields: string[]): string[] {
        if (ArrayUtil.isEmptyArray(sortFields) || ArrayUtil.isEmptyArray(this.excludeFromSortFields))
            return sortFields;
        for (const excludeField of this.excludeFromSortFields) {
            ArrayUtil.removeFromArray(sortFields, excludeField);
        }
        return sortFields;
    }

    private removeSpecialIdChars(value: string): string {
        return value?.replace(/[^a-zA-Z0-9_-]/g, "");
    }

    protected _matchIdToValue(oldValue: string, value: string, idPrefix: string = this.idPrefix) {
        if (this.__designer?.redisplayProp == null)
            return;
        if (StringUtil.isEmptyString(value)) {
            if (StringUtil.isEmptyString(this.id) && this.__designer["getNextNumber"] != null) {
                this.id = idPrefix + this.__designer["getNextNumber"](idPrefix);
                this.__designer.redisplayProp("id", this.id);
            }
            return;
        }
        oldValue = this.removeSpecialIdChars(oldValue);
        value = this.removeSpecialIdChars(value);
        const newId = StringUtil.toLowerCamelCase(idPrefix + "_" + value);
        if (value != null && this.id !== newId && this.hasDefaultDesignerId(idPrefix)) {
            this.id = newId;
            this.__designer.redisplayProp("id", this.id);
        }
    }

    hasDefaultDesignerId(idPrefix: string = this.idPrefix) {
        if (!this.id.startsWith(idPrefix))
            return false;
        const afterType = this.id.substring(idPrefix.length)
        return afterType.length === 0 || !isNaN(Number(afterType))
    }

    get idPrefix(): string {
        return this.serializationName;
    }

    get typeName(): string {
        if (this.serializationName == null) {
            log.info("Component needed to override serializationName", this);
            throw new Error("Could not find component type for " + this);
        }
        return this.serializationName;
    }

    protected _syncEnabled() {
        this._applyEnabled(this.enabled);
    }

    /**
     * Component subclasses need to override this to enable/disable
     * their DOM elements in response to the various enabled flags changing
     * @param value
     */
    _applyEnabled(value: boolean): void {
    }

    protected _syncDatabinding() {
        if (this.dataSource != null && this.field != null)
            this.dataSource.getMetadata().then(metadata => this._metadataChanged(metadata));
        else if (this.boundRow != null && this.field != null) {
            // Can get rid of this someday, when the PropertiesTable deals in real ModelRows.
            // Still need to check for a modelpath though.
            if (this.boundRow instanceof ModelRow) {
                // if (StringUtil.isEmptyString(this.boundRow._modelPath))
                this._metadataChanged(this.boundRow.getMetadata());
            }
        }
    }

    protected _metadataChanged(metadata: ApiMetadata) {
        if (metadata != null && this.field != null) {
            this._boundField = metadata.output[this.field];
            if (this._boundField == null)
                this._boundField = metadata.input[this.field];
            if (this._boundField == null)
                this._boundField = new MetadataField({ name: this.field });
            this.syncCaptionIfPossible();
            this._fieldBindingChanged();
        }
    }

    /**
     * This method is meant to be overridden if a subclass wants to take special action when it is bound to a field and we have retrieved the metadata for that field
     * from the server.
     *
     */
    protected _fieldBindingChanged() {
    }

    public get searchOnly(): boolean {
        if (this._searchOnly == null)
            return false;
        else
            return this._searchOnly;
    }

    public set searchOnly(value: boolean) {
        const oldValue = this.searchOnly;
        this._searchOnly = value;
        this._dataSource?.addBoundComponent(this, oldValue);
        this.sycnRequiredPropsInDesigner();
    }

    public get dataSource(): DataSource {
        return this._dataSource;
    }

    public set dataSource(value: DataSource) {
        if (this._dataSource != null && this.dataSource instanceof DataSource) {
            this._dataSource.removeBoundComponent(this);
            this._dataSource.removeHasChangedComponent(this);
        }
        this._dataSource = value;
        if (this._dataSource != null && this.dataSource instanceof DataSource)
            this._dataSource.addBoundComponent(this);
        this.syncHasChangedComponent();
        this._syncDatabinding();
        this.syncCaptionIfPossible();
    }

    displayData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        // Component subclasses should typically override this, display their data and then call super.displayData() afterwards
        this.fireDataDisplayListeners(data, allData, rowIndex);
    }

    public fireDataDisplayListeners(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        this.fireListeners(_dataDisplayListenerDef, () => new DataDisplayEvent(this, data, allData, rowIndex));
    }

    public _getTooltipAnchor(): HTMLElement {
        return this._element;
    }

    showTooltip(text: StringOrPropsOrComponent, options?: Partial<TooltipOptions>, props?: Partial<PanelProps>) {
        if (!StringUtil.isEmptyString(text || this._tooltip))
            return DynamicLoader.getModuleByName("components/page/Tooltip").showTooltip(this, text || this._tooltip, options, props);
    }

    hideTooltip() {
        this._mouseOver = false;
        DynamicLoader.getModuleByName("components/page/Tooltip").hideTooltip(this._tooltipInstance);
    }

    /**
     * Defines if the component can grow to the height of the container that contains it.
     * The default implementation of this method does nothing, so the component doesn't grow.
     *
     * @param height: the height to use to reset the component's height (provided as a number)
     */
    public growToContainerHeight(height: number) { }

    public get tooltip(): StringOrPropsOrComponent {
        return this._tooltip;
    }

    public set tooltip(value: StringOrPropsOrComponent) {
        this._tooltip = value;
        this._manageHoverEvents();
    }

    public get tooltipCallback(): TooltipCallback {
        return this._tooltipCallback;
    }

    public set tooltipCallback(value: TooltipCallback) {
        this._tooltipCallback = value;
        this._manageHoverEvents();
    }

    /**
     * This method is called for all Components on a Layout as it is loaded.  It allows the Component to make sure that any metadata that
     * it may need is loaded before the layout initialization finishes.
     * A good example of this is Textbox.lookupModel.
     */
    public async loadMetadata(): Promise<void> {

    }

    private async _displayTooltip(originatingEvent: MouseEvent) {
        if (this.suppressTooltip == true || (this.tooltip == null && this.tooltipCallback == null && this.disabledTooltip == null))
            return;
        let tooltip: Component;
        if (this.tooltip != null)
            tooltip = getComponentFromStringOrPropsOrComponent(this.tooltip, null, this);
        if (this.disabledTooltip != null && this.enabled === false) {
            const disabledTooltip = getComponentFromStringOrPropsOrComponent(this.disabledTooltip, null, this);
            tooltip = this.appendTooltipComponents(tooltip, disabledTooltip);
        }
        if (this.tooltipCallback == null)
            this._tooltipInstance = this.showTooltip(tooltip, { position: this.tooltipPosition, originatingEvent: originatingEvent });
        else 
            this._tooltipInstance = await this.tooltipCallback(tooltip, originatingEvent);
    }

    private appendTooltipComponents(...comps: Component[]): Component {
        if (comps == null || comps.length === 0)
            return undefined;
        const nonNullComps = [];
        for (const comp of comps)
            if (comp != null)
                nonNullComps.push(comp);
        comps = nonNullComps;
        if (comps.length === 1)
            return comps[0];
        const panelClass = DynamicLoader.getClassForPath("components/components/panel/Panel");
        const result = new panelClass();
        for (let i = 0; i < comps.length - 1; i++) {
            result.add(comps[i]);
            result.add(new panelClass({ marginBottom: 8 }));
        }
        result.add(comps[comps.length - 1]);
        return result;
    }

    private _manageHoverEvents() {
        if (this._designer != null && this["_allowTooltipInDesigner"] !== true)
            return;
        const eventsNeeded = this.tooltip != null || this.tooltipCallback != null || this._disabledTooltip != null;
        const eventsPresent = this._tooltipMouseEnterListener != null;
        if (eventsPresent === eventsNeeded)
            return;
        if (eventsNeeded) {
            this._tooltipMouseEnterListener = event => this._tooltipMouseEnter(event);
            this._element.addEventListener("mouseenter", this._tooltipMouseEnterListener);
        } else {
            this._element.removeEventListener("mouseenter", this._tooltipMouseEnterListener);
            delete this._tooltipMouseEnterListener;
            this._clearTooltipShowingEvents();
        }
    }

    protected _tooltipMouseEnter(event: DomMouseEvent) {
        log.debug("Fired mouseenter for component %o, event %o", this, event);
        //closing an overlay means that mouseenter can fire for a component if the mouse was over that component when the overlay closed,
        //even if the mouse didn't just enter the boundary of the component.  when that happens, we do not want to show a tooltip
        if (this === Component.getPreOverlayMouseOverComponent()) {
            log.debug("Ignoring mouse enter event; mouse was over this field before an overlay was displayed, and still is")
            return;
        }
        this._mouseOver = true;
        this._clearTooltipShowingEvents();
        this._tooltipMouseMoveListener = (event: any) => {
            log.debug("Fired mousemove for component %o, event %o", this, event);
            this._lastMouseOverEvent = event;
        };
        this._tooltipMouseLeaveListener = event => this._tooltipMouseLeave(event);
        this._element.addEventListener("mouseleave", this._tooltipMouseLeaveListener);
        this._element.addEventListener("mousemove", this._tooltipMouseMoveListener);
        this._tooltipDisplayTimeout = setTimeout(() => {
            if (this._mouseOver) {
                this.clearTooltipHideTimeout();
                this._displayTooltip(new MouseEvent(this, this._lastMouseOverEvent));
            }
        }, 500);

    }

    private _clearTooltipShowingEvents() {
        if (this._tooltipMouseLeaveListener != null)
            this._element.removeEventListener("mouseleave", this._tooltipMouseLeaveListener);
        if (this._tooltipMouseMoveListener != null)
            this._element.removeEventListener("mousemove", this._tooltipMouseMoveListener);
        delete this._tooltipMouseLeaveListener;
        delete this._tooltipMouseMoveListener;
    }

    protected _tooltipMouseLeave(event: DomMouseEvent) {
        log.debug("Fired mouseleave for component %o, event %o", this, event);
        this._mouseOver = false;
        this._clearTooltipShowingEvents();
        if (this._tooltipDisplayTimeout != null)
            clearTimeout(this._tooltipDisplayTimeout);
        if (this._tooltipInstance != null && !this._tooltipInstance._element.contains(event["toElement"])) {
            this._tooltipHideTimeout = setTimeout(() => this.hideTooltip(), 500);
            log.debug("Set tooltip hide timeout: %o for component id: %o", this._tooltipHideTimeout, this.id);
        }
    }

    clearTooltipHideTimeout() {
        if (this._tooltipHideTimeout != null) {
            log.debug("Clearing tooltip hide timeout: %o for component id: %o", this._tooltipHideTimeout, this.id);
            clearTimeout(this._tooltipHideTimeout);
            this._tooltipHideTimeout = null;
        }
    }

    public static getPreOverlayMouseOverComponent(): Component {
        return this._preOverlayMouseOverComponent;
    }

    public static setPreOverlayMouseOverComponent(comp: Component) {
        //if the supplied value is not null, only assign it if the mouse is currently over that component
        //always allow the value to be nulled out
        if (comp != null) {
            if (comp._mouseOver === true)
                this._preOverlayMouseOverComponent = comp;
        }
        else
            this._preOverlayMouseOverComponent = comp;
    }

    // temporary accessor until everything is converted to TS
    // ideally we want to delete this but, even after conversion to TS, there are lots of uses of it
    /**
     * @deprecated
    */
    public get element(): HTMLElement {
        return this._element;
    }

    public set element(value: HTMLElement) {
        this._element = value;
    }

    _deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, componentCreationCallback: ComponentCreationCallback): string[] {
        if (compDef.dataSource != null && dataSources != null)
            this.dataSource = dataSources[compDef.dataSource];
        return ["dataSource"];
    }

    get parent(): Container {
        return this._parent;
    }

    set parent(value: Container) {
        this._parent = value;
    }

    public get requiredDuringAdd(): boolean {
        return this._requiredDuringAdd ?? this.getDefaultRequired(DataSourceMode.ADD);
    }

    public set requiredDuringAdd(value: boolean) {
        this._requiredDuringAdd = value;
        this._syncRequired();
    }

    public get requiredDuringSearch(): boolean {
        return this._requiredDuringSearch ?? this.getDefaultRequired(DataSourceMode.SEARCH);
    }

    public set requiredDuringSearch(value: boolean) {
        this._requiredDuringSearch = value;
        this._syncRequired();
    }

    public get requiredDuringUpdate(): boolean {
        return this._requiredDuringUpdate ?? this.getDefaultRequired(DataSourceMode.UPDATE);
    }

    public set requiredDuringUpdate(value: boolean) {
        this._requiredDuringUpdate = value;
        this._syncRequired();
    }

    get required(): boolean {

        if (this.getDefaultRequired() !== true) {
            return false;
        }

        switch (this.getCurrentDataSourceMode()) {
            case DataSourceMode.ADD:
                return this.requiredDuringAdd;
            case DataSourceMode.SEARCH:
                return this.requiredDuringSearch;
            case DataSourceMode.UPDATE:
                return this.requiredDuringUpdate;
            default:
                return this.getDefaultRequired();
        }
    }

    set required(value: boolean) {
        this._explicitRequired = value;
        this.sycnRequiredPropsInDesigner();
        this._syncRequired();
    }

    private sycnRequiredPropsInDesigner() {
        if (this._designer != null) {
            this._requiredDuringAdd = undefined;
            this._requiredDuringUpdate = undefined;
            this._requiredDuringSearch = undefined;
        }
    }

    private getDefaultRequired(mode?: DataSourceMode): boolean {
        if (DataSourceMode.SEARCH === mode) {
            return this._explicitRequired && this.searchOnly === true;
        }

        const defaultRequired = this._explicitRequired ?? this._boundField?.required;
        if (DataSourceMode.ADD === mode || DataSourceMode.UPDATE === mode) {
            return defaultRequired && this.searchOnly !== true;
        }
        return defaultRequired;
    }

    protected _syncRequired() {
        this.syncCaptionIfPossible();
    }

    syncCaptionIfPossible() {
        if (typeof this["syncCaption"] === "function") {
            this["syncCaption"]();
        }
    }

    /**
     * Used by the designer to determine which props should be included during serialization.  Most Component subclasses
     * will have the same default value for a property regardless of the other properties in the Component, but some
     * may choose to change the default value for property X when property Y changes.  For example, when the Component
     * is databound, the default value for some fields will come from its bound field.
     * @param prop
     * @returns
     */
    getPropertyDefaultValue(prop: ComponentPropDefinition): any {
        if (prop.name === "required")
            return this._boundField?.required || false;
        else if (prop.name === "requiredDuringAdd")
            return this.getDefaultRequired(DataSourceMode.ADD);
        else if (prop.name === "requiredDuringUpdate")
            return this.getDefaultRequired(DataSourceMode.UPDATE);
        else if (prop.name === "requiredDuringSearch")
            return this.getDefaultRequired(DataSourceMode.SEARCH);
        else if (prop.name === "enabledDuringAdd")
            return this._boundField == null || this._boundField.allowAdd !== false;
        else if (prop.name === "enabledDuringSearch")
            return this._boundField == null || this._boundField.allowSearch !== false;
        else if (prop.name === "enabledDuringUpdate")
            return this._boundField == null || this._boundField.allowUpdate !== false;
        else if (prop.name === "enabled")
            return this.defaultEnabled;
        else if (prop.name === "sortField")
            return this.field;
        else if (prop.name === "displayLabel")
            return this["caption"] || this._boundField?.caption;
        return prop.defaultValue;
    }

    getUniqueIdentifier() {
        const route = DynamicLoader.getRouteFromURL();
        const id = this.id;
        if (route == null || id == null)
            return null;
        return route + ":" + id;
    }

    get valueAsString(): string {
        return null;
    }

    set valueAsString(value: string) {
    }

    storeUserChoiceIfRemembered() {
        if (this.rememberUserChoice === true && this._designer == null) {
            const id = this.getUniqueIdentifier();
            if (id == null)
                log.error("Could not store user choice because component has no unique identifier", this);
            else {
                const key = id + ".value";
                const value = this.valueAsString;
                LocalUserSettings.set(key, value);
            }
        }
    }

    restoreUserChoice() {
        const id = this.getUniqueIdentifier();
        if (id == null)
            log.error("Could not restore user choice because component has no unique identifier", this);
        else {
            const key = id + ".value";
            const value = LocalUserSettings.getString(key);
            if (value != null)
                this.valueAsString = value;
        }
    }

    get rememberUserChoice(): boolean {
        return this._rememberUserChoice == null ? false : this._rememberUserChoice;
    }

    set rememberUserChoice(value: boolean) {
        this._rememberUserChoice = value;
        if (value && !this.isMounted && this._designer == null)
            this.addMountListener(() => this.restoreUserChoice());
    }

    get isMounted(): boolean {
        return document.body.contains(this._element);
    }

    public dataSourceModeChanged(mode: DataSourceMode) {
        this._syncEnabled();
        this._syncVisible();
        this._syncRequired();
        this.resetValidation();
        if (DataSourceMode.ADD == mode)
            this.applyDefaultDataValue();
    }

    public applyDefaultDataValue() {
        if (this.defaultDataValue && this.field)
            this.getRelevantModelRow()?.set(this.field, this.defaultDataValue);
    }

    public updateBoundData(row: ModelRow, mode: DataSourceMode, fillingLinkedValues?: boolean) {
    }

    /**
     * Sets the key of the user's selected theme.  All the properties from the theme object will
     * be applied to this Component.  This is currently a set-only property so that we don't store
     * the key in the Component's object and use memory at such a low level.  If we ever have a
     * need to read the themeKey, we can evaluate that again.
     */
    public set themeKey(value: string) {
        if (value != null) {
            const themeObject = getThemeForKey(value);
            if (themeObject == null) {
                if (this.__designer == null)
                    log.error(() => ["Couldn't find theme for key", value, "Component", this, "Theme", getTheme()]);
            }
            else
                this.applyTheme(themeObject);
        }
    }

    public applyTheme(themeObject: object, force = false) {
        log.debug(() => ["Applying theme object to", this, "Theme", themeObject]);
        for (const key in themeObject) {
            if (force || !this.isPropSet(key)) {
                log.debug(() => ["Setting prop", key, "to theme value", themeObject[key]]);
                this[key] = themeObject[key];
            }
        }
    }

    /**
     * Override this for components that return a default value for a property when it is unset.  Currently,
     * this allows the logic that applies theme properties to know whether or not a component has specifically
     * set a property value.
     */
    protected isPropSet(propName: string) {
        return this[propName] != null;
    }

    public containsText(searchValue: string, ignoreCase: boolean = true): boolean {
        if (StringUtil.isEmptyString(searchValue))
            return true;
        if (ignoreCase)
            searchValue = searchValue.toLowerCase();
        const searchValues = this.getSearchValues();
        if (searchValues != null)
            for (let value of searchValues) {
                if (ignoreCase)
                    value = value?.toLowerCase();
                if (value != null && value.indexOf(searchValue) >= 0)
                    return true;
            }
        return false;
    }

    public getSearchValues(): string[] {
        return [];
    }

    public getDesigner() {
        return this._designer;
    }

    public setDesigner(designer: any) {
        this._designer = designer;
    }

    public addListenersToAll(listeners: any) {
        if (listeners != null) {
            for (const key of Object.keys(listeners)) {
                const listenerInfo = listeners[key];
                const listenerDef = listenerInfo.def;
                const listenerList = listenerInfo.listeners;
                for (const listener of listenerList) {
                    this.addEventListener(listenerDef, listener)
                }
            }
        }
    }

    public addMountListener(listener: () => void): void {
        MountUtil.addMountListener(this._element, listener);
    }

    public addUnmountListener(listener: () => void): void {
        MountUtil.addUnmountListener(this._element, listener);
    }

    public addResizeListener(listener: ResizeListener): void {
        this.addEventListener(ComponentListenerDefs.resize, listener);
        this.syncResizeObserver();
    }

    public removeResizeListener(listener: ResizeListener): void {
        this.removeEventListener(ComponentListenerDefs.resize, listener);
        this.syncResizeObserver();
    }

    private syncResizeObserver() {
        const listenerCount = this.getListeners(ComponentListenerDefs.resize)?.length;
        if (listenerCount > 0 && this.resizeObserver == null) {
            log.debug(() => ["Add resize observer", this]);
            const debouncedResized =  JSUtil.debounce(() => this.resized(), 100);
            this.resizeObserver = new ResizeObserver(debouncedResized);
            this.resizeObserver.observe(this._element);
        }
        else if (listenerCount === 0 && this.resizeObserver != null) {
            log.debug(() => ["Remove resize observer", this]);
            this.resizeObserver.unobserve(this._element);
            this.resizeObserver.disconnect();
            delete this.resizeObserver;
        }
    }

    protected resized() {
        log.debug(() => ["Resized", this]);
        const listeners = this.getListeners(ComponentListenerDefs.resize);
        if (listeners != null) {
            const event = new ResizeEvent(this);
            for (const listener of listeners.listeners)
                listener(event);
        }
    }

    get boundRow(): any {
        return this._boundRow;
    }

    set boundRow(row: any) {
        if (this.boundRow === row)
            return;
        this._boundRow = row;
        this._syncDatabinding();
    }

    get serializationName(): string {
        return undefined;
    }

    /**
     * Specifies the proper/pretty name for the component.  Every component should override this method.
     */
    get properName(): string {
        throw new Error("Every component must specify its proper name");
    }

    /**
     * This returns the position of the Component on the page (underlying HTMLElement's getBoundingClientRect()) or null
     * if the Component has not been added to the page yet.
     */
    get bounds(): DOMRect {
        const result = this._element.getBoundingClientRect();
        if (result?.x === 0 && result?.y === 0 && result?.width === 0 && result?.height === 0)
            return null;
        return result;
    }

    /**
     * Returns true if the point represented by x,y is over this Component.  Usually this is used in conjunction with MouseEvent
     * xOffset and yOffset to determine if the mouse is over a Component.
     *
     * @param x
     * @param y
     * @returns
     */
    containsPoint(x: number, y: number): boolean {
        const bounds = this.bounds;
        if (this.bounds == null)
            return false;
        return x >= bounds.left && x <= bounds.right && y >= bounds.top && y <= bounds.bottom;
    }

    public hasBoundField(): boolean {
        return this._boundField != null;
    }
    /**
     * Reattaches instances of EventListenerList to the target element (as defined by getEventTarget()).
     *
     * This is necessary when a Component exists, but its target element has been thrown away and needs to be recreated.
     * A common scenario occurs when a Component that can be rendered as printable has been made printable, and is
     * subsequently being toggled back to non-printable.  When this occurs, the Component contains its EventListenerLists
     * throughout, but those events need to be re-attached to the new target DOM element (that is created when the
     * component is being converted back to non-printable).
     *
     * To do this, this method calls EventListenerList.syncDomEvent() each EventListenerList that the Component contains.
     */
    reattachListeners() {
        const listenerDefs = this.getListenerDefs();
        for (const def of Object.values(listenerDefs)) {
            this[def.listName]?.syncDomEvent();
        }
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...ComponentListenerDefs,
            "dataDisplay": { ..._dataDisplayListenerDef }
        };
    }

    /**
     * Gets the value of the field as it was when the field first got focus.
     *
     * @returns any
     */
    get preFocusValue(): any {
        return this._preFocusValue;
    }

    /**
     * Saves the value of the field at the time of the field gaining focus.
     * This value can be used later to determine if the field changed while it had focus.
     */
    private _setPreFocusValue(event: Event) {
        if (this._element.contains(event.domEvent["relatedTarget"])) {
            log.debug("Not setting pre-focus value for component ID %o, the element gaining focus is within this same component", this.id);
            return;
        }
        this._preFocusValue = this.getDataValue();
        log.debug("Set pre-focus value: %o", this._preFocusValue);
    }

    getRelevantModelRow(): ModelRow {
        return DynamicLoader.getModuleByName("components/base/ComponentDataLink").getRelevantModelRow(this);
    }

    getCurrentDataSourceMode(): DataSourceMode {
        return DynamicLoader.getModuleByName("components/base/ComponentDataLink").getCurrentDataSourceMode(this);
    }

    inDataSourceMode(...modes: DataSourceMode[]): boolean {
        const currentMode = this.getCurrentDataSourceMode();
        return currentMode != null &&  modes.some(mode => mode === currentMode);
    }

    /**
     * Gets the value of the field.  This could be:
     * - In the case of a data-bound component, this will be the value backing the field (taken from the ModelRow).
     * - In the case of a component that is not data-bound, this will be the value that best indicates the state of the field.
     *
     * @returns any
     */
    getDataValue(): any {
        const boundValue = this.getRelevantModelRow()?.get(this._field, null);
        if (boundValue != null)
            return boundValue;
        return this.getBasicValue();
    }

    /**
     * Method to be overridden in each sub-class of Component, which will return the Component's value when it is not data-bound.
     *
     * @returns any
     */
    getBasicValue(): any {
        return null;
    }

    changedWhileFocused(): boolean {
        const valueOnBlur = this.getDataValue()
        if (this.preFocusValue == null && valueOnBlur == null)
            return false;
        else
            return this.preFocusValue !== valueOnBlur;
    }

    public get displayLabel(): string {
        return this._displayLabel || this._boundField?.caption;
    }

    public set displayLabel(value: string) {
        this._displayLabel = value;
    }

    /**
     * This returns a string that describes the given field. Most Components that just display a single field
     * will just return the Component's regular displayLabel property.  Components that might display multiple fields,
     * like CityState might choose to have separate displayLabel properties for each field that they display
     * (or in CityState's case, we might choose to append some text ("City", "State", "Zip") to the displayLabel).
     */
    public getDisplayLabel(field: string, context: string): string {
        return this.displayLabel;
    }

    async ripple(color: string, options?: Partial<RippleOptions>): Promise<void> {
        color = getThemeColor(color);
        const speed = options?.speed || 300;
        const button = this._element;
        const ripple = document.createElement("span");
        ripple.className = "cmp-ripple";
        ripple.style.animation = "rippleEffect " + speed + "ms linear",
            ripple.style.backgroundColor = color;
        const thisStyle = getComputedStyle(this._element);
        button.appendChild(ripple);
        const rippleWrapper = document.createElement("span");
        if (thisStyle.borderRadius != null)
            rippleWrapper.style.borderRadius = thisStyle.borderRadius;
        rippleWrapper.className = "cmp-rippleWrapper"
        rippleWrapper.appendChild(ripple)
        this._element.appendChild(rippleWrapper);
        return JSUtil.delayPromise(speed).then(() => {
            rippleWrapper?.parentNode?.removeChild(rippleWrapper);
        });
    }

    addUnderlineWhenMouseOver() {
        if (this != null) {
            this.addMouseEnterListener(event => {
                this.underlineWhenMouseOver(event.target, true);
            });
            this.addMouseLeaveListener(event => {
                this.underlineWhenMouseOver(event.target, false);
            });
        }
    }

    underlineWhenMouseOver(comp: Component, isOver: boolean) {
        if (isOver) {
            comp.addClass(LabelStyles.linkHover);
        }
        else
            comp.removeClass(LabelStyles.linkHover);
    }

    /**
     * This is called by EventListenerList after a DOM event is triggered but before
     * the event listeners for that event are fired.  If gives Component and its subclasses
     * a way to be notified of events so that they can respond as they see fit.
     *
     * This method should return true if the event listeners should be fired, or
     * false if the event listeners should not be fired.
     */
    domEventFiringListeners(event: DomEvent, eventDef: ListenerListDef): boolean {
        const result = this.enabled !== false || eventDef?.activeWhenDisabled === true
            || (event instanceof DragEvent && this._designer != null);

        if (eventDef === ComponentListenerDefs.click && this.hideTooltipOnClick === true)
            this.hideTooltip();
        return result;
    }

    get hideTooltipOnClick(): boolean {
        return this._hideTooltipOnClick == null ? true : this._hideTooltipOnClick;
    }

    set hideTooltipOnClick(value: boolean) {
        this._hideTooltipOnClick = value;
    }

    get keyHandlers(): KeyHandler[] {
        return this._keyHandlers;
    }

    set keyHandlers(value: KeyHandler[]) {
        this._keyHandlers = value;
    }

    addKeyHandler(handler: KeyHandler) {
        if (this.keyHandlers == null)
            this.keyHandlers = [];
        this.keyHandlers.push(handler);
    }

    removeKeyHandler(handler: KeyHandler) {
        ArrayUtil.removeFromArray(this.keyHandlers, handler);
        if (ArrayUtil.isEmptyArray(this.keyHandlers))
            this.keyHandlers = null;
    }

    addHandlerForKey(key: string, modifiers?: ModifierKeys, listener?: (event) => void) {
        this.addKeyHandler(this.createKeyHandler(key, listener, modifiers));
    }

    getKeyHandlers(): KeyHandler[] {
        return this._keyHandlers;
    }

    createKeyHandler(key: string, listener: (event) => void, modifiers?: ModifierKeys, scope?: HTMLElement): KeyHandler {
        return { key: key, modifiers: modifiers, listener: listener, element: this._element, scope: scope };
    }

    /**
     * This method returns the permissions types that this Component can handle.  The base Component class
     * can handle 'view' permissions and Component subclasses can override this to return the
     * permissions types that they are capable of handling.
     */
    getPermissionsTypes(): PermissionsDefinition[] {
        return [
            {
                permsType: "V",
                description: "View security",
                availableToAllDescription: "This item is visible to everyone",
                availableToNoneDescription: "This item is hidden from everyone"
            }
        ];
    }

    public isOrContains(anotherComponent: Component): boolean {
        return DOMUtil.isOrContains(this._element, anotherComponent?._element);
    }

    getRootLayout(): Layout {
        const parents: Container[] = [];
        this.addRecursiveParents(this, parents);
        return parents.reverse().find(container => container instanceof Layout) as Layout;
    }

    getParentLayout(): Layout {
        const parents: Container[] = [];
        this.addRecursiveParents(this, parents);
        return parents.find(container => container instanceof Layout) as Layout;
    }

    private addRecursiveParents(component: Component, result: Container[]) {
        if (component.parent != null) {
            // check for circular hierarchy to avoid an infinite recursion loop
            if (result.includes(component.parent))
                return [];
            result.push(component.parent)
            this.addRecursiveParents(component.parent, result);
        }
    }

    findParentWithId(parentId: string): Container {
        if (this.parent == null)
            return null;
        if (this.parent.id === parentId)
            return this.parent;
        return this.parent.findParentWithId(parentId);
    }

    findParentOfType(parentSerializationName: string): Component {
        if (this.parent == null)
            return null;
        if (this.parent.serializationName === parentSerializationName)
            return this.parent;
        return this.parent.findParentOfType(parentSerializationName);
    }

    override syncBaseVersionProps() {
        super.syncBaseVersionProps();
        if (this._designer != null)
            this.setClassIncluded(classes.designerModifiedBaseComponent, this.baseVersionProps != null);
    }

    public get doOnDataSourceChanged(): (dataSource: DataSource, changesPresent: boolean) => void {
        return this._doOnDataSourceChanged;
    }

    public set doOnDataSourceChanged(value: (dataSource: DataSource, changesPresent: boolean) => void) {
        if (value === this._doOnDataSourceChanged)
            return;
        this._doOnDataSourceChanged = value;
        this.syncHasChangedComponent();
    }

    private syncHasChangedComponent() {
        if (this.dataSource != null && this.dataSource instanceof DataSource) {
            if (this._doOnDataSourceChanged != null)
                this.dataSource.addHasChangedComponent(this);
            else
                this.dataSource.removeHasChangedComponent(this);
        }
    }

    /**
     * Indicates if the component has a value.  It should be overridden in each Component class, so that each
     * component can define its own logic for determining if a value is present.
     * @returns boolean
     */
    public isEmpty(): boolean {
        //it would be nice if we threw an error to help us identify places where this could be implemented,
        //but that breaks existing screens
        //throw new Error("isEmpty() has not been implemented for this component");
        return false;
    }

    /**
     * This method should be overriden in classes that can contain a component that can be enlarged.  It allows that
     * panel/container to remove/hide all other components, so that the enlarging component(s) have room to grow.
     *
     * This method is defined here (and not in Container) because of classes like TableRow, which can contain other
     * components, but do not descend from Container.
     * @param componentsToEnlarge An array of Components that will be enlarged once the parent panel/container is ready
     */
    public doBeforeComponentEnlarge(componentsToEnlarge: Component[]) {
        throw new Error("doBeforeComponentEnlarge should not be called on simple components");
    }

    /**
     * This method should be overriden in classes that can contain a component that can be enlarged.  It allows that
     * panel/container to redisplay comoponents that were removed.hidden before other compoents were enlarged.
     *
     * This method is defined here (and not in Container) because of classes like TableRow, which can contain other
     * components, but do not descend from Container.
     */
    public doAfterComponentsShrink() {
        throw new Error("doAfterComponentsShrink should not be called on simple components");
    }

    public get enlarged(): boolean {
        return this._enlarged == null ? false : this._enlarged;
    }

    /**
     * Tell a component to enlarge or shrink.
     * This will also call a method on the defined scope component so it can react accordingly.
     */
    public set enlarged(value: boolean) {
        if (this.enlarged === value)
            return;
        if (this.enlargeScope == null) {
            log.debug("Cannot enlarge component, no scope has been defined");
            return;
        }
        if (value === true)
            this.enlargeScope.doBeforeComponentEnlarge([this]);
        this._enlarged = value;
        this.handleEnlargeOrShrink();
        if (value !== true)
            this.enlargeScope.doAfterComponentsShrink();
    }

    public toggleEnlarged() {
        this.enlarged = !this.enlarged;
    }

    /**
     * This method is intended to be overridden in subclasses to define how that particular component behaves
     * when it is enlarged/shrunk.  This method is needed due to the timing of enlarging/shrinking the component
     * in relation to when the parent panel/container needs to be adjusted.
     */
    handleEnlargeOrShrink() {
    }

    public get enlargeScope(): Component {
        return this._enlargeScope;
    }

    public set enlargeScope(value: Component) {
        this._enlargeScope = value;
    }

    public doWhileDimensionsComputed(callback: (component?: Component) => void) {
        const rect = this._element.getBoundingClientRect();
        if (rect.width === 0 && rect.height === 0)
            this.doWhileOffScreen(callback);
        else
            callback(this);
    }

    public doWhileOffScreen(callback: (component?: Component) => void) {
        const prevParent = this._element.parentElement;
        const prevSibling = this._element.nextElementSibling;
        const prevVisible = this.visible;
        const prevPosition = this._element.style.position;
        const prevLeft = this._element.style.left;
        try {
            this.visible = true;
            this._element.style.position = "absolute";
            this._element.style.left = "-9999";
            prevParent?.removeChild(this._element);
            document.body.appendChild(this._element);
            callback(this);
        }
        finally {
            this.visible = prevVisible;
            this._element.style.position = prevPosition;
            this._element.style.left = prevLeft;
            document.body.removeChild(this._element);
            prevParent?.insertBefore(this._element, prevSibling);
        }
    }

    /**
     * This method is intended to be overridden by sub-classes so that each sub-class can return a list of components
     * that are considered to be standalone components.  The returned list should include the normal 'components' array,
     * as well as components residing in special areas (such as table tools, etc).  It should not return components that
     * are used to make up another component (such as Location's textCombined Textbox).
     * @returns an array of Components that are contained by this component that should be validated by the UI Designer
     */
    public discoverIncludedComponents(): Component[] {
        return null;
    }

    /**
     * This method is intended to be overridden by sub-classes to return a list of special Layout components that are not
     * part of the standard 'components' array. These might include layouts within special panels or tools sections that
     * are managed separately from the main component hierarchy.
     * @returns an array of Layout components that must be loaded before the parent layout loads
     */
    public getSpecialLayouts(): Layout[] {
        return null;
    }

    public override get selectableInDesigner(): boolean {
        return super.selectableInDesigner === true || this.deserialized === true;
    }

    public get removedByServer(): boolean {
        return this._removedByServer;
    }

    private set removedByServer(value: boolean) {
        this._removedByServer = value;
    }
}
