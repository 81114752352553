import { AbstractSettings, AbstractSettingsDef, SettingsManager } from "@mcleod/core";

export interface PortalCustomerSettingsDef extends AbstractSettingsDef {
    allow_location_order_entry: boolean;
    assistance_contact: string;
    collections_contact: any;    
    credit_status: string;
    display_brk_status: boolean;
    display_eta: string;
    display_ysplit_stops: boolean;
    enforce_revenue_code: boolean;
    id: string;
    ltl_include_holiday: boolean;
    ltl_include_saturday: boolean;
    ltl_include_sunday: boolean;
    ltl_max_days_out: number;
    name: string;
    operations_user: any;
    order_email_list: string;
    order_pickup_window_minutes: number;
    order_prep_time_minutes: number;
    order_submit_ok: string;
    order_terms_conditions: string;
    order_welcome_message: string;
    order_contact_name: string;
    order_contact_phone: string;
    pending_booking_message: string;
    quote_sheet_template: number;
    salesperson: any;
}

export class PortalCustomerSettings extends AbstractSettings {
    constructor() {
        super("customer_settings");
    }

    public static getSingleton(): PortalCustomerSettings {
        return SettingsManager.getSingleton("customer_settings") as PortalCustomerSettings;
    }

    public static get(): PortalCustomerSettingsDef {
        return PortalCustomerSettings.getSingleton().values as PortalCustomerSettingsDef;
    }

    public override get values(): PortalCustomerSettingsDef {
        return super.values as PortalCustomerSettingsDef;
    }

    protected getValuesInternal(): PortalCustomerSettingsDef {
        return this._values as PortalCustomerSettingsDef;
    }
}
