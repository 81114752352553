import { ChangeEvent, ClickEvent, DataDisplayEvent, Label, Snackbar } from "@mcleod/components";
import { Clipboard, Navigation } from "@mcleod/core";
import { AutogenLayoutRequestDetail } from "./autogen/AutogenLayoutRequestDetail";

export class RequestDetail extends AutogenLayoutRequestDetail {
    private log: string;
    public logFileName: string;
    public logFileContents: string;
    public highlightText: string;

    onLoad() {
        this.log = window["passedLog"];
        if (this.log != null) {
            this.labelDetail.caption = this.log;
            delete window["passedLog"];
        }
        this.switchWrapOnChange(null);
        // sets the Panel's row that the label is on to let it scroll.
        // it's a bummer to have to do that... maybe the library can handle it
        this.labelDetail._element.parentElement.style.overflowY = "auto";
    }

    searchRequest(requestId: string, requestMillis: number) {
        this.searchWithFilter({ request_id: requestId, request_start_millis: requestMillis });
    }

    searchWithFilter(filter: any) {
        this.addLayoutLoadListener(() => {
            this.labelDetail.busy = true;
            this.sourceLogReader.search({ 
                log_name: this.logFileName, 
                log_contents: this.logFileContents, 
                detail_level: "line",
                ...filter
            }).then(() => {
                this.labelDetail.busy = false;
                this.log = this.sourceLogReader.data[0].get("detail");
            }).catch(error => this.labelDetail.busy = false)
        });
    }

    set maxDetailHeight(value: number) {
        this.addLayoutLoadListener(() => this.panelDetail.maxHeight = value);
    }

    /** This is an event handler for the onClick event of buttonCopy.  */
    buttonCopyOnClick(_event: ClickEvent) {
        Clipboard.copyText(this.log);
        Snackbar.showSnackbar("Log copied to clipboard!");
    }

    /** This is an event handler for the onClick event of buttonPopout.  */
    async buttonPopoutOnClick(event: ClickEvent) {
        const screen = window.screen as any;
        const left = screen.availLeft + (screen.availWidth / 2) - 500;
        const top = screen.availTop + (screen.availHeight / 2) - 350;
        const openedWindow = await Navigation.navigateTo("common/logreader/RequestDetail", { newTab: true, left, top, height: 700, width: 1000, windowDecorators: false });
        openedWindow["passedLog"] = this.log;
    }

    /** This is an event handler for the onChange event of switchWrap.  */
    switchWrapOnChange(_event: ChangeEvent) {
        this.labelDetail.style.whiteSpace = this.switchWrap.checked ? "pre-wrap" : "pre";
    }

    /** This is an event handler for the onDataDisplay event of labelDetail.  */
    labelDetailOnDataDisplay(event: DataDisplayEvent) {
        // this code hightlights the error tag and scrolls to it.  It's way too much direct DOM manipulation.
        if (this.highlightText != null) {
            const element = (event.target as Label)._element;
            const html = element.innerHTML;
            if (html.indexOf(this.highlightText) > 0) {
                element.innerHTML = html.replace(this.highlightText, '<span style="background-color:yellow">' + this.highlightText + "</span>");
                let span = null;
                for (let i = element.childNodes.length - 1; i >= 0; i--) {
                    const child = element.childNodes.item(i);
                    if (child instanceof HTMLSpanElement)
                        span = child;
                    else if (child instanceof SVGElement)
                        element.removeChild(child); // the busy indicator doesn't go away since we are mucking with the HTML directly
                }
                if (span != null)
                    span.scrollIntoView();
            }
        }

    }
}
