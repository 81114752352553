import { AuthToken } from "..";
import { ProcessInfo } from "../../ProcessInfo";
import { WindowTitle } from "../WindowTitle";
import { UserSettings } from "../settings/UserSettings";
import { Client } from "./Client";

enum ClientInfoReplyType {
    EXISTING_PROCESS = "EXISTING_PROCESS",
    PROCESS_ADDED = "PROCESS_ADDED",
    PROCESS_REMOVED = "PROCESS_REMOVED"
}

//This interface defines the client-related information that's needed as part of the ClientInfoReply
export interface ClientInfo {
    company: string;
    hostName: string;
    processInfo: ProcessInfo;
    sessionId: string;
    type: string;
    userName: string;
    vmIdentifier: string;
    tokenExpSeconds: number;
    tokenFinalExpSeconds: number;
}

export class ClientInfoReply {
    replyType: string;
    userName: string;
    currentScreen: string;
    client: ClientInfo;

    constructor(includeDetail: boolean = false, type: ClientInfoReplyType = ClientInfoReplyType.EXISTING_PROCESS) {
        this.replyType = type;
        this.userName = UserSettings.getUserName() || null;
        this.currentScreen = WindowTitle.get();
        this.client = this.getClientInfo(includeDetail);
    }

    /**
     * Builds the ClientInfo object
     * 
     * @param includeDetail Indicates if we are getting detail info for the client (including the execution log)
     * @returns any
     */
    public getClientInfo(includeDetail: boolean = false): ClientInfo {
        const client = new Client();
        const token = AuthToken.get();
        let exp: number = null;
        let finalExp: number = null;
        if (token != null) {
            const parsedToken = AuthToken.parseToken(token);
            exp = parsedToken.exp;
            finalExp = parsedToken.final_exp;
        }
        return {
            company: client.companyId,
            hostName: client.hostName,
            processInfo: new ProcessInfo(includeDetail),
            sessionId: client.sessionId,
            type: client.type,
            userName: client.userName,
            vmIdentifier: client.vmIdentifier,
            tokenExpSeconds: exp,
            tokenFinalExpSeconds: finalExp
        }
    }
}
