export enum Alignment {
    TOP = "top",
    LEFT = "left",
    RIGHT = "right",
    BOTTOM = "bottom",
    CENTER = "center"
}

export enum HorizontalAlignment {
    LEFT = Alignment.LEFT,
    CENTER = Alignment.CENTER,
    RIGHT = Alignment.RIGHT
}

export type LeftOrRightAlignment = Alignment.LEFT | Alignment.RIGHT;

export enum VerticalAlignment {
    TOP = Alignment.TOP,
    CENTER = Alignment.CENTER,
    BOTTOM = Alignment.BOTTOM
}
