import { CloneComponent, Component, Container } from "@mcleod/components";
import { ActionAddToContainer } from "./ActionAddToContainer";
import { ActionAddToContainerResult } from "./ActionAddToContainerResult";

interface UndoInfo {
    component: Component;
    parent: Container;
    index?: number
}

export class ActionPasteComponent implements ActionAddToContainer {
    topLevelParent: Container;
    componentsToPaste: Component[];
    parent: Container;
    cutting: boolean;
    undoInfo: UndoInfo[];

    constructor(topLevelParent, componentsToPaste, parent, cutting) {
        this.topLevelParent = topLevelParent;
        this.componentsToPaste = componentsToPaste;
        this.parent = parent;
        this.cutting = cutting;
        this.undoInfo = [];
    }

    execute(): ActionAddToContainerResult {
        const newComponents = [];
        if (this.cutting) {
            this.componentsToPaste.forEach(comp => this.undoInfo.push({ component: comp, parent: comp.parent, index: comp.parent.components.indexOf(comp) }))
        }
        for (const comp of this.componentsToPaste) {
            if (this.cutting) {
                comp.parent.remove(comp);
                this.parent.add(comp);
            }
            else {
                const copy = CloneComponent.clone({ 
                    component: comp, 
                    overrideProps: { deserialized: true }, 
                    idCalcFunction: (src, clonedIds) => this.calcId(src, clonedIds)
                });
                newComponents.push(copy);
                this.parent.add(copy);
                this.undoInfo.push({ component: copy, parent: copy.parent });
            }
        }
        return { success: true, container: this.parent, componentsAdded: newComponents };
    }

    calcId(component: Component, clonedIds: string[]) {
        const prefix = component.serializationName || component.constructor.name.toLowerCase();
        let number = 1;
        while (clonedIds.indexOf(prefix + number) >= 0 || this.idExists(prefix + number, this.topLevelParent)) {
            number++;
        }
        return prefix + number;
    }

    idExists(id: string, container: Container) {
        for (const comp of container.components) {
            if (comp.id === id)
                return true;
            if (comp instanceof Container && this.idExists(id, comp))
                return true;
        }
        return false;
    }

    undo() {
        for (const info of this.undoInfo) {
            if (this.cutting) {
                this.parent.remove(info.component);
                info.parent.insert(info.component, info.index);
            }
            else
                info.component.parent.remove(info.component);
        }
    }

    toString() {
        return `Paste ${this.componentsToPaste[0].constructor.name} - ${this.componentsToPaste[0].id}`;
    }
}
