export interface ModelFieldDefinition {
    name: string;
    required: boolean;
}

export interface ModelTableDefinition {
    tableName: string;
    alias: string;
    nestField: string;
    joinType: JoinType;
    cardinality: Cardinality;
    joinConditions: any[];
    joins: Partial<ModelTableDefinition>[];
    fields: (string | Partial<ModelFieldDefinition>)[];
    qualifyFields: boolean;
    editable: boolean;
    autoAddLookupModelJoins: boolean;
    recordType: string;
    respFilteringControlType: string;
    respFilteringContactType: string;
    respFilteringIdField: string;
}

export interface ModelDefinition extends ModelTableDefinition {
    auth: string[];
    fieldListRequired: boolean;
    orderBy: string[];
    modelForDomain: string;
    displayField: string;
    entryLayout: string;
    quickInfoLayout: string;
    lookupLayout: string;
    databaseKey: string;
    multiCompany: boolean;
}


export enum JoinType { INNER = "inner", OUTER = "outer" }

export enum Cardinality { ONE = "one", MANY = "many" }
