import { Attachment, Button, DataSource, HorizontalSpacer, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutUploadImages extends Layout {
	attachmentImage: Attachment;
	buttonCancel: Button;
	buttonSave: Button;
	buttonSaveAndClose: Button;
	horizontalspacer1: HorizontalSpacer;
	panelImages: Panel;
	sourceAddOrderImage: DataSource;
	textboxImageTypes: Textbox;
}
