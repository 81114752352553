import { DynamicLoader } from "@mcleod/core";
import { StringOrPropsOrComponent } from "../page/getComponentFromStringOrPropsOrComponent";

/**
 * This class allows classes at this level (@mcleod/components) to show snackbars and avoid circular dependencies.
 * For examples, a Label might want to show a Snackbar, but a Snackbar is a more complex component that has 
 * Labels and Buttons and such.  Some parameter types are simplified so that we don't bring in the dependencies on
 * those types. The original method signatures are documented here, but will not be enforced at compile-time,
 * so use this carefully.
 */
export class ReflectiveSnackbars {
    private static _snackbarClass: any;

    static get snackbarClass() {
        if (ReflectiveSnackbars._snackbarClass == null)
            ReflectiveSnackbars._snackbarClass = DynamicLoader.getClassForPath("components/page/Snackbar");
        return ReflectiveSnackbars._snackbarClass;
    }

    /**
    * Signature of the reflected method:
    * public static showSnackbar(message: StringOrPropsOrComponent, options?: SnackbarOptions): Snackbar
    */
    public static showSnackbar(message: StringOrPropsOrComponent, options?: any): any {
        return ReflectiveSnackbars.snackbarClass.showSnackbar(message, options);
    }

    /**
    * Signature of the reflected method:
    * public static showWarningSnackbar(message: StringOrPropsOrComponent, options?: SnackbarOptions): Snackbar
    */
    public static showWarningSnackbar(message: StringOrPropsOrComponent, options?: any): any {
        return ReflectiveSnackbars.snackbarClass.showWarningSnackbar(message, options);
    }

    /**
    * Signature of the reflected method:
    * public static showDownloadSnackbar(title: string, message: StringOrPropsOrComponent, 
    * options?: SnackbarOptions): Snackbar
    */
    public static showDownloadSnackbar(title: string, message: StringOrPropsOrComponent, options?: any): any {
        return ReflectiveSnackbars.snackbarClass.showDownloadSnackbar(message, options);
    }
    
}
