import { Component } from "../../base/Component";
import { ComponentPropDefinitions, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { ContainerProps } from "../../base/ContainerProps";
import { PropType } from "../../base/PropType";

export enum SplitterOrientation {
    vertical = "vertical",
    horizontal = "horizontal"
}

export interface SplitterProps extends ContainerProps {
    firstComponent: Component;
    secondComponent: Component;
    orientation: SplitterOrientation;
    position: number | string;
    expandButtonsVisible: boolean;
}

let splitterPropDefs: ComponentPropDefinitions;

export class SplitterPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (splitterPropDefs == null) {
            splitterPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                orientation: { type: PropType.string, defaultValue: SplitterOrientation.horizontal, dropdownProps: { items: Object.keys(SplitterOrientation) }, description: "This specifies the layout of this Splitter.\n\nvertical means two components are stacked vertically.\n\nhorizontal means they are side-by-side." },
                position: { type: PropType.string, defaultValue: "50%", description: "This specifies the initial position of the bar that separates the splitter's two components.  It can be a number of pixels or a pecentage." },
                expandButtonsVisible: { type: PropType.bool, defaultValue: true, description: "This specifies whether or not the two arrow buttons to expand the first or second component are visible." },
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(splitterPropDefs);
        }
        return splitterPropDefs;
    }
}
