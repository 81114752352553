import { CommonDialogs } from "@mcleod/common";
import { Attachment, Button, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, Dialog, Event, Label, Layout, TableRowDisplayEvent } from "@mcleod/components";
import { HorizontalAlignment } from "@mcleod/core";
import { OrderHistoryImages } from "../../portal-common/src/imaging/OrderHistoryImages";
import { AutogenLayoutFactoringOrdersDetails } from "./autogen/AutogenLayoutFactoringOrdersDetails";

export class FactoringOrdersDetails extends AutogenLayoutFactoringOrdersDetails {

    factoringImageDialog: Dialog;

    public removeTabset() {
        this.remove(this.tabset_);
    }

    public removeFactoringImages() {
        this.tabset_.remove(this.tabImages);
    }

    public removeFactoringComments() {
        this.tabset_.remove(this.tabComments);
    }

    public setSelectedTabset(tabset: string) {
        if (tabset === "Comments") {
            this.tabComments.select();
        }
        if (tabset === "Images") {
            this.tabImages.select();
        }
    }

    public setlabelCommentStatus(labelCommentStatus: Label) {
        labelCommentStatus.imageColor = "primary";
    }

    public setlabelImageStatus(labelImageStatus: Label) {
        labelImageStatus.imageColor = "primary";
    }

    public setupFactoringImagesLayout(movementId: string, orderId: string, addImageButton: Button, labelImageStatus: Label) {
        addImageButton.addClickListener((event: Event) => {
            const layout = Layout.getLayout("portal-common/imaging/OrderHistoryImages", {
                maxWidth: 1100,
                width: window.innerWidth * .55,
                backgroundColor: "defaultBackground",
                borderRadius: 4,
                borderWidth: 1,
                borderShadow: true,
                borderColor: "strokeSecondary"
            }) as OrderHistoryImages;
            layout.addLayoutLoadListener((event: Event) => {
                this.tabImages.select();
            });
            layout.setup(movementId, orderId, null,
                (movementId: string, orderId: string) => this.factoringImageDialog.close());
            this.factoringImageDialog = CommonDialogs.createDialog(layout,
                { title: "Upload Images", okVisible: false });
            this.factoringImageDialog.show().finally(() => {
                if (layout.addedAnImage === true)
                    this.populateImages(movementId, orderId, labelImageStatus);
            });
        });
    }

    private populateImages(movementId: string, orderId: string, labelImageStatus: Label) {
        this.tabImages.dataSource.search({ movement_id: movementId, order_id: orderId });
        this.setlabelImageStatus(labelImageStatus);
    }

    imagesOnDisplay(event: TableRowDisplayEvent) {
        const attachment = event.getTableRow().findComponentById("attachmentAttachment") as Attachment;
        if (attachment["_printLabel"]) {
            attachment["_printLabel"].align = HorizontalAlignment.LEFT;
            attachment.minHeight = attachment["_printLabel"].height;
        }
    }
}
