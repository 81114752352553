import { ComponentPropDefinitions, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { ChartFillType, ChartLegendPosition } from "./Chart";

export interface ChartProps {
    animate: boolean;
    chartType: string;
    fillType: ChartFillType;
    datasetLabel: string;
    legendPosition: ChartLegendPosition;
    showGridLines: boolean;
    showTooltips: boolean;
    smooth: boolean;
    suggestedMaxPercentage: number;
    suggestedMinPercentage: number;
    title: string;
    xAxisField: string;
}

export class ChartPropDefinitions {
    private static defs: ComponentPropDefinitions;
    public static getDefinitions(): ComponentPropDefinitions {
        if (ChartPropDefinitions.defs == null) {
            ChartPropDefinitions.defs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                animate: { type: PropType.bool, defaultValue: true },
                chartType: { type: PropType.string, dropdownProps: { items: ["bar", "bubble", "doughnut", "line", "pie", "polarArea", "radar", "scatter"] }, defaultValue: "line", description: "This specifies the basic type of the chart that is being used." },
                datasetLabel: { type: PropType.string },
                fillType: { type: PropType.string, dropdownProps: { items: ["none", "origin", "start", "end"] }, defaultValue: "none" },
                legendPosition: { type: PropType.string, defaultValue: "top", dropdownProps: { items: ["top", "right", "bottom", "left", "none"] } },
                showGridLines: { type: PropType.bool, defaultValue: true },
                showTooltips: { type: PropType.bool, defaultValue: true },
                smooth: { type: PropType.bool },
                suggestedMaxPercentage: { type: PropType.number, defaultValue: 5, description: "Defines a percent value to add to the largest value in the chart, so that there is some space between that data point and the edge of the chart." },
                suggestedMinPercentage: { type: PropType.number, defaultValue: 5, description: "Defines a percent value to add to the smallest value in the chart, so that there is some space between that data point and the edge of the chart." },
                title: { type: PropType.string, description: "This specifies the title that is shown within the chart." },
                xAxisField: { type: PropType.string },
            }
        }
        return ChartPropDefinitions.defs;
    }
}
