import {
    Component, Layout, LayoutProps, Panel, PanelProps, StringOrPropsOrComponent, Textbox, TextboxProps,
    TooltipOptions
} from "@mcleod/components";
import { getComponentFromStringOrPropsOrComponent } from "@mcleod/components/src/page/getComponentFromStringOrPropsOrComponent";
import { DisplayType, HorizontalAlignment, ModelRow, getLogger } from "@mcleod/core";
import { KeyValuePair } from "./KeyValuePair";
import { PanelKeyValuePairs } from "./PanelKeyValuePairs";

const log = getLogger("components/page/Tooltip");

export class CommonTooltips {
    /**
     * This method displays a toolitp that contains key/value pairs.
     *
     * @param heading The content to display above the key value pairs
     * @param keyValuePairs
     * @returns The Panel that contains the resulting tooltip content.
     */
    public static createKeyValueTooltip(keyValuePairs: KeyValuePair[], header?: StringOrPropsOrComponent, footer?: StringOrPropsOrComponent): Panel {
        const panel = new Panel({ fillRow: true, margin: 0, padding: 0 });
        if (header != null) {
            const component = getComponentFromStringOrPropsOrComponent(header);
            panel.add(component);
        }
        const keyValuePanel = new PanelKeyValuePairs({ margin: 0, padding: 0 }, keyValuePairs);
        panel.add(keyValuePanel);
        if (footer != null) {
            const component = getComponentFromStringOrPropsOrComponent(footer);
            panel.add(component);
        }
        return panel;
    }

    public static setTooltipFromLayoutCallback(component: Component, idOrData: string | ModelRow<any>,
        layoutName: string, layoutProps?: Partial<LayoutProps>, options?: Partial<TooltipOptions>,
        searchFilter?: any, tooltipPanelProps?: Partial<PanelProps>) {
        if (idOrData == null && searchFilter == null) return;
        component.tooltipCallback = (baseTooltip, originatingEvent) => {
            const layout = Layout.getLayout(layoutName, layoutProps);
            layout.addLayoutLoadListener(async event => {
                if (idOrData instanceof ModelRow) {
                    layout.mainDataSource.data = [idOrData];
                    layout.mainDataSource.rowIndex = 0;
                }
                else {
                    if (searchFilter == null)
                        searchFilter = { id: idOrData };
                    await layout.mainDataSource.search(searchFilter);
                }
                component["_tooltipInstance"] = component.showTooltip(layout, options,
                    { themeKey: "quickInfo", color: null, ...tooltipPanelProps });
            });
            return null;
        };
    }

    public static setTooltipCallback(component: Component, tooltip: StringOrPropsOrComponent, options?: Partial<TooltipOptions>, checkEmpty: boolean = true) {
        if (component == null || (checkEmpty && component.isEmpty()))
            return;
        component.tooltipCallback = (baseTooltip, originatingEvent) => {
            return component.showTooltip(tooltip, options, { themeKey: "quickInfo", color: null });
        };
    }

    public static setPanelTooltipCallback(component: Component, textboxProps: () => Partial<TextboxProps>[], options?: Partial<TooltipOptions>) {
        CommonTooltips.setTooltipCallback(component, () => {
            const panel = new Panel({ padding: 0, margin: 0 });
            textboxProps?.().forEach(props => {
                const textbox = new Textbox({ ...props, printable: true, fontBold: true });
                if (textbox.displayType === DisplayType.CURRENCY)
                    textbox.printableLabel.align = HorizontalAlignment.LEFT;
                panel.add(textbox);
            });
            component.tooltip = panel;
            return component.tooltip as Panel;
        }, options);
    }
}
