import { ScrollOptions } from "./DOMDefinitions";
import { HeightWidth } from "./HeightWidth";
import { getLogger } from "./Logger";

const log = getLogger("core.DOMUtil");

export class DOMUtil {
    public static moveDOMElementBefore(element: Node, insertBeforeElement: Node): void {
        const currentParent = element.parentNode;
        currentParent?.removeChild(element);
        insertBeforeElement.parentNode.insertBefore(element, insertBeforeElement);
    }

    public static moveDOMElementAfter(element: Node, insertAfterElement: Node): void {
        const currentParent = element.parentNode;
        currentParent?.removeChild(element);
        insertAfterElement.parentNode.insertBefore(element, insertAfterElement.nextSibling);
    }

    public static switchDOMElements(nodeA: Node, nodeB: Node): void {
        const parentA = nodeA.parentNode;
        const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;
        nodeB.parentNode.insertBefore(nodeA, nodeB);
        parentA.insertBefore(nodeB, siblingA);
    }

    public static isScrollbarVisible(element: HTMLElement): boolean {
        return element.scrollHeight > element.clientHeight;
    }

    public static removeChild(parent: Element, child: Element) {
        if (child != null && parent?.contains(child))
            parent.removeChild(child);
    }

    public static isOrContains(outerElement: Element, innerElement: Element): boolean {
        if (outerElement == null || innerElement == null)
            return false;
        return outerElement === innerElement || outerElement.contains(innerElement);
    }

    /**
     * This will iterate the specified element's container hierarchy and return the first parent
     * element that has a vertical scrollbar that is visible.
     * @param element
     * @returns
     */
    public static findScrollableParent(element: HTMLElement): HTMLElement {
        const parent = element.parentElement;
        if (parent == null || parent === document.body)
            return null;
        if (DOMUtil.isScrollbarVisible(parent))
            return parent;
        return DOMUtil.findScrollableParent(parent);
    }

    public static scrollElementIntoView(element: HTMLElement, options?: Partial<ScrollOptions>, parent?: HTMLElement) {
        const p = parent == null ? DOMUtil.findScrollableParent(element) : parent;
        if (p == null)
            return;
        const percent = DOMUtil.getPercentVerticallyVisible(element, p);
        if (percent < 1) {
            log.debug("Scrolling Tabset - parent scroll height before scroll %o", parent?.scrollHeight)
            log.debug("Scrolling Tabset - parent client height before scroll %o", parent?.clientHeight)
            const behavior: ScrollBehavior = options?.smooth === true ? "smooth" : "auto";
            const block: ScrollLogicalPosition = options?.block == null ? "start" : options.block;
            (element).scrollIntoView({ behavior: behavior, block: block });
        }
    }

    public static getSizeSpecifier(value: number | string): string {
        if (value == null)
            return "";
        if (typeof value === "number")
            return value + "px";
        else if (typeof value === "string" && value.length > 0) {
            const lastChar = value[value.length - 1];
            if (lastChar >= "0" && lastChar <= "9")
                return value + "px";
        }
        return value;
    }

    public static convertStyleAttrToNumber(styleValue: string): number {
        if (styleValue == null)
            return 0;
        const digits = styleValue.match(/\-*\d+\.*\d*/);
        if (digits == null)
            return 0;
        let resultAsString = "";
        for (const digit of digits)
            resultAsString += digit;
        return Number(resultAsString);
    }

    public static getStyleAttrAsNumber(styleValue: string | number): number {
        if (styleValue == null)
            return NaN;
        if (typeof styleValue === "string")
            return DOMUtil.convertStyleAttrToNumber(styleValue);
        return styleValue;
    }

    /**
     * Gets a style value from an element or its first ancestor that has that style value specified.
     *
     * @param styleKey
     * The id of the style attribute for which the value is needed.  Example: backgroundColor
     * @param element
     * The element from which the search for the style value will begin.
     * @param defaultValue
     * The value to return if neither the element or any of its ancestors specifies a value for the provided style key.  This is an optional parameter.  When not specified, null can be returned from this method.
     */
    public static getStyleValueFromElementOrAncestor(styleKey: string, element: HTMLElement, defaultValue?: any): any {
        let testElement = element;
        while (testElement != null) {
            const parentStyleValue = testElement.style[styleKey];
            if (parentStyleValue != null && parentStyleValue != "")
                return parentStyleValue;
            testElement = testElement.parentElement;
        }
        return defaultValue;
    }

    public static getElementHeightString(element: Element): string {
        return document.defaultView.getComputedStyle(element).height;
    }

    public static getElementHeight(element: Element, includePadding: boolean = true,
        includeMargin: boolean = false): number {
        if (element == null)
            return 0;
        const computedStyle = document.defaultView.getComputedStyle(element);
        return this.getElementHeightFromStyle(computedStyle, includePadding, includeMargin);
    }

    private static getElementHeightFromStyle(style: CSSStyleDeclaration,
        includePadding: boolean = true, includeMargin: boolean = false): number {
        let result = DOMUtil.convertStyleAttrToNumber(style.height);
        if (includePadding === false) {
            result -= DOMUtil.convertStyleAttrToNumber(style.paddingTop);
            result -= DOMUtil.convertStyleAttrToNumber(style.paddingBottom);
        }
        if (includeMargin === true) {
            result += DOMUtil.convertStyleAttrToNumber(style.marginTop);
            result += DOMUtil.convertStyleAttrToNumber(style.marginBottom);
        }
        return result;
    }

    public static getElementWidthString(element: Element): string {
        return document.defaultView.getComputedStyle(element).width;
    }

    public static getElementWidth(element: Element, includePadding: boolean = true,
        includeMargin: boolean = false): number {
        if (element == null)
            return 0;
        const computedStyle = document.defaultView.getComputedStyle(element);
        return this.getElementWidthFromStyle(computedStyle, includePadding, includeMargin);
    }

    private static getElementWidthFromStyle(style: CSSStyleDeclaration,
        includePadding: boolean = true, includeMargin: boolean = false): number {
        let result = DOMUtil.convertStyleAttrToNumber(style.width);
        if (includePadding === false) {
            result -= DOMUtil.convertStyleAttrToNumber(style.paddingLeft);
            result -= DOMUtil.convertStyleAttrToNumber(style.paddingRight);
        }
        if (includeMargin === true) {
            result += DOMUtil.convertStyleAttrToNumber(style.marginLeft);
            result += DOMUtil.convertStyleAttrToNumber(style.marginRight);
        }
        return result;
    }

    /**
     * Calculates the width of an element as a percentage of a given basis.
     *
     * @param {HTMLElement} element - The element to calculate the width for.
     * @param {number} percentageBasis - The basis for percentage calculation, default is window.innerWidth.
     * @param {boolean} round - Whether to round the result to two decimal places, default is true.
     * @returns {number} The percentage relative to the percentageBasis.
     */
    public static getElementWidthPercentage(element: HTMLElement, percentageBasis: number = window.innerWidth, round: boolean = true): number {
        const elementWidth = this.getElementWidth(element);
        let percentage = (elementWidth / percentageBasis) * 100;

        if (round) {
            percentage = Math.round(percentage * 100) / 100;
        }

        return percentage;
    }


    public static getElementHeightWidth(element: Element, includePadding: boolean = true, includeMargin: boolean = false): HeightWidth {
        const computedStyle = document.defaultView.getComputedStyle(element);
        const height = this.getElementHeightFromStyle(computedStyle, includePadding, includeMargin);
        const width = this.getElementWidthFromStyle(computedStyle, includePadding, includeMargin);
        return { height: height, width: width };
    }

    public static getComputedStyle(stylekey: string, element: Element): string {
        return document.defaultView.getComputedStyle(element)[stylekey];
    }

    /**
     * Determines how visible the component is within some other component.  Only the vertical height is examined.
     *
     * @param element
     * The element for which the visibility percent is needed.
     * @param outerElement
     * The element that defines the bounds from which the percentage should be calculated.
     */
    public static getPercentVerticallyVisible(element: HTMLElement, outerElement: HTMLElement): number {
        const elementRect = element.getBoundingClientRect();
        const elementRectTop = elementRect.top;
        const elementRectHeight = elementRect.height;
        const elementRectBottom = elementRectTop + elementRectHeight;
        const contentRect = outerElement.getBoundingClientRect();
        const contentRectTop = contentRect.top;
        const contentRectHeight = contentRect.height;
        const contentRectBottom = contentRectTop + contentRectHeight;

        if (elementRectHeight === 0 || contentRectHeight === 0) //elements probably haven't rendered yet
            return 0;
        if (elementRectBottom < contentRectTop) //element is above the visible area
            return 0;
        if (elementRectTop > contentRectBottom) //element is below the visible area
            return 0;
        if (elementRectTop >= contentRectTop && elementRectBottom <= contentRectBottom) //element is completely visible
            return 1;
        if (elementRectTop >= contentRectTop) { //element is partially visible, runs off the bottom of the visible area
            const visibleHeight = contentRectBottom - elementRectTop;
            return visibleHeight / elementRectHeight;
        }
        if (elementRectBottom <= contentRectBottom) { //element is partially visible, runs off the top of the visible area
            const visibleHeight = elementRectBottom - contentRectTop;
            return visibleHeight / elementRectHeight;
        }

        //element is partially visible, runs off the top of the visible area AND the bottom of the visible area
        return contentRectHeight / elementRectHeight;
    }

    public static getScrollbarWidth() {
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll'; // forcing scrollbar to appear
        document.body.appendChild(outer);
        const inner = document.createElement('div');
        outer.appendChild(inner);
        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
        outer.parentNode.removeChild(outer);
        return scrollbarWidth;
    }

    public static convertSizeStyleToPixels(styleSize: string | number, percentageBasis: number, valueIfUnset?: number): number {
        if (typeof styleSize === "number")
            return styleSize;
        if (styleSize == null || styleSize.length === 0)
            return valueIfUnset;
        if (styleSize.endsWith("%")) {
            const percent = parseInt(styleSize.substring(0, styleSize.length - 1));
            return percent / 100 * percentageBasis;
        }
        if (styleSize.endsWith("px"))
            styleSize = styleSize.substring(0, styleSize.length - 2);
        return parseInt(styleSize);
    }

    public static setStyles(element: HTMLElement, style: Partial<CSSStyleDeclaration>) {
        Object.assign(element, style);
    }

    public static getDraggedFiles(event: DragEvent): File[] {
        const result: File[] = [];
        if (event.dataTransfer.items != null) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                const item = event.dataTransfer.items[i];
                if (item.kind === 'file')
                    result.push(item.getAsFile());
            }
        } else
            for (let i = 0; i < event.dataTransfer.files.length; i++)
                result.push(event.dataTransfer.files[i]);
        return result;
    }

    public static isActiveElement(element: HTMLElement) {
        return document.activeElement === element;
    }
}
