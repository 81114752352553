import { Layout } from "@mcleod/components";
import { Api, ModelSearchResult } from "@mcleod/core";
import { DriverChoice } from "./DriverChoice";
import { AutogenLayoutDriverPortal } from "./autogen/AutogenLayoutDriverPortal";

export class DriverPortal extends AutogenLayoutDriverPortal {
    override onLoad() {
        Api.search("portal/driver/auth-settings").then((response) => {
            if (response.enable_driver_choice === true) {
                this.tabset1.visible = true;
                this.layoutManualSearch.settingsData = response;
                const driverChoiceLayout = this.tabMyMatchingLoads.findComponentById("layout1") as DriverChoice;
                driverChoiceLayout.beforeQuery();
                driverChoiceLayout.mainDataSource.search().then((result: ModelSearchResult) => {
                    driverChoiceLayout.afterQuery(result?.modelRows);
                });
            }
            else
                Layout.clearCachedLayout(this.layout1.layoutName); // this stops cached layout from trying to load and throwing "this.dataSourceIDs is not iterable"
        });
    }
}
