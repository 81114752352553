import { DesignableObjectProps } from "./DesignableObjectProps";

export class DesignableObjectTempState {
    private _originalProps: Partial<DesignableObjectProps>; //the property values before the state change
    private _appliedProps: Partial<DesignableObjectProps>; //the property values being applied as part of the state change
    private _objects: Map<string, any>; //ad-hoc objects that need to be tracked as part of the state change

    constructor(originalProps: Partial<DesignableObjectProps>, appliedProps?: Partial<DesignableObjectProps>) {
        this._originalProps = originalProps;
        this._appliedProps = appliedProps;
    }

    public get originalProps(): Partial<DesignableObjectProps> {
        return this._originalProps;
    }

    public get appliedProps(): Partial<DesignableObjectProps> {
        return this._appliedProps;
    }

    public get objects(): Map<string, any> {
        return this._objects;
    }

    public getObject(key: string): any {
        return this.objects?.get(key);
    }

    public addObject(key: string, object: any) {
        this.initializeObjects();
        this._objects.set(key, object);
    }

    public removeObject(key: string): boolean {
        return this._objects != null ? this._objects?.delete(key) : false;
    }

    private initializeObjects() {
        if (this._objects == null)
            this._objects = new Map<string, any>();
    }
}