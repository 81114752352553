import { AbstractSettings, AbstractSettingsDef, SettingsManager } from "@mcleod/core";

export interface PortalFactoringSettingsDef extends AbstractSettingsDef {
    factoring_search_days: number;    
}

export class PortalFactoringSettings extends AbstractSettings {
    constructor() {
        super("factoring_settings");
    }

    public static getSingleton(): PortalFactoringSettings {
        return SettingsManager.getSingleton("factoring_settings") as PortalFactoringSettings;
    }

    public static get(): PortalFactoringSettingsDef {
        return PortalFactoringSettings.getSingleton().values as PortalFactoringSettingsDef;
    }

    public override get values(): PortalFactoringSettingsDef {
        return super.values as PortalFactoringSettingsDef;
    }

    protected getValuesInternal(): PortalFactoringSettingsDef {
        return this._values as PortalFactoringSettingsDef;
    }
}
