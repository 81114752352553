import { AdditionalDetailsFTL } from "../AdditionalDetailsFTL";
import { OrderImages } from "../OrderImages";
import { OrderMapView } from "../OrderMapView";
import { TableStops } from "../TableStops";
import { AdditionalDetailsLTL } from "../ltl/AdditionalDetailsLTL";
import { DataSource, Label, Layout, Panel, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutOrderDetails extends Layout {
    freightItems: Label;
    labelConsigneeETA: Label;
    labelEtaDateTime: Label;
    labelMapConsigneeETA: Label;
    labelMapEtaDateTime: Label;
    labelPieces: Label;
    labelPiecesValue: Label;
    labelRateDetails: Label;
    labelSpots: Label;
    labelSpotsValue: Label;
    labelTotalCharges: Label;
    labelTotalChargesValue: Label;
    labelWeight: Label;
    labelWeightValue: Label;
    layoutFTLAddlDetails: AdditionalDetailsFTL;
    layoutImageTab: OrderImages;
    layoutLTLAddlDetails: AdditionalDetailsLTL;
    layoutOrderMapView: OrderMapView;
    layoutStops: TableStops;
    panelAddlDetails: Panel;
    panelEta: Panel;
    panelFreightItems: Panel;
    panelLtlDetails: Panel;
    panelLtlDetailsMain: Panel;
    panelMain2: Panel;
    panelMainStops: Panel;
    panelMapEta: Panel;
    panelMapView: Panel;
    panelRateDetails: Panel;
    panelRates: Panel;
    panelStops: Panel;
    paneltabImagesAddlDetails: Panel;
    paneltabLTLDetailsAddlDetails: Panel;
    paneltabMapViewAddlDetails: Panel;
    paneltabStopsAddlDetails: Panel;
    ratings: Label;
    sourceCustomerOrderDetail: DataSource;
    sourceFreightGroupItem: DataSource;
    sourceLtlOrderDetails: DataSource;
    sourceLtlRatings: DataSource;
    tabImages: Tab;
    tabLTLDetails: Tab;
    tabMapView: Tab;
    tabStops: Tab;
    tableFreightGroupItems: Table;
    tableLTLDetails: Table;
    tabsetOrderDetails: Tabset;
    textboxExciseTaxTotal: Textbox;
    textboxFreightCharge: Textbox;
    textboxOtherChargeTotal: Textbox;
    textboxTotalChargeAndExciseTax: Textbox;
}
