import { ModelRow } from "@mcleod/core";
import { DesignableObject } from "../base/DesignableObject";
import { Event, EventListener } from "./Event";
import { Textbox } from "../components/textbox/Textbox";

export interface LookupModelSelectionListener extends EventListener {
    (event: LookupModelSelectionEvent): void;
}

export class LookupModelSelectionEvent extends Event {
    selectedRow: ModelRow = null;
    
    constructor(component: DesignableObject, selectedRow: ModelRow) {
        super(component);
        this.selectedRow = selectedRow;
    }

    public override get target(): Textbox {
        return super.target as Textbox;
    }
}
