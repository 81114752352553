import { Collection, GeneralSettings, StringUtil, UrlUtil } from ".";

export class ServiceAddresses {
    private static _ON_PREM_URL: string;
    private static apiContext: string = "mcleod-api-lme";
    private static localHostServerPort = 8080;
    private static defaultAddresses: Collection<string> = {};


    public static get ON_PREM_URL(): string {
        if (this._ON_PREM_URL == null) {
            let result = window.location.origin;
            const localHostPos = result.indexOf("localhost");
            if (localHostPos >= 0) {
                const slashPos = result.lastIndexOf("/", localHostPos);
                result = result.substring(slashPos + 1, localHostPos + 9);
                result = "http://" + result + ":" + ServiceAddresses.localHostServerPort + "/";
            }
            else if (result.indexOf("onemcleod.com") >= 0 || result.indexOf("mcleodraptor.com") >= 0 ) { // temporary hard coding
                result = "https://api.auth.develop.onemcleod.com/"
            } else {
                result += "/" + this.apiContext + "/";
            }
            this._ON_PREM_URL = result;
        }
        return this._ON_PREM_URL;
    }

    public static getEndpointUrl(endpoint: string) {
        if (endpoint.indexOf("://") >= 0)
            return endpoint;
        const serviceName = StringUtil.stringBefore(endpoint, ":", false, null);
        endpoint = StringUtil.stringAfter(endpoint, ":", false, endpoint);
        return UrlUtil.joinUrl(ServiceAddresses.get(serviceName), endpoint);
    }    

    public static setLocalHostServerPort(value: number) {
        ServiceAddresses.localHostServerPort = value;
    }

    /**
       * This method sets the first path of the context path of the URL that is used when making api calls.
        * @param value
    */
    public static setContextPath(value: string) {
        this.apiContext = value;
    }

    public static get(serviceName: string) {
        let result = serviceName == null ? null : GeneralSettings.getSingleton().getServiceAddress(serviceName);
        if (result == null) {
            result = this.defaultAddresses[serviceName] || ServiceAddresses.ON_PREM_URL;
        }
        return result;
    }

    public static setDefaultAddress(serviceName: string, address: string) {
        this.defaultAddresses[serviceName] = address;
    }
}