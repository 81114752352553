import { Button, Chart, Checkbox, DataSource, HorizontalSpacer, Label, Layout, Panel, SearchButton, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutLogReader extends Layout {
    buttonCollapseCharts: Button;
    buttonReadLog: SearchButton;
    chartDBExecByTime: Chart;
    checkShowFullLog: Checkbox;
    horizontalspacer1: HorizontalSpacer;
    labelOrDropALogOrZipFileHere: Label;
    labelResultsSummary: Label;
    labelSummaryHeader: Label;
    layoutChartStat: Layout;
    panelCharts: Panel;
    panelDragLog: Panel;
    panelHeader: Panel;
    panelResults: Panel;
    panelSummary: Panel;
    panelSummaryContent: Panel;
    sourceLogReader: DataSource;
    textDatabaseCount: Textbox;
    textDatabaseTime: Textbox;
    textEndDate: Textbox;
    textFilterText: Textbox;
    textLogCount: Textbox;
    textLogFile: Textbox;
    textLogSpan: Textbox;
    textStartDate: Textbox;
    textThreadCount: Textbox;
    textTotalTime: Textbox;
    textUser: Textbox;
}
