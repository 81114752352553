import {
    CityState, ClickEvent, DataSourceExecutionEvent, DataSourceMode,
    Layout, LookupModelSelectionEvent
} from "@mcleod/components";
import { ModelRow, StringUtil } from "@mcleod/core";
import { LOOKUP_MODEL_PREFIX } from "@mcleod/core/src/ModelRow";
import { DriverChoiceTable } from "./DriverChoiceTable";
import { AutogenLayoutDriverChoiceManualSearch } from "./autogen/AutogenLayoutDriverChoiceManualSearch";

export class DriverChoiceManualSearch extends AutogenLayoutDriverChoiceManualSearch {

    layoutTable: DriverChoiceTable;
    private _settingsData: any;

    public get settingsData(): any {
        return this._settingsData;
    }

    public set settingsData(value: any) {
        this._settingsData = value;

        if (this.settingsData != null) {
            this.mainDataSource.searchRow.setValues({ ...this.settingsData, days_out: "3", ship_radius: 250 });

            const lmData = this.mainDataSource.searchRow.get(LOOKUP_MODEL_PREFIX + "ship_city");
            if (lmData?.length > 0) {
                const shipCityLmData: ModelRow = new ModelRow("common/city-suggestion", false, { ...lmData[0] });
                this.citystateShipCity.textCombined["_lookupModelData"] = [];
                this.citystateShipCity.textCombined.lookupModelData.push(shipCityLmData);
            }
            this.citystateShipCity.updateBoundData(this.mainDataSource.searchRow, DataSourceMode.SEARCH);
            this.panelSearch.displayData(this.mainDataSource.searchRow, null, 0);
        }
    }


    override onLoad() {
        this.layoutTable = Layout.getLayout("portal-driver/DriverChoiceTable", { id: "layoutTable" }) as DriverChoiceTable;
        this.layoutTable.addLayoutLoadListener(() => {
            this.layoutDriverChoice.remove(this.layoutDriverChoice.buttonSearchPreferences);
            this.replaceMainDatasource(this.layoutDriverChoice.mainDataSource);
            this.panelSearch.forEveryChildComponent(comp => {
                if (comp.dataSource != null)
                    comp.dataSource = this.layoutDriverChoice.mainDataSource;
                if (comp instanceof CityState) {
                    comp.textCombined.addLookupModelSelectionListener((event: LookupModelSelectionEvent) => {
                        this.citystateDestCity.textCombined.tooltipCallback = null;
                    });
                }
            });
        });

        this.mainDataSource.addBeforeExecutionListener((event: DataSourceExecutionEvent) => {
            this.layoutDriverChoice.beforeSearch = true;
            this.layoutDriverChoice.driverPositionAdded = false;
            this.layoutDriverChoice.beforeQuery();
        });

        this.mainDataSource.addAfterExecutionListener((event: DataSourceExecutionEvent) => {
            this.layoutDriverChoice.afterQuery(this.mainDataSource.data);
        });
    }

    onClickManualSearch(event: ClickEvent) {
        if (!this.textboxShipRadius.validateSimple()) {
            this.textboxShipRadius.validationWarning = "Shipping radius required.";
            this.textboxShipRadius.showTooltip("Shipping radius required.");
        }
        else if (StringUtil.isEmptyString(this.citystateShipCity.textCombined.text)) {
            this.citystateShipCity.textCombined.validationWarning = "Shipping location required.";
            this.citystateShipCity.textCombined.showTooltip("Shipping location required.");
        }
        else
            this.mainDataSource.search(this.mainDataSource.getDataboundValues(DataSourceMode.SEARCH, null, true));
    }
}
