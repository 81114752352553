import { ClickEvent } from "@mcleod/components";
import { Navigation } from "@mcleod/core";
import { AutogenLayoutOrderConfirmationHeader } from "./autogen/AutogenLayoutOrderConfirmationHeader";
import { PortalCustomerSettings } from "../../customer/src/settings/PortalCustomerSettings";

export class OrderConfirmationHeader extends AutogenLayoutOrderConfirmationHeader {

    override onLoad() {
        this.labelOrderSubmitMsg.caption = PortalCustomerSettings.get().order_submit_ok ?? "";
    }

    buttonListViewOnClick(event: ClickEvent) {
        Navigation.navigateTo("/");
    }


    displayHoldPanel(holdWarning: any) {
        const warningText = holdWarning?.text;
        if (warningText) {
            this.panelHold.visible = true;
            this.labelHold.caption = warningText;
            this.labelHoldEmail.visible = holdWarning?.email;
            this.labelHoldEmail.caption = holdWarning?.email;
            if (holdWarning?.email && holdWarning?.subject)
                this.labelHoldEmail.link = `mailto:${holdWarning?.email}?subject=${holdWarning?.subject}`;
        }
    }
}
