import { Textbox } from "..";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface LookupModelSearchListener extends EventListener {
    (event: LookupModelSearchEvent): void;
}

export class LookupModelSearchEvent extends Event {
    public _filter: any;
    private _preventReason: string;

    constructor(target: Textbox, filterText?: string, domEvent?: DomEvent) {
        super(target, domEvent);
        this._filter = { text_search: target.text };

        if (filterText != null) {
            if (target.hasLookupModel()) {
                this._filter = { lm_search: filterText };
                if (target.multipleLookupModelValuesSelected()) {
                    const values = [];
                    const resultField = target.lookupModelResultField;
                    for (const selection of target.lookupModelData) {
                        values.push({ id: selection.get(resultField) });
                    }
                    this._filter.lm_search_selected = {
                        fieldName: resultField,
                        values: values
                    }
                }
            } else {
                this._filter = { text_search: filterText };
            }
        }
    }

    public override get target(): Textbox {
        return super.target as Textbox;
    }

    public get filter() {
        return this._filter;
    }

    public set filter(filter: any) {
        this._filter = filter;
    }

    public get preventReason(): string {
        return this._preventReason;
    }

    public set preventReason(value: string) {
        this._preventReason = value;
        if (value) {
            this.preventDefault();
        }
    }
}
