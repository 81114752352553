export class UIDesignerTailorProvider {
    public static get(): UIDesignerTailorProvider {
        if (_singleton == null)
            _singleton = new UIDesignerTailorProvider();
        return _singleton;
    }

    public static set(provider: UIDesignerTailorProvider) {
        _singleton = provider;
    }

    public getTailorOverrideTitle(): string {
        return null;
    }

    public tailorShowsMenuBasedOpenLayout(): boolean {
        return true;
    }
}

let _singleton: UIDesignerTailorProvider;