import { UIDesignerTailorProvider } from "@mcleod/common";
import {
    Button, ButtonVariant, ChangeEvent, Component, Container, DesignableObject, Dialog, McLeodTailorPropertyEvaluator,
    PropType, ResourceFileProps, Snackbar
} from "@mcleod/components";
import { ReflectiveDialogs } from "@mcleod/components/src/base/ReflectiveDialogs";
import { GeneralSettings, StringUtil, getLogger } from "@mcleod/core";
import { LocalStorageManager } from "../../common/LocalStorageManager";
import { ModelTailor } from "../../model/ModelTailor";
import { AbstractUIDesigner } from "../AbstractUIDesigner";
import { LayoutDesignerTab } from "../LayoutDesignerTab";
import { McLeodTailorAgreementManager } from "./McLeodTailorAgreementManager";
import { PanelApplyBaseProps } from "./PanelApplyBaseProps";
import { PanelOpenLayoutMenu } from "./PanelOpenLayoutMenu";
import { PanelOpenPortalLayoutMenu } from "./PanelOpenPortalLayoutMenu";

const log = getLogger("common.designer.McLeodTailor");

export class McLeodTailor extends AbstractUIDesigner {
    private buttonBaseProps: Button;
    private panelApplyBaseProps: PanelApplyBaseProps;
    private savedSplitterPosition: string | number;
    constructor(props?) {
        super({
            ...props,
            title: "McLeod Tailor",
            localStorageManager: new LocalStorageManager( "custom.designer.ui.open", "custom.designer.ui.selected"),
            endpointPath: "custom-layout"
        });
        this.addBasePropsButton();
        this.syncRevertPropsButton();
    }

    override async onLoad() {
        const appSpecificTitle = UIDesignerTailorProvider.get().getTailorOverrideTitle();
        if (StringUtil.isEmptyString(appSpecificTitle) === false)
            this.title = appSpecificTitle;
        await new McLeodTailorAgreementManager().handleAgreement();
        super.onLoad();
    }

    addBasePropsButton() {
        this.buttonBaseProps = new Button({
            caption: "i", visible: true, padding: 0, marginRight: 5, marginTop: 5,
            color: "primary.reverse", width: 17, height: 17,
            variant: ButtonVariant.round, borderWidth: 2, borderColor: "primary.reverse", borderRadius: 50,
            style: { fontFamily: "Georgia, serif" },
            onClick: () => this.showTableApplyProps()
        });
        this.panelPropsHeader.add(this.buttonBaseProps);
    }

    get allowedDesignerTools(): string[] {
        return [
            "textbox",
            "label",
            "checkbox",
            "switch",
            "button",
            "numbereditor",
            "panel",
            "horizontalspacer",
            "citystate",
            "location",
            "layout",
            "iframe",
            "tailorextensionbutton"
        ];
    }

    get tabsetTools(): Button[] {
        return [
            this.buttonRun,
            this.buttonOpen,
            this.buttonSave,
            this.buttonSaveNewVersion,
            this.buttonManageVersions,
            this.buttonUndo,
            this.buttonRedo
        ]
    }

    override showOpen() {
        this.updateToolbarButtonVisibility(this.getActiveTab()); //this call will remove the buttons if there isn't an active tab
        if (UIDesignerTailorProvider.get().tailorShowsMenuBasedOpenLayout() === true)
            this.showOpenLayoutMenu();
        else
            this.showOpenPortalLayout();
    }

    private showOpenLayoutMenu() {
        const pnl = new PanelOpenLayoutMenu();
        ReflectiveDialogs.showDialog(pnl, { title: "Open Layout", height: 600, width: 500 }).then((dialog: Dialog) => {
            const path = pnl.tree?.selectedNode?.layoutPath;
            if (!dialog.wasCancelled && path != null) {
                const path = pnl.tree?.selectedNode?.layoutPath;
                this.openTab({path, baseVersion:false}, true);
            }
        });
    }

    private showOpenPortalLayout() {
        const pnl = new PanelOpenPortalLayoutMenu();
        ReflectiveDialogs.showDialog(pnl, { title: "Open Layout", height: 600, width: 500 }).then((dialog: Dialog) => {
            if (dialog.wasCancelled === true || pnl.tree?.selectedNode?.path == null)
                return;
            let path = "";
            const selectedNode = pnl.tree.selectedNode;
            if (selectedNode.hasChildren() === true) //user tried to select a directory entry in the tree
                return; //returning here is better than an error, but is still weak sauce.  ideally the OK button in the dialog would not be available in this situation.
            selectedNode.path.forEach((node) => {
                path += (node.data.unmodified_name == null ? node.caption : node.data.unmodified_name) + "/";
            });

            //When running locally, we will have a root node that doesn't exist when running deployed.  (That root node
            //represents the service project folder of mcleod-api-portal.)  We don't need that included in the path, so
            //get rid of it.
            if (GeneralSettings.getSingleton().isRunningInIDE())
                path = StringUtil.stringAfter(path, "/");

            path = path.substring(0, path.length - 1); //remove trailing slash
            const baseVersion: boolean = selectedNode.data.base_version;
            this.openTab({path, baseVersion}, true);
        });
    }

    override async loadInitialTabs() {
        await super.loadInitialTabs();
        if (this.tabset.isEmpty())
            this.showOpen();
    }

    override async openTab( props: ResourceFileProps, selectTab: boolean = true ):  Promise<LayoutDesignerTab | undefined> {
        if (props.path == null) {
            this.showOpen();
            return undefined;
        }

        return super.openTab(props, selectTab).then((tab) => {
            tab?.dataSourcePanel?.setButtonModelViewVisibile(
                GeneralSettings.isExperimentActiveOrUndefined("custom_models")
            );
            return tab;
        });
    }

    override showSave(createBaseTS: boolean = false, baseTsOnly: boolean = false) {
        const activeTab = this.getActiveTab();
        if (activeTab != null)
            return super.saveCustomLayout(activeTab);
    }

    override async saveActiveTab(createBaseTS: boolean = false, baseTSOnly: boolean = false, comment?: string) {
        const tab = this.getActiveTab();
        if (tab != null) {
            if (tab.baseVersion === true)
                tab.firstSaveOfCustomResource = true;
            tab.baseVersion = false;
            super.saveActiveTab(false, false, comment);
        }
    }

    override doAfterActiveTabSave(tab: LayoutDesignerTab, baseTSOnly: boolean, response: any) {
        try {
            if (tab.firstSaveOfCustomResource === true) {
                //remove the baseVersion from the last open tabs
                const tabDescriptor = tab.descriptor;
                tabDescriptor.baseVersion = true;
                this.localStorageManager.removeFromLastOpen(tabDescriptor);
            }
            super.doAfterActiveTabSave(tab, baseTSOnly, response);
        }
        finally {
            tab.firstSaveOfCustomResource = undefined;
        }
    }

    override doOnActiveTabSaveError(tab: LayoutDesignerTab, error: any) {
        try {
            //if initial save of a custom layout failed, tab needs to indicate it's still the base version
            if (tab.firstSaveOfCustomResource === true)
                tab.baseVersion = true;
        }
        finally {
            tab.firstSaveOfCustomResource = undefined;
        }
        super.doOnActiveTabSaveError(tab, error);
    }

    override addNewTab(): LayoutDesignerTab {
        //calling openTab() below would actually ending up calling showOpen().
        //But we don't want to do this when the last tab is closed (which may happen from the manage custom versions slideout).
        //So do nothing here.
        return null;
    }

    override filterProps(props: any, selectedComponent: Component) {
        for (const key of Object.keys(props)) {
            log.debug("Evaluating property for McLeod Tailor visibility: %o for component %o", props[key], selectedComponent);
            if (McLeodTailorPropertyEvaluator.isVisible(props[key], selectedComponent) !== true) {
                log.debug("Removing property from McLeod Tailor: %o", props[key]);
                delete props[key];
            }
        }
    }

    override disablePropertyEditors(prop: any, editorComponents: Component[], selectedComponent: Component): void {
        log.debug("Evaluating property for McLeod Tailor editability: %o for component %o", prop, selectedComponent);
        if (McLeodTailorPropertyEvaluator.isEditable(prop, selectedComponent) !== true) {
            log.debug("Disabling edit of property within McLeod Tailor: %o", prop);
            for (const component of editorComponents) {
                if (component instanceof Button)
                    component.parent?.remove(component);
                else
                    component.enabled = false;
            }
        }
    }

    override deleteComponents(components: DesignableObject[]) {
        if (this.selectedComponents == null)
            return;
        const compsToDelete = [];
        for (const component of this.selectedComponents) {
            if (this.canDelete(component)) {
                this.modified();
                compsToDelete.push(component);
            } else {
                Snackbar.showWarningSnackbar("Only custom components can be deleted.");
                return;
            }
        }
        super.deleteComponents(compsToDelete);
    }

    canDelete(comp: DesignableObject) {
        if (comp instanceof Component && (comp.isCustom || !comp.deserialized)) {
            if (comp instanceof Container) {
                for (const child of comp.components) {
                    if (!this.canDelete(child))
                        return false;
                }
            }
            return true;
        }
        return false;
    }

    notifyComponentAdded(component: any, container: Container) {
        super.notifyComponentAdded(component, container);
        component.isCustom = true;
    }

    override selectComponent(component: DesignableObject, add: boolean = false) {
        super.selectComponent(component, add);
        this.syncRevertPropsButton();
        this.panelApplyBaseProps?.close()
    }

    syncRevertPropsButton() {
        if (this.buttonBaseProps != null) {
            const modified = this.selectedLayoutComponents?.length == 1 && this.firstSelectedLayoutComponent?.baseVersionProps != null;
            this.buttonBaseProps.visible = modified;
        }
    }

    showTableApplyProps() {
        if (!this.panelProps.contains(this.panelApplyBaseProps)) {
            this.savedSplitterPosition = this.splitterProps.position;
            this.panelApplyBaseProps = new PanelApplyBaseProps(this);
            this.panelApplyBaseProps.onClose = () => this.hidePanelApplyBaseProps();
            this.panelProps.replace(this.tableProps, this.panelApplyBaseProps);
            this.splitterProps.position = "70%";
        }
        else
            this.hidePanelApplyBaseProps();
    }

    private hidePanelApplyBaseProps() {
        this.panelProps.replace(this.panelApplyBaseProps, this.tableProps);
        this.panelApplyBaseProps = null;
        this.splitterProps.position = this.savedSplitterPosition;
    }

    override doBeforePropChanged(component: Component, propName: string, propsSeen: string[] = []) {
        log.debug("Invoked doBeforePropChanged for property: %o", propName);
        if (propsSeen.includes(propName)) {
            log.debug("Property %o already seen in doBeforePropChanged", propName);
            return;
        }
        propsSeen.push(propName);
        if (component.isCustom || "id" == propName) return;
        const affectsProps: string[] = component.getPropertyDefinitions()[propName]?.affectsProps;
        const baseProps = component.baseVersionProps ?? {};
        const value = this.cleanPropValue(component, propName, component[propName]);
        if (!(propName in baseProps))
            baseProps[propName] = value;
        component.baseVersionProps = baseProps;
        this.syncBasePropsComponents(component, propName);
        log.debug("Property %o affects other properties: %o", propName, affectsProps);
        affectsProps?.forEach(affect => this.doBeforePropChanged(component, affect, propsSeen));
    }

    syncPropChanged(component: Component, propName: string, oldValue: any, newValue: any, redisplayProp?: boolean) {
        this.setBaseVersionProp(component, propName, oldValue, newValue);
        this.syncBasePropsComponents(component, propName);
    }

    setBaseVersionProp(component: Component, propName: string, oldValue: any, newValue: any) {
        if (component.isCustom || "id" == propName) return;
        const baseProps = component.baseVersionProps ?? {};
        oldValue = this.cleanPropValue(component, propName, oldValue);
        newValue = this.cleanPropValue(component, propName, newValue);
        if (!(propName in baseProps))
            baseProps[propName] = oldValue;
        else if (baseProps[propName] == newValue)
            delete baseProps[propName];
        component.baseVersionProps = baseProps;
    }

    syncBasePropsComponents(component: Component, propName: string) {
        if (component === this.firstSelected) {
            this.tableProps.syncRowCaptionStyle(propName);
            this.syncRevertPropsButton();
        }
    }

    checkForPropChange(event: ChangeEvent, data: any) {
        const propName = data.prop_name;
        const newValue = this.cleanPropValue(this.firstSelected, propName, event.newValue);
        if ((newValue == data.value || newValue == this.firstSelected?.baseVersionProps?.[propName])) {
            for (const comp of this.selectedComponents)
                this.doAfterPropChanged(comp, propName, event.oldValue, event.newValue, true);
        }
    }

    cleanPropValue(comp: DesignableObject, propName: string, value: any): any {
        const prop = comp.getPropertyDefinitions()[propName];
        if (prop.type === PropType.bool)
            value ??= false;
        else if (value == null || StringUtil.isEmptyString(value))
            return null;
        return value;
    }

    canModifyProp(propName: string, component: Component): boolean {
        const prop = component.getPropertyDefinitions()[propName];
        return McLeodTailorPropertyEvaluator.isVisible(prop, component)
            && McLeodTailorPropertyEvaluator.isEditable(prop, component);
    }

    override openModelDesigner(url: string) {
        ModelTailor.openSlideout(url, () => {
            this.getActiveTab().dataSourcePanel.syncMetadata(url).then(() => {
                this.toolsPanel.displayDataSourceTools();
            });
        });
    }
}
