import { DataSource, Label, Layout, Tab, Table, Tabset } from "@mcleod/components";

export abstract class AutogenLayoutFactoringOrdersDetails extends Layout {
    factoringComments: Table;
    factoringImagesDetailTable: Table;
    labelNoComments: Label;
    sourceFactoringComments: DataSource;
    sourceFactoringImages: DataSource;
    tabComments: Tab;
    tabImages: Tab;
    tabset_: Tabset;
}
