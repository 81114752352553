import { getLogger } from "@mcleod/core";
import { Component } from "../base/Component";
import { ListenerListDef } from "../base/ListenerListDef";
import { ReflectiveDialogs } from "../base/ReflectiveDialogs";
import { ValidationResult } from "../base/ValidationResult";
import { DataSourceValidationEvent } from "../events/DataSourceValidationEvent";
import { DataSource } from "./DataSource";
import { ValidationUtil } from "./ValidationUtil";

const log = getLogger("components.databinding.BoundComponentValidator");

export class BoundComponentValidator {

    constructor(private dataSource: DataSource, private validationListenerDef: ListenerListDef) {}

    validate(useBoundSearchComponents?: boolean, showDialog?: boolean): ValidationResult[] {
        const results: ValidationResult[] = [];
        for (const component of this.dataSource.getRelevantBoundComponents(useBoundSearchComponents)) {
            this.addValidationResult(component, results);
        }
        this.fireValidationEvent(results, useBoundSearchComponents);

        this.dataSource?.childDataSources?.forEach(child => {
            results.push(...child.componentValidator.validate(useBoundSearchComponents, false));
         });

        if (showDialog === true)
            ValidationUtil.showFailedValidationDialog(results, useBoundSearchComponents, this.dataSource);

        return results;
    }

    addValidationResult(component:Component, results: ValidationResult[]) {
        component.validate()?.forEach(result => {
            if (result?.isValid === false) {
                log.debug(() => ["DataSource validation failed.  DataSource", this, "Failed bound component", component]);
                results.push(result);
            }
        });
    }

    private fireValidationEvent(results: ValidationResult[], useBoundSearchComponents?: boolean) {
        const row = useBoundSearchComponents === true ? this.dataSource.searchRow : this.dataSource.activeRow;
        const event = new DataSourceValidationEvent(this.dataSource, row, this.dataSource.childDataSources, results);
        this.dataSource.fireListeners(this.validationListenerDef, event);

    }
}
