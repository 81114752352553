import { DataSource } from "@mcleod/components";
import { HorizontalAlignment, ModelRow, UserSettings } from "@mcleod/core";
import { AutogenLayoutFtlQuoteAndOrderOverview } from "./autogen/AutogenLayoutFtlQuoteAndOrderOverview";

export class FtlQuoteAndOrderOverview extends AutogenLayoutFtlQuoteAndOrderOverview {
    private _quoteId: string;
    private _orderId: string;

    override onLoad() {
        if (UserSettings.isUserTypeLocation()) {
            this.panelMain.padding = 0;
            this.panelMain.components.forEach(comp => {
                if (comp.id == "panelOrderNumber")
                    comp.setProps({ padding: 0, align: HorizontalAlignment.CENTER, visible: true, borderWidth: 0 })
                else
                    comp.visible = false;
            });
        }
    }

    setup(orderSource: DataSource, selectedRate: ModelRow<any>, isOrder: boolean = false) {
        this.labelFreightCharge.displayData(orderSource.activeRow, null, 0);
        this.labelOtherchargetotal.displayData(orderSource.activeRow, null, 0);
        this.labelTotalCharge.displayData(orderSource.activeRow, null, 0);
        this.labelWeight.displayData(orderSource.activeRow, null, 0);
        this.labelBillDistance.displayData(orderSource.activeRow, null, 0);
        if (isOrder)
            this.orderId = orderSource.activeRow.get("id");
        else
            this.quoteId = orderSource.activeRow.get("id");
    }

    public get quoteId(): string {
        return this._quoteId;
    }

    public set quoteId(value: string) {
        this._quoteId = value;
        this.syncQuoteOrderPanels();
    }

    public get orderId(): string {
        return this._orderId;
    }
    public set orderId(value: string) {
        this._orderId = value;
        this.syncQuoteOrderPanels();
    }

    syncQuoteOrderPanels() {
        this.labelQuoteId.caption = this.quoteId;
        this.layoutBOL.orderId = this.orderId;
        this.panelQuoteNumber.visible = this.orderId == null;
        this.panelOrderNumber.visible = this.orderId != null;
    }
}
