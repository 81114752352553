import { Client, IFrame, IMessage, StompSubscription } from "@stomp/stompjs";
import { Api } from "../Api";
import { ErrorHandlerFunction } from "../ErrorHandler";
import { getLogger } from "../Logger";
import { UUID } from "../UUID";
import { Messaging } from "./Messaging";

const log = getLogger("core.messaging.MessageSubscriber");

export type MessageListener = {
    (message: object): void;
}

export class MessageSubscriber {
    id: string;
    client: Client;
    subscription: StompSubscription;
    listener: MessageListener;
    private _destination: string;
    private _onConnect: () => void;
    private _errorHandler: ErrorHandlerFunction;

    constructor(destination: string, isTempQueue: boolean, listener: MessageListener) {
        if (isTempQueue === true)
            this.createTemporaryQueue(destination).then(queueName => this.destination = queueName);
        else
            this.destination = destination;
        this.listener = listener;
    }

    set onConnect(value: () => void) {
        this._onConnect = value;
    }

    set errorHandler(value: (error) => void) {
        this._errorHandler = value;
    }

    get destination(): string {
        return this._destination;
    }

    set destination(value: string) {
        this._destination = value;
        this.id = value + "-" + UUID.randomUUID();
        this.client = Messaging.getClient();
        this.client.onConnect = frame => this.handleConnect(frame);
        this.client.onStompError = frame => this.handleStompError(frame);
        this.client.activate();
    }

    private async createTemporaryQueue(address: string): Promise<string> {
        const result = address + "-" + UUID.randomUUID();
        await Api.post("messaging/create-temp-queue", { name: result });
        return result;
    }

    private handleConnect(frame: IFrame) {
        log.debug(() => ["Connected client to destination", this._destination, this.client, frame]);
        this.subscription = this.client.subscribe(this._destination, message => this.handleMessage(message), { id: this.id });
        if (this._onConnect != null)
            this._onConnect();
    }

    private handleMessage(message: IMessage) {
        try {
            log.debug(() => ["Received message on", this.destination, "-", message, "subscription", this.subscription]);
            let json: string = message.body;
            if (json.startsWith("__c=")) {
                const index = json.indexOf("{");
                if (index > 0)
                    json = json.substring(index);
            }
            const payload = JSON.parse(json);
            log.debug(() => ["Message payload", payload]);
            this.listener(payload);
        } catch (error) { // if we allow exceptions to make it back to the caller, the subscriber will stop
            if (this._errorHandler == null)
                log.error(() => ["Error handling message", message, error]);
            else
                this._errorHandler(error);
        }
    }

    private handleStompError(frame: IFrame) {
        // there seem to be a lot of TTL errors that just reconnect and continue to work
        // logging these at info level for now until we can figure out what the "real" errors are
        // if (this._errorHandler == null)
        log.info(() => ["Messaging error", frame, "on destination", this.destination, "subscription", this.subscription]);
        // else
        //     this._errorHandler("STOMP error " + frame);
    }

    public unsubscribe() {
        if (this.subscription != null) {
            log.debug("Unsubscribing %o from destination %o", this.subscription, this._destination);
            this.subscription.unsubscribe();
        }
    }
}
