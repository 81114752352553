export class Stack<T> {
    private items: T[];

    constructor() {
        this.items = [];
    }

    public push(item: T): void {
        this.items.push(item);
    }

    public pop(): T | undefined {
        return this.items.pop();
    }

    public peek(): T | undefined {
        const size = this.size();
        return size > 0 ? this.items[size - 1] : undefined;
    }

    public getOldest(): T | undefined {
        const size = this.size();
        return size > 0 ? this.items[0] : undefined;
    }

    public isEmpty(): boolean {
        return this.size() === 0;
    }

    public size(): number {
        return this.items.length;
    }

    public clear(): void {
        this.items = [];
    }
}
