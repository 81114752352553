import { ModelRow, ObjectUtil } from "@mcleod/core";
import { Button, Component, DataSourceMode, Label, ValidationResult } from "../..";
import { MultiButton } from "../../components/multibutton/MultiButton";
import { MultiButtonProps } from "../../components/multibutton/MultiButtonProps";
import { PanelProps } from "../../components/panel/PanelProps";
import { EditRowDecoratorProps } from "./EditRowDecoratorProps";
import { SlideoutDecorator } from "./SlideoutDecorator";

export class EditRowDecorator extends SlideoutDecorator implements EditRowDecoratorProps {
    private _dataSourceMode: DataSourceMode;
    data: ModelRow;
    multiButton: MultiButton;
    btnDelete: Button;
    saveButtonProps: Partial<MultiButtonProps>;
    onDelete: () => void;
    onSave: (updatedData: ModelRow | any) => void;
    onValidate: (updatedData: ModelRow | any) => ValidationResult[];
    onAddAnother: () => void;

    constructor(props?: Partial<EditRowDecoratorProps>) {
        super(props);
        this.themeKey = "editRowDecorator.outerPanel"; //applies EditRowDecorator theme on top of the SlideoutDecorator theme applied in super()
    }

    public get dataSourceMode(): DataSourceMode {
        return this._dataSourceMode ?? DataSourceMode.UPDATE;
    }

    public set dataSourceMode(value: DataSourceMode) {
        this._dataSourceMode = value;
    }

    override createContentPanel(props: Partial<PanelProps>) {
        if (ObjectUtil.containsKey(props, "padding") !== true) {
            if (props == null)
                props = {};
        }
        super.createContentPanel(props);
    }

    override addHeaderActions(addlComponents: Component[]): void {
        this.addComponentsToHeader(addlComponents);
        this.addDeleteButton();
        this.addSaveButton();
        this.addCloseButton();
    }

    addDeleteButton() {
        if (DataSourceMode.SEARCH !== this.dataSourceMode && this.onDelete != null) {
            this.btnDelete = new Button({ themeKey: "editRowDecorator.deleteButton", id: "erdDeleteButton", rowBreak: false });
            this.btnDelete.addClickListener(() => this.onDelete());
            this.panelHeader.add(this.btnDelete);
        }
    }

    addSaveButton() {
        if (this.onSave != null) {
            const addlActions = [];
            if (DataSourceMode.SEARCH !== this.dataSourceMode && this.onAddAnother != null)
                addlActions.push(new Label({ caption: "Save & Add Another", id: "erdSaveAddAnotherLabel", onClick: () => this.saveAndAddAnotherClicked() }));

            this.multiButton = new MultiButton({
                themeKey: "editRowDecorator.multiButton",
                id: "erdMultiButton",
                rowBreak: false,
                dropdownItems: addlActions,
                ...this.saveButtonProps
            });

            this.multiButton.primaryButton.setProps({
                themeKey: "editRowDecorator.primaryButton",
                id: "erdButtonSaveClose",
                caption: "Save & Close",
                default: true
            });

            this.multiButton.primaryButton.addClickListener(() => this.saveAndCloseClicked());
            this.panelHeader.add(this.multiButton);
        }
    }

    override addLayout(): void {
        if (this.data != null && this.layout != null) {
            this.layout.mainDataSource.setRowsAndMode(this.dataSourceMode, [this.data]);
        }
        super.addLayout();
    }

    private saveAndCloseClicked(): void {
        if (this.save() && this.onClose != null)
            this.onClose(false);
    }

    private saveAndAddAnotherClicked(): void {
        if (this.save() && this.onAddAnother != null)
            this.onAddAnother();
    }

    private save(): boolean {
        if (this.validateSimple(true) !== true)
            return false;
        if (this.onSave != null)
            this.onSave(this.data);
        return true;
    }

    override validate(checkRequired: boolean = true, showErrors: boolean = true): ValidationResult[] {
        let result = super.validate(checkRequired, showErrors);
        if (this.onValidate != null) {
            const onValidateResult = this.onValidate(this.data);
            if (result == null)
                result = onValidateResult;
            else
                result = result.concat(onValidateResult);
        }
        return result;
    }

    get fillVerticalSpace(): boolean {
        return this._fillVerticalSpace == null ? true : super.fillVerticalSpace;
    }

    set fillVerticalSpace(value: boolean) {
        super.fillVerticalSpace = value;
    }
}
