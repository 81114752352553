import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Step, Stepper, Switch, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutDriverApplication extends Layout {
	buttonAddJob: Button;
	buttonAgree: Button;
	horizontalspacer1: HorizontalSpacer;
	labelDriverApplication: Label;
	labelEndorsements: Label;
	labelJobExp: Label;
	labelMessage: Label;
	labelRead: Label;
	nulltextboxtractorwheelbase: Textbox;
	panelFelonyConviction: Panel;
	panelOwner: Panel;
	sourceDriverApplication: DataSource;
	sourceDriverEmpHistory: DataSource;
	step2: Step;
	stepCertificate: Step;
	stepConfirmation: Step;
	stepJobExperience: Step;
	stepPersonalInfo: Step;
	stepRecords: Step;
	stepperDriverApp: Stepper;
	switchCitizen: Switch;
	switchConvictFelony: Switch;
	switchDoubleTriples: Switch;
	switchHazmat: Switch;
	switchLicenseRevoked: Switch;
	switchOwnerOperator: Switch;
	switchRightToWork: Switch;
	switchTanks: Switch;
	switchUSTravel: Switch;
	tableJobExp: Table;
	textboxAccidentDesc: Textbox;
	textboxAccidents: Textbox;
	textboxBirthDate: Textbox;
	textboxConvictFelonyD: Textbox;
	textboxDriverAddress: Textbox;
	textboxDriverCellPhone: Textbox;
	textboxDriverCity: Textbox;
	textboxDriverEmail: Textbox;
	textboxDriverFname: Textbox;
	textboxDriverInitial: Textbox;
	textboxDriverLname: Textbox;
	textboxDriverPhone: Textbox;
	textboxDriverState: Textbox;
	textboxDriverZip: Textbox;
	textboxExperience: Textbox;
	textboxFax: Textbox;
	textboxHaultype: Textbox;
	textboxLicenseDate: Textbox;
	textboxLicenseExpDate: Textbox;
	textboxLicenseNumber: Textbox;
	textboxLicenseRevokedD: Textbox;
	textboxLicenseState: Textbox;
	textboxLicenses: Textbox;
	textboxNumTickets: Textbox;
	textboxSocialSecurityNo: Textbox;
	textboxTicketDescr: Textbox;
	textboxfifthhgt: Textbox;
	textboxmaxrpm: Textbox;
	textboxrearend: Textbox;
	textboxtractormanufacturer: Textbox;
	textboxtractortype: Textbox;
	textboxtractoryear: Textbox;
	textboxtrweight: Textbox;
}
