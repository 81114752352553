import { AbstractSettings, AbstractSettingsDef } from "./AbstractSettings";
import { SettingsManager } from "./SettingsManager";

export interface LocationComponentSettingsDef extends AbstractSettingsDef {
    //auth
    enforce_shipper_id?: string;
    google_places_entry_delay?: number;
}

export class LocationComponentSettings extends AbstractSettings {
    constructor() {
        super("location_component_settings");
    }

    public static getSingleton(): LocationComponentSettings {
        return SettingsManager.getSingleton("location_component_settings") as LocationComponentSettings;
    }

    public static get(): LocationComponentSettingsDef {
        return LocationComponentSettings.getSingleton().values as LocationComponentSettingsDef;
    }

    public override get values(): LocationComponentSettingsDef {
        return super.values as LocationComponentSettingsDef;
    }

    protected getValuesInternal(): LocationComponentSettingsDef {
        return this._values as LocationComponentSettingsDef;
    }
}
