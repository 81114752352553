import { Login, McLeodMainPage, SupportEmailProvider, UIDesignerTailorProvider } from "@mcleod/common";
import { ServiceAddresses } from "@mcleod/core";
import { PortalLoginFooter } from "../portal-common/src/PortalLoginFooter";
import { PortalSupportEmailProvider } from "../portal-common/src/PortalSupportEmailProvider";
import { PortalUIDesignerTailorProvider } from "../portal-common/src/PortalUIDesignerTailorProvider";
import { PortalsSettingsManager } from "../portal-common/src/settings/PortalSettingsManager";

const rootElement = document.getElementById("root");
if (rootElement == null)
    throw new Error("Could not mount component because DOM element named root could not be found.");
provideAppSpecificConfiguration();
const mainPage = new McLeodMainPage({
    settingsRoot: "portal/",
    apiContext: "mcleod-api-portal",
    modules: [
        { path: "designer", context: require["context"]("@mcleod/designer/src", true) },
        { path: "common", context: require["context"]("@mcleod/common/src", true) },
        { path: "portal-carrier", context: require["context"]("@mcleod/portal-carrier/src", true) },
        { path: "portal-customer", context: require["context"]("@mcleod/portal-customer/src", true) },
        { path: "portal-factoring", context: require["context"]("@mcleod/portal-factoring/src", true) },
        { path: "portal-reports", context: require["context"]("@mcleod/portal-reports/src", true) },
        { path: "portal-driver", context: require["context"]("@mcleod/portal-driver/src", true) },
        { path: "portal-common", context: require["context"]("@mcleod/portal-common/src", true) }
    ]
});
rootElement.appendChild(mainPage._element);

function provideAppSpecificConfiguration() {
    Login.displayCallback = PortalLoginFooter.addFooterToLogin;
    ServiceAddresses.setLocalHostServerPort(8082);
    PortalsSettingsManager.initializeSettings();
    SupportEmailProvider.set(new PortalSupportEmailProvider());
    UIDesignerTailorProvider.set(new PortalUIDesignerTailorProvider());
}