import { Endpoint, RowConstructor } from "../../Endpoint";
import { ModelRow } from "../../ModelRow";

const AutogenApiDocEndpoint = "lib/api-doc";

export class AutogenModelApiDoc<RowType extends ModelRow> extends Endpoint<RowType, RowApiDocFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenApiDocEndpoint, ctor);
    }
}

export class AutogenRowApiDoc extends ModelRow<RowApiDocFields> {
    constructor() {
        super(AutogenApiDocEndpoint);
    }
}

export interface RowApiDocFields {
    endpoint: string;
    source_links: string;
    auth: string;
    description: string;
    input: string;
    output: string;
    methods: string;
}
