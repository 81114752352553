import {
    ConfigSettings, CurrencySettings, LicenseSettings, LocationComponentSettings,
    MapSettings, SettingsManager, UserSettings
} from "@mcleod/core";
import { PortalGeneralSettings } from "./PortalGeneralSettings";
import { PortalCompanySettings } from "./PortalCompanySettings";
import { PortalDriverSettings } from "../../../driver/src/settings/PortalDriverSettings";
import { PortalCarrierSettings } from "../../../portal-carrier/src/settings/PortalCarrierSettings";
import { PortalCustomerSettings } from "../../../customer/src/settings/PortalCustomerSettings";
import { PortalFactoringSettings } from "../../../portal-factoring/src/settings/PortalFactoringSettings";

export class PortalsSettingsManager {
    static initializeSettings() {
        SettingsManager.register(new ConfigSettings());
        SettingsManager.register(new CurrencySettings());
        SettingsManager.register(new LicenseSettings());
        SettingsManager.register(new LocationComponentSettings());
        SettingsManager.register(new MapSettings());
        SettingsManager.register(new PortalCarrierSettings());
        SettingsManager.register(new PortalCompanySettings());
        SettingsManager.register(new PortalCustomerSettings());
        SettingsManager.register(new PortalDriverSettings());
        SettingsManager.register(new PortalFactoringSettings());
        SettingsManager.register(new PortalGeneralSettings());
        SettingsManager.register(new UserSettings());
    }
}