import { Button, DataSource, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutAdditionalDetailsFTL extends Layout {
    buttonShare: Button;
    labelAdditionalDetails: Label;
    labelOrderEmail: Label;
    labelReferenceNumbers: Label;
    panelAdditionalDetailsFTL: Panel;
    sourceCustomerOrderDetail: DataSource;
    sourceOrderReferenceNumbers: DataSource;
    tableReferenceNumbers: Table;
    textboxCommodity: Textbox;
    textboxReturnTemp: Textbox;
    textboxTractor: Textbox;
    textboxTrailer: Textbox;
}
