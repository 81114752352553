import { CommonDialogs } from "@mcleod/common";
import { Button, ButtonVariant, CrudDecorator, DataSourceMode, LayoutProps, ResourceFileProps, Snackbar } from "@mcleod/components";
import { Api } from "@mcleod/core";
import { AbstractModelDesigner } from "./AbstractModelDesigner";
import { ModelDesignerTab } from "./ModelDesignerTab";
import { ModelVersions } from "./ModelVersions";

export class ModelTailor extends AbstractModelDesigner {
    private buttonSaveNewVersion: Button;
    private buttonManageVersions: Button;

    constructor(props?: Partial<LayoutProps>, open?: string) {
        super({
            ...props,
            localStorageManager: null,
            endpointPath: "metadata/model",
            open: open
        });
    }

    get tabsetTools(): Button[] {

        this.buttonSaveNewVersion = this.createTabButton("Save a new copy of this custom layout",
            "duplicateDisk", () => this.saveCustomModel(this.getActiveTab(), true));

        this.buttonManageVersions = this.createTabButton("Manage the versions of this model",
            "formPencil", () => ModelVersions.showSlideout(this));

        const closeButton = new Button({
            borderWidth: 0,
            padding: 2,
            variant: ButtonVariant.round,
            marginLeft: 50,
            imageProps: { name: "x", height: 32, width: 32, color: "primary" },
            onClick: () => this.slideOut()
        });

        return [
            this.buttonSave,
            this.buttonSaveNewVersion,
            this.buttonManageVersions,
            closeButton
        ];
    }

    override addNewTab(): ModelDesignerTab {
        return null;
    }

    override tabChanged(tab: ModelDesignerTab) {
        this.buttonSaveNewVersion.visible = tab != null ? tab.isCustom : false
     }

    afterTabClosed() {
        if (this.tabset.getComponentCount() === 0) {
            this.slideOut();
        }
    }

    override async openTab(props: ResourceFileProps, selectTab: boolean = true): Promise<ModelDesignerTab> {
        if (props?.path == null)
            return Promise.resolve(null);
        return super.openTab(props).then(tab => {
            this.disableActions(tab);
            return tab;
        });
    }

    override showSave() {
        const activeTab = this.getActiveTab();
        if (activeTab != null)
            return this.saveCustomModel(activeTab);
    }

    async saveCustomModel(tab: ModelDesignerTab, savingACopy?: boolean) {
        let descr: string = null;
        if (tab.baseVersion === true || savingACopy === true) {
            descr = await tab.promptForVersionDescription();
            if (descr == null)
                return;
        }
        tab.firstSaveOfCustomResource = true;
        tab.baseVersion = false;

        const body = { ...this.getSaveRequestBody(tab), descr };

        if (!savingACopy) {
            body.id = tab.customId;
        }
        this.saveActiveTab(body, (error) => {
            CommonDialogs.showError(error);
            if (tab.firstSaveOfCustomResource === true)
                tab.baseVersion = true;
            tab.firstSaveOfCustomResource = undefined;
        });
    }

    async saveAndOpenCopy(body: any = this.getSaveRequestBody(this.getActiveTab())) {
        const tab = this.getActiveTab();
        Api.update("metadata/model", body).then((response) => {
            const responseData = response.data;
            this.openTab({ ...tab.descriptor, customId: responseData.id }, true).then(() => {
                Snackbar.showSnackbar(responseData.message);
            });
        }).catch(reason => CommonDialogs.showError(reason));
    }

    private disableActions(tab: ModelDesignerTab) {
        tab.designerPanel.panelModelProps.panelDetail.forEveryChildComponent((comp) => {
            comp.enabled = comp instanceof Button
        })
    }

    public static openSlideout(path: string, doAfterSlideOut: ()=> void) {
        const layout = new ModelTailor({hideTitle: true}, path);

        const crudPanel = new CrudDecorator({
            layout: layout,
            mode: DataSourceMode.NONE,
            headerProps: { visible: false },
            doAfterSlideOut: () => {
                doAfterSlideOut();
            }
        });

        crudPanel.slideIn(
            { speed: 200 }, true,
            { closeOnClickOff: true, greyedBackground: true },
            { paddingLeft: 24, paddingRight: 24, paddingTop: 0, paddingBottom: 0 }
        );
    }
}
