import { Button, DataSource, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutAdditionalDetailsLTL extends Layout {
    buttonBOL: Button;
    buttonShare: Button;
    labelAdditionalDetails: Label;
    labelConsigneeContact: Label;
    labelConsigneeContactName: Label;
    labelConsigneePhone: Label;
    labelOrderEmail: Label;
    labelReferenceNumbers: Label;
    labelShipperContact: Label;
    labelShipperContactName: Label;
    labelShipperPhone: Label;
    panelAdditionalDetails: Panel;
    panelBilling: Panel;
    panelCarrier: Panel;
    panelConsigneeContact: Panel;
    panelLastKnownLocation: Panel;
    panelShipperContact: Panel;
    sourceCustomerOrderDetail: DataSource;
    sourceOrderReferenceNumbers: DataSource;
    tableReferenceNumbers: Table;
    textboxBillingMethod: Textbox;
    textboxCustomername: Textbox;
    textboxLastKnownLocation: Textbox;
    textboxPayeename: Textbox;
}
