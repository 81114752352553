/**
 * This class is used to display a basic connectivity error with the web server.
 * These errors can occur when the server is not running or the client is not connected to the internet.
 * Since it's a connectivity error, we can't ask the server for a layout so the UI of this class must be hard-coded.
 */

import { AuthType, GeneralSettings, HorizontalAlignment, VerticalAlignment } from "@mcleod/core";
import { Image, Label, Layout } from "..";

export class PanelStaticError extends Layout {
    constructor(props) {
        super({
            auth: AuthType.UNAUTH,
            align: HorizontalAlignment.CENTER,
            fillRow: true,
            verticalAlign: VerticalAlignment.CENTER,
            needsServerLayout: false,
            ...props
        });
        this.add(new Image({ name: "brokenPallet", height: 140, width: 388, color: "primary", rowBreak: true, marginTop: 64, marginBottom: 48 }));
        if (props.errorSummary != null)
            this.add(new Label({ caption: props.errorSummary, color: "primary", fontSize: "xxlarge" }));
        if (props.errorDetail != null)
            this.add(new Label({ caption: props.errorDetail, color: "primary", fontSize: "large" }));
        if (GeneralSettings.getSingleton()?.isRunningInIDE() && props.devDetail != null)
            this.add(new Label({ caption: props.devDetail, color: "error", fontSize: "large" }));
    }
}
