import { Base64Util, getLogger, getThemeColor, MimeTypes } from "@mcleod/core";
import { getImageModule } from "@mcleod/images";
import { Component } from "../../base/Component";
import { ComponentPropDefinitions } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ImagePropDefinitions, ImageProps } from "./ImageProps";
import { ImageStyles } from "./ImageStyles";
import { ImageType } from "./ImageType";
import TIFF from 'tiff.js';

const log = getLogger("components.Image");

export class Image extends Component implements ImageProps {
    private _fill: string;
    private _imageBytes: string;
    private _imageType: ImageType;
    private _name: string;
    private _rotation: number;
    private _stroke: string;
    private _strokeWidth: number;
    private _rotate: boolean;
    private _busy: boolean;
    private _nameBeforeBusy: string;
    private path: SVGPathElement;
    private tiff: TIFF;
    private _numPages: number;
    private _pageIndex: number;

    constructor(props?: Partial<ImageProps>) {
        if (props != null && (props.imageType === ImageType.IMG || props.imageType === ImageType.TIFF)) {
            super("img", props);
            this._imageType = props.imageType;
            delete props.imageType;
        }
        else if (props != null && (props.imageType === ImageType.PDF)) {
            super("object", props);
            this._imageType = props.imageType;
            delete props.imageType;
        }
        else {
            super("div");
            (this as any)._element = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            this._initElement(props);
        }
        this._element.setAttribute("class", ImageStyles.imageBase);
        this.setProps(props);
    }

    setProps(props: Partial<ImageProps>) {
        super.setProps(props);
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this.resetMultiPageAttributes();
        if (this._name === value)
            return;
        this._name = value;
        if (this._nameBeforeBusy != null && !this.busy)
            this._nameBeforeBusy = value;
        if (value == null || value.length === 0) {
            this._element.innerHTML = "";
            return;
        }
        const module = getImageModule(value);
        if (module == null) {
            log.info("Could not find module for image " + value);
            return;
        }
        const props = (module as any).props;
        if (props != null)
            for (const key in props) {
                if (key === "fillRule")
                    this._element.setAttribute("fill-rule", props[key]);
                else if (key !== "innerHTML")
                    this._element.setAttribute(key, props[key]);
            }
        if (props == null || props.viewBox == null)
            this._element.setAttribute("viewBox", "0 0 24 24");
        if ((module as any).innerHTML != null)
            this._element.innerHTML = (module as any).innerHTML;
        else {
            this._element.innerHTML = "";
            const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
            path.setAttribute("d", module.path);
            this._element.appendChild(path);
        }
    }

    set rotation(degrees: number) {
        this._rotation = degrees;
        if (degrees == null)
            this._element.style.transform = "";
        else
            this._element.style.transform = "rotate(" + degrees + "deg)";
    }

    get rotation(): number {
        return this._rotation;
    }

    get stroke(): string {
        return this._stroke;
    }

    set stroke(value: string) {
        this._stroke = value;
        this._element.style.stroke = getThemeColor(value);
    }

    get fill(): string {
        return this._fill;
    }

    set fill(value: string) {
        this._fill = value;
        this._element.style.fill = getThemeColor(value);
    }

    get strokeWidth(): number {
        return this._strokeWidth;
    }

    set strokeWidth(value: number) {
        this._strokeWidth = value;
        if (value == null)
            this._element.style.strokeWidth = "";
        else
            this._element.style.strokeWidth = value.toString();
    }

    set preserveAspectRatio(value: boolean) {
        if (value)
            this._element.style.objectFit = "contain";
        else
            this._element.style.objectFit = "";
    }

    get imageBytes(): string {
        return this._imageBytes;
    }

    set imageBytes(value: string) {
        this.resetMultiPageAttributes();
        this._imageBytes = value;
        if (this.imageType === ImageType.IMG) {
            if (!value.startsWith("data:image"))
                value = "data:image/jpeg;base64," + value;
            this._element.setAttribute("src", value);
        }
        else if (this.imageType === ImageType.TIFF) {
            const arrayBuffer = Base64Util.toArrayBuffer(this._imageBytes);
            this.tiff = new TIFF({ buffer: arrayBuffer });
            this.numPages = this.tiff.countDirectory();
            this.pageIndex = 0;
            this.displayCurrentPage();
        }
        else if (this.imageType === ImageType.PDF) {
            const base64 = window.atob(value);
            const len = new Array(base64.length);
            for (let i = 0; i < base64.length; i++)
                len[i] = base64.charCodeAt(i);
            const byteArray = new Uint8Array(len);
            const blob = new Blob([byteArray], { type: MimeTypes.PDF });
            let fileURL = URL.createObjectURL(blob);
            fileURL += "#toolbar=0&navpanes=0";
            this._element.setAttribute("data", fileURL);
        }
        else
            throw new Error("Cannot set imageBytes on an SVG image.");
    }

    get pageIndex(): number {
        return this._pageIndex;
    }

    private set pageIndex(value: number) {
        this._pageIndex = value;
    }

    get imageType(): ImageType {
        return this._imageType;
    }

    private resetMultiPageAttributes() {
        this.tiff = null;
        this.numPages = null;
        this.pageIndex = null;
    }

    get numPages(): number {
        return this._numPages == null ? 1 : this._numPages;
    }

    private set numPages(value: number) {
        this._numPages = value;
    }

    get isMultiPage(): boolean {
        return this.numPages > 1;
    }

    /**
     * This method will change the image to display a particular page within that image.
     *
     * If the provided page index is too high or too low, the first or last page will be displayed.
     * @param pageIndex The index of the page to be displayed
     * @returns The index of the page that was displayed
     */
    goToPage(pageIndex: number): number {
        const oldPageIndex = this.pageIndex;
        if (this.isMultiPage !== true || pageIndex == null)
            return oldPageIndex;
        this.setPageIndex(pageIndex);
        if (this.pageIndex !== oldPageIndex)
            this.displayCurrentPage();
        return this.pageIndex;
    }

    /**
     * Displays a page within the image based on a delta that is relative to the current page.
     * @param delta The change relative to the current page index.  For example, provide 1 to display the next page,
     *              or -1 to display the previous page.
     * @returns The index of the page that was displayed
     */
    advancePage(delta: number): number {
        if (delta == null)
            return this.pageIndex;
        return this.goToPage(this.pageIndex + delta);
    }

    /**
     * Updates the <img> element to display the page specified by the current page index.
     */
    private displayCurrentPage() {
        this.tiff.setDirectory(this.pageIndex);
        const canvas = this.tiff.toCanvas();
        const dataUrl = canvas.toDataURL();
        this._element.setAttribute("src", dataUrl);
    }

    /**
     * This method will update the page index value, but only to a valid value:
     * - If the provided page index value is too low, the pageIndex value will be set to 0.
     * - If the provided page index value is too high, the pageIndex value will be set to the last page in the image.
     * @param index The page index value that should be displayed.
     */
    private setPageIndex(value: number) {
        this.pageIndex = value;
        if (this.pageIndex < 0)
            this.pageIndex = 0;
        else {
            const lastPageIndex = this.getLastPageIndex();
            if (this.pageIndex > lastPageIndex)
                this.pageIndex = lastPageIndex;
        }
    }

    getLastPageIndex(): number {
        if (this.isMultiPage !== true)
            return 0;
        return Math.max(0, (this.numPages - 1));
    }

    /**
     * This method determines if the provided pageIndex value is valid for this image.
     * @param pageIndex The page index value to test.
     * @returns A number or null:
     * - A value of 0 signifies that the pageIndex value is valid.
     * - A value of -1 signifies that the pageIndex value is too low (less than zero).
     * - A value of 1 signifies that the pageIndex value is too hight (greater than the highest page index in the 
     *   image).
     * - A value of null signifies that the image is not a multi-page image.
     */
    isValidPageIndex(pageIndex: number): number {
        if (this.isMultiPage !== true)
            return null;
        if (pageIndex < 0)
            return -1;
        if (pageIndex > this.getLastPageIndex())
            return 1;
        return 0;
    }

    public override getPropertyDefinitions(): ComponentPropDefinitions {
        return ImagePropDefinitions.getDefinitions();
    }

    get rotate() {
        return this._rotate == null ? false : this._rotate;
    }

    set rotate(value: boolean) {
        this._rotate = value;
        if (value)
            this._element.classList.add(ImageStyles.rotate);
        else
            this._element.classList.remove(ImageStyles.rotate);
    }

    get busy(): boolean {
        return this._busy == null ? false : this._busy;
    }

    set busy(value: boolean) {
        this._busy = value;
        if (value === true) {
            this._nameBeforeBusy = this._name;
            this.name = "spinner";
        }
        else {
            this.name = this._nameBeforeBusy;
            delete this._nameBeforeBusy;
        }
        this.rotate = value;
    }

    override get serializationName() {
        return "image";
    }

    override get properName(): string {
        return "Image";
    }

    override getBasicValue(): any {
        return this.name;
    }

    public static createFileBasedImage(props: Partial<ImageProps>, fileName: string) {
        if (props.imageType == null) {
            props.imageType = ImageType.IMG;
            if (fileName.endsWith(".tiff"))
                props.imageType = ImageType.TIFF;
            if (fileName.endsWith(".pdf"))
                props.imageType = ImageType.PDF;
        }
        return new Image(props);
    }
}

ComponentTypes.registerComponentType("image", Image.prototype.constructor);
