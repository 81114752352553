import {
    Button, ButtonProps, Component, Dialog, DialogProps, Label, Panel, PanelProps, StackDialog,
    StringOrPropsOrComponent, Textbox, TextboxProps
} from "@mcleod/components";
import { getComponentFromStringOrPropsOrComponent } from "@mcleod/components/src/page/getComponentFromStringOrPropsOrComponent";
import {
    ErrorMessage, GeneralSettings, HorizontalAlignment, JSUtil, ModuleLogger, ServerError, getLogger,
    getThemeColor
} from "@mcleod/core";

export interface YesNoDialogProps extends DialogProps {
    defaultButton?: "yes" | "no",
    yesButtonProps?: Partial<ButtonProps>;
    noButtonProps?: Partial<ButtonProps>;
    yesButtonCaption?: string;
    noButtonCaption?: string;
}

export enum DiscardSaveCancelChoice {
    DISCARD = "discard",
    SAVE = "save",
    CANCEL = "cancel"
}

export class CommonDialogs {
    private static _log: ModuleLogger;

    public static createDialog(components: StringOrPropsOrComponent | StringOrPropsOrComponent[], dialogProps: Partial<DialogProps>): Dialog {
        const dialog = new Dialog(dialogProps);
        if (!Array.isArray(components))
            components = [components];
        for (const comp of components) {
            const realComponent = getComponentFromStringOrPropsOrComponent(comp, { color: getThemeColor("defaultColor") });
            dialog.add(realComponent);
            // commenting this out 11/7/23 because the Layout isn't actually loaded at this point
            // and the assumptions made in onLoad() (that the Layout's members are non-null) are not valid
            // I bet there was some side effect where this onLoad() call was beneficial.
            // If you see this comment after a few months and we feel like we're sure there aren't any 
            // negative side effects, please delete this comment and the code below.
            // if (realComponent instanceof Layout)
                // realComponent.onLoad();
        }
        return dialog;
    }

    public static async showDialog(components: StringOrPropsOrComponent | StringOrPropsOrComponent[], dialogProps?: Partial<DialogProps>): Promise<Dialog> {
        const dialog = CommonDialogs.createDialog(components, dialogProps);
        await dialog.show();
        return Promise.resolve(dialog);
    }

    /**
     * This function shows an error dialog and allows the user to email support with the error details.
     *
     * @param {string} error
     * @param {string} title
     */
    public static async showError(error: StringOrPropsOrComponent | ServerError | Error | ErrorMessage, title: string = "Error"): Promise<Dialog> {
        if (error == null)
            error = "An exception with no detail was thrown.";
        if (error instanceof ServerError)
            return CommonDialogs.showServerError(error, title === "Error" ? undefined : title);
        else if (error instanceof Error)
            return StackDialog.showStackDialog(error, { title: title });
        else if (typeof error === "object") {
            if ("displayed" in error && error.displayed === true) // the ErrorMessage interface from ErrorHandler
                return Promise.resolve(null);
        }
        return CommonDialogs.showMessage(error.toString(), title);
    }

    /**
     * This displays a dialog that prompts the user to select 'Yes' or 'No'.  This function returns a
     * Promise whose result is a boolean value.  A true value in that result indicates the user selected 'Yes'.
     * A false value in that result indicates the user selected 'No'.  An undefined value in that result indicates
     * the user cancelled, either by clicking the X button or by using the Escape key.
     * @param caption
     * @param title
     * @param props
     * @returns
     */
    public static showYesNo(caption: StringOrPropsOrComponent, title?: string, props?: Partial<YesNoDialogProps>, showIcons?: boolean, buttonWidth?: number): Promise<boolean> {
        const panel = new Panel({ fillRow: true });
        const component = getComponentFromStringOrPropsOrComponent(caption);
        panel.add(component);
        const panelYesNo = new Panel({ fillRow: true, align: HorizontalAlignment.CENTER, marginTop: 24 });
        const buttonNo = new Button({
            caption: props?.noButtonCaption ?? "No", color: props?.defaultButton === "no" ? "primary.reverse" : "subtle.darker", backgroundColor: props?.defaultButton === "no" ? "primary" : "", imageName: "x", rowBreak: false, marginRight: 48, ...props?.noButtonProps
        });
        buttonNo.addClickListener(() => dlg.close(false));
        const buttonYes = new Button({ caption: props?.yesButtonCaption ?? "Yes", color: props?.defaultButton === "no" ? "subtle.darker" : "primary.reverse", backgroundColor: props?.defaultButton === "no" ? "" : "primary", imageName: "check", default: true, ...props?.yesButtonProps });
        buttonYes.addClickListener(() => {
            if (dlg.validateSimple())
                dlg.close(true);
        });
        if (showIcons != null && !showIcons) {
            buttonNo.imageName = null;
            buttonYes.imageName = null;
        }
        if (buttonWidth != null) {
            buttonNo.width = buttonWidth;
            buttonYes.width = buttonWidth;
        }
        panelYesNo.add(buttonNo);
        panelYesNo.add(buttonYes);
        panel.add(panelYesNo);
        props = props ?? {};
        props.panelTitleProps = { backgroundColor: "primary.darker", color: "primary.reverse" };
        const dlg = CommonDialogs.createDialog(panel, { title: title, okVisible: false, ...props });
        return dlg.show();
    }

    public static showDestructive(caption: StringOrPropsOrComponent, title?: string, props?: Partial<YesNoDialogProps>, showIcons?: boolean, buttonWidth?: number): Promise<boolean> {
        if (props == null)
            props = {};
        const defaultProps: Partial<YesNoDialogProps> = { yesButtonProps: { backgroundColor: "error", color: "error.reverse" } };
        JSUtil.assignIfUndefined(defaultProps, props);
        return CommonDialogs.showYesNo(caption, title, props, showIcons, buttonWidth);
    }

    /**
     * This displays a dialog that prompts the user to select 'Discard Changes', 'Save Changes', or 'Cancel'.  This function returns a
     * Promise whose result is a boolean value.  A true in that result indicates the user selected 'Save Changes'.
     * false is returned if the user clicked 'Discard Changes', 'Cancel', or somehow otherwise closed the dialog.
     * @param props
     * @returns
     */
    public static async showDiscardSaveCancelDialog(props?: Partial<PanelProps>): Promise<DiscardSaveCancelChoice> {
        const panel = new Panel({ fillRow: true, padding: 0, ...props });
        panel.add(new Label({ caption: "You have unsaved data. Do you want to:", rowBreak: true }));
        const discardButton = new Button({ caption: "Discard Changes", rowBreak: false, color: "subtle.darker" });
        discardButton.addClickListener(event => dialog.close(DiscardSaveCancelChoice.DISCARD));
        panel.add(discardButton);
        const saveButton = new Button({ caption: "Save Changes", rowBreak: false, color: "subtle.darker" });
        saveButton.addClickListener(event => dialog.close(DiscardSaveCancelChoice.SAVE));
        panel.add(saveButton);
        const cancelButton = new Button({ caption: "Cancel", rowBreak: false, default: true, color: "primary" });
        cancelButton.addClickListener(event => dialog.close(DiscardSaveCancelChoice.CANCEL));
        panel.add(cancelButton);
        const dialog = CommonDialogs.createDialog(panel, { title: "Are you sure?", okVisible: false });
        return dialog.show();
    }

    /**
     * Shows a dialog with a message and a simple text box for the user to enter a value.
     *
     * @param {string} The message to display to the user.  See {@link getComponentFromFuctionStringOrPropsOrComponent} for more information about what this value can be.
     * @param {string} title The optional title of the dialog.  If not provided, it will simply display the application name (usually something like "LoadMaster - ABC Trucking")
     * @param {object} inputProps An optional set of properties to pass to the Textbox that is shown on the Dialog
     * @param {object} dialogProps An optional set of properties to pass to the dialog that is shown.
     * @returns
     */
    public static async showInputDialog(caption: StringOrPropsOrComponent, title?: string, textboxProps?: Partial<TextboxProps>, dialogProps?: Partial<DialogProps>): Promise<string> {
        const panel = new Panel({ fillRow: true }); // do we need an argument to affect the props of this panel?
        const comp = getComponentFromStringOrPropsOrComponent(caption);
        const input = new Textbox({ captionVisible: false, fillRow: true, required: true, ...textboxProps });
        panel.add(comp);
        panel.add(input);
        let returnValue = undefined;
        const dlg = CommonDialogs.createDialog(panel, { title: title, okVisible: true, ...dialogProps });
        const response = await dlg.show();
        if (response != undefined) {
            returnValue = input.text;
        }
        return Promise.resolve(returnValue);
    }

    /**
     * Shows a simple text message to the user in a modal window.
     *
     * @param {string} caption The message to display to the user.  See {@link getComponentFromFuctionStringOrPropsOrComponent} for more information about what this value can be.
     * @param {string} title The optional title of the dialog.  If not provided, it will simply display the application name (usually something like "LoadMaster - ABC Trucking")
     * @param {object} dialogProps An optional set of properties to pass to the dialog that is shown.
    */
    public static async showMessage(caption: StringOrPropsOrComponent, title?: string, dialogProps?: Partial<DialogProps>): Promise<Dialog> {
        const comp = getComponentFromStringOrPropsOrComponent(caption);
        return CommonDialogs.showDialog(comp, { title: title, ...dialogProps });
    }

    /**
     * This is a convenience function to display a very generic error message when we fail to process the response from an API call.  It will also log the error behind the scenes so we can diagnose it.
     * This differs from showServerError in that this should be displayed if the API call succeeds but we have a problem reading or handling the response.
     *
     * @param {object} response
     * @param {string} error
     */
    public static async showUnknownResponseError(response: any, error: string): Promise<Dialog> {
        CommonDialogs.log.info("Unknown response %o     Error %o", response, error);
        return CommonDialogs.showError("There was an error handling the response from the server.");
    }

    /**
     * This displays a ServerError (created by exceptions in Api calls) in a modal dialog box.
     *
     * @param {*} title
     * @param {*} error
     */
    public static async showServerError(error: ServerError, title = "Server Error"): Promise<Dialog> {
        const labels: Component[] = CommonDialogs.convertStringsToLabels(error.messages);
        if (error.errorTag != null) {
            labels.push(new Label({ caption: "Error ID: " + error.errorTag, marginTop: 15 }));
            const link = `mailto:${GeneralSettings.get()?.support_email_address}?subject=Error report&body=Received error tag ` + error.errorTag + ".";
            labels.push(new Label({ caption: "Email support", link: link }));
        }
        CommonDialogs.log.info("Server error %o", error);
        return CommonDialogs.showDialog(labels, { title: title });
    }

    private static convertStringsToLabels(content: string | string[]): Label[] {
        if (content == null)
            return null;
        if (!Array.isArray(content))
            content = [content];
        const labels: Label[] = [];
        for (const line of content)
            labels.push(new Label({ caption: CommonDialogs.appendPeriod(line), fontBold: true, fillRow: true }));
        return labels;
    }

    private static appendPeriod(message: string): string {
        if (message == null || message.endsWith("."))
            return message;
        return message + ".";
    }

    private static get log(): ModuleLogger {
        if (CommonDialogs._log == null)
            CommonDialogs._log = getLogger("components/page/Dialog");
        return CommonDialogs._log;
    }
}
