import { DisplayType, DisplayValue, HorizontalAlignment, MetadataField, ModelRow, VerticalAlignment, makeStyles } from "@mcleod/core";
import { Checkbox, Component, Switch } from "../..";
import { ComponentPropDefinition } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { PropType } from "../../base/PropType";
import { DataSource } from "../../databinding/DataSource";
import { Panel } from "../panel/Panel";
import { Table } from "./Table";
import { TableCellPropDefinitions, TableCellProps } from "./TableCellProps";
import { TableColumn } from "./TableColumn";
import { TableRow, TableRowMode } from "./TableRow";

const classes = makeStyles("td", {
    base: { verticalAlign: "top", paddingTop: "4px", paddingBottom: "4px", display: "table-cell", overflow: "hidden" },
});

export class TableCell extends Panel implements TableCellProps {
    public _table: Table;
    public _col: TableColumn;
    private _isHeading: boolean;
    private _caption: string;
    public displayType: DisplayType;
    public format: string;
    public row: TableRow;
    private _printableToggleEnabled: boolean;
    private hiddenComponents: Component[];

    constructor(props?: Partial<TableCellProps>) {
        super(props);
    }

    override setProps(props: Partial<TableCellProps>) {
        super.setProps(props);
    }

    get table(): Table {
        return this._table;
    }

    set table(value: Table) {
        this._table = value;
        (this as any)._parent = this.table;
        this.table._applyCellProps(this);
    }

    get col(): TableColumn {
        return this._col;
    }

    set col(value: TableColumn) {
        this._col = value;
        this.width = value?.headingCell?.width;
    }

    override allowDropInDesigner(component: Component): boolean {
        return !this.isHeading;
    }

    public get printableToggleEnabled(): boolean {
        return this._printableToggleEnabled == null ? true : this._printableToggleEnabled;
    }

    public set printableToggleEnabled(value: boolean) {
        this._printableToggleEnabled = value;
    }

    override _addDefaultClassName() {
        this.element.classList.add(classes.base);
    }

    override displayData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        this.fireDataDisplayListeners(data, allData, rowIndex);

        if (this._designer != null)
            return;
        for (const component of this.components) {
            if (component.displayData != null)
                component.displayData(data, allData, rowIndex);
        }

        //if field has been specified and no components are present, show value from field in cell's caption
        //this shouldn't happen anymore in non-designer screens due to populateSimpleComponent()
        if (this.field != null && this.components.length == 0) {
            if (data == null)
                this.caption = null;
            else if (data != null) {
                if (data instanceof ModelRow) // this *should* always be true but isn't while PropertiesTable adds rows with raw data to the Tabel
                    this.caption = DisplayValue.getDisplayValue(data.get(this.field), this.displayType, this.format);
                else
                    this.caption = DisplayValue.getDisplayValue(data[this.field], this.displayType, this.format);
            }
        }
    }

    private matchHeadingAlignment() {
        if (this.isHeading && this.components.length > 0 && this.align != null)
            this.components[0].align = this.align;
    }

    override set align(value: HorizontalAlignment) {
        super.align = value;
        if (value === HorizontalAlignment.RIGHT)
            this._element.style.textAlign = "right";
        else if (value === HorizontalAlignment.CENTER)
            this._element.style.textAlign = "center";
        else
            this._element.style.textAlign = "";
        this.matchHeadingAlignment();
    }

    override get align(): HorizontalAlignment {
        return super.align;
    }

    override add(...components: Component[]): Component {
        const result = super.add(...components);
        this.matchHeadingAlignment();
        return result;
    }

    override get components(): Component[] {
        return super.components;
    }

    override set components(value: Component[]) {
        super.components = value;
        this.matchHeadingAlignment();
    }

    get isHeading(): boolean {
        return this._isHeading;
    }

    set isHeading(value: boolean) {
        this._isHeading = value;
        if (value === true) {
            this.allowSelect = false;
            this._element.classList.add(classes.heading);
        }
    }

    get caption(): string {
        return this._caption;
    }

    set caption(value: string) {
        const oldValue = this._caption;
        this._caption = value;
        if (value == null)
            this._element.innerText = "";
        else
            this._element.innerText = value;
        this._matchIdToValue(oldValue, value, this._isHeading !== true ? this.idPrefix : "columnHeader");
    }

    get verticalAlign() {
        return super.verticalAlign;
    }

    set verticalAlign(value) {  // does this work without this getter/setter????  Panel does something similar.
        super.verticalAlign = value;
        if (value === VerticalAlignment.CENTER)
            this._element.style.verticalAlign = "middle";
        else if (value === VerticalAlignment.BOTTOM)
            this._element.style.verticalAlign = "bottom";
        else
            this._element.style.verticalAlign = "";
    }

    override getPropertyDefinitions() {
        return TableCellPropDefinitions.getDefinitions();
    }

    override _deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, componentCreationCallback): string[] {
        //if cell doesn't define any components and a dataSource field is defined, create simple component in the component def
        //create textbox if cell is editable, label if not
        //(don't do this in the designer)
        if (this._designer == null) {
            const field = compDef.field;
            const dataSource = this.table.dataSource;
            this.populateSimpleComponent(compDef, field, dataSource);
        }

        const result = super._deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, componentCreationCallback);
        if (this._designer == null) {
            // this code (and the related block below that is also commented out) was used to save changes on the blur of every component
            // let addlCompListeners = null;
            // if (this.table.rowModeControlType === RowModeControlType.AUTO) {
            //   addlCompListeners = {};
            //   addlCompListeners[ComponentListenerDefs.blur.domEventName] = {
            //     def: ComponentListenerDefs.blur,
            //     listeners: [
            //       (event: BlurEvent) => this.row.autoSave(event)
            //     ]
            //   }
            // }

            const boundRowPresent = this.row.boundRow != null;
            const inSearchMode = this.row.mode === TableRowMode.SEARCH;
            this.forEveryChildComponent(component => {
                component.insideTableCell = true;
                if (component.boundRow == null && boundRowPresent)
                    component.boundRow = this.row.boundRow;
                if (component instanceof Checkbox || component instanceof Switch)
                    component.allowIndeterminate = inSearchMode;
            });
            if (compDef.printableToggleEnabled && this.table.printableToggleEnabled) {
                const initiallyPrintable = this.row.rowIsPrintable();
                for (const component of this.components) {
                    if (component instanceof TableCell && !component.printableToggleEnabled)
                        continue;
                    if (this.row.mode !== TableRowMode.QUICK_ADD) {
                        if (component["printable"] != null)
                            component["printable"] = initiallyPrintable;
                        // if (addlCompListeners != null) {
                        //   component.addListenersToAll(addlCompListeners);
                        // }
                    }
                    else {
                        if (component["printable"] != null) {
                            component["printable"] = false;
                        }
                    }
                }
            }
        }
        return result;
    }

    override _serializeNonProps(): string {
        if (this.isHeading)
            return "";
        else
            return super._serializeNonProps();
    }

    override getPropertyDefaultValue(prop: ComponentPropDefinition): any {
        if (prop.name === "paddingLeft" && this._isFirstCellInRow() === true)
            return 12;
        if (prop.name === "paddingRight" && this._isLastCellInRow() === true)
            return 12;
        return super.getPropertyDefaultValue(prop);
    }

    private _isFirstCellInRow(): boolean {
        return this.row.isFirstCellInRow(this);
    }

    private _isLastCellInRow(): boolean {
        return this.row.isLastCellInRow(this);
    }

    populateSimpleComponent(compDef: any, field: string, dataSource: DataSource) {
        if (compDef.components == null && field != null && dataSource != null) {
            const md = dataSource.getMetadataFromCache();
            const mdField: MetadataField = md?.output?.[field];
            if (mdField != null) {
                compDef.components = [];
                const commonProps = { field, displayType: mdField.displayType, caption: mdField.caption, id: "cell" + field, required: mdField.required, fillRow: true };
                if (this.row.canBeEdited) {
                    if (mdField.dataType !== (PropType.bool as string))
                        compDef.components.push({ ...commonProps, type: "textbox" });
                    else
                        compDef.components.push({ ...commonProps, type: "checkbox" });
                }
                else
                    compDef.components.push({ ...commonProps, type: "label", caption: undefined });
            }
        }
    }

    // rowBreak isn't supported by TableCell and was removed from TableCellPropDefinitions
    // avoid calling the super which throws an error because this.parent is a Table and not a Container
    override set rowBreak(value: boolean) { }

    override get idPrefix(): string {
        return "tablecell";
    }

    override get serializationName() {
        return "cell";
    }

    override get properName(): string {
        return "Cell";
    }

    public override doBeforeComponentEnlarge(componentsToIgnore: Component[]) {
        this.hiddenComponents = [];
        for (const component of this.components) {
            if (component.visible === true && componentsToIgnore.includes(component) !== true) {
                this.hiddenComponents.push(component);
                component.visible = false;
            }
        }
        this.enlarged = true;
    }

    public override doAfterComponentsShrink() {
        this.enlarged = false;
        for (const hiddenComponent of this.hiddenComponents) {
            hiddenComponent.visible = true;
        }
        this.hiddenComponents = undefined;
    }

    override get enlargeScope(): Component {
        if (super.enlargeScope != null)
            return super.enlargeScope;
        super.enlargeScope = this.row;
        return super.enlargeScope;
    }

    public set enlargeScope(value: Component) {
        super.enlargeScope = value;
    }

    public override get selectableInDesigner(): boolean {
        return true;
    }

    protected override getDesignerWidth(): number {
        return 80;
    }
}

ComponentTypes.registerComponentType("cell", TableCell.prototype.constructor);
