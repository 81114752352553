/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCompany.ts                       *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "common/company";

export class AutogenModelCompany<RowType extends ModelRow> extends Endpoint<RowType, RowCompanyFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowCompany extends ModelRow<RowCompanyFields> {
	constructor(values?: Partial<RowCompanyFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowCompanyFields {
	ace_id: string;
	address1: string;
	address2: string;
	city: string;
	company_id: string;
	company_type: string;
	currency: string;
	def_move_type: string;
	distance_um: string;
	dot_number: string;
	email_address: string;
	email_bcc: string;
	email_client: string;
	email_host: string;
	email_imap_host: string;
	email_imap_password: string;
	email_imap_sent_folder: string;
	email_imap_ssl: string;
	email_imap_username: string;
	email_password: string;
	email_port: number;
	email_protocol: string;
	email_use_sent_folder: string;
	email_username: string;
	enable_br_ltl: string;
	enable_encryption: string;
	enable_mfa: string;
	enable_phoneformat: string;
	enable_portal_mfa: string;
	enable_transferlog: string;
	enable_web_mfa: string;
	fax: string;
	icon_image: string;
	id: string;
	is_main_company: string;
	logo: string;
	logo_name: string;
	ltl_company: string;
	mfa_prompt_interval: number;
	multi_curr_enabled: string;
	name: string;
	phone_format: string;
	phone_number: string;
	scac: string;
	security_admin_email: string;
	state: string;
	support_email: string;
	temperature_um: string;
	user_lic_contact_email: string;
	user_lic_contact_name: string;
	user_lic_contact_phone: string;
	website: string;
	weight_um: string;
	zip: string;
}

