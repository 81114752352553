import { GeneralSettings, GeneralSettingsDef } from "@mcleod/core";

export interface PortalGeneralSettingsDef extends GeneralSettingsDef {
}

export class PortalGeneralSettings extends GeneralSettings {
    public static getSingleton(): PortalGeneralSettings {
        return GeneralSettings.getSingleton() as PortalGeneralSettings;
    }

    public static get(): PortalGeneralSettingsDef {
        return PortalGeneralSettings.getSingleton().values as PortalGeneralSettingsDef;
    }

    public override get values(): PortalGeneralSettingsDef {
        return super.values as PortalGeneralSettingsDef;
    }

    protected override getValuesInternal(): PortalGeneralSettingsDef {
        return this._values as PortalGeneralSettingsDef;
    }
}
