import { Component } from "./Component";

export class Captioned extends Component {
    private _caption: string = "";

    getMixinClassName() {
        return "Captioned";
    }

    get caption(): string {
        return this._caption;
    }

    set caption(value: string) {
        if (this.captionValueMatches(value) === true) {
            return;
        }
        this._caption = value;
    }

    asCaptioned(): Captioned {
        return this;
    }

    captionValueMatches(value: string) {
        return this._caption === value;
    }

    getCaptionPrefix(): string {
        return this.required === true && this["printable"] !== true ? "*" : "";
    }

    getPrefixedCaption(): string {
        const prefix = this.getCaptionPrefix();
        if (this.caption != null && !this.caption.startsWith(prefix)) {
            return prefix + this.caption;
        }
        return this.caption;
    }
}

/**
 * Call this function, passing a Component, to return its Captioned mixin.  Returns undefined
 * if the passed Component is not Captioned;
 * @param component
 * @returns
 */
export function asCaptioned(component: Component): Captioned {
    return component?.["asCaptioned"]?.() as Captioned;
}

/**
 * Call this function to return whether or not the passed Component contains the Captioned mixin.
 * @param component
 * @returns
 */

export function isCaptioned(component: Component): boolean {
    return component?.["asCaptioned"] != null;
}


/*
import { Component } from "./Component";

type Constructor<T> = new (...args: any[]) => T;

export function Captioned<T extends Constructor<Component>>(Base: T) {
  return class extends Base {
    _caption: string;
    constructor(...args: any[]) {
      super(...args);
      this._caption = "";
    }

    get caption(): string {
      return this._caption;
    }

    set caption(value: string) {
      this._caption = value;
    }

    getCaptionPrefix(component: Component): string {
      return component.required === true && component.dataSource?.isAddingOrUpdating() ? "*" : "";
    }
  };
}
export const CaptionedComponent = Captioned(Component);
*/
