import { CommonDialogs } from "@mcleod/common";
import { Button, DataSourceMode, Dialog, Image, ImageType, Layout, MultiButton, Panel, Toast } from "@mcleod/components";
import { Api, FileUtil, ModelRow, Navigation, StringUtil, UrlUtil, getLogger } from "@mcleod/core";
import { ErrorHandlerFunction } from "@mcleod/core/src/ErrorHandler";
import { CarrierOfferAcceptPdfDialog } from "./CarrierOfferAcceptPdfDialog";
import { AutogenLayoutAccept } from "./autogen/AutogenLayoutAccept";
import { PortalCompanySettings } from "../../../portal-common/src/settings/PortalCompanySettings";

const log = getLogger("portal-carrier/Accept");

export class Accept extends AutogenLayoutAccept {

    override onLoad() {
        const props = UrlUtil.getPropsFromUrl(document.location.search);
        if (!StringUtil.isEmptyString(props.token)) {
            this.mainDataSource.search({ type: "pending", token: props.token }, this._errorHandler).then(response => {
                if (response?.modelRows?.length > 0) {
                    this.panelAvailable.visible = true;
                }
                else {
                    this.panelNotAvailable.visible = true;
                    this.panelAvailable.visible = false;
                }
            });
        } else {
            this.panelNotAvailable.visible = true;
            this.panelAvailable.visible = false;
        }

        this.mainDataSource.mode = DataSourceMode.NONE;
    }

    async confirmAcceptance() {
        const companySettings = PortalCompanySettings.get();
        const legalTerm = companySettings.require_legal_term;
        const orderConf = companySettings.require_order_term;
        const acceptButton = (this.buttonConfirmAccept as Button);
        const data = this.mainDataSource.activeRow;
        await this.shouldShowOfferDialog(data?.get("offer_id")).then(response => {
            //you want is_erate result to be false. Otherwise don't show dialog
            if (response == false && (legalTerm === "Y" || orderConf === "Y")) {
                this.getPdfData(data, legalTerm, orderConf);
            }
            else
                this.acceptOfferConfirmationDialog(acceptButton, data);
        })
    }

    acceptOfferConfirmationDialog(acceptButton: Button | MultiButton, modelRow: ModelRow, dialog?: Dialog) {
        CommonDialogs.showYesNo("Are you sure you want to accept the offer?",
            "Order " + modelRow?.get("order_id") + " - Accept Offer Confirmation").then(clickedYes => {
                if (clickedYes) {
                    acceptButton.busy = true;
                    const props = UrlUtil.getPropsFromUrl(document.location.search);
                    Api.post("portal/carrier/carrier-accept-offer", { token: props.token }).then(response => {
                        acceptButton.busy = false;
                        if (!StringUtil.isEmptyString(response.data[0].success)) {
                            if (dialog != null)
                                dialog.close();
                            Toast.showSuccessToast(response.data[0].success);
                            this.buttonConfirmAccept.visible = false;
                            this.labelAccept.caption = "Accepted Rate";
                        }
                    }).catch(err => {
                        acceptButton.busy = false;
                        CommonDialogs.showError(err);
                    });
                }
            });
    }

    shouldShowOfferDialog(offerId: String): Promise<Boolean> {
        return new Promise((resolve) => {
            Api.post("portal/carrier/erate_confirmation", { offer_id: offerId }).then(response => {
                resolve(response.data[0]?.is_erate);
            });
        })
    }

    async getPdfData(modelRow: ModelRow, legalTerm: String, orderConf: String) {
        const orderId = modelRow?.get("order_id");
        const movementId = modelRow?.get("movement_id");

        let imgTerms;
        let imgConf;
        const props = UrlUtil.getPropsFromUrl(document.location.search);
        const responses = await Promise.all([legalTerm === "Y" && Api.post("portal/carrier/legal_terms"), orderConf === "Y"
            && Api.post("portal/carrier/order_confirmation", { order_id: orderId, movement_id: movementId, token: props.token })]);
        if (responses != null && responses.length > 0) {
            const layout = Layout.getLayout("portal-carrier/offer/CarrierOfferAcceptPdfDialog") as CarrierOfferAcceptPdfDialog;
            const dialog = CommonDialogs.createDialog(layout, { fillHeight: true, minWidth: 800, title: "Offer Details", okVisible: false });
            dialog.show();
            layout.addLayoutLoadListener(() => {
                const acceptButton = (layout.buttonAccept as Button);
                (layout.buttonCancel as Button).addClickListener(() => { dialog.close(); });
                acceptButton.addClickListener(() => { this.acceptOfferConfirmationDialog(acceptButton, modelRow, dialog) });

                if (responses[0] !== false) {
                    imgTerms = new Image({ imageType: ImageType.PDF, fillHeight: true, minWidth: 700, id: "imageLegalTerms" });
                    imgTerms.imageBytes = responses[0].data[0].legal_terms;
                    (layout.panelPdf as Panel).add(imgTerms);
                }
                if (responses[1] !== false) {
                    imgConf = new Image({ imageType: ImageType.PDF, fillHeight: true, minWidth: 700, id: "imageOrderConf" });
                    imgConf.imageBytes = responses[1].data[0].rate_confirmation;
                    (layout.panelPdf as Panel).add(imgConf);
                }
                if (imgTerms != null && !StringUtil.isEmptyString(imgTerms.imageBytes))
                    (layout.buttonDownloadTerms as Button).addClickListener(() => {
                        FileUtil.downloadBase64AsFile(responses[0].data[0].legal_terms, `LegalTerms ${orderId}.pdf`);
                    })
                else
                    (layout.buttonDownloadTerms as Button).visible = false;
                (layout.buttonDownloadRateConfirmation as Button).visible = false;
            });
        }
    }

    _errorHandler: ErrorHandlerFunction = (error) => {
        Navigation.navigateTo("");
    }
}
