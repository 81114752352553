import { ArrayUtil, DynamicLoader } from "@mcleod/core";
import { ValidationResult } from "../../base/ValidationResult";
import { DataSourceMode } from "../../databinding/DataSource";
import { Textbox } from "./Textbox";

export abstract class AbstractInputParser {

    private _displayValue: string;
    private _dataValue: any;

    abstract getDisplayValue(): string;
    abstract getDataValue(): any;
    abstract validate(checkRequired: boolean, showErrors?: boolean): ValidationResult;

    constructor(public textbox: Textbox, displayValue?: string) {
        this._displayValue = displayValue;
    }

    public get displayValue(): string {
        if (this._displayValue == null) {
            if (this.textbox.hasLookupModel() || this.textbox.items != null) {
                this._displayValue = this.textbox.text;
            } else {
                this._displayValue = this.getDisplayValue();
            }
        }
        return this._displayValue ??= "";
    }

    public get dataValue(): any {
        if (this._dataValue == null) {
            if (this.textbox.hasLookupModel()) {
                this._dataValue = AbstractInputParser.getLookupModelResultData(this.textbox);
            } else if (this.textbox.items != null) {
                this._dataValue = this.textbox?.selectedItem?.value;
            } else {
                this._dataValue = this.getDataValue();
            }
        }
        return this._dataValue;
    }

    static getLookupModelResultData(textbox: Textbox): any {
        const { lookupModelData, lookupModelResultField } = textbox;
        if (ArrayUtil.isEmptyArray(lookupModelData))
            return null;
        else if (lookupModelData.length === 1)
            return lookupModelData[0].get(lookupModelResultField);
        let prefix = "";
        if (textbox.inDataSourceMode(DataSourceMode.SEARCH, DataSourceMode.NONE)) {
            prefix += "in ";
        }
        const resultString = lookupModelData.map(row => row.get(lookupModelResultField)).join(",");
        return prefix + resultString;
    }

    static createParser(textbox: Textbox, displayValue?: string): AbstractInputParser {
        if (textbox.inDataSourceMode(DataSourceMode.SEARCH)) {
            const parserClass = DynamicLoader.getClassForPath("components/components/textbox/SearchInputParser");
            return new parserClass(textbox);
        } else {
            const parserClass = DynamicLoader.getClassForPath("components/components/textbox/InputParser");
            return new parserClass(textbox, displayValue);
        }
    }
}
