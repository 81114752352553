import { Component, Container, DragEvent, DragListener } from "@mcleod/components";
import { DropTargetPanel } from "@mcleod/components/src/components/panel/DropTargetPanel";
import { AbstractUIDesigner } from "./AbstractUIDesigner";
import { DesignerTool } from "./DesignerTool";
import { doDesignerAction } from "./UIDesignerActionHistory";
import { ActionComponentDrop } from "./actions/ActionComponentDrop";
import { ActionDropWithinParent } from "./actions/ActionDropWithinParent";

interface DropTarget { component: Component, container: Container, index?: number }

export class DragAndDropHandler {
    private _dragStartListener: DragListener;
    private _dragEnterListener: DragListener;
    private _dragOverListener: DragListener;
    private _dropListener: DragListener;
    private _dragEndListener: DragListener;
    private designer: AbstractUIDesigner;
    private dragComponent: Component;
    private _selectedComponent: Component;
    private dropTarget: DropTarget;

    constructor(designer: AbstractUIDesigner) {
        this.designer = designer;
    }

    set selectedComponent(value: Component) {
        if (this.selectedComponent != null)
            this.selectedComponent.getDragTarget().draggable = false;
        if (this.canDragComp(value)) {
            value.getDragTarget().draggable = true;
            this._selectedComponent = value;
        } else {
            this._selectedComponent = null
        }
    }

    get selectedComponent(): Component {
        return this._selectedComponent
    }

    componentSelected() {
        if (this.designer.selectedLayoutComponents.length == 1)
            this.selectedComponent = this.designer.firstSelectedLayoutComponent;
        else
            this.selectedComponent = null;
    }

    private canDragComp(comp: any): boolean {
        return comp instanceof Component &&
            comp.deserialized == true &&
            comp.getDragTarget() != null &&
            (!this.designer.isDesignerContainer(comp) || comp.serializationName == "panel") &&
            this.designer.isActiveLayoutComponent(comp)
    }

    get dragStartListener(): DragListener {
        if (this._dragStartListener == null)
            this._dragStartListener = (event: DragEvent) => this.dragComponent = event.target as Component;
        return this._dragStartListener;
    }

    get dragEnterListener(): DragListener {
        if (this._dragEnterListener == null) {
            this._dragEnterListener = (event: DragEvent) => {
                this.setDropTarget(event);
                if (this.dragComponent != null && this.dropTarget != null)
                    event.preventDefault();
            }
        }
        return this._dragEnterListener;
    }

    get dragOverListener(): DragListener {
        if (this._dragOverListener == null) {
            this._dragOverListener = (event: DragEvent) => {
                if (this.dropTarget != null)
                    event.preventDefault();
            }
        }
        return this._dragOverListener;
    }


    get dropListener(): DragListener {
        if (this._dropListener == null)
            this._dropListener = (event: DragEvent) => this.componentDropped(event);
        return this._dropListener;
    }

    get dragEndListener(): DragListener {
        if (this._dragEndListener == null) {
            this._dragEndListener = (event: DragEvent) => {
                this.dropTarget = null;
                this.dragComponent = null;
            };
        }
        return this._dragEndListener;
    }

    private setDropTarget(event: DragEvent) {
        this.dropTarget = null;
        const targetComp = this.getTargetComponent(event);
        if (targetComp != null) {
            const target: DropTarget = { component: targetComp, container: targetComp?.parent };
            if (!this.designer.isDesignerContainer(targetComp) && target.container != null)
                target.index = target.container.components.indexOf(target.component)
            else
                target.container = target.component as Container;
            if (this.designer.canAddComponentToContainer(this.dragComponent, target.container))
                this.dropTarget = target;
        }
    }

    private getTargetComponent(event: DragEvent) {
        const comp = event.target as Component;
        if (this.dragComponent == null ||
            comp == this.dragComponent ||
            comp == this.dragComponent.parent ||
            !this.designer.isActiveLayoutComponent(comp))
            return null;
        if (comp instanceof DropTargetPanel)
            return comp.getSpecialDesignerDropTarget();
        return comp;
    }

    addListeners(comp: any) {
        if (comp instanceof Component) {
            comp.addDragStartListener(this.dragStartListener);
            comp.addDragEndListener(this.dragEndListener);
            comp.addDragEnterListener(this.dragEnterListener);
            comp.addDragOverListener(this.dragOverListener);
            comp.addDropListener(this.dropListener);
        }
    }

    private componentDropped(event: DragEvent) {
        if (this.dropTarget != null && this.dragComponent != null) {
            if (this.dragComponent instanceof DesignerTool) {
                this.designer.addToolToContainer(this.dragComponent, this.dropTarget.container, this.dropTarget.index);
            } else {
                // dropped on a component within the same container
                if (this.dropTarget.container == this.dragComponent.parent) {
                    doDesignerAction(this.designer, new ActionDropWithinParent(this.dragComponent, this.dropTarget.index));
                } else if (this.designer.isDesignerContainer(this.dropTarget.container)) {
                    // dropped on a container
                    doDesignerAction(this.designer, new ActionComponentDrop(this.dragComponent, this.dropTarget.container as Container));
                } else {
                    // dropped on a component that is not a container. Insert into targetContainer at a specifiic index
                    doDesignerAction(this.designer, new ActionComponentDrop(this.dragComponent, this.dropTarget.container, this.dropTarget.index));
                }
            }
        }
    }
}
