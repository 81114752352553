import { Textbox } from "../components/textbox/Textbox";
import { DropdownItem } from "../components/textbox/TextboxProps";
import { DomEvent } from "./DomEvent";
import { EventListener } from "./Event";
import { SelectionEvent } from "./SelectionEvent";

export interface DropdownSelectionListener extends EventListener {
    (event: DropdownSelectionEvent): void;
}

export class DropdownSelectionEvent extends SelectionEvent {
    constructor(component: any, newSelection: any, newIndex: number, oldSelection: any, oldIndex: number, domEvent?: DomEvent) {
        super(component, newSelection, newIndex, oldSelection, oldIndex, domEvent);
    }

    public override get target(): Textbox {
        return super.target as Textbox;
    }

    public override get oldSelection(): DropdownItem {
        return super.oldSelection as DropdownItem;
    }

    public override get newSelection(): DropdownItem {
        return super.newSelection as DropdownItem;
    }
}
