import { Button, ChangeEvent, ClickEvent, DataDisplayEvent, DataSourceMode, Label, Layout, Panel, Snackbar, TableRow, TableRowDisplayEvent, TableRowExpansionEvent, Textbox, Toast } from "@mcleod/components";
import { Alignment, Api, ModelRow, StringUtil } from "@mcleod/core";
import { CommonDialogs } from "./CommonDialogs";
import { AutogenLayoutUserList } from "./autogen/AutogenLayoutUserList";
import { UserDevicesGridExpansion } from "./UserDevicesGridExpansion";

export class UserList extends AutogenLayoutUserList {
    override onLoad() {
        this.switchActive.checked = true;
    }

    /** This is an event handler for the onClick event of buttonRevoke.  */
    async buttonRevokeOnClick(_event: ClickEvent) {
        this.doUserAction("auth/revoke", null, this.buttonRevoke, userName => "The login tokens for " + userName + " have been successfully invalidated.");
    }

    /** This is an event handler for the onClick event of buttonUnlock.  */
    async buttonUnlockOnClick(_event: ClickEvent) {
        this.doUserAction("user/lock", "U", this.buttonUnlock, userName => "The account for " + userName + " has been unlocked.");
    }

    /** This is an event handler for the onClick event of buttonLock.  */
    buttonLockOnClick(_event: ClickEvent) {
        this.doUserAction("user/lock", "L", this.buttonLock, userName => "The account for " + userName + " has been locked.");
    }

    private async doUserAction(endpoint: string, lockStatus: string, button: Button, messageCallback: (userName: string) => string) {
        const userRow: ModelRow = this.tableSearch.selectedRow?.data;
        if (userRow == null) {
            button.showTooltip("Select a user first", { shaking: true, position: Alignment.RIGHT });
            return;
        }
        button.busy = true;
        try {
            const args: any = { user_id: userRow.get("id") };
            if (lockStatus != null)
                args.lock_status = lockStatus;
            await Api.post(endpoint, args);
            const user = userRow.get("name", userRow.get("id"));
            if (lockStatus != null) {
                userRow.set("lock_status", lockStatus);
                this.tableSearch.selectedRow.displayComponentValues();
            }
            Snackbar.showSnackbar(messageCallback(user));
        } finally {
            button.busy = false;
        }
    }

    /** This is an event handler for the onDataDisplay event of tableSearch.  */
    onRowExpand(expansionEvent: TableRowExpansionEvent) {
        const tableRow: TableRow = expansionEvent.target as TableRow;
        const expandPanel = expansionEvent.expandComponentParent as Panel;
        const layoutExpanded: UserDevicesGridExpansion = expandPanel.findComponentById("layoutUserDevicesGridExpansion") as UserDevicesGridExpansion
        if (expansionEvent.isExpanding) {
            layoutExpanded.addLayoutDataLoadListener(async () => {
                const userID = await tableRow.data.get("id");
                layoutExpanded.tableDevices.dataSource.search({ user_id: userID });
            })
        }
    }

    /** This is an event handler for the onDataDisplay event of labelLockStatus.  */
    labelLockStatusOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        label.caption = "";
        const lockStatus = event.rowData.get("lock_status");
        if (lockStatus == null || "U" === lockStatus) {
            label.imageName = null;
            label.tooltip = null;
        } else {
            label.imageName = "padlock";
            if (lockStatus === "A")
                label.tooltip = "This user was locked because they entered an incorrect password too many times.";
            else if (lockStatus === "L")
                label.tooltip = "This user was locked manually.";
            else if (lockStatus === "B")
                label.tooltip = "This user was locked because they had suspicious activity.";
        }
    }

    /** This is an event handler for the onClick event of buttonRevokeAll.  */
    async buttonRevokeAllOnClick(event: ClickEvent) {
        const rows = this.tableSearch.filteredRows;
        if (rows.length === 0) {
            Snackbar.showSnackbar("There are no users that match your current filter.");
            return;
        }
        if (!await CommonDialogs.showYesNo("This will log " +
            StringUtil.pluralString(rows.length, "user", "users") +
            " out and invalidate their login " + (rows.length === 1 ? "token" : "tokens") +
            ".  Do you want to continue?"))
            return;
        const button = event.target as Button;
        button.busy = true;
        try {
            await Api.post("auth/revoke", { user_id: rows.map(row => row.data.get("id")) });
            Snackbar.showSnackbar(StringUtil.pluralString(rows.length, " user has ", "users have") +
                " been logged out and their login tokens have been invalidated.");
        } finally {
            button.busy = false;
        }
    }

    /** This is an event handler for the onChange event of switchActive.  */
    switchActiveOnChange(event: ChangeEvent) {
        this.search({is_active: event.newValue ? "Y" : ">"});
    }
}
