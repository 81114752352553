import { Attachment, Button, DataSource, HorizontalSpacer, Layout, Panel, SaveButton, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutOrderHistoryImages extends Layout {
	attachmentImage: Attachment;
	buttonCancel: Button;
	buttonSave: SaveButton;
	buttonSaveAndClose: SaveButton;
	horizontalspacer1: HorizontalSpacer;
	panelImages: Panel;
	sourceOrderHistoryImages: DataSource;
	textboxImageTypes: Textbox;
}
