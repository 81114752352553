import { Collection, DOMUtil, getLogger } from "@mcleod/core";
import { Tabset } from "./Tabset";
import { Panel } from "../panel/Panel";
import { Tab } from "./Tab";

const log = getLogger("components.tabset.ScrollTabsetMutationHandler");
/**
 * This class contains logic around handling height changes to a scrolling tabset.  The goals of this logic are:
 *   1) Maintain a user's scroll position (so they continue viewing the same portion of the page) when a tab is
 *      selected and the user has scrolled down (isn't at the top of the scrollable area), and the content above
 *      the selected tab changes (and becomes a new height).
 *
 *      Example: When opening a movement, the stops list is the last thing that finishes loading.  Previously, if you
 *      selected a tab below the stops list before it finished loading, the extra height added to the scrollable area
 *      would cause you to no longer be looking at the tab you'd selected (you'd be looking at something higher up the
 *      page).  So in this example the mutation observer would adjust the scrollTop of the scrollable area based on any
 *      height added to the page.
 *
 *   2) Help facilitate with automated selection of tab when a screen opens.
 *
 *      Example: After adding a brokerage order, and selecting a next action of 'Find a carrier', the movement is
 *      opened and we automatically select the TopMatch tab.  We want to be able to scroll down to this tab, and also
 *      keep that tab in view if any tabs above the TopMatch tab change height has they are loaded.
 * 
 *   3) Help maintain a user's scroll position if they manually select a tab while a page is being loaded.  This is
 *      effectively the same logic as #2, except the tab selection is initiated by a user click.
 *
 * To manage these scenarios, we monitor mutations to the tabset.  If the user has selected a tab and scrolled down, or
 * if we have already identified the position to which we will scroll (but we just haven't started scrolling yet),
 * then we calculate the new position of the selected tab.  If the position of the selected tab has changed we then
 * adjust the scroll position (the scroll top of the scrollable content area) accordingly.
 *
 * Note that when checking for an adjusted scroll position, we test against a collection of values that holds each
 * tab's offset top value (which is the position at which that tab starts in the scrollable content).  We keep an
 * object that always holds the previous state of this colleciton (so we can see how each mutation affected the
 * position of each tab).  These values are stored in the 'prevScrollTopValues' collection.  But we also can store a
 * second set of these values (in the 'savedScrollTopValues' collection).  That collection holds the values at the
 * point in time that we initiated a scroll event by setting the scroll root's scrollTop value.  We have to keep this
 * second set of values to handle the case where we initiate a scroll, but we don't start scrolling until after more
 * mutations are handled.
 *
 * Also, with regards to cases #2 and #3 above: it is possible for a tab to be selected before the height of each tab
 * (and the overall scrollable content area) is known.  This is accommodated by the 'needsDelayedScrollToTab' variable.
 * When this variable is set, we know that we need to scroll to that tab when the mutation observer sees that we can
 * determine a valid scroll position for that tab.
 */
export class ScrollTabsetMutationHandler {
    private tabset: Tabset;
    private scrollRoot: Panel; //the scrolling content inside the Tabset
    private scrollMutationObserver: MutationObserver;
    private prevScrollRootHeight: number;
    private _needsDelayedScrollToTab: Tab;
    private _scrollingToPosition: number;
    private isScrolling: boolean;
    private prevScrollTopValues: Collection<number>; //see class description above for details
    private savedScrollTopValues: Collection<number>; //see class description above for details

    constructor(tabset: Tabset, scrollRoot: Panel) {
        this.tabset = tabset;
        this.scrollRoot = scrollRoot;
        this.resetScrollTopValues();
        this.setupMutationObserver();
        this.setupScrollStartListener();
        this.setupScrollEndListener();
    }

    get needsDelayedScrollToTab(): Tab {
        return this._needsDelayedScrollToTab;
    }

    set needsDelayedScrollToTab(value: Tab) {
        this._needsDelayedScrollToTab = value;
    }

    get scrollingToPosition(): number {
        return this._scrollingToPosition;
    }

    set scrollingToPosition(value: number) {
        this._scrollingToPosition = value;
        this.savedScrollTopValues = this.generateScrollTopValues();
    }

    private setupScrollStartListener() {
        this.scrollRoot._element.parentElement.onscroll = (event) => {
            log.debug(this.tabset.getLoggerPrefix() + "Scrolling at %o, current position %o", new Date().getTime(), this.tabset.getScrollRootScrollTop());
            if (this.isScrolling !== true && this.savedScrollTopValues != null) {
                log.debug(this.tabset.getLoggerPrefix() + "Just started scrolling, check for updated scroll destination change");
                this.checkForScrollAdjustment(this.savedScrollTopValues);
            }
            this.isScrolling = true;
        };
    }

    private setupScrollEndListener() {
        this.scrollRoot._element.parentElement.onscrollend = (event) => {
            log.debug(this.tabset.getLoggerPrefix() + "Stopped scrolling at %o, current position %o", new Date().getTime(), this.tabset.getScrollRootScrollTop());
            this.isScrolling = false;
            this.scrollingToPosition = null;
        };
    }

    private setupMutationObserver() {
        this.disconnect();
        this.scrollMutationObserver = new MutationObserver((mutations: MutationRecord[], observer: MutationObserver) => {
            log.debug(this.tabset.getLoggerPrefix() + "Tabset observed mutations: %o, currently scrolling: %o", mutations, this.isScrolling);
            this.checkForScrollAdjustment(this.prevScrollTopValues);
        });
        this.scrollMutationObserver.observe(this.scrollRoot._element.parentElement, { childList: true, subtree: true });
    }

    resetScrollTopValues() {
        this.prevScrollTopValues = this.generateScrollTopValues();
        log.debug(this.tabset.getLoggerPrefix() + "Scroll top values have been reset: %o", this.prevScrollTopValues);
    }

    generateScrollTopValues(): Collection<number> {
        const result: Collection<number> = {};
        this.scrollRoot.components.forEach(tab => result[tab.id] = tab._element.offsetTop);
        return result;
    }

    private checkForScrollAdjustment(scrollTopValues: Collection<number>) {
        const newScrollRootHeight = DOMUtil.getElementHeight(this.scrollRoot._element);
        try {
            log.debug(this.tabset.getLoggerPrefix() + "Scroll root height before mutation: %o, after mutation: %o",
                this.prevScrollRootHeight, newScrollRootHeight);
            const selectedTab = this.tabset.getActiveTab();
            if (this.tabset.userHasScrolledDown()) {
                this.needsDelayedScrollToTab = null;
                const selectedTabElement = selectedTab._element;
                const prevScrollTopValue = scrollTopValues[selectedTab.id];
                log.debug(this.tabset.getLoggerPrefix() + "User has scrolled down, selected tab is %o, " +
                    "previous scroll top %o, new scroll top %o",
                    selectedTab, prevScrollTopValue, selectedTabElement.offsetTop);
                const tabDiff = selectedTabElement.offsetTop - prevScrollTopValue;
                log.debug(this.tabset.getLoggerPrefix() + "Selected tab top differs by %o", tabDiff);
                if (tabDiff !== 0) {
                    let newScrollRootScrollTop: number;
                    if (this.scrollingToPosition != null) {
                        newScrollRootScrollTop = this.scrollingToPosition + tabDiff;
                        log.debug(this.tabset.getLoggerPrefix() +
                            "Will scroll to position %o using in progress scroll target %o " +
                            "and selected tab top diff %o",
                            newScrollRootScrollTop, this.scrollingToPosition, tabDiff);
                        this.tabset.scrollToPosition(newScrollRootScrollTop);
                    }
                    else if (Object.keys(scrollTopValues).length !== this.scrollRoot.components.length) {
                        // If the number of tabs changed, adjust the position based on the current scroll position.
                        // During testing, this wasn't necessary if the height of a tab above the selected tab
                        // simply changed height.
                        const tabsetCurrentScrollTop = this.tabset.getScrollRootScrollTop();
                        newScrollRootScrollTop = tabsetCurrentScrollTop + tabDiff;
                        log.debug(this.tabset.getLoggerPrefix() +
                            "Will scroll to position %o using existing tabset scroll root scroll top %o " +
                            "and selected tab top diff %o",
                            newScrollRootScrollTop, tabsetCurrentScrollTop, tabDiff);
                        this.tabset.scrollToPosition(newScrollRootScrollTop);
                    }
                }
            }
            else if (this.needsDelayedScrollToTab != null) {
                log.debug(this.tabset.getLoggerPrefix() + "Tabset needs to process a delayed scroll to tab %o, " +
                    "because we tried to scroll to this tab before had been fully populated and could calculate a " +
                    "scroll position(offsetTop).", this.needsDelayedScrollToTab);
                const tabOffsetTop = this.needsDelayedScrollToTab._element.offsetTop;
                if (tabOffsetTop !== 0) {
                    log.debug(this.tabset.getLoggerPrefix() + "Scroll to tab %o at position %o",
                        this.needsDelayedScrollToTab, tabOffsetTop);
                    this.needsDelayedScrollToTab = null;
                    this.tabset.scrollToPosition(tabOffsetTop);
                }
            }
        }
        finally {
            this.resetScrollTopValues();
            this.prevScrollRootHeight = newScrollRootHeight;
        }
    }

    disconnect() {
        this.scrollMutationObserver?.disconnect();
    }
}