import { ClientRegistration } from "./ClientRegistration";
import { InstanceIdentifier } from "../InstanceIdentifier";
import { CompanySettings } from "../settings/CompanySettings";
import { UserSettings } from "../settings/UserSettings";

export class Client {
    get companyId(): string {
        return CompanySettings.get().id;
    }

    get userName(): string {
        return UserSettings.getUserId() || null;
    }

    get hostName(): string {
        return "Web";
    }

    get vmIdentifier(): string {
        return InstanceIdentifier.get();
    }

    get sessionId(): string {
        return ClientRegistration._getSessionId();
    }

    get type(): string {
        return "WEB_CLIENT";
    }
}
