import { getThemeColor } from "@mcleod/core";
import { Component } from "../base/Component";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../base/ComponentProps";
import { ComponentTypes } from "../base/ComponentTypes";
import { DesignerInterface } from "../base/DesignerInterface";

const horizontalSpacerProps: ComponentPropDefinitions = {
    ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions()
};

horizontalSpacerProps.fillRow.defaultValue = true;
horizontalSpacerProps.rowBreak.defaultValue = false;

/**
 * This is just a convenience class that will occupy the horizontal space in a container.  It's typically
 * used in containers where some content is intended to be left-aligned and other content is intended to be
 * right-aligned.  In that case, the HorizontalSpacer component would be added in between the left- and right-aligned content.
 */
export class HorizontalSpacer extends Component {
    constructor(props?: Partial<ComponentProps>) {
        super("div", props);
        this.setProps({ fillRow: true, rowBreak: false, ...props });
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return horizontalSpacerProps;
    }

    override get serializationName() {
        return "horizontalspacer";
    }

    override get properName(): string {
        return "Horizontal Spacer";
    }

    override set _designer(value: DesignerInterface) {
        super._designer = value
        if (value != null)
            this.style.border = "1px dashed " + getThemeColor("subtle");
    }

    get _designer(): DesignerInterface {
        return super._designer
    }
}

ComponentTypes.registerComponentType("horizontalspacer", HorizontalSpacer.prototype.constructor, true);
