/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCity.ts                          *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "common/city";

export class AutogenModelCity<RowType extends ModelRow> extends Endpoint<RowType, RowCityFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowCity extends ModelRow<RowCityFields> {
	constructor(values?: Partial<RowCityFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowCityFields {
	airport_code: string;
	alk_id: number;
	central_location: string;
	company_id: string;
	county: string;
	id: number;
	inactive: string;
	isi_id: number;
	latitude: number;
	longitude: number;
	name: string;
	pcm_window_city_id: number;
	rmm_id: number;
	state_id: string;
	three_digit_zip: string;
	timezone_id: string;
	zip_code: string;
	zone_id: string;
}

