import { ModelRow } from ".";
import { StringUtil } from "./StringUtil";

interface StopDateType {
    caption: string;
    value: string;
}
export class LocationUtil {

    public static readonly DATE_ON:StopDateType = { caption: "On", value: "O" };
    public static readonly DATE_BETWEEN: StopDateType = { caption: "Between", value: "B" };
    public static readonly PICKUP_STOP_TYPES: string[] = ["PU", "SP"];    
    public static readonly VIA_POINT_STOP_TYPES: string[] = ["VP", "VA", "VN"];

    /**
     * Formats the given locationName, locationId, city, state and zip in a way that is presentable to the user.  This allows for any of those pieces to be null and
     * will still return a presentable string (i.e. there won't be a trailing comma if there is a null state)
     *
     * @param locationName
     * @param locationId
     * @param city
     * @param state
     * @param zip
     * @returns
     */
    public static formatLocation(locationName: string, locationId: string, address: string, city: string, state: string, zip?: string): string {
        let result = "";
        if (!StringUtil.isEmptyString(locationName))
            result += locationName;
        result = LocationUtil.append(result, address, ",");
        result = LocationUtil.append(result, city, ",");
        result = LocationUtil.append(result, state, ",");
        result = LocationUtil.append(result, zip, "");
        if (!StringUtil.isEmptyString(locationId))
            result = LocationUtil.append(result, "(" + locationId + ")", "");
        return result;
    }

    private static append(prefix: string, appendValue: string, delimiter: string): string {
        let result = prefix;
        if (!StringUtil.isEmptyString(appendValue)) {
            if (result.length > 0)
                result += delimiter + " ";
            result += appendValue;
        }
        return result;
    }

    public static getLocationDefaultStopType(rowLocation: ModelRow<any>, stopType: string): StopDateType {
        //If values present, ignore location
        const defaultStopDateType: string = (LocationUtil.PICKUP_STOP_TYPES.includes(stopType) ? rowLocation.get("pickup_date_option") : rowLocation.get("delivery_date_option")) ?? null;
            if (defaultStopDateType) {
                return defaultStopDateType === LocationUtil.DATE_ON.value ? LocationUtil.DATE_ON : LocationUtil.DATE_BETWEEN;
            } else {
                // Default to Between when no option is set for this pickup type
                return LocationUtil.DATE_BETWEEN;
            }
    }
}
