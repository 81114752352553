import {
    Component, DesignableObject, DesignerInterface, Event, Layout, LayoutProps, TableCell
} from "@mcleod/components";
import { ResourceFileProps, DesignerToolAcceptor } from "@mcleod/components/src/base/DesignerInterface";
import { Collection, Navigation, getThemeColor, makeStyles } from "@mcleod/core";
import { designerHandleComponentSelection } from "@mcleod/designer";
import { ModelPermsSecureItem, RowPermsSecureItem } from "../models/ModelPermsSecureItem";
import { PanelPermsGrant } from "./PanelPermsGrant";
import { PermsContainer } from "./PermsContainer";

interface PermissionsEditorProps extends LayoutProps {
    page: string;
}

export const designerClasses = makeStyles("perm", {
    permed: { border: "1px solid " + getThemeColor("error.light"), borderColor: getThemeColor("error.light") + " !important" },
});

export class PermissionsEditor extends Layout implements PermsContainer, DesignerInterface {
    panelContent: Layout;
    panelGrants: PanelPermsGrant;
    selectedComponents: Component[] = [];
    page: string;
    private permsByItem: Collection<RowPermsSecureItem> = {};

    constructor(props?: PermissionsEditorProps) {
        super({ needsServerLayout: false });
        if (props.page == null)
            throw new Error("Cannot edit permissions without the page.");
        this.title = "Permissions Editor";
        this.page = props.page;
        this.panelContent = new Layout({ _designer: this, layoutName: props.page, rowBreak: false, applyFieldLevelPermissions: false });
        this.panelContent.onLoad = () => this.layoutLoaded();
        this.panelGrants = new PanelPermsGrant();
        this.panelGrants.permsContainer = this;
        this.add(this.panelContent, this.panelGrants);
    }


    setPropsForDeserialization(componentType: string, props: any) {
        if (componentType === "layout")
            return { ...props, applyFieldLevelPermissions: false, _designer: this };
        return props;
    }

    addEventHandlerFunction: (component: DesignableObject, eventPropName: string) => void;
    redisplayProp: (propName: string, propValue: any) => void;
    addDesignerContainerProperties: (component: Component, minWidth: number, minHeight: number, width?: number,
        allowDropAcceptor?: DesignerToolAcceptor) => void;

    permsChanged(row: RowPermsSecureItem, deleted: boolean) {
        if (row != null) {
            const comp = this.panelContent[row.get("item_name")];
            if (comp != null)
                comp.setClassIncluded(designerClasses.permed, !deleted);
            const key = row.get("item_name") + ":" + row.get("security_type");
            if (deleted)
                delete this.permsByItem[key];
            else
                this.permsByItem[key] = row;
            Layout.clearCachedLayout(this.page);
        }
    }

    onLoad() {
        new ModelPermsSecureItem().search({ screen_class: this.page }).then(rows => {
            for (const row of rows.modelRows)
                this.permsByItem[row.get("item_name") + ":" + row.get("security_type")] = row;
            this.layoutLoaded();
        });
    }

    layoutLoaded() {
        if (this.permsByItem != null)
            for (const key in this.permsByItem) {
                const compId = key.substring(0, key.indexOf(":"));
                const comp = this.panelContent[compId];
                if (comp != null)
                    comp.setClassIncluded(designerClasses.permed)
            }
    }


    selectComponent(component: DesignableObject, add: boolean = false) {
        const comp = component as Component;
        if (comp instanceof TableCell && !comp.isHeading)
            return;
        designerHandleComponentSelection(this.selectedComponents, comp, false, null);
        if (component == null || comp.id == null) {
            //set item in panelGrants in load listener to ensure panelGrants has finished loading
            this.panelGrants.addLayoutLoadListener((event: Event) => {
                this.panelGrants.setItemBeingEdited(null, null, null, undefined)
            });
        }
        else {
            const perms = [];
            for (const row of Object.values(this.permsByItem))
                if (row.get("item_name") === comp.id)
                    perms.push(row);
            //set item in panelGrants in load listener to ensure panelGrants has finished loading
            this.panelGrants.addLayoutLoadListener((event: Event) => {
                this.panelGrants.setItemBeingEdited(this.page, comp.id, comp.getPermissionsTypes(), perms);
            });
        }
    }

    modified() {

    }

    get allowsDrop(): boolean {
        return false;
    }

    openTab(props: ResourceFileProps): any {
        return Navigation.navigateTo("common/permissions/PermissionsEditor?page=" + props.path, { newTab: true });
    }

    componentDropped(comp: Component) {
        throw new Error("Method not implemented.");
    }
    getActiveTab() {
        throw new Error("Method not implemented.");
    }
    applyChangeToSelectedComponents(data: any, newValue: any): void {
        throw new Error("Method not implemented.");
    }
    displayDataSourceTools(): void {
        throw new Error("Method not implemented.");
    }
}
