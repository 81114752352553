import {
    CurrencyUtil, DateUtil, displaysDateOrTime, DisplayType, isDisplayTypeNumeric, NumberUtil
} from "@mcleod/core";
import { ValidationResult } from "../../base/ValidationResult";
import { AbstractInputParser } from "./AbstractInputParser";
import { TextboxValidator } from "./TextboxValidator";

export class InputParser extends AbstractInputParser {
    dataSource: any;


    getDisplayValue(): string {
        return this.textbox.inputFormatter.formatText();
    }

    getDataValue() {

        if (displaysDateOrTime(this.textbox.displayType, false)) {
            return DateUtil.parseDateByDisplayType(this.displayValue, this.textbox.displayType, this.textbox.timezone);
        }

        if (this.textbox.displayType === DisplayType.CURRENCY) {
            return parseFloat(CurrencyUtil.removeFormatting(this.displayValue));
        }
        else if (isDisplayTypeNumeric(this.textbox.displayType)) {
            return NumberUtil.getFloat(this.displayValue);
        }

        return this.displayValue;
    }

    validate(checkRequired: boolean, showErrors: boolean): ValidationResult {
        return TextboxValidator.validate(this.textbox, checkRequired, showErrors, this.textbox.addlValidationCallback);
    }
}
