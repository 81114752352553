import { ChangeEvent, Component, Label, Layout, Tab } from "@mcleod/components";
import { TableCarrierLoadboard } from "./TableCarrierLoadboard";
import { TableCarrierOfferHistory } from "./TableCarrierOfferHistory";
import { AutogenLayoutCarrierPortal } from "./autogen/AutogenLayoutCarrierPortal";
import { PortalCarrierSettings } from "./settings/PortalCarrierSettings";

export class CarrierPortal extends AutogenLayoutCarrierPortal {

    override onLoad() {
        this.showSelectedTabData();
        if (PortalCarrierSettings.get().loadboard_active === true)
            this.tabLoadboard.visible = true;

        this.tabset.components.forEach((tab: Tab, index) => {
            const tabLayout = tab.components?.[0] as Layout;

            const dataSource = tabLayout?.mainDataSource;
            if (dataSource != null) {
                dataSource.addAfterExecutionListener(() => {
                    if (tab == this.tabset.getActiveTab()) {
                        let countLabel = this.tabset.getActiveTab().heading.findComponentById("labelCount") as Label;
                        if (countLabel == null) {
                            countLabel = new Label({ id: "labelCount", rowBreak: false, imageColor: "primary" });
                            this.tabset.getActiveTab().heading.add(countLabel);
                        }
                        countLabel.caption = `(${dataSource.data?.length ?? 0})`;
                    }
                })
            }
            if (tab != this.tabset.getActiveTab() && tab.heading.findComponentById("labelCount") != null)
                tab.heading.removeAt(1);
        });
    }

    afterTabSelection(event: ChangeEvent) {
        this.showSelectedTabData();
    }

    private showSelectedTabData() {
        const tabLayout = this.tabset.getActiveTab()?.components?.[0] as Layout;
        if (tabLayout instanceof TableCarrierLoadboard) {
            tabLayout.panelFilter.forEveryChildComponent((component: Component) => {
                tabLayout.clearFields(component);
            });
        }
        const loadboardShowB4Search = PortalCarrierSettings.get().loadboard_show_b4_search;
        if (tabLayout?.mainDataSource) {
            // for all layouts except TableCarrierOfferHistory, pass in empty filter so beforeExecutionListeners can modify the filter
            const filter = (tabLayout instanceof TableCarrierOfferHistory) ? null : {}
            if (tabLayout instanceof TableCarrierLoadboard) {
                if (loadboardShowB4Search) {
                    tabLayout.mainDataSource.search(filter)
                }
            } else {
                tabLayout.mainDataSource.search(filter);
            }
        }
    }

    tabsetBeforeTabSelection(event: ChangeEvent) {
        const tab = this.tabset.getActiveTab();
        if (tab.heading.findComponentById("labelCount") != null)
            tab.heading.removeAt(1);
    }
}
