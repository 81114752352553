import { AbstractSettings, AbstractSettingsDef, SettingsManager } from "@mcleod/core";

export interface PortalDriverSettingsDef extends AbstractSettingsDef {
    allow_driver_modification: boolean;
    enable_driver_choice: boolean;
    id: string;
    name: string;
    choice_priority1: string;
    choice_priority1_value: string;
    choice_priority2: string;
    choice_priority2_value: string;
    ship_radius: string;
    ship_city: string;
    trailer_type: string;
}

export class PortalDriverSettings extends AbstractSettings {
    constructor() {
        super("driver_settings");
    }

    public static getSingleton(): PortalDriverSettings {
        return SettingsManager.getSingleton("driver_settings") as PortalDriverSettings;
    }

    public static get(): PortalDriverSettingsDef {
        return PortalDriverSettings.getSingleton().values as PortalDriverSettingsDef;
    }

    public override get values(): PortalDriverSettingsDef {
        return super.values as PortalDriverSettingsDef;
    }

    protected getValuesInternal(): PortalDriverSettingsDef {
        return this._values as PortalDriverSettingsDef;
    }
}
