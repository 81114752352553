export class JSUtil {
    /**
     * This is a tweak on Math.min() except that it will ignore arguments that are not numbers (usually null or undefined).
     *
     * @param numbers
     * @returns
     */
    public static min(...numbers: number[]): number {
        return Math.max(...numbers.filter(e => !isNaN(e)));
    }

    /**
     * This is a tweak on Math.max() except that it will ignore arguments that are not numbers (usually null or undefined).
     *
     * @param numbers
     * @returns
     */
    public static max(...numbers: number[]): number {
        return Math.max(...numbers.filter(e => !isNaN(e)));
    }

    public static numberWithCommas(x: number): string {
        const parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    public static delayPromise(millis: number, fn?: () => any): Promise<any> {
        return new Promise(function (resolve) {
            setTimeout(resolve.bind(null, fn), millis);
        });
    }

    public static appendKeyToString(key: string, targetString: string): string {
        if (key === "Backspace") {
            if (targetString.length <= 1)
                return "";
            else
                return targetString.substring(0, targetString.length - 1);
        }
        if (key.length > 1)
            return targetString;
        return targetString + key;
    }

    /**
     * This is a convenience method to resolve an argument that could either be a property value or a function that could return
     * the property value.
     *
     * For example, we may want a boolean property like Component.visible that determines if a component should be shown.  Most of the
     * time, this property will just either either true or false, but we may want to be flexible enough to allow logic (a function) to determine whether
     * the component will be shown or not.  This makes it easier to support that case.
     *
     * @param prop The property value or function to evaluate
     * @param argsIfFunction If the argument happens to be a function, these are optional arguments to pass to that function.
     * @returns The property value after it has been resolved
     */
    public static evaluateProp(prop: any | (() => any), ...argsIfFunction: any[]): any {
        if (typeof prop === "function")
            return prop(...argsIfFunction);
        else
            return prop;
    }

    /**
     * This is a convenience method to resolve an argument that could either be a property value or a function.  If the argument is a function
     * it can return a Promise that will be resolved.
     *
     * For example, we may want a boolean property like Component.visible that determines if a component should be shown.  Most of the
     * time, this property will just either either true or false, but we may want to be flexible enough to allow logic (a function) to determine whether
     * the component will be shown or not.  Then, assume that function needs to make an api call to determine if it should be shown or not.
     * This makes it easier to support that case.
     *
     * @param prop The property value or function to evaluate
     * @param argsIfFunction If the argument happens to be a function, these are optional arguments to pass to that function.
     * @returns The property value after it has been resolved
     */
    public static async evaluatePropAsync(prop: any | (() => any) | (() => Promise<any>), ...argsIfFunction: any[]): Promise<any> {
        if (typeof prop === "function") {
            const result = prop(...argsIfFunction);
            if (result instanceof Promise)
                return await result;
            else
                return Promise.resolve(result);
        }
        else
            return Promise.resolve(prop);
    }

    /**
     * This function will copy all the members from src into target if those members are undefined in target.
     *
     * @param target
     * @param src
     * @returns the target object (that was passed in) after assigning members from src If target was null, this will return a new object with all the members of src.
     */
    public static assignIfUndefined(src: object, target: object): object {
        if (target == null)
            target = {};
        if (src != null) {
            for (const key in src) {
                const value = target[key];
                if (value === undefined)
                    target[key] = src[key];
            }
        }
        return target;
    }

    public static applyMixins(target: any, mixins: any[]) {
        for (const mixin of mixins)
            JSUtil.applyMixin(target, mixin);
    }

    static applyMixin(target: any, mixin: any) {
        let mixinName = mixin.name;
        const mixinObject = new mixin();
        if (mixinObject.getMixinClassName != null) {
            mixinName = mixinObject.getMixinClassName();
        }
        for (const mixinPropertyName of Object.getOwnPropertyNames(mixin.prototype)) {
            const mixinPropDescriptor = Object.getOwnPropertyDescriptor(mixin.prototype, mixinPropertyName) || Object.create(null);
            JSUtil.mixinProperty(target, mixinName, mixinPropertyName, mixinPropDescriptor);
        }
    }

    static mixinProperty(target: any, mixinName: string, propName: string, propDescriptor: PropertyDescriptor) {
        if (Object.getOwnPropertyDescriptor(target.prototype, propName) == null)
            Object.defineProperty(target.prototype, propName, propDescriptor);
        else {
            const targetMethodName = "_mixin-" + mixinName + "-" + propName;
            Object.defineProperty(target.prototype, targetMethodName, propDescriptor);
        }
    }

    /**
     * Returns a debounced version of the provided function.
     * The provided function will only be called after the provided delay has passed since the last call.
     * @param fn The function to debounce
     * @param delay The debounce delay in milliseconds
     * @returns A debounced version of the provided function
     */
    static debounce<T extends (...args: any[]) => any>(fn: T, delay: number): T {
        let timeoutId: number;
        return function(this: any, ...args: Parameters<T>) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = window.setTimeout(() => {
                fn.apply(this, args)
            }, delay);
        } as T;
    }
}
