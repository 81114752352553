import { ClickEvent, DataSourceExecutionEvent, DataSourceMode, Layout, Snackbar } from "@mcleod/components";
import { Collection, DateUtil, DurationFormat, ModelRow, StringUtil } from "@mcleod/core";
import { ChartLogReaderStat } from "./ChartLogReaderStat";
import { LogReaderFileDrop } from "./LogReaderFileDrop";
import { LogReaderLogFileTextbox } from "./LogReaderLogFileTextbox";
import { AutogenLayoutLogReader } from "./autogen/AutogenLayoutLogReader";
import { RequestDetail } from "./RequestDetail";

export class LogReader extends AutogenLayoutLogReader {
    private logData: ModelRow;
    private chartStat: ChartLogReaderStat;
    private requestDetail: RequestDetail;

    onLoad() {
        LogReaderFileDrop.intitializeDragDropArea(this.panelDragLog, this.textLogFile, () => this.sourceLogReader.search());
        LogReaderLogFileTextbox.initializeFileNameTextbox(this.textLogFile);
        this.chartDBExecByTime.xAxisProps.title = { text: "Execution time(ms)", display: true };
        this.chartDBExecByTime.yAxisProps.title = { text: "Number of statements", display: true };
        this.chartStat = this.layoutChartStat as ChartLogReaderStat;
        this.chartStat.detailContainer = this.panelResults;
        this.buttonReadLog.clicked();
        this.requestDetail = Layout.getLayout("common/logreader/RequestDetail", {
            id: "requestDetail",
            fillRow: true, 
            width: "100%",
            maxWidth: 1900,
            minWidth: 1200
        }) as RequestDetail;
    }

    /** This is an event handler for the onDisplay event of sourceLogReader.  */
    sourceLogReaderAfterExecution(event: DataSourceExecutionEvent) {
        const durationFormat = new DurationFormat()
            .dayFormat({ suffix: "d", separator: " " })
            .hourFormat({ suffix: "h", separator: " " })
            .minuteFormat({ suffix: "m" });
        this.panelResults.visible = true;
        this.panelCharts.expand();
        [this.textLogSpan, this.textTotalTime, this.textLogCount, this.textDatabaseTime,
        this.textDatabaseCount, this.textThreadCount].forEach(textbox => textbox.text = "");
        this.logData = event.dataSource.data[0];
        this.chartStat.detailContainer = this.checkShowFullLog.checked ? null : this.panelResults;  
        if (this.checkShowFullLog.checked) {
            this.requestDetail.logFileName = this.textLogFile.selectedItem?.value;
            if (!this.panelResults.contains(this.requestDetail))
                this.panelResults.add(this.requestDetail);
            const filter = this.sourceLogReader.getDataboundValues(DataSourceMode.SEARCH, false, true);
            console.log("Filter", filter, "Data", filter?.data);
            this.requestDetail.searchWithFilter(filter?.data);
        } else
            this.panelResults.remove(this.requestDetail);
        this.chartStat.logFileName = this.textLogFile.selectedItem?.value;
        this.chartStat.data = this.logData;
        this.chartDBExecByTime.displayData(null, [], 0);
        if (this.logData == null)
            return;
        const startMillis = this.logData.get("start_millis");
        const endMillis = this.logData.get("end_millis");
        this.textLogSpan.text = DateUtil.formatDateTime(new Date(startMillis), "MM/dd HH:mm")
            + " - " + DateUtil.formatDateTime(new Date(endMillis), "MM/dd HH:mm")
            + " (" + durationFormat.format(endMillis - startMillis) + ")";
        const statsTotal = this.logData.get("stats_total");
        this.textTotalTime.text = durationFormat.format(statsTotal["requestTime"]);
        this.textLogCount.text = statsTotal["count"].toLocaleString();
        this.textDatabaseTime.text = durationFormat.format(statsTotal["dbTime"]);
        this.textDatabaseCount.text = statsTotal["dbCount"].toLocaleString();
        this.textThreadCount.text = this.logData.get("stats_by_thread")?.length;
        this.displayStatementsByExecutionTime(this.logData.get("statements_by_execution_time"));
    }

    private displayStatementsByExecutionTime(stmtsByExecTime: Collection<number>) {
        const rows = [] as ModelRow[];
        let start = 0;
        for (const [threshold, value] of Object.entries(stmtsByExecTime)) {
            if (start > 0) {
                const label = threshold.length < 8 ? start + " - " + threshold + "ms" : start + "+ ms";
                rows.push(new ModelRow(null).setValues({ label, value }));
            }
            start = parseInt(threshold) + 1;
        }
        this.chartDBExecByTime.displayData(rows[0], rows, 0);
    }

    /** This is an event handler for the onClick event of chartDBExecByTime.  */
    chartDBExecByTimeOnClick(event: ClickEvent) {
        const points = this.chartDBExecByTime.getDatasetPointsForEvent(event);
        if (points?.length > 0) {
            const index = points[0].index;
            const stmtsByExecTime = this.logData.get("statements_by_execution_time");
            // need to display some data that shows the statements in the time bucket selected
        }
    }

    /** This is an event handler for the onClick event of buttonCollapseCharts.  */
    buttonCollapseChartsOnClick(event: ClickEvent) {
        this.panelCharts.toggleCollapsed();
    }

/** This is an event handler for the beforeExecution event of sourceLogReader. */
  sourceLogReaderBeforeExecution(event: DataSourceExecutionEvent) {
    if (this.checkShowFullLog.checked &&  
        StringUtil.isEmptyString(this.textStartDate.valueAsString) &&
        StringUtil.isEmptyString(this.textEndDate.valueAsString) && 
        StringUtil.isEmptyString(this.textUser.valueAsString) && 
        StringUtil.isEmptyString(this.textFilterText.valueAsString)) {
            Snackbar.showWarningSnackbar("When showing the unfiltered log, you need to filter by start date, end date, user, or text.");
            event.preventDefault();
        }
  }
}
