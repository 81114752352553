import { DOMUtil, getLogger } from "@mcleod/core";
import { Component } from "./Component";

const log = getLogger("components.base.ComponentUtil");
export type CompOrElementOrRect = Element | Component | DOMRect;

export class ComponentUtil {
    /**
     * Finds the most visible component in a vertically scrollable area, based on a threshold percentage (that is typically provided).  This is done by:
     * - Finding the percentage of vertical space that is visible for each component.
     * - If the first component is completely visible, return that component.
     * - If the last component is completely visible, return that component.
     * - If the threshold percentage was supplied:
     *     - If the first component's visibility percentage exceeds the threshold, return that component.
     *     - If the last component's visibility percentage exceeds the threshold, return that component.
     * - Find the most visible component, and return it.
     * @param threshold
     * Can be a decimal number between 0 and 1, which represents a percentage.  See above description for details about how this param impacts the result when specified.
     */
    public static getFirstMostVisibleComponent(components: Component[], outerComponent: Component, threshold?: number): Component {
        if (components == null || components.length === 0) {
            return null;
        }

        const visibilityPercentages: number[] = [];
        for (const component of components) {
            const visibilityPercent = DOMUtil.getPercentVerticallyVisible(component.element, outerComponent.element);
            visibilityPercentages.push(visibilityPercent);
            log.debug(() => [component.element.id, "visibility:", visibilityPercent]);
        }

        if (visibilityPercentages[0] === 1) {
            log.debug(() => ["the first component:", components[0].element.id, "is completely visible"]);
            return components[0];
        }

        if (visibilityPercentages[visibilityPercentages.length - 1] === 1) {
            log.debug(() => ["the last component:", components[components.length - 1].element.id, "is completely visible"]);
            return components[components.length - 1];
        }

        if (threshold != null) {
            const firstComponentVisibility = visibilityPercentages[0];
            if (firstComponentVisibility > threshold) {
                const component = components[0];
                log.debug(() => ["the first component:", component.element.id, "exceeds the threshold of", threshold, "with a visibility of:", firstComponentVisibility]);
                return component;
            }

            const lastComponentVisibility = visibilityPercentages[visibilityPercentages.length - 1];
            if (lastComponentVisibility > threshold) {
                const component = components[components.length - 1];
                log.debug(() => ["the last component:", component.element.id, "exceeds the threshold of", threshold, "with a visibility of:", lastComponentVisibility]);
                return component;
            }
        }

        let mostVisible = null;
        let mostVisiblePercent: number = 0;
        for (let x = 0; x < components.length; x++) {
            const component = components[x];
            const visibilityPercent = visibilityPercentages[x];

            if (visibilityPercent > mostVisiblePercent) {
                mostVisible = component;
                mostVisiblePercent = visibilityPercent;
            }
        }
        log.debug(() => ["the first, most visible component is:", mostVisible?.element.id, "visibility:", mostVisiblePercent]);
        return mostVisible;
    }

    public static getRect(compOrElementOrRect: CompOrElementOrRect) {
        if (compOrElementOrRect instanceof Component)
            // in some (or maybe all) cases, we need to get the first child instead
            // const elem = element.childNodes[0] as HTMLElement;
            return compOrElementOrRect._element.getBoundingClientRect();
        else if (compOrElementOrRect instanceof Element)
            return compOrElementOrRect.getBoundingClientRect();
        else
            return compOrElementOrRect;
    }

    /**
     * Given a raw DOM Element, returns the McLeod Component that contains it.
     */
    public static getComponentForElement(element: Element): Component {
        const result = element["_mcleodComponent"];
        if (result != null) {
            return result;
        }
        const parent = element.parentElement;
        return parent == null ? null : ComponentUtil.getComponentForElement(parent);
    }

    /**
     * Returns the McLeod Component that currently has focus, or null if no Component has focus 
     * (or if the focused Element is not contained within a McLeod Component).
     */
    public static getFocusedComponent(): Component {
        const focusedElement = document.activeElement;
        if (focusedElement == null) {
            return null;
        }
        return ComponentUtil.getComponentForElement(focusedElement);
    }
}
