import { UIDesignerTailorProvider } from "@mcleod/common";

export class PortalUIDesignerTailorProvider extends UIDesignerTailorProvider {
    public override getTailorOverrideTitle(): string {
        return "Web Portals - McLeod Tailor";
    }

    public override tailorShowsMenuBasedOpenLayout(): boolean {
        return false;
    }
}
