import { DOMUtil, FileUtil, HorizontalAlignment, JSUtil, Model, ModelRow, StringUtil, VerticalAlignment, getLogger } from "@mcleod/core";
import { BorderType } from "../../base/BorderType";
import { getCurrentDataSourceMode } from "../../base/ComponentDataLink";
import { ComponentPropDefinition, ComponentPropDefinitions } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { Printable, printableListenerDef } from "../../base/PrintableComponent";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { DataSourceMode } from "../../databinding/DataSource";
import { DomDragEvent } from "../../events/DomEvent";
import { DragEvent } from "../../events/DragEvent";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { CompoundComponent } from "../compound/CompoundComponent";
import { Image } from "../image/Image";
import { ImageType } from "../image/ImageType";
import { Label } from "../label/Label";
import { AttachmentFileType } from "./AttachmentFileType";
import { AttachmentPropDefinitions, AttachmentProps } from "./AttachmentProps";
import { ImageViewer } from "./ImageViewer";
import { ListenerListDef } from "../../base/ListenerListDef";
import { FileUploadEvent, FileUploadListener } from "../../events/FileUploadEvent";
import { FileRemovalEvent, FileRemovalListener } from "../../events/FileRemovalEvent";
import { ReflectiveSnackbars } from "../../base/ReflectiveSnackbars";
import { PrintableEvent, PrintableListener } from "../../events/PrintableEvent";

const log = getLogger("components.Attachment");

const _enabledBorderProps = {
    borderWidth: 2,
    borderType: BorderType.DASHED,
    borderRadius: 4
}

const _disabledBorderProps = {
    borderWidth: 0,
    borderType: null,
    borderRadius: 4
}
const _fileRemovalListenerDef: ListenerListDef = { listName: "_fileRemovalListeners" };
const _fileUploadListenerDef: ListenerListDef = { listName: "_fileUploadListeners" };

export class Attachment extends CompoundComponent implements AttachmentProps {
    allowedFileTypes: AttachmentFileType[];
    fileNameField: string;
    fileName: string;
    fileBase64: string;
    attachmentId: string;
    idField: string;
    private _maxSize: number;
    displayThumbnail: boolean;
    public validationCallback: (file: File) => string;

    private _caption: string;
    private _loading: boolean;
    private _loadMessage: string;
    private enterCount: number = 0;
    private _labelCaption: Label;
    private _buttonRemove: Button;
    private _svgImage: Image;
    private _thumbnail: Image;
    private _showSVG: boolean;
    private _printLabel: Label;
    private _nonRemoveClickListener: (event) => void;
    private _removeClickListener: (event) => void;

    constructor(props?: Partial<AttachmentProps>) {
        super(props, false);
        this._nonRemoveClickListener = event => this.imageClicked();
        this._removeClickListener = event => this.removeAttachment();
        this._svgImage = new Image({ fillRow: true, fillHeight: true, name: "designer/attachment", maxHeight: 100, align: HorizontalAlignment.CENTER });
        this._svgImage.addClickListener(this._nonRemoveClickListener);
        this._thumbnail = new Image({ imageType: ImageType.IMG, height: 180, fillRow: true, align: HorizontalAlignment.CENTER, preserveAspectRatio: true });
        this._thumbnail.addClickListener(this._nonRemoveClickListener);
        this._labelCaption = new Label({ fillRow: true, fontSize: "large", align: HorizontalAlignment.CENTER, rowBreak: false, allowSelect: false });
        this._labelCaption.addClickListener(this._nonRemoveClickListener);
        this._printLabel = new Label({ fillRow: true, fontSize: "large", align: HorizontalAlignment.CENTER, rowBreak: false, allowSelect: false, visible: false });
        this._printLabel.addClickListener(this._nonRemoveClickListener);
        this._buttonRemove = new Button({ variant: ButtonVariant.round, imageName: "x", visible: false, cursor: null, margin: 0 });
        this._buttonRemove.addClickListener(this._removeClickListener);
        this.add(this._svgImage, this._labelCaption, this._buttonRemove, this._printLabel);
        this.printableDuringSearch = true;
        this._shouldAddDesignerContainerProperties = false;
        this.setProps({
            ..._enabledBorderProps,
            color: "primary",
            padding: 8,
            verticalAlign: VerticalAlignment.CENTER,
            ...props
        });
        this._syncDisplay();
    }

    _afterDeserialize() {
        if (this._designer == null) {
            this.addDragEnterListener(event => this.dragEnter());
            this.addDragOverListener(event => this.dragOver(event));
            this.addDragLeaveListener(event => this.dragLeave());
            this.addDropListener(event => this.dropped(event));
        }
    }

    dragEnter() {
        if (!this.printable && this.enabled === true) {
            this.backgroundColor = "background4";
            this.color = "primary.light";
            this.enterCount++;
        }
    }

    dragOver(event: DragEvent) {
        if (!this.printable && this.enabled === true)
            event.consume();
    }

    dragLeave() {
        if (!this.printable && this.enabled === true) {
            this.enterCount--;
            if (this.enterCount <= 0)
                this.resetMouseOver();
        }
    }

    dropped(event: DragEvent) {
        if (!this.printable && this.enabled === true) {
            event.consume();
            const files = DOMUtil.getDraggedFiles(event.domEvent as DomDragEvent);
            this.resetMouseOver();
            if (files.length > 0)
                this.setAttachmentWithUI(files[0]);
        }
    }

    async setAttachmentWithUI(file: File) {
        let invalidAttachmentMessage: string = null;
        if (this.validationCallback != null)
            invalidAttachmentMessage = this.validationCallback(file);
        if (StringUtil.isEmptyString(invalidAttachmentMessage) === true) {
            const maxSize = this.maxSize;
            if (maxSize != null && file.size > maxSize)
                invalidAttachmentMessage = "The file is too big to attach. The file must be smaller than " + this.maxSize / (1024 * 1024) + "MB.";
        }
        if (StringUtil.isEmptyString(invalidAttachmentMessage) === true) {
            this.setFile(file);
            this.fireListeners(_fileUploadListenerDef, new FileUploadEvent(this, file));
        }
        else
            this.showTooltip(invalidAttachmentMessage, { shaking: true, timeout: 5000 });
    }

    get maxSize(): number {
        return this._maxSize == null ? this._boundField?.length : this._maxSize;
    }

    set maxSize(value: number) {
        this._maxSize = value;
    }

    removeAttachment() {
        if (this.enabled === true) {
            const fileName = this.fileName;
            this.fileName = null;
            this.fileBase64 = null;
            this.updateIfBound();
            this._syncDisplay();
            this.fireFileRemovalListeners(new FileRemovalEvent(this, fileName));
        }
    }

    setFile(file: File) {
        this.fileName = file.name;
        const reader = new FileReader();
        this.loading = true;
        this._loadMessage = null;
        this.fileBase64 = null;
        reader.readAsDataURL(file);
        this._syncDisplay();
        reader.onload = () => {
            let base64 = reader.result as string;
            base64 = StringUtil.stringAfter(base64, ",");
            this.fileBase64 = base64;
            this.loading = false;
            this.updateIfBound();
            this._syncDisplay();
        };
        reader.onerror = (error) => {
            this._loadMessage = "Error loading " + file.name;
            this.loading = false;
            this._syncDisplay();
        };
    }

    private resetMouseOver() {
        this.backgroundColor = null;
        this.color = "subtle.light";
        this.enterCount = 0;
    }

    override _fieldBindingChanged() {
        super._fieldBindingChanged();
        this.updateThumbnailIfApplicable();
    }

    private imageClicked() {
        if (this.fileBase64 == null) {
            if (this.fileName == null) {
                if (!this.printable && this.enabled === true) {
                    FileUtil.showOpenFileDialog().then(file => {
                        if (file != null)
                            this.setAttachmentWithUI(file);
                    });
                }
            }
            else
                this.fetchBinaryDynamically(true);
        }
        else
            this.viewOrDownload();
    }

    private viewOrDownload(): void {
        if (this.imageEligibleToView())
            ImageViewer.viewImage(this.fileBase64, this.fileName);
        else {
            FileUtil.downloadBase64AsFile(this.fileBase64, this.fileName);
            ReflectiveSnackbars.showSnackbar("Downloaded " + this.fileName);
        }
    }

    fetchBinaryDynamically(shouldDownload: boolean) {
        if (this._boundField == null)
            return;
        this.loading = true;
        this._loadMessage = "Downloading...";
        this._syncDisplay();
        if (this._boundField.tableName == null) {
            log.error("Cannot fetch binary for attachment because the table name is null.");
            return;
        }
        Model.searchSingleRecord("lib/blob-detail", { table_name: this._boundField.tableName, field_name: this.field, id: this.attachmentId }).then(row => {
            this.loading = false;
            this._loadMessage = null;
            this.fileBase64 = row.get("binary");
            this._syncDisplay();
            if (shouldDownload)
                this.viewOrDownload();
        }).catch(error => {
            this.loading = false;
            this._loadMessage = null;
            this._syncDisplay();
            ReflectiveDialogs.showError(error);
        });
    }

    override displayData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        if (data == null) {
            this.fileName = null;
            this.fileBase64 = null;
            this.attachmentId = null;
        } else {
            if (this.fileNameField != null)
                this.fileName = data.get(this.fileNameField);
            if (this.field != null)
                this.fileBase64 = data.get(this.field);
            if (this.idField != null)
                this.attachmentId = data.get(this.idField);
            // this.updateThumbnailIfApplicable();
        }
        this._syncDisplay();
    }

    private updateThumbnailIfApplicable() {
        if (this.displayThumbnail === true && this.isThumbnailAvailableForFileType())
            this.fetchBinaryDynamically(false);
    }

    updateIfBound() {
        if (this._boundField != null) {
            let row = null;
            if (this.dataSource != null && this.dataSource.activeRow != null)
                row = this.dataSource.activeRow;
            else if (this.boundRow != null)
                row = this.boundRow;
            if (row != null)
                this.updateBoundData(row, DataSourceMode.UPDATE);
        }
    }

    updateBoundData(data: ModelRow, mode: DataSourceMode) {
        if (this.fileNameField != null && mode !== DataSourceMode.SEARCH)
            data.set(this.fileNameField, this.fileName, this);
        if (this.field != null)
            data.set(this.field, this.fileBase64, this);
    }

    _serializeNonProps(): string {
        return "";
    }

    get caption(): string {
        return this._caption;
    }

    set caption(value: string) {
        this._caption = value;
        this._syncDisplay();
    }

    set showSVG(value: boolean) {
        this._showSVG = value;
        this._syncDisplay();
    }

    get showSVG(): boolean {
        return this._showSVG;
    }

    get loading() {
        return this._loading == null ? false : this.loading;
    }

    set loading(value: boolean) {
        this._loading = value;
        this._labelCaption.imageProps = { rotate: value === true };
        this._labelCaption.imageName = this._loading === true ? "spinner" : null;
    }

    _syncDisplay() {
        this._labelCaption.caption = this._loadMessage || this.fileName || this._caption || (this.enabled === true ? "Add Attachment" : null);
        this._printLabel.caption = this._loadMessage || this.fileName || this._caption || "";
        this.addRemovePrintLabelClickListener();
        this._buttonRemove.visible = (this.fileName != null || this.fileBase64 != null) && this.enabled === true && this.printable !== true;
        this._svgImage.visible = this._showSVG === true && getCurrentDataSourceMode(this) !== DataSourceMode.SEARCH ? true : false;
        if (this._svgImage.visible) {
            if (this.fileBase64 != null && this.isThumbnailAvailableForFileType()) {
                this._thumbnail.imageBytes = this.fileBase64;
                this.setThumbnailVisible(this.displayThumbnail);
            } else if (this.fileName == null) {
                this._svgImage.name = "designer/attachment";
                this.setThumbnailVisible(false);
            } else {
                this._svgImage.name = "document";
                this.setThumbnailVisible(false);
            }
        }
    }

    private isThumbnailAvailableForFileType() {
        if (this.fileBase64 != null && this.fileName == null)
            return true;
        else if (this.fileName == null)
            return false;
        const fileName = this.fileName.toLowerCase();
        return fileName.endsWith(".jpg") || fileName.endsWith(".png") || fileName.endsWith(".gif");
    }

    private imageEligibleToView() {
        return this.fileBase64 != null && this.fileName != null && ImageViewer.eligibleToView(this.fileName);
    }

    setThumbnailVisible(value: boolean) {
        if (value && !this.contains(this._thumbnail)) {
            this.replace(this._svgImage, this._thumbnail);
        } else if (!value && !this.contains(this._svgImage))
            this.replace(this._thumbnail, this._svgImage);
    }

    override setProps(props: Partial<AttachmentProps>) {
        super.setProps(props);
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return AttachmentPropDefinitions.getDefinitions();
    }

    override getPropertyDefaultValue(prop: ComponentPropDefinition): any {
        if (prop.name === "maxSize")
            return this._maxSize == null ? this._boundField?.length : this._maxSize;
        if (this["printable"] === true || this["printableDuringSearch"] == true) {
            if (prop.name === "minHeight")
                return 32;
            else if (prop.name === "marginTop")
                return 4;
        }

        return super.getPropertyDefaultValue(prop);
    }

    override getFieldNames(): string[] {
        const result = [];
        if (this.fileNameField != null)
            result.push(this.fileNameField);
        return result;
    }

    override get serializationName() {
        return "attachment";
    }

    override get properName(): string {
        return "Attachment";
    }

    get printable(): boolean {
        return this["_mixin-Printable-printable"];
    }

    set printable(value: boolean) {
        this["_mixin-Printable-printable"] = value;
    }

    get printableDuringAdd(): boolean {
        return this["_mixin-Printable-printableDuringAdd"];
    }

    set printableDuringAdd(value: boolean) {
        this["_mixin-Printable-printableDuringAdd"] = value;
    }

    get printableDuringSearch(): boolean {
        return this["_mixin-Printable-printableDuringSearch"];
    }

    set printableDuringSearch(value: boolean) {
        this["_mixin-Printable-printableDuringSearch"] = value;
    }

    get printableDuringUpdate(): boolean {
        return this["_mixin-Printable-printableDuringUpdate"];
    }

    set printableDuringUpdate(value: boolean) {
        this["_mixin-Printable-printableDuringUpdate"] = value;
    }

    override getBasicValue(): any {
        return this.fileName;
    }

    override dataSourceModeChanged(mode: DataSourceMode) {
        super.dataSourceModeChanged(mode);
        this["_syncPrintable"]();
    }

    override _applyEnabled(value: boolean) {
        if (this.printable === true)
            return;
        if (value === true)
            this.setProps({ ..._enabledBorderProps });
        else
            this.setProps({ ..._disabledBorderProps });
    }

    protected _applyPrintable(value: boolean) {
        if (value === true) {
            this.setProps({ ..._disabledBorderProps });
            this._configureImageListeners(false);
            this._labelCaption.visible = false;
            this._labelCaption.removeClickListener(this._nonRemoveClickListener);
            this._printLabel.visible = true;
            this.addRemovePrintLabelClickListener();
            this._buttonRemove.removeClickListener(this._removeClickListener);
        }
        else {
            this.setProps({ ..._enabledBorderProps });
            this._configureImageListeners(true);
            this._labelCaption.visible = true;
            this._labelCaption.addClickListener(this._nonRemoveClickListener);
            this._printLabel.visible = false;
            this._printLabel.removeClickListener(this._nonRemoveClickListener);
            this._buttonRemove.addClickListener(this._removeClickListener);
        }
        this.marginTop = 4;
        this.minHeight = 32;
        this._syncDisplay();
        this._syncEnabled();
        this.fireListeners(printableListenerDef, new PrintableEvent(this));
    }

    private addRemovePrintLabelClickListener() {
        if (!StringUtil.isEmptyString(this._printLabel.caption))
            this._printLabel.addClickListener(this._nonRemoveClickListener);
        else
            this._printLabel.removeClickListener(this._nonRemoveClickListener);
    }

    private _configureImageListeners(activate: boolean) {
        if ((activate === false && StringUtil.isEmptyString(this._printLabel.caption)) || getCurrentDataSourceMode(this) === DataSourceMode.SEARCH) {
            this._svgImage.removeClickListener(this._nonRemoveClickListener);
            this._thumbnail.removeClickListener(this._nonRemoveClickListener);
            return;
        }
        this._svgImage.addClickListener(this._nonRemoveClickListener);
        this._thumbnail.addClickListener(this._nonRemoveClickListener);
    }

    addFileRemovalListener(value: FileRemovalListener) {
        this.addEventListener(_fileRemovalListenerDef, value);
    }

    removeFileRemovalListener(value: FileRemovalListener) {
        this.removeEventListener(_fileRemovalListenerDef, value);
    }

    fireFileRemovalListeners(removalEvent: FileRemovalEvent) {
        this.fireListeners(_fileRemovalListenerDef, removalEvent);
    }

    addFileUploadListener(value: FileUploadListener) {
        this.addEventListener(_fileUploadListenerDef, value);
    }

    removeFileUploadListener(value: FileUploadListener) {
        this.removeEventListener(_fileUploadListenerDef, value);
    }

    fireFileUploadListeners(uploadEvent: FileUploadEvent) {
        this.fireListeners(_fileUploadListenerDef, uploadEvent);
    }

    addPrintableListener(value: PrintableListener) {
        this.addEventListener(printableListenerDef, value);
    }

    removePrintableListener(value: PrintableListener) {
        this.removeEventListener(printableListenerDef, value);
    }
}

JSUtil.applyMixins(Attachment, [Printable]);
ComponentTypes.registerComponentType("attachment", Attachment.prototype.constructor, true);
