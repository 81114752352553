import { CommonDialogs } from "@mcleod/common";
import { Button, ChangeEvent, CrudDecorator, DataHeader, DataSourceExecutionEvent, DataSourceMode, DataSourceModeChangeEvent, HorizontalSpacer, Label, Layout, Panel, Step, StepEvent, Table, TableAddRowResult, TableRow, TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { DataSource, DataSourceAction, ModelDataChangeType } from "@mcleod/components/src/databinding/DataSource";
import { Api, DateUtil, FieldUpdateEvent, HorizontalAlignment, ModelRow, Navigation, getLogger } from "@mcleod/core";
import { showCreditSnackbar, userCanEnterLtlQuote, validateRevenuCode } from "./../OrderValidation";
import { getDatesBasedOnMaxDaysOut, setSelectedDay } from "./LtlMaxDaysOut";
import { LtlUtil } from "./LtlUtil";
import { AutogenLayoutLtlQuote } from "./autogen/AutogenLayoutLtlQuote";
import { PortalCustomerSettings } from "../../../customer/src/settings/PortalCustomerSettings";
import { PortalCompanySettings } from "../../../portal-common/src/settings/PortalCompanySettings";

const log = getLogger("portal-customer.ltl.LtlQuote");

const executeButtonProps = { fontSize: "medium", rowBreak: false, backgroundColor: "primary", color: "primary.reverse", width: 250, busyWhenDataSourceBusy: true }
const navigationButtonPros = { fontSize: "medium", width: 125, rowBreak: false, color: "primary" }

export class LtlQuote extends AutogenLayoutLtlQuote {
    private changingStep: boolean = false;
    private clearRates: boolean = false;
    private panelFooter: Panel;
    private selectedRate: ModelRow<any>;
    private buttonGetQuote = new Button({ ...executeButtonProps, caption: "Get Quote", onClick: () => this.postFirstStep() });
    private buttonBookOrder = new Button({ ...executeButtonProps, visible: false, caption: "Book Order", onClick: () => this.postLastStep() });
    private buttonCancel = new Button({ ...navigationButtonPros, caption: "Cancel", onClick: () => Navigation.navigateTo("/") });
    private buttonPrevious = new Button({
        ...navigationButtonPros,
        caption: "Previous", visible: false,
        onClick: () => {
            this.buttonPreviousOnClick();
        }
    });

    private errorHandler = (error: any) => {
        CommonDialogs.showError(error);
        delete this.activeRow.data["convert_to_order"];
        delete this.activeRow.data["selected_bfg"];
        this.buttonPrevious.enabled = true;
        // defect exists where posting fails but linkedModels remain,
        // causing duplicates to be sent to server
        this.activeRow["linkedModels"] = null;
    }

    override async onLoad() {
        if (userCanEnterLtlQuote()) {
            this.mainDataSource.addAfterModeChangeListener((event: DataSourceModeChangeEvent) => {
                if (event.newMode === DataSourceMode.ADD)
                    this.validateCreditAndRevenueCode();
            })
            this.dataHeader = new DataHeader({ visible: false });
            this.mainDataSource.preventFocusFirstField = true;
            const isAssetLtl = PortalCompanySettings.getSingleton().isAssetLtl();
            if (isAssetLtl) {
                this.panelPaymentTerms.visible = true;
                this.textboxPaymentTerms.required = true;
            }
            this.setupStepper();
            await this.layoutHdr.populateTable();
            this.layoutHdr.hdrCallback = (row: ModelRow<any>, type: ModelDataChangeType) => this.hdrChanged(row, type);
            this.layoutFreightItems.sourceQuote = this.sourceLtlQuoteOrder;
            this.textPickupDate.items = getDatesBasedOnMaxDaysOut();
            this.addTableListeners(this.sourceQuoteStops, this.tableStops);
            this.addTableListeners(this.layoutFreightItems.sourceQuoteFreightGroupItem, this.layoutFreightItems.tableFreightItems);
            this.labelWelcomeMsg.caption = PortalCustomerSettings.get().order_welcome_message;
            this.sourceHdr.addAfterExecutionListener(async (event: DataSourceExecutionEvent) => {
                await this.layoutHdr.populateHdrs(this.sourceHdr.data, true)
            });

            this.layoutCarrierSelection.selectRateCallback = (modelRow: ModelRow<any>) => {
                this.selectedRate = modelRow;
                this.changingStep = true;
                this.layoutQuoteDetails.doBeforeDisplay(modelRow);
                this.stepper.selectedIndex++;
            }

            this.layoutFreightItems.sourceQuoteFreightGroupItem.addAfterExecutionListener((event: DataSourceExecutionEvent) => {
                if (event.getAction() === DataSourceAction.SEARCH) {
                    this.textboxFreightClass.text = LtlUtil.getOverallClassCode(this.layoutFreightItems.sourceQuoteFreightGroupItem);
                }
            });

            this.sourceQuoteStops.addAfterExecutionListener((event: DataSourceExecutionEvent) => {
                if (event.getAction() === DataSourceAction.SEARCH) {
                    const pickupDate = this.sourceQuoteStops.data[0]?.get("sched_arrive_early");
                    if (pickupDate != null) {
                        setSelectedDay(this.textPickupDate, pickupDate);
                        this.mainDataSource.activeRow.set("ship_date", this.textPickupDate.selectedItem?.value);
                        this.labelOverviewPickupDate.caption = DateUtil.formatDate(DateUtil.parseDateTime(pickupDate), "iiii MMMM dd, yyyy");
                    }
                }
            });

            this.layoutFreightItems.doBeforeCommoditySearch = (filter: any) => {
                filter.product_book_location = this.sourceQuoteStops?.data?.[0].get("location_id");
            };

            if (isAssetLtl === false && PortalCustomerSettings.get().quote_sheet_template !== 0) {
                this.buttonQuoteSheet.visible = true;
                this.buttonQuoteSheet.addClickListener(() => LtlUtil.downloadQuoteSheet(this.activeRow?.get("id")));
            }

        } else {
            Navigation.navigateTo("/");
        }
    }

    buttonPreviousOnClick() {
        this.searchIfNeeded().then(() => {
            this.changingStep = true;
            this.stepper.selectedIndex -= 1
        })
    }

    private searchIfNeeded(): Promise<any> {
        // if navigating back to first step, search mainDataSource
        // in case user changed data on current step
        if (this.stepper.selectedIndex == 1)
            return this.mainDataSource.search({ id: this.activeRow.get("id") });
        return Promise.resolve();
    }


    setupStepper() {
        this.mainDataSource.addBusyComponent(this.buttonGetQuote);
        this.mainDataSource.addBusyComponent(this.buttonBookOrder);
        this.stepper["panelHeader"].forEveryChildComponent(comp => comp._element.style.cursor = null);
        this.panelFooter = this.stepper["panelFooter"];
        this.panelFooter.removeAll();
        this.panelFooter.align = HorizontalAlignment.LEFT;
        this.panelFooter.add(this.buttonPrevious, new HorizontalSpacer(), this.buttonCancel, this.buttonGetQuote, this.buttonBookOrder);
        if (PortalCompanySettings.getSingleton().isAssetLtl()) {
            this.stepper.removeStep(this.stepper.components[1] as Step);
        }
    }

    addTableListeners(dataSource: DataSource, table: Table) {
        this.sourceLtlQuoteOrder.addBeforeExecutionListener((event: DataSourceExecutionEvent) => {
            if (event.getAction() == DataSourceAction.SEARCH && !table.busy)
                table.busy = true;
        });
        dataSource.addAfterExecutionListener((event: DataSourceExecutionEvent) => {
            if (event.getAction() == DataSourceAction.SEARCH && table.busy == true)
                table.busy = false;
        });
    }

    async sourceQuoteStopsAfterModeChange(event: DataSourceModeChangeEvent) {
        if (event.newMode == DataSourceMode.ADD) {
            await this.addStop().then(row => row?.data?.set("stop_type", "PU"));
            await this.addStop().then(row => row?.data?.set("stop_type", "SO"))
        }
    }

    async addStop(): Promise<TableRow> {
        return await this.tableStops.dataSource.createBlankRow().then(row => {
            const addRowResult: TableAddRowResult = this.tableStops.addRow(row, { mode: TableRowMode.ADD }, { display: true, addToData: true, save: false });
            return addRowResult.row;
        });
    }

    textPickupDateOnChange(event: ChangeEvent) {
        if (event.userInitiatedChange) {
            const textbox = event.target as Textbox;
            this.sourceLtlQuoteOrder.activeRow.set("ship_date", textbox.selectedItem?.value);
            this.sourceQuoteStops?.data?.forEach(data => {
                data.set("ship_date", textbox.selectedItem?.value);
                data.set("sched_arrive_early", textbox.selectedItem?.value);
                data.set("sched_arrive_late", textbox.selectedItem?.value);
            })
        }
    }

    beforeStepListener(event: StepEvent) {
        const currentStep = this.stepper?.components?.[event.oldIndex] as Step;
        const nextStep = this.stepper?.components?.[event.newIndex] as Step;
        if (!this.changingStep) {
            event.preventDefault();
            return;
        }

        if (event.newIndex > event.oldIndex) {
            if ("stepCarrierSelection" == currentStep.id && this.layoutCarrierSelection.selectedRate == null) {
                event.preventDefault();
                return;
            } else if ("stepCarrierSelection" == nextStep.id) {
                this.doBeforeCarrierSelection();
            } else if (PortalCompanySettings.getSingleton().isAssetLtl() && "stepDetails" == nextStep.id) {
                this.layoutQuoteDetails.doBeforeDisplay(null)
            }
        }

        this.buttonBookOrder.visible = "stepDetails" == nextStep.id;
        this.buttonPrevious.visible = event.newIndex != 0;
        this.buttonGetQuote.visible = event.newIndex == 0;
        this.changingStep = false;
    }

    doBeforeCarrierSelection() {
        if (this.clearRates) {
            this.layoutCarrierSelection.sourceRates.clear();
            this.clearRates = false;
        }
        this.layoutCarrierSelection.carrierRates = this.activeRow?.get("carrier_rates");
        this.layoutCarrierSelection.quoteId = this.activeRow?.get("id");
    }

    tableStopsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const modelRow = tableRow.data;
        tableRow.canBeDeleted = false;
        (tableRow.findComponentById("labelStop") as Label).caption = tableRow.index == 0 ? "Pickup From" : "Deliver To";
        if (modelRow["_stopListener"] == null) {
            modelRow["_stopListener"] = (event: FieldUpdateEvent) => this.stopFieldUpdated(event);
            modelRow.addAfterFieldUpdateListener(modelRow["_stopListener"]);
        }
    }

    stopFieldUpdated(event: FieldUpdateEvent) {
        if (event.oldValue != event.newValue && "location_id" == event.fieldName) {
            this.updateOpenCloseTimesFromLocation(event.row);
        }
    }

    async updateOpenCloseTimesFromLocation(row: ModelRow<any>) {
        row.set({ "contact_name": null, "phone": null, "appt_required": "N" });
        if (!row.isNull("location_id")) {
            await Api.search("portal/customer/dispatch/location-auto-fill", {
                id: row.get("location_id"),
                ltl: true
            }).then(response => {
                const data = response?.data[0];
                row.set({ "contact_name": data.name, "phone": data.phone, "appt_required": data.appt_required });
            }).catch(error => {
                row.set({ "contact_name": null, "phone": null, "appt_required": "N" });
            });
        }
    }

    postFirstStep() {
        const step1 = this.stepper.components[0] as Step;
        if (this.layoutFreightItems.validateBeforePost() && this.validatePanel(step1)) {
            this.mainDataSource.post(this.errorHandler).then(() => {
                this.clearRates = true;
                this.changingStep = true;
                this.stepper.selectedIndex = 1;
            });
        }
    }

    async postLastStep() {
        if (await this.layoutQuoteDetails.validateBeforePost() && (this.validatePanel(this.layoutQuoteDetails))) {
            this.buttonPrevious.enabled = true;
            this.activeRow.set("selected_bfg", this.selectedRate?.get("bfg_uid"));
            this.activeRow.set("convert_to_order", true);
            await this.mainDataSource.post(this.errorHandler);
            const orderId = this.activeRow.get("order_id");
            if (orderId != null)
                Navigation.navigateTo("portal-customer/ltl/LtlOrderConfirmation?key=" + orderId);
        }
    }

    validatePanel(panel: Panel): boolean {
        const valid = panel.validateSimple();
        if (!valid)
            CommonDialogs.showError("Unable to save this record because data is missing or invalid.");
        return valid;
    }

    hdrChanged(hdr: ModelRow<any>, type: ModelDataChangeType) {
        if (type == ModelDataChangeType.ADD) {
            hdr.set("fgp_uid", this.activeRow.get("fgp_uid"));
            this.sourceHdr.addRow(hdr, this.sourceHdr.data.length, false);
        } else if (type == ModelDataChangeType.DELETE) {
            this.sourceHdr.data.forEach((row, index) => {
                if (row == hdr) {
                    this.sourceHdr.deleteTableRow(index, true);
                    return;
                }
            });
        }
    }

    tableStopOverviewOnRowDisplay(event: TableRowDisplayEvent) {
        const row = event.getTableRow()
        const labelIndex = row.findComponentById("labelIndex") as Label;
        const labelStopType = row.findComponentById("labelStopType") as Label;
        labelIndex.caption = (row.index + 1).toString();
        if (row.index == 0) {
            labelStopType.caption = "Pickup";
        }
        if (row.index == 1) {
            labelStopType.caption = "Delivery";
            row.findComponentById("panelSpacer").borderLeftWidth = 0;
        }
    }

    sourceLtlQuoteOrderAfterExecution(event: DataSourceExecutionEvent) {
        if (this.stepper.selectedIndex == 0 && (event.getAction() == DataSourceAction.ADD || event.getAction() == DataSourceAction.UPDATE)) {
            this.sourceLtlQuoteOrder.mode = DataSourceMode.UPDATE;
        }
    }

    validateCreditAndRevenueCode() {
        if (this.activeRow != null) {
            validateRevenuCode(this.activeRow.get("revenue_code_id"), false);
            showCreditSnackbar(this.activeRow.getBoolean("credit_status_valid", false), false);
        }
    }

    static slideInCrudDecorator(key: string): Promise<LtlQuote> {
        return new Promise(async (resolve) => {
            const layout = Layout.getLayout("portal-customer/ltl/LtlQuote", { paddingLeft: 12, paddingRight: 12 }) as LtlQuote;
            layout.addLayoutLoadListener(async () => {
                layout.layoutQuoteDetails.validateShipperAfterSearch = false;
                if (await Navigation.pseudoNavigateTo(`/${layout.layoutName}?mode=update&key=${key}`, () => Navigation.navigateTo("/"))) {
                    const quotePanel = new CrudDecorator({
                        layout: layout, mode: DataSourceMode.UPDATE, key: key,
                        headerProps: { showClose: false, showExecute: false, title: null },
                        doAfterSlideIn: () => {
                            layout.validateCreditAndRevenueCode();
                            layout.layoutQuoteDetails.validateShipperAfterSearch = true;
                            layout.layoutQuoteDetails.validateShipper();
                        }
                    });
                    layout.mainDataSource.addAfterSearchPlusChildrenListener(() => {
                        layout.changingStep = true;
                        layout.stepper.selectedIndex = 1;
                        quotePanel.slideIn({ speed: 200 }).then(() => resolve(layout));
                    });
                }
            });
        })
    }
}
