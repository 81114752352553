import { Attachment } from "../components/attachment/Attachment";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface FileUploadListener extends EventListener {
    (event: FileUploadEvent): void;
}

export class FileUploadEvent extends Event {
    private file: File = null;

    constructor(component: any, file: File, domEvent?: DomEvent) {
        super(component, domEvent);
        this.file = file;
    }

    public get target(): Attachment {
        return super.target as Attachment;
    }

    public getFile(): File {
        return this.file;
    }
}
