import { CompanySettings, CompanySettingsDef } from "@mcleod/core";

export interface PortalCompanySettingsDef extends CompanySettingsDef {
    //unauth
    allow_driver_recruiting: boolean;
    canadian_drapp: boolean;
    dr_contact_email: string;
    dr_contact_name: string;
    dr_contact_phone: string;
    dr_response: string;
    enable_unauth_tracking: boolean;
    ltl_max_weight: number;
    require_legal_term: string;
    require_order_term: string;
    suppress_felony: boolean;
}

export class PortalCompanySettings extends CompanySettings {
    public static getSingleton(): PortalCompanySettings {
        return CompanySettings.getSingleton() as PortalCompanySettings;
    }

    public static get(): PortalCompanySettingsDef {
        return PortalCompanySettings.getSingleton().values as PortalCompanySettingsDef;
    }

    public override get values(): PortalCompanySettingsDef {
        return super.values as PortalCompanySettingsDef;
    }

    protected getValuesInternal(): PortalCompanySettingsDef {
        return this._values as PortalCompanySettingsDef;
    }
}
