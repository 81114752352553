import {
    CompanySettings, CurrencyUtil, DateRange, DateUtil, displaysDateOrTime, DisplayType, EmailUtil,
    isDisplayTypeNumeric, NumberUtil, StringUtil
} from "@mcleod/core";
import { ValidationResult } from "../../base/ValidationResult";
import { Textbox } from "./Textbox";

export class TextboxValidator {
    public static validate(textbox: Textbox, checkRequired: boolean, showErrors: boolean = true, addlValidationCallback?: (value: string) => ValidationResult): ValidationResult {
        const validResult: ValidationResult = { component: textbox, isValid: true };
        let result: ValidationResult;

        if (checkRequired !== false) {
            result = TextboxValidator.validateRequired(textbox, showErrors);
            if (result?.isValid === false)
                return result;
        }

        if (textbox.hasText()) {
            if (!textbox.lookupModelAllowFreeform && textbox.userSelectedFromDropdown === false)
                return { component: textbox, isValid: false, validationMessage: "Free-form text is not allowed in this field.  Please select a value from the list." };

            switch (textbox.displayType) {
                case DisplayType.INTEGER: result = TextboxValidator._validateDecimal(textbox, 0); break;
                case DisplayType.CURRENCY: result = TextboxValidator._validateCurrency(textbox); break;
                case DisplayType.DECIMAL: result = TextboxValidator._validateDecimal(textbox, 4); break;
                case DisplayType.DISTANCE: result = TextboxValidator._validateDecimal(textbox, 1); break;
                case DisplayType.FLOAT: result = TextboxValidator._validateDecimal(textbox, 6); break;
                case DisplayType.LENGTH: result = TextboxValidator._validateDecimal(textbox, 1); break;
                case DisplayType.TEMPERATURE: result = TextboxValidator._validateDecimal(textbox, 1); break;
                case DisplayType.WEIGHT: result = TextboxValidator._validateDecimal(textbox, 4); break;

                case DisplayType.DATE: result = TextboxValidator._validateDate(textbox, true, false); break;
                case DisplayType.DATERANGE: result = TextboxValidator._validateDateRange(textbox); break;
                case DisplayType.DATETIME: result = TextboxValidator._validateDate(textbox, true, true); break;
                case DisplayType.TIME: result = TextboxValidator._validateDate(textbox, false, true); break;

                case DisplayType.EMAIL: result = TextboxValidator._validateEmail(textbox); break;
                case DisplayType.CITY: result = TextboxValidator._validateCity(textbox); break;
                case DisplayType.LOCATION: result = TextboxValidator._validateLocation(textbox); break;
                case DisplayType.STATE: result = TextboxValidator._validateState(textbox); break;
                case DisplayType.PHONE: result = TextboxValidator._validatePhone(textbox); break;
                case DisplayType.LINK: result = TextboxValidator._validateLink(textbox); break;
            }

            result ??= validResult;

            if (result.isValid === false)
                return result;

            if (addlValidationCallback != null) {
                const addlValidationResult = addlValidationCallback(textbox.text);
                if (addlValidationResult != null)
                    return addlValidationResult;
            }

            return result;
        }
        if (showErrors === true) {
            textbox.validationWarning = null;
        }
        return validResult;
    }

    public static validateRequired(textbox: Textbox, showErrors: boolean = true): ValidationResult {
        const isRequiredAndEmpty = textbox._designer == null && textbox.required && textbox.isEmpty();
        const validationMessage = showErrors && isRequiredAndEmpty ? "Required" : null;

        return { component: textbox, isValid: !isRequiredAndEmpty,  validationMessage };
    }

    public static validateSearchText(textbox: Textbox, text: string, minValue: number, maxValue: number): ValidationResult {
        if (StringUtil.isEmptyString(text) || textbox.items != null || textbox.hasLookupModel())
            return {component: textbox, isValid: true};
        if (isDisplayTypeNumeric(textbox.displayType)) {
            if (!TextboxValidator.isValidNumericalValue(text))
                return { component: textbox, isValid: false, validationMessage: "Please enter a valid numerical value" };
            const rangeMessage = TextboxValidator.validateNumericMinMax(parseFloat(text), minValue, maxValue);
            if (StringUtil.isEmptyString(rangeMessage) !== true)
                return { component: textbox, isValid: false, validationMessage: rangeMessage };
        }
        if (displaysDateOrTime(textbox.displayType, false) && !TextboxValidator.isValidDate(text)) {
            return { component: textbox, isValid: false, validationMessage: "Please enter a valid date." };
        }
        if (DisplayType.DATERANGE === textbox.displayType) {
            return TextboxValidator._validateDateRange(textbox, text);
        }
        return {component: textbox, isValid: true};
    }

    private static isValidDate(text: string): boolean {
        const parsed = DateUtil.parseDateWithKeywords(text);
        return parsed != null && DateUtil.isDateValid(parsed);
    }

    private static isValidNumericalValue(text: string): boolean {
        const value = NumberUtil.removeFormatting(CurrencyUtil.removeFormatting(text));
        return !isNaN(parseFloat(value));
    }

    private static _validateCurrency(textbox: Textbox): ValidationResult {
        return TextboxValidator._validateDecimalText(textbox, CurrencyUtil.removeFormatting(textbox.text), 2, null, textbox.minValue, textbox.maxValue);
    }

    private static _validateDecimal(textbox: Textbox, defaultScale: number): ValidationResult {
        const scale = textbox.scale != null ? textbox.scale : defaultScale;
        let textToTest;
        if (textbox.selectedItem != null)
            textToTest = textbox.selectedItem.value;
        else
            textToTest = textbox.getDataValue().toString();
        return TextboxValidator._validateDecimalText(textbox, textToTest, scale, textbox.precision, textbox.minValue, textbox.maxValue);
    }

    private static _validateDecimalText(textbox: Textbox, text: string, scale: number, precision?: number, minValue?: number, maxValue?: number): ValidationResult {
        let textToTest = NumberUtil.removeFormatting(text);
        if ( text.indexOf(".") < 0 )
            textToTest = text + ".";

        const [leftOfTheDecimal, rightOfTheDecimal] = textToTest.split('.');
        const numberToTest = parseFloat(textToTest);
        const isNAN = isNaN(numberToTest);
        const exceedsScale = rightOfTheDecimal.length > scale;
        const exceedsPrecision = precision != null && leftOfTheDecimal.length + rightOfTheDecimal.length > precision;
        let msg: string;
        if (precision == null) {
            if (isNAN === true || exceedsScale) {
                if (scale === 0)
                    msg = "Please enter a whole number.";
                else if (scale === 1)
                    msg = "Please enter a number with at most 1 decimal place.";
                else
                    msg = "Please enter a number with at most " + scale + " decimal places.";
            }
        }
        else {
            if (isNAN === true || exceedsScale ||  exceedsPrecision) {
                if (scale === 0)
                    msg = "Please enter a whole number that is at most " + precision + " digits in length.";
                else if (scale === 1)
                    msg = "Please enter a number that is at most " + precision + " digits in length, with at most 1 decimal place.";
                else
                    msg = "Please enter a number that is at most " + precision + " digits in length, with at most " + scale + " decimal places.";
            }
        }
        if (isNAN === false) {
            const rangeMessage = TextboxValidator.validateNumericMinMax(numberToTest, minValue, maxValue);
            if (StringUtil.isEmptyString(rangeMessage) !== true)
                msg = !StringUtil.isEmptyString(msg) ? msg + "\n" + rangeMessage : rangeMessage;
        }
        if (StringUtil.isEmptyString(msg))
            return null;
        return { component: textbox, isValid: false, validationMessage: msg };
    }

    private static validateNumericMinMax(numberToTest: number, minValue: number, maxValue: number): string {
        const isNAN = isNaN(numberToTest);
        if (isNAN === true)
            return null;
        const greaterThanMax = maxValue != null && numberToTest > maxValue;
        const lessThanMin = minValue != null && numberToTest < minValue;
        if (maxValue != null && minValue != null && (greaterThanMax || lessThanMin))
            return "Please enter a number between " + minValue + " and " + maxValue + ".";
        else if (lessThanMin)
            return "Please enter a number greater than or equal to " + minValue + ".";
        else if (greaterThanMax)
            return "Please enter a number less than or equal to " + maxValue + ".";
        return null;

    }

    private static _validateDate(textbox: Textbox, hasDate: boolean, hasTime: boolean): ValidationResult {
        const value = textbox.text;
        const parsed = DateUtil.parseDateWithKeywords(value, hasDate, hasTime, textbox.timezone);
        if (parsed == null || isNaN(parsed.getTime())) {
            if (hasDate && hasTime)
                return { component: textbox, isValid: false, validationMessage: "Please enter a valid date/time." };
            else if (hasDate)
                return { component: textbox, isValid: false, validationMessage: "Please enter a valid date." };
            else
                return { component: textbox, isValid: false, validationMessage: "Please enter a valid time." };
        }
        else if (hasDate && hasTime)
            return { component: textbox, isValid: true };
        else if (hasDate)
            return { component: textbox, isValid: true };
        else
            return { component: textbox, isValid: true };
    }

    private static _validateDateRange(textbox: Textbox, text: string = textbox.text): ValidationResult {
        const dateRange = DateRange.parseDateRange(text);
        const isValid = dateRange.hasValidDates();
        return { component: textbox, isValid, validationMessage: isValid ? undefined : "Please enter a valid date or date range." };
    }

    private static _validateEmail(textbox: Textbox): ValidationResult {
        const value = textbox.text;
        // use RFC 2822 standard email validation
        const regex = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;
        if (regex.test(value))
            return { component: textbox, isValid: true }
        else
            return { component: textbox, isValid: false, validationMessage: "Please enter a valid email address." };
    }

    private static _validateState(textbox: Textbox): ValidationResult {
        return null;
    }

    private static _validateCity(textbox: Textbox): ValidationResult {
        return null;
    }

    private static _validateLocation(textbox: Textbox): ValidationResult {
        return null;
    }

    private static _validatePhone(textbox: Textbox): ValidationResult {
        let value = textbox.text;
        const settings = CompanySettings.get();
        if (settings.enable_phone_format === false || value.startsWith("0")) // international phone number - don't try to format
            return { component: textbox, isValid: true };
        value = value.toLowerCase();
        const xIndex = value.indexOf("x");
        if (xIndex >= 0) {
            value = value.substring(0, xIndex);
        }
        const repl = value.replace(new RegExp("([( )-\. ])", 'g'), "");
        if (Number.isNaN(Number(repl)))
            return { component: textbox, isValid: false, validationMessage: "Phone numbers can only contain digits and formatting characters." };
        if (repl.length === 7)
            return { component: textbox, isValid: false, validationMessage: "Please enter an area code." };
        if (repl.length < 10)
            return { component: textbox, isValid: false, validationMessage: "Please enter a ten-digit phone number.  Formatting characters can be included or omitted." };
        return { component: textbox, isValid: true };
    }

    private static _validateLink(textbox: Textbox): ValidationResult {
        const value = textbox.text;
        let result;
        if (value.indexOf("/www.") > 0)
            result = /.+:\/\/www\..+\..+$/.test(value);
        else
            result = /.+:\/\/.+\..+$/.test(value);
        if (result === true)
            return { component: textbox, isValid: true};
        return { component: textbox, isValid: false, validationMessage: "Please enter a valid link.  Note that '://' must be included (example: https://example.com)." };
    }
}
