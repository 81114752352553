import {
    DialogProps, DropdownItem, DropdownSelectionEvent, Panel,
    PanelProps, TailorExtensionParamInputCreator, Textbox
} from "@mcleod/components";
import { ArrayUtil, StringUtil, getLogger } from "@mcleod/core";

const log = getLogger("common.designer.ui.TailorExtensionEditor");

export class TailorExtensionEditor extends Panel {
    dialogProps: Partial<DialogProps> = { scrollY: true, okDefault: false, cursor: null, title: "Tailor Extension Setup" };
    private extensionsMetadata: any[];
    private textboxId: Textbox;
    private idDropdownItems: DropdownItem[];
    private paramPanel: Panel;
    private selectedId: string;

    constructor(props: Partial<PanelProps> = {}, valueAsJson: string, extensionsMetadata: any[]) {
        super({ fillRow: true, fillHeight: true, minWidth: 500, minHeight: 400, ...props });
        this.scrollY = true;
        this.extensionsMetadata = extensionsMetadata;
        this.createIdTextbox();
        this.paramPanel = new Panel({ margin: 0, padding: 0, fillRow: true, rowBreakDefault: true });
        this.add(this.paramPanel);
        if (StringUtil.isEmptyString(valueAsJson) === false) {
            const currentValues: any = JSON.parse(valueAsJson);
            if (Object.keys(currentValues).length > 0) {
                this.textboxId.selectedItem = this.getDropdownItemForId(currentValues.id);
                this.reloadFromId(currentValues.id);
                this.populateFromData(currentValues);
            }
        }
    }

    private createIdTextbox() {
        this.loadExtensionIdItems();
        this.textboxId = new Textbox({ caption: "Extension", field: "id", id: "textboxId", items: this.idDropdownItems, fillRow: true, rowBreak: true, required: true });
        this.textboxId.addDropdownSelectionListener((event: DropdownSelectionEvent) => {
            this.reloadFromId(event.newSelection.value);
        });
        this.add(this.textboxId);
    }

    private loadExtensionIdItems() {
        this.idDropdownItems = [];
        if (this.extensionsMetadata == null)
            return;
        for (const extensionMetadata of this.extensionsMetadata) {
            this.idDropdownItems.push({ caption: extensionMetadata.descr, value: extensionMetadata.id });
        }
    }

    private getDropdownItemForId(id: string): DropdownItem {
        for (const item of this.idDropdownItems) {
            if (item.value === id)
                return item;
        }
        return null;
    }

    private getMetadataForId(id: string): any {
        for (const extensionMetadata of this.extensionsMetadata) {
            if (id === extensionMetadata.id)
                return extensionMetadata;
        }
        return null;
    }

    private reloadFromId(id: string) {
        if (id === this.selectedId)
            return;
        this.selectedId = id;
        for (const comp of [...this.paramPanel.components]) {
            this.paramPanel.remove(comp);
        }
        const metadata = this.getMetadataForId(id);
        if (metadata?.params != null) {
            for (const param of metadata.params) {
                const comp = TailorExtensionParamInputCreator.create(param.id,
                    param.descr, param.required, param.allow_manual_input, true);
                this.paramPanel.add(comp);
            }
        }
    }

    private populateFromData(currentValues: any) {
        if (currentValues == null)
            return;
        const metadataForId = this.getMetadataForId(currentValues.id);
        if (metadataForId == null)
            return;
        const metadataParams: any[] = metadataForId.params;
        if (metadataParams != null) {
            const paramValues: any[] = currentValues.params;
            if (paramValues != null) {
                OUTER:
                for (const metadataParam of metadataParams) {
                    for (const paramValue of paramValues) {
                        if (metadataParam.id === paramValue.id) {
                            const comp = this.paramPanel.findComponentByField(paramValue.id)?.[0] as Textbox;
                            if (comp != null)
                                comp.text = paramValue.value;
                            continue OUTER;
                        }
                    }
                }
            }
        }
    }

    getValue(): string {
        if (this.textboxId.selectedItem == null)
            return null;
        const resultObject: any = { id: this.textboxId.selectedItem.value };
        if (ArrayUtil.isEmptyArray(this.paramPanel.components) === false) {
            const params: any[] = [];
            resultObject.params = params;
            for (const paramComp of this.paramPanel.components) {
                const paramTextbox = paramComp as Textbox;
                params.push({ id: paramTextbox.field, value: paramTextbox.text });
            }
        }
        const result = JSON.stringify(resultObject);
        log.debug("TailorExtensionEditor result object: %o, string: %s", resultObject, result);
        return result;
    }
}
