import { ClickEvent, Label, LabelProps } from "@mcleod/components";
import { ExternalLink, GeneralSettings, Navigation } from "@mcleod/core";

export class PageHeaderProvider {
    public static get(): PageHeaderProvider {
        if (_singleton == null)
            _singleton = new PageHeaderProvider();
        return _singleton;
    }

    public static set(provider: PageHeaderProvider) {
        _singleton = provider;
    }

    getUserDropdownMenuItems(sharedProps: Partial<LabelProps>): Label[] {
        const result: Label[] = [];
        if (GeneralSettings.get()?.tenant_settings?.allow_switching_tenants) {
            const labelSettings = new Label({
                caption: "Change tenants",
                imageName: "designer/datasource",
                ...sharedProps
            });
            labelSettings.addClickListener((event: ClickEvent) => {
                Navigation.navigateTo("auth/TenantSelection");
            });
            result.push(labelSettings);
        }
        return result;
    }

    public shouldDisplayHelpLinks(): boolean {
        return false;
    }

    public getAddlExternalLinks(): ExternalLink[] {
        return [];
    }
}

let _singleton: PageHeaderProvider;