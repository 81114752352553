import { SupportEmailProvider } from "@mcleod/common";
import { AuthToken, GeneralSettings } from "@mcleod/core";

export class PortalSupportEmailProvider extends SupportEmailProvider {
    public override shouldDisplayPageHeaderSupportEmailLink(): boolean {
        return AuthToken.isAuthenticated();
    }

    public override getMailToLink(): string {
        return `mailto:${GeneralSettings.get().support_email_address}`;
    }
}
