import { ComponentPropDefinitions, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { PanelProps } from "../panel/PanelProps";
import { AttachmentFileType } from "./AttachmentFileType";

export interface AttachmentProps extends PanelProps {
    allowedFileTypes: AttachmentFileType[];
    caption: string;
    displayThumbnail: boolean;
    fileNameField: string;
    idField: string;
    maxSize: number;
    showSVG: boolean;
    printable: boolean;
    printableDuringAdd: boolean;
    printableDuringSearch: boolean;
    printableDuringUpdate: boolean;
}

let attachmentPropDefs: ComponentPropDefinitions;

export class AttachmentPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (attachmentPropDefs == null) {
            attachmentPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                allowedFileTypes: { type: PropType.string },
                caption: { type: PropType.string },
                fileNameField: { type: PropType.string, category: "Data" },
                displayThumbnail: { type: PropType.bool },
                idField: { type: PropType.string, description: "This defines the field that contains the primary key of the record this attachment is on.  It is used to lookup the attachment to download it." },
                maxSize: { type: PropType.number },
                onFileUpload: { type: PropType.event, category: "Events", addListenerMethod: "addFileUploadListener", removeListenerMethod: "removeFileUploadListener", eventSignature: "OnFileUpload(event: FileUploadEvent)" },
                onFileRemoval: { type: PropType.event, category: "Events", addListenerMethod: "addFileRemovalListener", removeListenerMethod: "removeFileRemovalListener", eventSignature: "OnFileRemoval(event: FileRemovalEvent)" },
                showSVG: { type: PropType.bool },
                onPrintable: { type: PropType.event, category: "Events", addListenerMethod: "addPrintableListener", removeListenerMethod: "removePrintableListener", eventSignature: "OnPrintable(event: PrintableEvent)", description: "This specifies a function to be called when the component's printable property has changed."},
                printable: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    description: "This toggles whether the Attachment appears in a state where the attachment's content cannot be changed/deleted (printable = true), or in a state where the content can be changed (printable = false).  This is often changed at runtime.",
                },
                printableDuringAdd: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    description: "This toggles whether the Attachment should appear in its printable state during add mode.",
                },
                printableDuringSearch: {
                    type: PropType.bool,
                    defaultValue: true,
                    category: "Appearance",
                    description: "This toggles whether the Attachment should appear in its printable state during search mode.  The default value is currently false, since we do not yet provide support for any type of search functionality related to attachments.",
                },
                printableDuringUpdate: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    description: "This toggles whether the Attachment should appear in its printable state during update mode.",
                }
            };
            attachmentPropDefs.borderWidth.defaultValue = 2;
            attachmentPropDefs.borderType.defaultValue = "dashed";
            attachmentPropDefs.borderRadius.defaultValue = 4;
            attachmentPropDefs.color.defaultValue = "primary";
            attachmentPropDefs.padding.defaultValue = 8;
            attachmentPropDefs.showSVG.defaultValue = false;
            ComponentPropDefinitionUtil.populateComponentPropNames(attachmentPropDefs);
        }
        return attachmentPropDefs;
    }
}
