import { getThemeColor, getThemeFontSize, makeStyles } from "@mcleod/core";

export const TableStyles = makeStyles("tbl", () => {
    return {
        base: {
            display: "flex",
            flexDirection: "column"
        },
        tableBase: {
            width: "100%",
            flex: "1",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden"
        },
        theadBase: {
            width: "100%",
            minHeight: "38px",
            display: "flex",
            fontSize: getThemeFontSize("small"),
            backgroundColor: getThemeColor("table.headingRow.backgroundColor"),
            color: getThemeColor("table.headingRow.color"),
            borderTop: "1px solid " + getThemeColor("strokePrimary"),
            borderBottom: "1px solid " + getThemeColor("strokePrimary"),
            overflowX: "auto"
        },
        "hideScroll::-webkit-scrollbar": {
            display: "none"
        },
        hideScroll: {
            MsOverflowStyle: "none",
            scrollbarWidth: "none",
            overflow: "hidden"
        },
        tbodyBase: {
            display: "block",
            overflowY: "auto",
            outline: "none",
            flex: "1"
        },
        theadRowBase: {
            display: "table",
            width: "100%",
            tableLayout: "fixed",
            borderSpacing: "0px",
            paddingTop: "2px",
            paddingBottom: "2px"
        }
    };
});
