import { Button, DataSource, Label, Layout, Tab, Table, Tabset } from "@mcleod/components";

export abstract class AutogenLayoutCarrierDeliveredOrdersDetails extends Layout {
    buttonAdd: Button;
    carrierCallins: Table;
    carrierImagesDetailTable: Table;
    labelNoCallins: Label;
    sourceCarrierCallins: DataSource;
    sourceCarrierImages: DataSource;
    tabCallins: Tab;
    tabImages: Tab;
    tabset_: Tabset;
}
