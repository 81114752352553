
import { DataDisplayEvent, Label, Textbox } from "@mcleod/components";
import { AutogenLayoutTableCarrierOfferHistory } from "./autogen/AutogenLayoutTableCarrierOfferHistory";

export class TableCarrierOfferHistory extends AutogenLayoutTableCarrierOfferHistory {

    labelStatusOnDataDisplay(event: DataDisplayEvent) {
        const status = event.rowData.get("carrier_offer_status");
        switch (status) {
            case "Awarded":
                (event.target as Label).imageName = "circleCheck";
                (event.target as Label).imageColor = "success";
                break;
            case "Declined":
            case "Declined/Lane":
            case "Declined/Equipment":
                (event.target as Label).imageName = "circleX";
                (event.target as Label).imageColor = "error";
                break;
            case "Expired":
                (event.target as Label).imageName = "clock";
                (event.target as Label).imageColor = "error";
                break;
            case "Countered":
                (event.target as Label).imageName = "replyArrowFilled";
                (event.target as Label).imageColor = "caution";
                break;
            default:
                break;
        }
    }

    /** This is an event handler for the onDataDisplay event of labelContactEmail.  */
    labelContactEmailOnDataDisplay(event: DataDisplayEvent) {
        const email = event.rowData.get("users.email_address");
        if (email != null) {
            const id = event.rowData.get("order_id");
            const subject = `Question about Order ${id}`;
            (event.target as Label).link = `mailto:${email}?subject=${subject}`;
        }
    }

    /** This is an event handler for the onDataDisplay event of textboxMovementMoveDistance.  */
    textboxMovementMoveDistanceOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Textbox).text = `${event.rowData.get("movement.move_distance")} MI`;
    }
}
