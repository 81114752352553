import { JSUtil, ModelRow } from "@mcleod/core";
import { Captioned } from "../../base/CaptionedComponent";
import { ComponentTypes } from "../../base/ComponentTypes";
import { Printable } from "../../base/PrintableComponent";
import { DataSourceMode } from "../../databinding/DataSource";
import { LookupModelSearchEvent } from "../../events/LookupModelSearchEvent";
import { Layout } from "../layout/Layout";
import { Location } from "../location/Location";
import { TextboxProps } from "../textbox/TextboxProps";
import { LocationProps } from "./LocationProps";


export class PortalLocation extends Location {
    private _webLocationsOnly = false;

    constructor(props?: Partial<LocationProps>) {
        super(props);
        this.textCombined.manualAddLayoutLoadedCallback = (layout: Layout) => {
            const checkboxSave = layout.findComponentById("checkboxSave");
            if (checkboxSave != null)
                checkboxSave.visible = this.isForStop === true;
        }
    }

    public get webLocationsOnly() {
        return this._webLocationsOnly;
    }
    public set webLocationsOnly(value) {
        if (this.__designer == null)
            this._webLocationsOnly = value;
    }

    override get textCombinedProps(): Partial<TextboxProps> {
        const props = super.textCombinedProps;

        return {
            ...props,
            id: "locationCombined",
            fillRow: true,
            lookupModel: undefined,
            lookupModelLayout: "portal-common/LocationLucene",
            manualAddLayout: "portal-common/LocationManualAdd",
            lookupModelInputDelay: null
        }
    }

    override beforeLookupModelSearch(event: LookupModelSearchEvent) {
        if (!this._filterOwner)
            event.filter.filter_owner = false;
        if (this._webLocationsOnly === true)
            event.filter.web_locations_only = true;
    }

    public override updateBoundData(data: ModelRow, mode: DataSourceMode) {
        if (mode !== DataSourceMode.SEARCH && this.printable !== true && data != null && this._lookupModelDataForUpdate?.get("save_address") != null) {
            data.set("save_address", this._lookupModelDataForUpdate.get("save_address"), this);
        }
        super.updateBoundData(data, mode);
    }

    override get serializationName() {
        return "portalLocation";
    }

    override get properName(): string {
        return "Portal Location";
    }
}


JSUtil.applyMixins(PortalLocation, [Captioned, Printable]);
ComponentTypes.registerComponentType("portalLocation", PortalLocation.prototype.constructor, true);
