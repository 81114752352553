import { ClickEvent, DataSource, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, Label, Panel, PrintableEvent, PrintableListener, TableRow, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { HorizontalAlignment, ModelRow, Navigation } from "@mcleod/core";
import { PortalCustomerSettings } from "../../../customer/src/settings/PortalCustomerSettings";
import { PortalCompanySettings } from "../../../portal-common/src/settings/PortalCompanySettings";
import { LtlUtil } from "./LtlUtil";
import { AutogenLayoutLtlOrderConfirmation } from "./autogen/AutogenLayoutLtlOrderConfirmation";

export class LtlOrderConfirmation extends AutogenLayoutLtlOrderConfirmation {
    private printableListenter: PrintableListener;
    private key: string;
    override async onLoad() {
        this.printableListenter = (event: PrintableEvent) => this.setPrintableLabelProps(event.target as Textbox);
        this.layoutStops.isLtl = true;
        await this.layoutHdr.populateTable(false);
        this.layoutHeader.labelOrderSubmitMsg.caption = PortalCustomerSettings.get().order_submit_ok ?? "";
        this.textboxShipperRef.visible = PortalCompanySettings.getSingleton().isAssetLtl();
        if (this.__designer == null && this.key != null) {
            this.layoutOverview.visible = false;
            this.panelRight.visible = false;
            await this.mainDataSource.search({ id: this.key, "": true });
        }
    }

    tableStopsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const data = tableRow.data;
        (tableRow.findComponentById("labelSequence") as Label).caption = (tableRow.index + 1).toString();
        tableRow.findComponentById("labelApptRequired").visible = data.getBoolean("appt_required");
    }

    buttonListViewOnClick(event: ClickEvent) {
        Navigation.navigateTo("/");
    }

    /** This is an event handler for the afterExecution event of sourceLtlOrder.  */
    sourceLtlOrderAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.SEARCH && this.activeRow != null) {
            this.mapData("stops", this.layoutStops.mainDataSource);
            this.mapData("freight_items", this.sourceFreightGroupItem);
            this.mapData("handling_reqs", this.sourceLtlOrderHandlingReq);
            this.layoutHdr.populateHdrs(this.sourceLtlOrderHandlingReq.data);

            this.labelTotalUnits.caption = LtlUtil.getFgiTotal(this.sourceFreightGroupItem, "handling_units") + "";

            let carrierData = null;

            if (!this.activeRow.isNull("selected_carrier")) {
                carrierData = new ModelRow("portal/customer/dispatch/billing-freight-group", false, this.activeRow.get("selected_carrier"));
                this.sourceBillingFreightGroup.setRowsAndMode(DataSourceMode.NONE, [carrierData]);
                this.sourceRevenueDetail.setRowsAndMode(DataSourceMode.NONE, carrierData.get("revenue_details")?.map(data => new ModelRow(this.sourceRevenueDetail.url, false, data)));
            }
            this.layoutHeader.displayHoldPanel(this.activeRow.get("hold_warning"));
            this.layoutOverview.visible = true;
            this.panelRight.visible = true;

            this.layoutOverview.setup(this.mainDataSource, this.sourceBillingFreightGroup.activeRow, true);
            this.mapData("freight_items", this.layoutOverview.sourceQuoteFreightGroupItem);
        }
    }

    private mapData(fieldName: string, dataSource: DataSource) {
        dataSource.setRowsAndMode(DataSourceMode.NONE, this.activeRow.get(fieldName)?.map(data => new ModelRow(dataSource.url, false, data)));
    }

    tableFreightItemsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.target as TableRow;
        this.setupRow(tableRow);
    }

    setupRow(tableRow: TableRow) {
        tableRow.setProps({ borderWidth: 1, borderRadius: 4, borderColor: "subtle.light", paddingTop: 12, paddingBottom: 20, marginTop: 15 });
        const panelHazmat = tableRow.findComponentById("panelHazmat") as Panel;
        panelHazmat.visible = tableRow.data?.getBoolean("hazmat", false);
        tableRow.forEveryChildComponent((comp) => {
            if (comp instanceof Textbox) {
                comp.addPrintableListener(this.printableListenter);
                this.setPrintableLabelProps(comp);
            }
        });
    }

    setPrintableLabelProps(textbox: Textbox) {
        if (textbox.printableLabel) {
            if (textbox.required)
                textbox.printableLabel.paddingLeft = 5;
            textbox.printableLabel.setProps({ width: undefined, margin: undefined, marginRight: undefined, align: HorizontalAlignment.LEFT });
        }
    }

    buttonBOLOnClick(event: ClickEvent) {
        LtlUtil.downloadBOL(this.activeRow.get("id"));
    }
}
