import {
    Alignment, Api, ApiMetadata, ArrayUtil, Collection, Color, CurrencySettings, CurrencyUtil, DateRange, DisplayType,
    DisplayValue, DOMUtil, ExtendedDateFormat, getApiMetadata, getApiMetadataFromCache, getLogger,
    getRelativeDateString, getThemeColor, isDisplayTypeNumeric, isRightAlignedDisplayType, JSUtil, Keys, makeStyles,
    ModelRow, ModelRowType, NumberUtil, ObjectUtil, StringUtil, Timezone, UserSettings
} from "@mcleod/core";
import { HorizontalAlignment } from "@mcleod/core/src/constants/Alignment";
import { DbDisplayValue } from "@mcleod/core/src/DbDisplayValue";
import {
    DesignerInterface, ImageProps, LabelProps, LayoutProps, LookupModelSelectionEvent, LookupModelSelectionListener,
    OverlayedList, PanelProps, PrintableEvent, PrintableListener, TooltipOptions
} from "../..";
import { Captioned } from "../../base/CaptionedComponent";
import { Component } from "../../base/Component";
import { getCurrentDataSourceMode, getRelevantModelRow } from "../../base/ComponentDataLink";
import { ComponentPropDefinition, ComponentPropDefinitions } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ListenerListDef } from "../../base/ListenerListDef";
import { PermissionsDefinition } from "../../base/PermissionsDefinition";
import { Printable, printableListenerDef } from "../../base/PrintableComponent";
import { QuickInfo } from "../../base/QuickInfoComponent";
import { ValidationResult } from "../../base/ValidationResult";
import { Button } from "../button/Button";
import { ButtonProps } from "../button/ButtonProps";
import { Image } from "../image/Image";
import { Label } from "../label/Label";
import { DataSourceMode } from "../../databinding/DataSource";
import { BlurEvent } from "../../events/BlurEvent";
import { ChangeEvent, ChangeListener } from "../../events/ChangeEvent";
import { ClickEvent } from "../../events/ClickEvent";
import { DomEvent } from "../../events/DomEvent";
import { DropdownSelectionEvent, DropdownSelectionListener } from "../../events/DropdownSelectionEvent";
import { Event } from "../../events/Event";
import { LookupModelSearchEvent, LookupModelSearchListener } from "../../events/LookupModelSearchEvent";
import { MouseEvent } from "../../events/MouseEvent";
import { SelectionEvent } from "../../events/SelectionEvent";
import { Anchor, AnchorProps } from "../../page/Anchor";
import { Overlay } from "../../page/Overlay";
import { OverlayStyles } from "../../page/OverlayStyles";
import { ButtonVariant } from "../button/ButtonVariant";
import { ReadMoreType } from "../label/ReadMoreType";
import { Layout } from "../layout/Layout";
import { List } from "../list/List";
import { Panel } from "../panel/Panel";
import { Table } from "../table/Table";
import { AbstractInputParser } from "./AbstractInputParser";
import { ClearButtonVisible } from "./ClearButtonVisible";
import { ForcedCase } from "./ForcedCase";
import { InputFormatter } from "./InputFormatter";
import { LookupModelPopulatedButton } from "./LookupModelPopulatedButton";
import { LookupModelSelectedItemLabel } from "./LookupModelSelectedItemLabel";
import { TextboxButtonHandler } from "./TextboxButtonHandler";
import { DropdownItem, TextboxPropDefinitions, TextboxProps } from "./TextboxProps";
import { TextboxStyles } from "./TextboxStyles";
import { TextboxVariant } from "./TextboxVariant";
import { LookupModelLayoutManager } from "./LookupModelLayoutManager";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners", eventCreatorFunction: (component, event) => new ChangeEvent(component, (component as Textbox).text, (component as Textbox).text, event) };
const _lookupListenerDef: ListenerListDef = { listName: "_lookupListeners", eventCreatorFunction: (component, event) => new ChangeEvent(component, (component as Textbox).text, (component as Textbox).text, event) };
const _dropdownSelectionListenerDef: ListenerListDef = { listName: "_dropdownSelectionListeners" };
const _lookupModelSelectionListenerDef: ListenerListDef = { listName: "_lookupModelSelectionListeners" };

const dynamicStyles = {};
const TextboxConsumedKeys = [Keys.ARROW_LEFT, Keys.ARROW_RIGHT, Keys.DELETE, Keys.BACKSPACE, Keys.HOME, Keys.END];
const TextboxConsumedCtrlKeys = [Keys.C, Keys.X, Keys.V, Keys.A, Keys.Z];
const log = getLogger("components.Textbox");

/**
 * TextBox is a decorator around the Input component that adds a caption and a label to display validation warnings.
 */
export class Textbox extends Component implements TextboxProps {
    private _allowDropdownBlank: boolean;
    private _buttonProps: Partial<ButtonProps>;
    private _captionAlignment: Alignment.LEFT | Alignment.TOP;
    private _captionVisible: boolean;
    private _captionVisibleInsideTable: boolean;
    private _dropdown: OverlayedList | Table;
    public dropDownAnchor: Component;
    public _displayType: DisplayType;
    private _forcedCase: ForcedCase;
    public format: any;
    private _imagePre: Image;
    private _imagePreName: string;
    private _imagePost: Image;
    private _imagePostName: string;
    private _inputAttributes: Collection<string>;
    private _inputClassList: any[];
    private _inputStyles: Collection<any>;
    public _input: HTMLInputElement | HTMLTextAreaElement;
    public _inputDiv: HTMLElement;
    private _items: string[] | (() => string[]) | DropdownItem[] | (() => DropdownItem[]);
    public _captionLabel: Label;
    private _lastKeyPress: Date;
    private _lastKeyString: string;
    private _lookupModel: string;
    public lookupModelLayoutHeight: number;
    public lookupModelLayoutWidth: number;
    private _lookupModelAllowSearchAll: boolean;
    private _lookupModelAllowShowAllResults: boolean;
    private _lookupModelAllowFreeform: boolean;
    private _lookupModelAllowMultiSelect: boolean;
    private _lookupModelLayout: string;
    private lookupModelLayoutManager: LookupModelLayoutManager;
    private _lookupModelResultField: string;
    private _lookupModelData: ModelRow[];
    private _lookupModelDisplayField: string;
    private _lookupModelExtraFieldList: string;
    private _lookupModelFieldListInfo: object;
    private _lookupModelMaxResults: number;
    private _lookupModelMinChars: number;
    private _lookupModelKeyMonitor: string | KeyboardEvent;
    private _lookupModelInputDelay: number;
    private _lookupModelPopulatedButton: LookupModelPopulatedButton;
    private _lookupModelSelectedItemPanel: Panel;
    private _lookupModelSelectedItemEllipsis: Button;
    private _lookupModelAllowMultiSelectTooltip: boolean;
    private _lookupModelDisabled: boolean;
    private _multiline: boolean;
    private _nullDisplayValue: string;
    private _overlay: Overlay;
    private _password: boolean;
    private _placeholder: string;
    private _printableLabel: Label;
    private _readMoreType: ReadMoreType;
    private _preReadMoreMaxHeight: string | number;
    private _selectedItem: DropdownItem;
    private _textboxAlign: HorizontalAlignment;
    private _userSelectedFromDropdown: boolean;
    private _addlValidationCallback: (value: string) => ValidationResult;
    private _validationWarning: string;
    private validationWarningProps: Partial<LabelProps>;
    private _validationPlaceholder: string;
    private _variant: TextboxVariant;
    private _text: string = "";
    private _placeholderColor;
    private _clearButtonVisible: ClearButtonVisible;
    private _acTimeoutHandle: number;
    private _dateDefault: any;
    private _manualAddLayout: string;
    private _manualAddLayoutLoadedCallback: (layout: Layout) => void;
    private _currencyColorCallback: (num: number) => string;
    private _dropdownAdditionalActions: Button[];
    private _maxValue: number;
    private _minValue: number;
    private _timezone: Timezone;
    private _defaultDataValue: string;
    private _inputFormatter: InputFormatter;
    private buttonHandler: TextboxButtonHandler;

    constructor(props?: Partial<TextboxProps>) {
        super("div", props);
        this._multiline = false;
        this.setClassIncluded(TextboxStyles.base);
        this._captionLabel = new Label({ fontSize: "small", padding: 0, paddingBottom: 2, height: 16, color: "component.palette.textbox.caption.color", allowSelect: false, readMoreType: ReadMoreType.NONE });
        this._element.appendChild(this._captionLabel._element);
        this._captionVisible = true;
        this._captionVisibleInsideTable = false;
        this._readMoreType = ReadMoreType.NONE;
        this.buttonHandler = new TextboxButtonHandler(this);
        this._inputFormatter = new InputFormatter(this);
        this._createInputDiv();
        this._createTextElement(true);
        this.setProps(props);
        if (this._clearButtonVisible == null)
            this.buttonHandler.syncListeners();
    }

    private get inputParser(): AbstractInputParser {
        return AbstractInputParser.createParser(this, this.text);
    }

    public get inputFormatter(): InputFormatter {
        return this._inputFormatter;
    }

    public get defaultDataValue(): string {
        return this._defaultDataValue;
    }

    public set defaultDataValue(value: string) {
        this._defaultDataValue = value;
    }

    override applyDefaultDataValue() {
        const row = this.getRelevantModelRow();
        if (this.defaultDataValue && row && this.lookupModelLayout && this.lookupModelResultField) {
            const lookupLayout = Layout.getLayout(this.lookupModelLayout);
            lookupLayout.addLayoutLoadListener(() => {
                this._createLookupModelFieldListInfo(lookupLayout);
                const filter = { [this.lookupModelResultField]: this.defaultDataValue };
                lookupLayout.mainDataSource.search(filter, null, this._lookupModelFieldListInfo).then(response => {
                    if (response?.modelRows?.length === 1) {
                        row.setLookupModelData(this.field, response.modelRows[0]);
                        row.set(this.field, this.defaultDataValue);
                    }
                });
            })
        } else {
            super.applyDefaultDataValue();
        }
    }

    public get fontBold(): boolean {
        return super.fontBold;
    }

    public set fontBold(value: boolean) {
        super.fontBold = value;
        if (this._printableLabel != null)
            this._printableLabel.fontBold = value;
    }

    private _syncPlaceholder(): void {
        let value: string;
        if (this.placeholder != null)
            value = this.placeholder;
        else if (this._designer != null && this.field != null)
            value = this.field;
        else
            value = null;
        this._applyStringInputAttribute("placeholder", value);
    }

    _setText(value: string, event: Event) {
        this._internalSetText(value, event?.domEvent);
        this.userChangedText();
    }

    public addDropdownAdditionalAction(props: Partial<ButtonProps>) {
        const actionButton = new Button({ color: "primary", variant: ButtonVariant.text, ...props, rowBreak: false});
        if (this._dropdownAdditionalActions == null) {
            this._dropdownAdditionalActions = [];
        }
        this._dropdownAdditionalActions.push(actionButton);
    }

    get dropdownAdditionalActions(): Button[] {
        return this._dropdownAdditionalActions;
    }

    get nullDisplayValue(): string {
        if (this._designer == null && this.printable && this._nullDisplayValue == null && this.caption != null && this.captionVisible)
            return "--";
        return this._nullDisplayValue;
    }

    set nullDisplayValue(value: string) {
        this._nullDisplayValue = value;
    }

    get valueAsString(): string {
        return this.text;
    }

    set valueAsString(value: string) {
        this.text = value;
    }

    get clearButtonVisible(): ClearButtonVisible {
        return this._clearButtonVisible == null ? this.getPropertyDefinitions().clearButtonVisible.defaultValue : this._clearButtonVisible;
    }

    set clearButtonVisible(value: ClearButtonVisible) {
        this._clearButtonVisible = value;
        this.buttonHandler.syncButton();
        this.buttonHandler.syncListeners()
    }

    get multiline(): boolean {
        return this._multiline;
    }

    set multiline(value: boolean) {
        if (value === this._multiline)
            return;
        this._multiline = value;
        this._createTextElement(false, this.text);
    }

    override handleEnlargeOrShrink() {
        const multilineExpandButton = this.buttonHandler.multilineExpandButton
        if (multilineExpandButton == null)
            return;

        const currentlyEnlarged = multilineExpandButton.imageName === "shrink";
        if (currentlyEnlarged !== true) {
            const tempProps: Partial<TextboxProps> = {
                fillRow: true,
                fillHeight: true,
                maxHeight: undefined
            };
            if (this.enlargeScope.serializationName === "cell") {
                //haven't been able to get the multiline text area to grow vertically when in a table, except
                //when we explicitly set its height.  this feels like a hack, but for now set the height of the
                //textbox based on the height of the cell
                const cellHeight = DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("height", this.enlargeScope._element));
                const cellPaddingTop = DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("padding-top", this.enlargeScope._element))
                const cellPaddingBottom = DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("padding-bottom", this.enlargeScope._element))
                const textboxMarginTop = DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("margin-top", this._element))
                const textboxMarginBottom = DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("margin-bottom", this._element));
                log.debug("Enlarge scope:  Cell Height=%o,  Cell PaddingTop=%o,  Cell Padding Bottom=%o,  Textbox Margin Top=%o,  Textbox Margin Bottom=%o", cellHeight, cellPaddingTop, cellPaddingBottom, textboxMarginTop, textboxMarginBottom);
                tempProps.height = cellHeight - cellPaddingTop - cellPaddingBottom - textboxMarginTop - textboxMarginBottom;
            }
            this.fillHeight = false; //need to set fillHeight=false before we set it to true (?)
            this.applyTempState(tempProps);
            multilineExpandButton.tooltip = "Click to collapse";
            multilineExpandButton.imageName = "shrink";
        }
        else {
            this.revertTempState();
            multilineExpandButton.tooltip = "Click to expand";
            multilineExpandButton.imageName = "expand";
        }
    }

    override get enlargeScope(): Component {
        if (super.enlargeScope != null)
            return super.enlargeScope;
        if (this.insideTableCell === true) {
            const enclosingTableCell = this.findParentOfType("cell");
            if (enclosingTableCell != null)
                super.enlargeScope = enclosingTableCell;
        }
        return super.enlargeScope;
    }

    public set enlargeScope(value: Component) {
        super.enlargeScope = value;
        this.buttonHandler.syncMultiLineExpandButton();
    }

    private _syncInput(): void {
        this._inputDiv?.classList.toggle(TextboxStyles.disablePointerEvents, !this._interactionEnabled);
        // need to sync a lot of things when changing from the input to textarea
        if (this.fontFamily != null)
            this.fontFamily = this.fontFamily;
    }

    public get _interactionEnabled() {
        return super._interactionEnabled;
    }

    public set _interactionEnabled(value: boolean) {
        super._interactionEnabled = value;
        this._syncInput();
    }

    get forcedCase(): ForcedCase {
        if (this._forcedCase == null)
            return ForcedCase.NONE;
        else
            return this._forcedCase;
    }

    set forcedCase(value: ForcedCase) {
        this._forcedCase = value;
    }

    get password(): boolean {
        return this._password || this.getPropertyDefinitions().password.defaultValue;
    }

    set password(value: boolean) {
        this._password = value;
        this._applyStringInputAttribute("type", value === true ? "password" : null);
        this.syncAutocomplete();
        this.buttonHandler.syncListeners();
    }

    private syncAutocomplete() {
        //setting to 'no' or off' doesn't always disable this ('off' doesn't in Chrome for sure)
        this._applyStringInputAttribute("autocomplete", this.password !== true ? "nope" : "new-password");
    }

    public allowAutocomplete(value: string = "on") {
        this._applyStringInputAttribute("autocomplete", value);
    }

    isDropdownVisible(): boolean {
        return this._overlay != null;
    }

    hideDropdown(focusTextbox: boolean): void {
        log.debug("hiding dropdown");
        if (this._overlay != null) {
            const componentToFocusOnClose = focusTextbox === true ? this : null;
            Overlay.hideOverlay(this._overlay, componentToFocusOnClose);
        }
    }

    cleanupDropdown(): void {
        if (!(this._lookupModelKeyMonitor instanceof KeyboardEvent))
            this._lookupModelKeyMonitor = null;
        this._overlay = null;
        this._dropdown = null;
    }

    private _selectDropdownItem(event: PointerEvent | KeyboardEvent, focusTextbox: boolean = true): void {
        log.debug("_selectDropdownItem", event, this._dropdown, focusTextbox);
        const table = this._dropdown as Table;
        if (table.selectedRow != null) {
            this._userSelectedFromDropdown = undefined;
            const data = table.selectedRow.data;
            log.debug("_selectDropdownItem row", data, this);
            const oldValue = this.text;
            if (this.lookupModelAllowMultiSelect !== true)
                this._clearLookupModelData();
            this._addLookupModelData(data);
            this._internalUpdateBoundData();
            const chgEvent = new ChangeEvent(this, oldValue, this.text, event);
            this._changed(chgEvent);
            this.fireLookupModelSelectionListeners(data);
        }
        else if (!this.lookupModelAllowFreeform)
            this.text = null;
        this.hideDropdown(focusTextbox);
    }

    get lookupModelAllowMultiSelect(): boolean {
        return this._lookupModelAllowMultiSelect == null ? false : this._lookupModelAllowMultiSelect;
    }

    set lookupModelAllowMultiSelect(value: boolean) {
        this._lookupModelAllowMultiSelect = value;
    }

    public get lookupModelData(): ModelRow[] {
        return this._lookupModelData;
    }

    private set lookupModelData(value: ModelRow[]) {
        this._lookupModelData = value;
    }

    public getFirstLookupModelData(): ModelRow {
        if (ArrayUtil.isEmptyArray(this.lookupModelData) !== true)
            return this.lookupModelData[0];
        return null;
    }

    private _addLookupModelData(value: ModelRow, updateResultField: boolean = true, setDisplayValue: boolean = true) {
        if (this.lookupModelData == null)
            this.lookupModelData = [];
        const lookupModelResultField = this.lookupModelResultField;
        if (ArrayUtil.arrayIncludesObjectWithValue(this.lookupModelData, lookupModelResultField, value.get(lookupModelResultField)) !== true) {
            this._lookupModelData.push(value);
            this._updateLookupModelFieldsInRow(null, updateResultField);
            if (setDisplayValue === true) {
                this._setDisplayValueFromLookupModel();
                this.syncSelectedItemLabels();
            }
        }
    }

    private _removeLookupModelData(lookupModelDataRow: ModelRow) {
        if (this.lookupModelData == null)
            return;
        const index = this.lookupModelData.indexOf(lookupModelDataRow);
        if (index >= 0)
            this._removeLookupModelDataRow(this.lookupModelData[index]);
    }

    private _updateLookupModelFieldsInRow(row?: ModelRow, updateResultField: boolean = true) {
        const rowToUpdate = row != null ? row : getRelevantModelRow(this);
        if (updateResultField === true)
            rowToUpdate?.set(this.field, this.inputParser.dataValue, this);
        rowToUpdate?.setLookupModelData(this.field, this._lookupModelData, this);
    }

    private _removeLookupModelDataRow(value: ModelRow) {
        ArrayUtil.removeFromArray(this._lookupModelData, value);
        if (ArrayUtil.isEmptyArray(this._lookupModelData))
            this._clearLookupModelData();
        this._updateLookupModelFieldsInRow();
        this._setDisplayValueFromLookupModel();
        this.syncSelectedItemLabels();
    }

    private syncSelectedItemLabels() {
        if (this.lookupModelAllowMultiSelect !== true)
            return;
        let labels = this._lookupModelSelectedItemPanel?.components as LookupModelSelectedItemLabel[];
        if (ArrayUtil.isEmptyArray(labels) !== true) {
            for (const selectedItemLabel of labels) {
                if (ArrayUtil.isEmptyArray(this.lookupModelData) || this.lookupModelData.includes(selectedItemLabel.modelRow) !== true)
                    this.removeSelectedItemLabel(selectedItemLabel);
            }
        }
        if (ArrayUtil.isEmptyArray(this.lookupModelData) === true)
            return;
        for (const lookupModelDataRow of this.lookupModelData) {
            let found = false;
            labels = this._lookupModelSelectedItemPanel?.components as LookupModelSelectedItemLabel[];
            if (labels != null) {
                for (const selectedItemLabel of labels) {
                    if (selectedItemLabel.modelRow === lookupModelDataRow) {
                        found = true;
                        break;
                    }
                }
            }
            if (found === false)
                this.addLookupModelSelectedItemLabel(lookupModelDataRow);
        }
    }

    private addLookupModelSelectedItemLabel(lookupModelDataRow: ModelRow) {
        if (this._lookupModelSelectedItemPanel == null)
            this.createLookupModelSelectedItemPanel();
        const sil = new LookupModelSelectedItemLabel(this._formatLookupModelDisplayValue(lookupModelDataRow), lookupModelDataRow, (modelRow) => this._removeLookupModelData(modelRow));
        this._lookupModelSelectedItemPanel.add(sil);
        this.relocateLookupModelSelectedItemPanel(true);
    }

    private removeSelectedItemLabel(selectedItemLabel: LookupModelSelectedItemLabel) {
        const labels = this._lookupModelSelectedItemPanel?.components as LookupModelSelectedItemLabel[];
        if (ArrayUtil.isEmptyArray(labels) === true)
            return;
        let index = -1;
        let foundIndex: number = -1;
        for (const labelInList of labels) {
            index++;
            if (labelInList.modelRow === selectedItemLabel.modelRow) {
                foundIndex = index;
                break;
            }
        }
        if (foundIndex >= 0)
            this._lookupModelSelectedItemPanel.remove(labels[foundIndex])
        this.relocateLookupModelSelectedItemPanel(false);
        if (ArrayUtil.isEmptyArray(this._lookupModelSelectedItemPanel?.components))
            this.removeLookupModelSelectedItemPanel();
    }

    private createLookupModelSelectedItemPanel() {
        this._lookupModelSelectedItemPanel = new Panel({ margin: 0, padding: 0, rowBreak: false, wrap: false });
    }

    private removeLookupModelSelectedItemPanel() {
        if (this._inputDiv.contains(this._lookupModelSelectedItemPanel._element))
            this._inputDiv.removeChild(this._lookupModelSelectedItemPanel._element);
        this._lookupModelSelectedItemPanel = null;
    }

    private relocateLookupModelSelectedItemPanel(adding: boolean) {
        if (adding) {
            //if we are already using the ellipsis button, return
            if (this._lookupModelSelectedItemEllipsis != null)
                return;
            //add the selected item panel to the input div, at least to see how long it will be
            if (this._inputDiv.contains(this._lookupModelSelectedItemPanel._element) !== true)
                this._inputDiv.insertBefore(this._lookupModelSelectedItemPanel._element, this._input);
            //if the selected item panel takes up too much space, remove it from the input div, and we'll show it via the ellipsis button
            this.doWhileDimensionsComputed(() => {
                if (this.lookupModelSelectedItemsFit() !== true) {
                    this._inputDiv.removeChild(this._lookupModelSelectedItemPanel._element);
                    this._lookupModelSelectedItemPanel.wrap = true;
                    this._createLookupModelSelectedItemEllipsis();
                }
            });
        }
        else {
            //if we are already display selected items next to the input, return
            if (this._inputDiv.contains(this._lookupModelSelectedItemPanel._element) === true) {
                return;
            }
            else {
                //if we were using the ellipsis and no longer need to, remove it and move selected items next to the input
                this._lookupModelSelectedItemPanel.wrap = false; //set panel contents to not wrap so we can see how wide it would be on one line
                this._lookupModelSelectedItemPanel._element.style.position = "absolute";
                this._lookupModelSelectedItemPanel.left = -9999;
                document.body.appendChild(this._lookupModelSelectedItemPanel._element);
                const selectedItemsFit = this.lookupModelSelectedItemsFit();
                document.body.removeChild(this._lookupModelSelectedItemPanel._element);
                this._lookupModelSelectedItemPanel._element.style.position = "";
                this._lookupModelSelectedItemPanel.left = null;
                if (selectedItemsFit === true) {
                    this._removeLookupModelSelectedItemEllipsis();
                    this._inputDiv.insertBefore(this._lookupModelSelectedItemPanel._element, this._input);
                }
                else {
                    //need to keep using the ellipsis, so let panel contents wrap again
                    this._lookupModelSelectedItemPanel.wrap = true;
                    //if we still have enough selected items to need the ellipsis button, redisplay the tooltip
                    //(so that the user can remove > 1 item at a time)
                    this._displaySelectedItemLabelPopup();
                }
            }
        }
    }

    private lookupModelSelectedItemsFit(): boolean {
        const sipWidth = DOMUtil.getElementWidth(this._lookupModelSelectedItemPanel?._element, true, true);
        let total = DOMUtil.getElementWidth(this._inputDiv);
        for (let x = 0; x < this._inputDiv.children.length; x++) {
            const child = this._inputDiv.children[x];
            if (child !== this._lookupModelSelectedItemPanel?._element && child !== this._input &&
                child !== this._lookupModelSelectedItemEllipsis?._element) {
                total -= DOMUtil.getElementWidth(child, true, true);
            }
        }
        const ratio = sipWidth / total;
        return Number.isNaN(ratio) ? false : ratio < 0.5;
    }

    private _createLookupModelSelectedItemEllipsis() {
        const imageProps: Partial<ImageProps> = {
            name: "multipleSelected",
            fill: "primary",
            borderWidth: 0,
            padding: 0,
            margin: 0
        };
        this._lookupModelSelectedItemEllipsis = new Button({
            imageProps: imageProps,
            margin: 2,
            padding: 0,
            borderWidth: 0,
            onClick: (event: ClickEvent) => this._displaySelectedItemLabelPopup(),
            rowBreak: false
        });
        this._inputDiv.insertBefore(this._lookupModelSelectedItemEllipsis._element, this._input.nextSibling);
    }

    private _removeLookupModelSelectedItemEllipsis() {
        if (this._inputDiv.contains(this._lookupModelSelectedItemEllipsis._element))
            this._inputDiv.removeChild(this._lookupModelSelectedItemEllipsis._element);
        this._lookupModelSelectedItemEllipsis = null;
    }

    private _displaySelectedItemLabelPopup() {
        if (this._lookupModelAllowMultiSelectTooltip === false)
            return;
        const options: Partial<TooltipOptions> = { position: Alignment.TOP, pointerColor: "strokePrimary" };
        const props: Partial<PanelProps> = { backgroundColor: "defaultBackground", borderColor: "strokePrimary", borderWidth: 1 };
        this.showTooltip(this._lookupModelSelectedItemPanel, options, props);
    }

    private _clearLookupModelData() {
        this._lookupModelData = null;
    }

    public clear() {
        if (this.lookupModelData != null) {
            let i = this.lookupModelData.length;
            while (i--) {
                this._removeLookupModelDataRow(this.lookupModelData[i]);
            }
        }
        this.text = null;
    }

    multipleLookupModelValuesSelected(): boolean {
        return this._lookupModelAllowMultiSelect === true && ArrayUtil.isEmptyArray(this.lookupModelData) !== true;
    }

    private _getLookupModelDisplayValue(): string {  // presumably we will have a listener here to all app code to customize the displayed value
        if (this._lookupModelAllowMultiSelect === true || this.lookupModelData == null)
            return null;
        this._syncHoverCallback();
        return this._formatLookupModelDisplayValue(this.lookupModelData[0]);
    }

    private _formatLookupModelDisplayValue(lookupModelData: ModelRow): string {
        let format = this.lookupModelDisplayField;
        if (!format?.includes("{"))
            format = "{" + this.lookupModelDisplayField + "}";
        return DisplayValue.getFormattedDataString(format, lookupModelData);
    }

    toggleDropdown(): void {
        if (this.isDropdownVisible)
            this.hideDropdown(true);
        if (this._items != null)
            this.showItemDropdown();
        else if (this.hasLookupModel()) {
            let filter = this._input.value;
            //if displaying the lookup model dropdown and there is already text in the field (a selection has been made),
            //we want to query for all values and not just the one that they already selected
            //this should only happen when the user clicks on the magnifying glass or uses the down arrow
            if (StringUtil.isEmptyString(this.text) === false && this.lookupModelAllowFreeform === false && this.lookupModelAllowSearchAll === true)
                filter = "";
            this.showLookupModelDropdown(filter);
        }
        else
            throw new Error("Cannot toggleDropdown() with no items or effective lookupModel/lookupModelLayout.");
    }

    private initOverlayAnchor(comp?: Component): AnchorProps {
        const heightWidth = DOMUtil.getElementHeightWidth(this._element, false);
        const anchor = {
            anchor: this.dropDownAnchor || this._inputDiv,
            align: Alignment.LEFT,
            position: Alignment.BOTTOM,
            minWidth: JSUtil.max(DOMUtil.convertSizeStyleToPixels(comp?.minWidth, document.body.offsetWidth), heightWidth.width),
            minHeight: JSUtil.max(DOMUtil.convertSizeStyleToPixels(comp?.minHeight || comp?.height || 240, document.body.offsetHeight), heightWidth.height)
        };
        if (comp != null)
            Anchor.sizeToAnchor(comp, anchor);
        return anchor;
    }

    showOverlay(comp: Component): void {
        comp._element.classList.add(OverlayStyles.popup);
        const anchor = this.initOverlayAnchor(comp);
        Component.setPreOverlayMouseOverComponent(this);
        this._overlay = Overlay.showInOverlay(comp, {
            onClose: () => this.cleanupDropdown(),
            anchor: anchor
        });
    }

    showLookupModelDropdown(filter?: string): void {
        if (this.lookupModelDisabled === true)
            return;
        this.lookupModelLayoutManager = null;
        this._setLookupModelKeyMonitor("start");
        log.debug("showLookupModelDropdown", filter, this.lookupModelLayout);

        let props: Partial<LayoutProps> = { scrollY: true, fillHeight: false, padding: 0 };
        if (this.lookupModelLayoutHeight)
            props = { ...props, minHeight: undefined, maxHeight: undefined, height: this.lookupModelLayoutHeight };

        if (this.lookupModelLayoutWidth)
            props = { ...props, minWidth: undefined, maxWidth: undefined, width: this.lookupModelLayoutWidth };

        const layout = Layout.getLayout(this.lookupModelLayout, props);
        layout.addLayoutLoadListener(() => {
            this._dropdown = layout.findComponentByType(Table)
            if (this._dropdown == null)
                throw new Error("Couldn't show a lookup model dropdown because the layout has no table on it. Layout: " + this.lookupModelLayout);
            this._dropdown.dataSource.maxResults = this.lookupModelMaxResults;
            this.lookupModelLayoutManager = new LookupModelLayoutManager(this, layout, this._dropdown);
            if (this._dropdown.dataSource != null) {
                this._createLookupModelFieldListInfo(layout);
                const lmSearchEvent = this.fireLookupListener(filter);
                this.lookupModelLayoutManager.search(lmSearchEvent).then(() => this._doAfterLookupModelSearch());
            }
            if (this.text === filter)
                this._userSelectedFromDropdown = false;
            this._dropdown.addSelectionListener(event => {
                if (ClickEvent.eventIsFromUserClick(event) === true)
                    this._selectDropdownItem(event);
            });
            this.showOverlay(layout);
            this.lookupModelLayoutManager.overlay = this._overlay;
        });
    }

    private _createLookupModelFieldListInfo(panel: Layout) {
        this._lookupModelFieldListInfo = { layoutName: panel.layoutName };
        if (this.lookupModelExtraFieldList != null)
            this._lookupModelFieldListInfo["extraFields"] = this.lookupModelExtraFieldList;
    }

    public get lookupModelFieldListInfo(): object {
        return this._lookupModelFieldListInfo;
    }

    private set lookupModelFieldListInfo(value: object) {
        this._lookupModelFieldListInfo = value;
    }

    public set manualAddLayoutLoadedCallback(value: (layout: Layout) => void) {
        this._manualAddLayoutLoadedCallback = value;
    }

    showManualAddDropdown(filter?: string): void {
        this.hideDropdown(true);
        log.debug("showManualAddDropdown", filter, this.manualAddLayout);
        const panel = Layout.getLayout(this.manualAddLayout, { maxHeight: 320, scrollY: true, fillHeight: false, padding: 0 });
        panel.addLayoutLoadListener(() => {
            if (this._manualAddLayoutLoadedCallback)
                this._manualAddLayoutLoadedCallback(panel);
            panel.findComponentsByType(Button).forEach(button => {
                button.addClickListener(event => {
                    if (button.cancel)
                        this.hideDropdown(true);
                    else {
                        this._selectManualDropdown(panel);
                    }
                });
            });
            return undefined;
        });

        Overlay.alignToAnchor(panel, this.dropDownAnchor || this._inputDiv, Alignment.RIGHT);
        this.showOverlay(panel);
    }

    private _selectManualDropdown(panel: Layout): void {
        log.debug("_selectManualDropdown", panel);
        if (panel.validateSimple(true, true)) {
            this._userSelectedFromDropdown = undefined;
            const data = panel.mainDataSource.data;
            const oldValue = this.text;
            this._addLookupModelData(data[0]);
            this._internalUpdateBoundData();
            const chgEvent = new ChangeEvent(this, oldValue, this.text, event);
            this._changed(chgEvent);
            this.hideDropdown(true);
        }
    }

    protected override _calcFill() {
        super._calcFill();
        if (this.style.flex !== "")
            this._element.classList.add(TextboxStyles.unsetWidth);
        else
            this._element.classList.remove(TextboxStyles.unsetWidth);
    }

    private fireLookupListener(filter?: string): LookupModelSearchEvent {
        const event: LookupModelSearchEvent = new LookupModelSearchEvent(this, filter);
        this.fireListeners(_lookupListenerDef, event);
        return event;
    }

    get userSelectedFromDropdown(): boolean {
        return this._userSelectedFromDropdown;
    }

    showItemDropdown(): void {
        const onSelect = (listSelectionEvent: SelectionEvent) => {
            const dropdownSelectionEvent = new DropdownSelectionEvent(this, listSelectionEvent.newSelection,
                listSelectionEvent.newValue, listSelectionEvent.oldSelection,
                listSelectionEvent.oldValue, listSelectionEvent.domEvent);
            this._selectedItem = dropdownSelectionEvent.newSelection;
            let selectedItemCaption: string;
            if (typeof dropdownSelectionEvent.newSelection === "object") {
                if (dropdownSelectionEvent.newSelection.value == "<blank>") {
                    if (this.dataSource?.mode !== DataSourceMode.SEARCH)
                        selectedItemCaption = null;
                    else
                        selectedItemCaption = "=";
                    this._selectedItem = null;
                }
                else
                    selectedItemCaption = dropdownSelectionEvent.newSelection.caption;
            }
            const oldValue = this.text;
            this.text = selectedItemCaption;
            if (this._boundField != null)
                this._internalUpdateBoundData();
            this.fireDropdownSelectionListeners(dropdownSelectionEvent);
            const changeEvent = new ChangeEvent(this, oldValue, selectedItemCaption, listSelectionEvent.domEvent);
            this.cleanupDropdown();
            this._changed(changeEvent);
        };
        this.initOverlayAnchor();
        let items = this.resolveItems();
        items = this._addBlankOption(items);
        Component.setPreOverlayMouseOverComponent(this);
        this._dropdown = Overlay.showDropdown(this.dropDownAnchor || this._inputDiv,
            items,
            onSelect,
            null,
            { onClose: () => this.cleanupDropdown(), componentToFocusOnClose: this },
            this._selectedItem
        );
        this._overlay = this._dropdown.overlay;
    }

    get allowDropdownBlank(): boolean {
        return this._allowDropdownBlank == null ? true : this._allowDropdownBlank;
    }

    set allowDropdownBlank(value: boolean) {
        this._allowDropdownBlank = value;
    }

    private _addBlankOption(items: DropdownItem[]): DropdownItem[] {
        if (this.allowDropdownBlank === true) {
            if (!this.required || this.dataSource?.mode === DataSourceMode.SEARCH) {
                const updatedItems = [...items];
                updatedItems.splice(0, 0, { value: "<blank>", caption: "--" })
                return updatedItems;
            }
        }
        return items;
    }

    get items(): string[] | (() => string[]) | DropdownItem[] | (() => DropdownItem[]) {
        return this._items;
    }

    set items(value: string[] | (() => string[]) | DropdownItem[] | (() => DropdownItem[])) {
        this._items = value;
        this.buttonHandler.syncButton();
    }

    get width(): string | number {
        return super.width == null ? this.getBoundFieldWidth() : super.width;
    }

    set width(value: string | number) {
        super.width = value;
    }

    protected getBoundFieldWidth(): number {
        const type = this._boundField?.displayType;
        if (type != null) {
            if (type === DisplayType.TIME)
                return 112;
        }
        return null;
    }

    protected _syncDatabinding() {
        super._syncDatabinding();
        if (this._designer != null)
            this._syncPlaceholder();
    }

    protected override _fieldBindingChanged(): void {
        super._fieldBindingChanged();
        log.debug(() => ["_fieldBindingChanged", this, this._boundField]);
        if (this._boundField?.upshifted === true && this._forcedCase === undefined && this.lookupModel == null)
            this.forcedCase = ForcedCase.UPPER;
        if (this._boundField?.lookupModel != null) {
            getApiMetadata(this._boundField?.lookupModel).then(() => {
                // if there are prop getters that rely on the lookup model, add code to make them sync up here
                this._syncHoverCallback();
            });
        }
        this._syncWidth();
        this._syncAlign();
        this._syncPlaceholder();
        this.buttonHandler.syncButton();
        this.syncDesignerDisplayTypeWidth();
        this._syncFormattingFocusListener();
        this._syncHoverCallback();
        this._createItemsFromDbDisplayValues();
        this._syncDateDefault();
    }

    protected _syncHoverCallback() {
        if (this._lookupModelAllowMultiSelect === true || this.hasCustomTooltipCallback())
            return;
        this["_mixin-QuickInfo-_syncHoverCallback"]();
        if (this.tooltipCallback != null)
            return;

        if (this.hasLookupModel()) {
            this.tooltipCallback = this.basicTooltipCallback;
        } else if (this.tooltipCallback != null) {
            this.tooltipCallback = null;
        }
    }

    private hasCustomTooltipCallback(): boolean {
        return this.tooltipCallback != null
        && this.tooltipCallback !== this.basicTooltipCallback
        && this.tooltipCallback !== this["_currencyTooltipCallback"]
        && this.tooltipCallback !== this["_quickInfoTooltipCallback"];
    }

    private basicTooltipCallback(baseTooltip: Component, originatingEvent: MouseEvent) {
        const resultFieldValue = this._getBasicLookupModelTooltip(baseTooltip);
        if (resultFieldValue != null)
            return this["_internalShowTooltip"](resultFieldValue, originatingEvent);
    }

    private _getBasicLookupModelTooltip(baseTooltip: Component): string {
        let resultFieldValue = null;
        if (this._lookupModelData?.length > 0)
            resultFieldValue = this._lookupModelData[0]?.get(this.lookupModelResultField, null);
        if (resultFieldValue == null) {
            const row = getRelevantModelRow(this);
            resultFieldValue = row?.get(this.field);
        }
        return resultFieldValue;
    }

    public override async loadMetadata(): Promise<void> {
        if (this.lookupModel != null)
            await getApiMetadata(this.lookupModel);
    }

    private _syncWidth() {
        if (this.width != null)
            this._element.style.width = DOMUtil.getSizeSpecifier(this.width);
    }

    set buttonProps(value: Partial<ButtonProps>) {
        if (value !== this._buttonProps) {
            this._buttonProps = value;
            this.buttonHandler.syncButtonProps();
        }
    }

    get buttonProps(): Partial<ButtonProps> {
        return this._buttonProps;
    }

    focus(): Textbox {
        if (this._input != null)
            this._input.focus();
        else if (this._printableLabel != null)
            this._printableLabel._element.focus();

        return this;
    }

    selectText(): Textbox {
        if (this._input != null)
            this._input.select();
        return this;
    }

    override get _designer(): DesignerInterface {
        return super._designer;
    }

    override set _designer(value: DesignerInterface) {
        super._designer = value;
        this._syncInput();
    }

    private _createInputDiv() {
        this._inputDiv = document.createElement("div");
        this._inputDiv.className = TextboxStyles.textboxBase;
        this._applyEnabled(this.enabled);
    }

    private _createTextElement(initialCreation: boolean, text: string = null) {
        const oldInput = this._input;
        if (this.multiline !== true) {
            this._createInput();
            this._inputDiv.style.alignItems = "";
        }
        else {
            this._createTextArea();
            this._inputDiv.style.alignItems = "start"; //aligns interior buttons to the top of the text area
        }
        if (this._inputDiv.contains(oldInput))
            this._inputDiv.replaceChild(this._input, oldInput);
        this._attachCommonListenersToTextElement();
        if (initialCreation !== true)
            this.reattachListeners();
        this._input.value = text;
        this._syncEnabled();
        this._syncInput();
        this.buttonHandler.syncButton();
        this.buttonHandler.syncMultiLineExpandButton();
        this._syncAlign();
        this._applyAllInputAttributes();
        this._applyAllInputClasses();
        this._applyAllInputStyles();
        this._syncPrintableLabel();
        this._element.classList.remove(TextboxStyles.unsetWidth)
        if (this.required === true) {
            this.placeholder = "Required";
        }
        if (!this._inputDiv.contains(this._input)) {
            if (this._imagePre == null)
                this._inputDiv.insertBefore(this._input, this._inputDiv.firstChild);
            else {
                if (!this._inputDiv.contains(this._imagePre._element))
                    this._inputDiv.insertBefore(this._input, this._inputDiv.firstChild);
                else
                    this._inputDiv.insertBefore(this._input, this._imagePre._element.nextSibling);
            }
        }
        if (!this._element.contains(this._inputDiv))
            this._element.appendChild(this._inputDiv);
    }

    private _createInput() {
        this._input = document.createElement("input");
        this._applyBooleanInputAttribute("spellcheck", true);
        this.syncAutocomplete();
        this._applyInputClass(TextboxStyles.inputBase);
    }

    private _createTextArea() {
        this._input = document.createElement("textarea");
        this._applyInputClass(TextboxStyles.textarea);
    }

    private _attachCommonListenersToTextElement() {
        this._input.addEventListener("keydown", (event) => this.keyDown(event));
        this._input.addEventListener("input", (event) => { // when there is a prior validation warning, check with every character to see if it is corrected (reward early)
            this._internalSetText((event.target as HTMLInputElement).value, event, true, true);
            // if (this.lookupModel == null)
            this.userChangedText();
        });
        this.syncSelectTextOnFocusListener();
        this._input.addEventListener("blur", (event) => {
            const relatedTarget = (event as FocusEvent).relatedTarget;
            if (this._dropdown != null && (!(relatedTarget instanceof HTMLElement) || !this._overlay?.getOverlayContent()._element.contains(relatedTarget)) && (this._dropdown instanceof Table && !this._dropdown.noRecordsMatch))  //!event.relatedTarget.contains(this._dropdown._element)))  I think I got 'which component should contain which' backwards the first pass.  Leaving it commented out to remind me in case the backwards seeming code was right.
                this.hideDropdown(false);
            this.checkForValidationSuccess(true);
        });
    }

    private syncSelectTextOnFocusListener() {
        this.removeFocusListener(this.selectTextOnFocus);
        if (UserSettings.get()?.sel_text_on_focus === true) {
            //this listener has to go into our EventListener list so that we can make sure it runs last (after the formatting listener)
            //otherwise things like removing currency formatting will de-select the text right after we select it here
            this.addFocusListener(this.selectTextOnFocus);
        }
    }

    private selectTextOnFocus(event) {
        const textbox = event.target as Textbox;
        if (DOMUtil.isActiveElement(textbox._input) !== true)
            return;
        textbox.selectText();
    }

    private updateRowToNull(row: ModelRow, mode: DataSourceMode): void {
        if (mode === DataSourceMode.SEARCH)
            this.updateBoundSearchData(row, undefined);
        else
            row.set(this.field, null, this);
    }

    public override updateBoundData(row: ModelRow, mode: DataSourceMode) {
        if (this.field == null || this.printable === true)
            return;
        const value = this._selectedItem?.value || this.text;
        log.debug(() => ["updateBoundData", this, "Row", row, "Current text", this.text, "Value", value]);
        if (this.hasLookupModel()) {
            log.debug(() => ["uBD with lookup model", this._getLookupModelDisplayValue()], row);
            if (StringUtil.isEmptyString(value) && this.multipleLookupModelValuesSelected() !== true) {
                row.setLookupModelData(this.field, null, this);
                this._clearLookupModelData();
                this.updateRowToNull(row, mode);
            }
            else {
                if (ArrayUtil.isEmptyArray(this._lookupModelData)) {
                    row.setLookupModelData(this.field, undefined, this);
                    this.updateRowToNull(row, mode);
                }
                else
                    this._updateLookupModelFieldsInRow(row);
            }
        }
        else if (StringUtil.isEmptyString(value))
            this.updateRowToNull(row, mode);
        else {
            const dataValue = this.inputParser.dataValue;
            if (mode === DataSourceMode.SEARCH) {
                this.updateBoundSearchData(row, dataValue);
            }
            else if (DisplayType.CURRENCY === this.displayType) {
                row.set(this.field, CurrencyUtil.updateCurrency(row.get(this.field), dataValue), this);
            }
            else {
                row.set(this.field, dataValue, this);
            }
        }
    }

    private updateBoundSearchData(row: ModelRow, value: any) {
        const fieldValue = StringUtil.isEmptyString(value) ? undefined : value;
        row.set(this.field, fieldValue, this);
        if (DisplayType.DATERANGE === this.displayType) {
            const dateRange = value != null ? DateRange.parseNumericDateRange(this.text) : null;
            row.set(this.field + ".start", dateRange?.beginningDate, this);
            row.set(this.field + ".end", dateRange?.endDate, this);
        }
    }

    protected userChangedText(): void {
        if (this._boundField == null)
            return;
        this._internalUpdateBoundData();
    }

    private _internalUpdateBoundData() {
        const row = getRelevantModelRow(this);
        if (row != null) {
            const mode = getCurrentDataSourceMode(this);
            this.updateBoundData(row, mode);
        }
    }

    protected override _getBorderPropTarget(): HTMLElement {
        return this._inputDiv;
    }

    get printableLabel(): Label {
        return this._printableLabel;
    }

    override get color(): Color {
        return super.color;
    }

    override set color(value: Color) {
        super.color = value;
        this._applyInputStyle("color", getThemeColor(value))
    }

    get placeholderColor(): Color {
        return this._placeholderColor;
    }

    set placeholderColor(value: Color) {
        // I bet we will want dynamic styles elsewhere and will want to extract this.  It's easy here when we can come up with a unique name for the style.  It may be tougher to make this generic.
        if (this._placeholderColor != null) {
            const color = getThemeColor(this._placeholderColor);
            const styleName = "plcColor-" + this.stripNonAlpha(color);
            this._removeInputClass(styleName)
        }
        this._placeholderColor = value;
        const color = getThemeColor(value);
        const stripped = this.stripNonAlpha(color);
        const styleName = "plcColor-" + stripped;
        let style = dynamicStyles[styleName];
        if (style == null) {
            style = makeStyles("plcColor", {
                [stripped]: {
                    "&::placeholder": {
                        color: color
                    }
                }
            });
            dynamicStyles[styleName] = style;
        }
        this._applyInputClass(styleName);
    }

    private stripNonAlpha(input: string): string {
        return input.replace(/\W/g, "");
    }

    get caption(): string {
        return this["_mixin-Captioned-caption"];
    }

    set caption(value: string) {
        if (this["captionValueMatches"](value) === true) {
            return;
        }
        this["_mixin-Captioned-caption"] = value;
        if (typeof value === "string" && value.startsWith("{") && this._designer == null)
            this._captionLabel.caption = null;
        else
            this.syncCaption();
    }

    set captionProps(value: Partial<LabelProps>) {
        this._captionLabel.setProps(value);
    }

    syncCaption(): void {
        this._captionLabel.caption = this["getPrefixedCaption"]();
        if (this.insideTableCell === true && this._captionVisibleInsideTable === false) {
            this.captionVisible = false;
            const currPlaceholder = this.placeholder;
            if (currPlaceholder == null || currPlaceholder === "" || currPlaceholder === "Required") {
                const mode = getCurrentDataSourceMode(this);
                if (this.required === true && mode !== DataSourceMode.SEARCH) {
                    this.placeholder = "Required";
                }
                else {
                    this.placeholder = "";
                }
            }
        }
    }

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._internalSetText(value, null);
    }

    private _checkMaxLength(value: string): boolean {
        if (value == null || this._boundField == null || this._boundField.length == null || this.hasLookupModel() || getCurrentDataSourceMode(this) === DataSourceMode.SEARCH)
            return true;
        return value.length <= this._boundField.length;
    }

    private _getMaxLength(): number {
        if (this._boundField == null || this._boundField.length == null || this.hasLookupModel() || getCurrentDataSourceMode(this) === DataSourceMode.SEARCH)
            return -1;
        return this._boundField.length;
    }

    private _internalSetText(value: string, domEvent: DomEvent, fireOnChange: boolean = true, checkMaxLength: boolean = false): void {
        const oldValue = this.text;
        if (value == null) {
            if (this.nullDisplayValue != null && oldValue == this.nullDisplayValue)
                return;
            value = "";
        }
        value = this.inputFormatter.cleanText(value);
        if (checkMaxLength === true && this._checkMaxLength(value) !== true) {
            this.showTooltip("You have exceeded the " + this._getMaxLength() + " character limit.", { position: Alignment.RIGHT, shaking: true, timeout: 5000 });
            this._input.value = oldValue;
            return;
        }
        this._text = value;
        if (value === oldValue)
            return;
        this.buttonHandler.syncButton();
        this.storeUserChoiceIfRemembered();
        if (this.printable === true) {
            this._syncPrintableComponentText(value);
        }
        else
            this._input.value = value;
        if (this.lookupModel != null || this.lookupModelLayout != null) {
            if (domEvent != null && value.length >= this.lookupModelMinChars) {
                this.updateLookupModelDropdown();
                if (this.lookupModelAllowMultiSelect !== true)
                    this._clearLookupModelData();
            }
            else if (domEvent != null && value.length < this.lookupModelMinChars && oldValue.length >= this.lookupModelMinChars) {
                this.hideDropdown(true);
                if (this.lookupModelAllowMultiSelect !== true)
                    this._clearLookupModelData();
            }

            if (domEvent != null && this.lookupModelAllowFreeform) {
                const lmData = {};
                lmData[this.lookupModelDisplayField] = value;
                lmData[this.lookupModelResultField] = value;
                const data = new ModelRow(this.lookupModel, false, lmData);
                log.debug("freeform text", data, this);
                this._clearLookupModelData();
                this._addLookupModelData(data, true, false);
            }
        }

        if (this.items != null && StringUtil.isEmptyString(value)) {
            this.selectedItem = null;
        }
        this._evalNegativeCurrencyStyle();
        if (fireOnChange === true && this.text !== oldValue) {
            const event = new ChangeEvent(this, oldValue, value, domEvent);
            this._changed(event);
        }

        this.checkForValidationSuccess(false);
    }

    checkForValidationSuccess(blurring: boolean): void { // on some validations, we are just looking to clear validation warnings
        if (this.validationWarning != null || blurring)
            this.validate(true);
    }

    private getTarget(): HTMLElement {
        return this._input == null ? this._printableLabel._element : this._input;
    }

    override getDragTarget(): HTMLElement {
        return this._element;
    }

    protected override getFontTarget(): HTMLElement {
        return this.getTarget();
    }

    public override getEventTarget(): HTMLElement {
        return this.getTarget();
    }

    protected override getFocusTarget(): HTMLElement {
        return this.getTarget();
    }

    get spellcheck(): boolean {
        return this._input != null ? this._input.spellcheck : null;
    }

    set spellcheck(value: boolean) {
        this._applyInputAttribute("spellcheck", value ? "true" : "false");
    }

    protected _changed(event: ChangeEvent): void {
        this.fireListeners(_changeListenerDef, event);
    }

    get placeholder(): string {
        return this._placeholder || this._validationPlaceholder;
    }

    set placeholder(value: string) {
        this._placeholder = value;
        this._syncPlaceholder();
    }

    get validationWarning(): string {
        return this._validationWarning;
    }

    set validationWarning(value: string) {
        if (this._validationWarning === value)
            return;
        this._validationWarning = value;
        this.syncValidationWarningProps();
    }

    private syncValidationWarningProps() {
        if (this.validationWarningProps != null) {
            this.revertTempState();
            this.validationWarningProps = null;
        }
        if (!StringUtil.isEmptyString(this._validationWarning)) {
            this.validationWarningProps = { borderWidth: 1, borderColor: getThemeColor("error"), tooltip: this._validationWarning };
            if (this._validationWarning === "Required")
                this.validationWarningProps.tooltip = "Required";

            this.applyTempState(this.validationWarningProps);
        }
    }

    get printable(): boolean {
        return this["_mixin-Printable-printable"];
    }

    set printable(value: boolean) {
        this["_mixin-Printable-printable"] = value;
    }

    get printableDuringAdd(): boolean {
        return this["_mixin-Printable-printableDuringAdd"];
    }

    set printableDuringAdd(value: boolean) {
        this["_mixin-Printable-printableDuringAdd"] = value;
    }

    get printableDuringSearch(): boolean {
        return this["_mixin-Printable-printableDuringSearch"];
    }

    set printableDuringSearch(value: boolean) {
        this["_mixin-Printable-printableDuringSearch"] = value;
    }

    get printableDuringUpdate(): boolean {
        return this["_mixin-Printable-printableDuringUpdate"];
    }

    set printableDuringUpdate(value: boolean) {
        this["_mixin-Printable-printableDuringUpdate"] = value;
    }

    private _syncPrintableLabel() {
        if (this._printableLabel != null) {
            this._printableLabel.style.alignItems = this._inputDiv.style.alignItems;
            if (this.multiline === true)
                this._printableLabel.wrap = true;
        }
    }

    private _syncPrintableComponentText(text: string) {
        if (this._printableLabel != null) {
            let caption = text;
            if (StringUtil.isEmptyString(caption) === true) {
                caption = this.nullDisplayValue;
            } else if (this.password && text?.length > 0) {
                caption = "\u2022".repeat(Math.min(text.length, 15));
            }
            this._printableLabel.caption = caption;
        }
    }

    private _createPrintableLabel(text: string) {
        this._printableLabel = new Label({
            padding: 0,
            // initially, I wanted to bind the printable Label to the DataSource, mainly so that it gets all the default properties for the bound field.
            // but then that makes the DataSource see the Label as another bound component and it tries to display its data in separate step from the Textbox.
            // since the Textbox is already trying to update the Label, this cause multiple displays for the same field.  At best, this is extra work.
            // At worst (the time when I commented this out), the display value is different between the Textbox and Label because they don't yet
            // support all the same properties.
            // dataSource: this.dataSource,
            // field: this.field,
            displayType: this.displayType,
            fontSize: this.fontSize,
            fontBold: this.fontBold,
            color: this.color,
            minWidth: this.minWidth,
            fillRow: this.fillRow,
            readMoreType: this._readMoreType,
            width: this.width,
            maxHeight: this._getPrintableLabelMaxHeight(),
            maxWidth: this.maxWidth,
            tooltip: this.tooltip,
            tooltipPosition: Alignment.RIGHT
        });
        this._syncPrintableLabel();
        this._syncPrintableComponentText(text);
        this._printableLabel.readMoreCallback = () => this.toggleReadMore(this);
        this._evalNegativeCurrencyStyle();
    }

    private _evalNegativeCurrencyStyle() {
        if (this.displayType !== DisplayType.CURRENCY)
            return;

        const num = parseFloat(CurrencyUtil.removeFormatting(this.text));

        if (this._currencyColorCallback) {
            const color = this._currencyColorCallback(num)
            if (this._input != null)
                this._input.style.color = color;
            if (this._printableLabel != null)
                this._printableLabel._element.style.color = color;
            return;
        }

        let isNegative = false;
        if (CurrencySettings.getSingleton()?.colorNegatives() === true && num < 0)
            isNegative = true;

        if (isNegative === true)
            this._applyInputClass(TextboxStyles.negativeCurrency)
        else
            this._removeInputClass(TextboxStyles.negativeCurrency)

        if (this._printableLabel != null) {
            if (isNegative === true)
                this._printableLabel._element.style.color = getThemeColor("error");
            else {
                const c = this.color != null ? getThemeColor(this.color) : null;
                this._printableLabel._element.style.color = c;
            }
        }
    }

    private _getPrintableLabelMaxHeight(): string | number {
        if (this.maxHeight == null) {
            return this.maxHeight;
        }
        const maxHeightInt = DOMUtil.getStyleAttrAsNumber(this.maxHeight);
        let captionLabelHeight = 0;
        if (this.isOrContains(this._captionLabel)) {
            captionLabelHeight = DOMUtil.getStyleAttrAsNumber(this._captionLabel.height);
        }
        return maxHeightInt - captionLabelHeight - captionLabelHeight;
    }

    get maxHeight(): string | number {
        return super.maxHeight;
    }

    set maxHeight(value: string | number) {
        if (value != null)
            this._preReadMoreMaxHeight = value;
        super.maxHeight = value;
        if (this._printableLabel != null)
            this._printableLabel.maxHeight = this._getPrintableLabelMaxHeight();
    }

    toggleReadMore(textbox: Textbox) {
        const curr = textbox._element.style.maxHeight;
        if (curr == null || curr === "")
            textbox.maxHeight = textbox._preReadMoreMaxHeight;
        else
            textbox.maxHeight = null;
    }

    get readMoreType(): ReadMoreType {
        return this._readMoreType;
    }

    set readMoreType(value: ReadMoreType) {
        this._readMoreType = value;
        if (this._printableLabel != null)
            this._printableLabel.readMoreType = value;
    }

    get dateDefault() {
        return this._dateDefault;
    }

    set dateDefault(value) {
        this._dateDefault = value;
        this._syncDateDefault();
    }

    private _syncDateDefault() {
        if (!this.hasText() && this._dateDefault != null && this._designer == null) {
            this.text = this.inputFormatter.formatDefaultDateString(this._dateDefault);
        }
    }

    get insideTableCell(): boolean {
        return super.insideTableCell;
    }

    set insideTableCell(value: boolean) {
        super.insideTableCell = value;
        this.syncCaption();
        this.buttonHandler.syncMultiLineExpandButton();
    }

    get captionVisibleInsideTable(): boolean {
        return this._captionVisibleInsideTable;
    }

    set captionVisibleInsideTable(value: boolean) {
        this._captionVisibleInsideTable = value;
        this.syncCaption();
    }

    set imagePre(value: Image) {
        if (this._imagePre != null)
            this._inputDiv.removeChild(this._imagePre._element);
        this._imagePre = value;
        if (value != null)
            this._inputDiv.insertBefore(value._element, this._input);
    }

    get imagePre(): Image {
        return this._imagePre;
    }

    set imagePreName(value: string) {
        this._imagePreName = value;
        if (value == null)
            this.imagePre = null;
        else
            this.imagePre = new Image({ name: this._imagePreName, color: "subtle.light", marginLeft: 4 });
    }

    get imagePreName(): string {
        return this._imagePreName;
    }

    set imagePost(value: Image) {
        if (this._imagePost != null)
            this._inputDiv.removeChild(this._imagePost._element);
        this._imagePost = value;
        if (value != null)
            this._inputDiv.appendChild(value._element);
    }

    get imagePost(): Image {
        return this._imagePost;
    }

    set imagePostName(value: string) {
        this._imagePostName = value;
        if (value == null)
            this.imagePost = null;
        else
            this.imagePost = new Image({ name: this._imagePostName, color: "subtle.light", marginRight: 4 });
    }

    get imagePostName(): string {
        return this._imagePostName;
    }

    get addlValidationCallback(): (value: string) => ValidationResult {
        return this._addlValidationCallback;
    }

    set addlValidationCallback(value: (value: string) => ValidationResult) {
        this._addlValidationCallback = value;
    }

    override validate(checkRequired: boolean, showErrors: boolean = true): ValidationResult[] {
        if (this.printable) return null;

        const inputParser = AbstractInputParser.createParser(this);

        try {
            if (this.hasFocus() === false) {
                const formatted = inputParser.displayValue;
                if (formatted !== this.text) {
                    this._validationWarning = null; // null out to prevent internalSet from calling validate again
                    this.text = formatted;
                    this._internalUpdateBoundData();
                }
            }

            const result = inputParser.validate(checkRequired, showErrors);
            this.validationWarning = result?.validationMessage;
            return result ? [result] : null;
        }
        catch (error) {
            log.debug("Error validating textbox for [" + this.id + "]");
            throw (error);
        }
    }

    override resetValidation() {
        this.validationWarning = null;
    }

    get selectedItem(): DropdownItem {
        return this._selectedItem;
    }

    set selectedItem(value: DropdownItem) {
        this._selectedItem = value;
        this.text = value == null ? "" : value.caption;
    }

    override displayData(data: ModelRow, allData: ModelRow[], rowIndex: number): void {
        if (this._boundField?.dynamicDbDisplayValues === true)
            this._createItemsFromDynamicDbDisplayValues(data, allData, rowIndex);
        else
            this._internalDisplayData(data, allData, rowIndex);
    }

    private _internalDisplayData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        if (this.field != null) {
            const value = data != null ? ((data instanceof ModelRow) ? data.get(this.field) : data[this.field]) : null;
            log.debug("displayData", this, data, value);
            if (value == null && this.visible === true) {
                this._clearLookupModelData();
                if (this.hasLookupModel() && data instanceof ModelRow && data?.get(ModelRow.getLookupModelFieldName(this.field)) != null) {
                    this._extractLookupModelDataFromRow(data, allData, rowIndex);
                    return;
                }
                else {
                    if (this.nullDisplayValue === "hide") {
                        this.text = "";
                        this.visible = false;
                    } else {
                        this.visible = true; // really shouldn't set 'visible' directly, but instead register our opinion about visibility in a different flag
                        this.text = this.nullDisplayValue;
                    }
                }
            }
            else {
                if (this._items != null) {
                    const items = this.resolveItems();
                    for (const item of items)
                        if ((item.value == null && item.caption === this.asString(value)) || (item.value === this.asString(value))) {
                            this._selectedItem = item;
                            this.text = item.caption;
                            break;
                        }
                } else if (this.hasLookupModel()) {
                    this._extractLookupModelDataFromRow(data, allData, rowIndex);
                    return;
                }
                else if (this.printable && this.displayType === DisplayType.DATETIME && this.format === ExtendedDateFormat.RELATIVE)
                    this.text = getRelativeDateString(value, { object: this, propertyToSet: "text" });
                else
                    this.text = DisplayValue.getDisplayValue(value, this.displayType, this.format);
                if (this.nullDisplayValue === "hide" && !StringUtil.isEmptyString(this.text))
                    this.visible = true;
            }
        }
        if (typeof this.caption === "string" && this.caption.startsWith("{") && this.caption.endsWith("}")) {
            if (data == null)
                this._captionLabel.caption = null;
            else
                this._captionLabel.caption = data != null ? ((data instanceof ModelRow) ? data.get(this.caption.substring(1, this.caption.length - 1)) : data[this.caption.substring(1, this.caption.length - 1)]) : null;
        }
        super.displayData(data, allData, rowIndex);
    }

    private asString(value: any): string {
        if (typeof value === "number")
            return "" + value;
        else
            return value;
    }

    private _extractLookupModelDataFromRow(row: ModelRow, allData: ModelRow[], rowIndex: number) {
        try {
            this._lookupModelAllowMultiSelectTooltip = false;
            if (!(row instanceof ModelRow)) return;
            this._clearLookupModelData();
            let lmDataFromRow = row.get(ModelRow.getLookupModelFieldName(this.field));
            const fieldValue = row.get(this.field);
            if (this.lookupModelAllowMultiSelect === true) {
                //field is a multi-select type-ahead field
                //if lmDataFromRow includes the same number of rows as there are values, use lmDataFromRow
                //otherwise, get lookup model data from the server

                let lmDataFromRowCount = 0;
                let itemsInFieldValue: number;
                if (typeof fieldValue === "string") {
                    if (lmDataFromRow != null) {
                        for (const dataRow of lmDataFromRow) {
                            if (dataRow.type === ModelRowType.LOOKUP_MODEL_DATA)
                                lmDataFromRowCount++;
                        }
                    }
                    itemsInFieldValue = fieldValue.split(",").length;
                }
                if (itemsInFieldValue == null || itemsInFieldValue === 0) {
                    //if there are no items in the field value (if the field value is blank/null), return
                    return;
                }
                if (itemsInFieldValue === lmDataFromRowCount) {
                    //if we already have lookup model data, and the number of rows in the lookup model data
                    //match the number of items in the field value, then use the lookup model data we have
                    //we should only need to update the display value and the selected item labels;
                    //the lookup model display/result fields should already be correct
                    for (const dataRow of lmDataFromRow) {
                        this._addLookupModelData(dataRow, false);
                    }
                    super.displayData(row, allData, rowIndex);
                }
                else {
                    //we don't have any lookup model data, or we don't have the right number of lookup model data rows
                    //so, ask the server for the lookup model data
                    //this will be the standard case for a multi-select type-ahead that gets its data from a normal model search,
                    //as those searches don't include multiple lookup model data rows
                    const panel = Layout.getLayout(this.lookupModelLayout, { maxHeight: 320, scrollY: true, fillHeight: false, padding: 0 });
                    panel.addLayoutLoadListener(() => {
                        const searchFilter = {};
                        searchFilter[this.lookupModelResultField] = "in " + fieldValue;
                        if (panel.mainDataSource != null) {
                            this._createLookupModelFieldListInfo(panel);
                            panel.mainDataSource.search(searchFilter, null, this._lookupModelFieldListInfo).then(response => {
                                if (response == null) {
                                    super.displayData(row, allData, rowIndex);
                                    return;
                                }
                                for (const dataRow of response.modelRows) {
                                    dataRow.type = ModelRowType.LOOKUP_MODEL_DATA;
                                    this._addLookupModelData(dataRow, false);
                                }
                                super.displayData(row, allData, rowIndex);
                            });
                        }
                    });
                }
            }
            else if (lmDataFromRow != null) {
                //field is not a multi-select type-ahead field
                //we expect the lookup model data to be in lmDataFromRow, so use that

                if (Array.isArray(lmDataFromRow) === true && ArrayUtil.isEmptyArray(lmDataFromRow) !== true) {
                    //only reading position zero because we can currently only get one row from the lookup model outer join in the model query
                    lmDataFromRow = lmDataFromRow[0];
                    if (lmDataFromRow instanceof ModelRow)
                        lmDataFromRow = lmDataFromRow.data;
                }
                const dataRow = new ModelRow(this.lookupModel, false, lmDataFromRow);
                dataRow.type = ModelRowType.LOOKUP_MODEL_DATA;
                this._addLookupModelData(dataRow, false);
                super.displayData(row, allData, rowIndex);
            }
        }
        finally {
            this._lookupModelAllowMultiSelectTooltip = undefined;
        }
    }

    private _setDisplayValueFromLookupModel() {
        this._internalSetText(this._getLookupModelDisplayValue(), null, false);
    }

    public override isEmpty(): boolean {
        if (this.lookupModelAllowMultiSelect === true)
            return ArrayUtil.isEmptyArray(this.lookupModelData);
        return !this.hasText();
    }

    public hasText(): boolean {
        return this.text.trim().length > 0;
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return TextboxPropDefinitions.getDefinitions();
    }

    addChangeListener(value: ChangeListener): Textbox {
        return this.addEventListener(_changeListenerDef, value) as Textbox;
    }

    removeChangeListener(value: ChangeListener): Textbox {
        return this.removeEventListener(_changeListenerDef, value) as Textbox;
    }

    addBeforeLookupModelSearchListener(value: LookupModelSearchListener): Textbox {
        return this.addEventListener(_lookupListenerDef, value) as Textbox;
    }

    removeBeforeLookupModelSearchListener(value: LookupModelSearchListener): Textbox {
        return this.removeEventListener(_lookupListenerDef, value) as Textbox;
    }

    public addDropdownSelectionListener(value: DropdownSelectionListener): Textbox {
        return this.addEventListener(_dropdownSelectionListenerDef, value) as Textbox;
    }

    public removeDropdownSelectionListener(value: DropdownSelectionListener): Textbox {
        return this.removeEventListener(_dropdownSelectionListenerDef, value) as Textbox;
    }

    private fireDropdownSelectionListeners(event: DropdownSelectionEvent): void {
        this.fireListeners(_dropdownSelectionListenerDef, event);
    }

    public addLookupModelSelectionListener(value: LookupModelSelectionListener): Textbox {
        return this.addEventListener(_lookupModelSelectionListenerDef, value) as Textbox;
    }

    public removeLookupModelSelectionListener(value: LookupModelSelectionListener): Textbox {
        return this.removeEventListener(_lookupModelSelectionListenerDef, value) as Textbox;
    }

    private fireLookupModelSelectionListeners(selectedItemData: ModelRow): void {
        this.fireListeners(_lookupModelSelectionListenerDef, () => new LookupModelSelectionEvent(this, selectedItemData));
    }

    public addPrintableListener(value: PrintableListener) {
        this.addEventListener(printableListenerDef, value);
    }

    public removePrintableListener(value: PrintableListener) {
        this.removeEventListener(printableListenerDef, value);
    }

    get captionVisible(): boolean {
        return this._captionVisible;
    }

    set captionVisible(value: boolean) {
        if (value === this._captionVisible)
            return;
        this._captionVisible = value;
        if (value)
            this._element.insertBefore(this._captionLabel._element, this._element.firstChild);
        else
            this._element.removeChild(this._captionLabel._element);
    }

    set captionAlignment(value: Alignment.LEFT | Alignment.TOP) {
        this._captionAlignment = value;
        if (value === Alignment.LEFT) {
            this._element.style.display = "flex";
            this._element.style.flexDirection = "row";
            this._element.style.alignItems = "center";
            this._captionLabel.marginRight = 4;
        }
        else {
            this._element.style.display = "unset";
            this._element.style.alignItems = "";
            this._captionLabel.marginRight = 0;
        }
    }

    get captionAlignment(): Alignment.LEFT | Alignment.TOP {
        if (this._captionAlignment == null)
            return Alignment.TOP;
        else
            return this._captionAlignment;
    }

    keyDown(kbEvent): void {
        const event = kbEvent as KeyboardEvent;
        if (event.key == null) {
            log.debug("Not processing null key in textbox %o, event: %o", this.id, event);
            return;
        }
        const dropdownVisible = this.isDropdownVisible();
        log.debug("keydown  event: %o  dropdownVisible: %o  _lookupModelKeyMonitor: %o", event, dropdownVisible, this._lookupModelKeyMonitor);
        if (event.key === Keys.TAB && this._lookupModelKeyMonitor === "start") {
            log.debug("setting _lookupModelKeyMonitor: %o", event);
            this._setLookupModelKeyMonitor(event);
        }
        else if (event.key === Keys.TAB && this._lookupModelKeyMonitor === "displayed" &&
            dropdownVisible && this._dropdown instanceof Table && this._dropdown.selectedRow == null) {
            this._selectFirstLookupModelResult(event);
        }
        else if (this._dropdown != null && this._dropdown.sendKey(event)) {
            event.stopPropagation();
            event.preventDefault();
        }
        else if ((event.key === Keys.ENTER || event.key === Keys.TAB) && (dropdownVisible || this._multiline)) {
            if (!this._multiline) {
                //This is only reachable for a lookup model dropdown.  When a user uses the Enter key to select an
                //item from a combo-style dropdown, the key is not captured by this method.  It is handled by the
                //List in the Overlay (specifically its selection listener).
                log.debug("selecting dropdown item based on KeyboardEvent: %o", event);
                this._selectDropdownItem(event);
                if (event.key !== Keys.TAB)
                    event.preventDefault();
            }
            //stop propagation unless the user hit Ctrl+Enter in a multiline field
            //in that case we want the key combination to trickle up to normal key handling (specifically for Tables)
            if (this._multiline !== true || event.key !== Keys.ENTER || event.ctrlKey !== true)
                event.stopPropagation();
        }
        else if (event.key === "Escape" && dropdownVisible) {
            this.hideDropdown(true);
            event.stopPropagation();
            event.preventDefault();
        }
        else if (event.key === Keys.BACKSPACE && this.items != null) {
            this.addDropdownKey(event);
            event.preventDefault();
        }
        else if (event.key != null &&
            ((TextboxConsumedKeys.includes(event.key) || TextboxConsumedKeys.includes(event.key.toUpperCase())) ||
                (event.ctrlKey && (TextboxConsumedCtrlKeys.includes(event.key) || TextboxConsumedCtrlKeys.includes(event.key.toUpperCase())))))
            event.stopPropagation();
        else if (this.items != null) {
            if (event.key === Keys.ARROW_DOWN && !dropdownVisible) {
                this.toggleDropdown();
                event.stopPropagation();
                event.preventDefault();
            }
            else if (event.key !== Keys.TAB) {
                this.addDropdownKey(event);
                event.preventDefault();
            }
        }
    }

    showAllLookupModelResults() {
        this.updateLookupModelDropdown(true);
    }

    private updateLookupModelDropdown(showAllResults: boolean = false) {
        if (this.lookupModelDisabled === true)
            return;
        this._setLookupModelKeyMonitor("start");
        if (this._acTimeoutHandle != null)
            window.clearTimeout(this._acTimeoutHandle);
        this._acTimeoutHandle = window.setTimeout(() => {
            if (this._dropdown == null) {
                if (this.valueAsString.length >= this.lookupModelMinChars)
                    this.showLookupModelDropdown(this.text);
            }
            else {
                const table = this._dropdown as Table;
                table.dataSource.maxResults = showAllResults === true ? null : this.lookupModelMaxResults;
                this.lookupModelLayoutManager.hideButtons();
                const lmSearchEvent = this.fireLookupListener(this.text);
                this.lookupModelLayoutManager.search(lmSearchEvent).then(() => this._doAfterLookupModelSearch());
            }
        }, this.lookupModelInputDelay);
    }

    private _doAfterLookupModelSearch() {
        log.debug("doAfterLookupModelSearch _lookupModelKeyMonitor: %o", this._lookupModelKeyMonitor);
        if (this._lookupModelKeyMonitor != null && this._lookupModelKeyMonitor instanceof KeyboardEvent)
            this._selectFirstLookupModelResult(this._lookupModelKeyMonitor);
        else if (this._lookupModelKeyMonitor === "start") {
            this._setLookupModelKeyMonitor("displayed");
            this.lookupModelLayoutManager.checkButtonAvailability();
        }
    }

    private _selectFirstLookupModelResult(event: KeyboardEvent) {
        const dropdownTable = this._dropdown as Table;
        if (dropdownTable?.rowCount > 0) {
            dropdownTable.selectedIndex = 0;
            this._selectDropdownItem(event, false);
        }
        else {
            if (this.lookupModelAllowFreeform !== true)
                this.text = null;
            this.hideDropdown(false);
        }
        this._setLookupModelKeyMonitor(null);
    }

    private _setLookupModelKeyMonitor(value: string | KeyboardEvent) {
        log.debug("may set _lookupModelKeyMonitor; current value: %o  proposed value: %o", this._lookupModelKeyMonitor, value);
        if (value === "start" && this._lookupModelKeyMonitor instanceof KeyboardEvent)
            return;
        this._lookupModelKeyMonitor = value;
    }

    hasDropdown(): boolean {
        return this.items != null || this.hasLookupModel();
    }

    hasLookupModel(): boolean {
        return this.lookupModel != null;
    }

    private addDropdownKey(kbEvent: KeyboardEvent): void {
        let key = kbEvent.key;
        if (key == null || (key.length !== 1 && key !== Keys.BACKSPACE)) {
            log.debug("Not processing key in dropdown: event %o", kbEvent);
            return;
        }
        const thisKeyPress = new Date();
        key = key.toLowerCase();
        if (this._lastKeyString != null && (this._lastKeyPress == null || (thisKeyPress.getTime() - this._lastKeyPress.getTime()) < 500)) {
            log.debug("Appending to dropdown search text: key %o, previous text %o", key, this._lastKeyString);
            this._lastKeyString = JSUtil.appendKeyToString(key, this._lastKeyString);
        }
        else {
            log.debug("Beginning dropdown search text: key %o", key);
            this._lastKeyString = JSUtil.appendKeyToString(key, "");
        }
        this._lastKeyPress = thisKeyPress;
        log.debug("Dropdown processing search text: %o", this._lastKeyString);
        if (StringUtil.isEmptyString(this._lastKeyString) !== true) {
            if (this._dropdown == null) {
                const item = this.findItem(this._lastKeyString, false);
                //fire dropdown selection listener here?
                this._selectedItem = item;
                if (item == null)
                    this._lastKeyString = "";
                else {
                    this._internalSetText(item.caption, kbEvent);
                    this.userChangedText();
                }
            }
            else if (this._dropdown instanceof List)
                this._dropdown.search(this._lastKeyString);
        }
        else {
            if (this.allowDropdownBlank === true) {
                if (this._selectedItem != null || StringUtil.isEmptyString(this.text) !== true) {
                    log.debug("Clearing dropdown selection");
                    this._selectedItem = null;
                    this._internalSetText("", kbEvent);
                    this.userChangedText();
                }
                else
                    log.debug("Not clearning dropdown selection, field already blank");
            }
            else
                log.debug("Not clearing dropdown selection, blank option not allowed");
        }
    }

    resolveItems(): DropdownItem[] {
        let items = this._items;
        if (typeof items === "function")
            items = items();
        if (items == null || items.length === 0 || typeof items[0] !== "string")
            return items as DropdownItem[];
        const result = [];
        for (const item of items)
            result.push({ caption: item });
        return result;
    }

    private findItem(startsWith: string, caseSensitive: boolean = true): DropdownItem | undefined {
        if (!caseSensitive)
            startsWith = startsWith.toLowerCase();
        const items = this.resolveItems();
        for (const item of items) {
            const itemString = item.caption;
            if (itemString != null && itemString.startsWith != null && ((caseSensitive && itemString.startsWith(startsWith)) || (!caseSensitive && itemString.toLowerCase().startsWith(startsWith))))
                return item;
        }
        return undefined;
    }

    get variant(): TextboxVariant {
        return this._variant;
    }

    set variant(value: TextboxVariant) {
        if (this._variant === value)
            return;
        this._variant = value;
        if (value !== TextboxVariant.UNDERLINED)
            this._inputDiv.classList.remove(TextboxStyles.inputUnderlined);
        else
            this._inputDiv.classList.add(TextboxStyles.inputUnderlined);
        if (value !== TextboxVariant.NO_LINES)
            this._inputDiv.classList.remove(TextboxStyles.inputNoLines);
        else
            this._inputDiv.classList.add(TextboxStyles.inputNoLines);
    }


    get lookupModelDisabled(): boolean {
        return this._lookupModelDisabled;
    }

    set lookupModelDisabled(value: boolean) {
        this._lookupModelDisabled = value;
        if (value === true) {
            this._lookupModel = null;
            this._lookupModelLayout = null;
            this._lookupModelDisplayField = null;
            this._lookupModelResultField = null;
        }
        this.buttonHandler.syncButton();
        this._syncHoverCallback();
    }

    get lookupModel(): string {
        if (this.lookupModelDisabled === true)
            return null;
        if (this._lookupModel == null && this._boundField != null)
            return this._boundField.lookupModel;
        return this._lookupModel;
    }

    set lookupModel(value: string) {
        this._lookupModel = value;
        if (value != null)
            getApiMetadata(value);
        this.buttonHandler.syncButton();
    }

    get lookupModelAllowSearchAll(): boolean {
        return this._lookupModelAllowSearchAll != null ? this._lookupModelAllowSearchAll : true;
    }

    set lookupModelAllowSearchAll(value: boolean) {
        this._lookupModelAllowSearchAll = value;
    }

    get lookupModelAllowShowAllResults(): boolean {
        return this._lookupModelAllowShowAllResults != null ? this._lookupModelAllowShowAllResults :
            this.getPropertyDefinitions().lookupModelAllowShowAllResults.defaultValue;
    }

    set lookupModelAllowShowAllResults(value: boolean) {
        this._lookupModelAllowShowAllResults = value;
    }

    get lookupModelAllowFreeform(): boolean {
        return this._lookupModelAllowFreeform != null ? this._lookupModelAllowFreeform : false;
    }

    set lookupModelAllowFreeform(value: boolean) {
        this._lookupModelAllowFreeform = value;
    }

    get lookupModelLayout(): string {
        return this._lookupModelLayout ?? this.getLookupModelApiMetadata()?.lookupLayout;
    }

    set lookupModelLayout(value: string) {
        this._lookupModelLayout = value;
        this.buttonHandler.syncButton();
    }

    get lookupModelInputDelay(): number {
        if (this._lookupModelInputDelay == null)
            return TextboxPropDefinitions.getDefinitions().lookupModelInputDelay.defaultValue;
        if (this._lookupModelInputDelay < 300)
            return 300;
        return this._lookupModelInputDelay;
    }

    set lookupModelInputDelay(value: number) {
        this._lookupModelInputDelay = value;
    }

    get lookupModelPopulatedButton(): LookupModelPopulatedButton {
        return this._lookupModelPopulatedButton;
    }

    set lookupModelPopulatedButton(value: LookupModelPopulatedButton) {
        this._lookupModelPopulatedButton = value;
    }

    get lookupModelResultField(): string {
        return this._lookupModelResultField ?? this.getLookupModelApiMetadata()?.keyFields?.[0];  // need to make sure this is pre-fetched (shows up as blank in the designer the first time a component is selected)
    }

    set lookupModelResultField(value: string) {
        this._lookupModelResultField = value;
        this.buttonHandler.syncButton();
    }

    get lookupModelDisplayField(): string {
        return this._lookupModelDisplayField ?? this.getLookupModelApiMetadata()?.displayField;
    }

    set lookupModelDisplayField(value: string) {
        this._lookupModelDisplayField = value;
        this.buttonHandler.syncButton();
    }

    get lookupModelExtraFieldList(): string {
        return this._lookupModelExtraFieldList;
    }

    set lookupModelExtraFieldList(value: string) {
        this._lookupModelExtraFieldList = value;
    }

    get lookupModelMaxResults(): number {
        return this._lookupModelMaxResults;
    }

    set lookupModelMaxResults(value: number) {
        this._lookupModelMaxResults = value;
    }

    get lookupModelMinChars(): number {
        return this._lookupModelMinChars != null ? this._lookupModelMinChars : 3;
    }

    set lookupModelMinChars(value: number) {
        this._lookupModelMinChars = value;
    }

    private getLookupModelApiMetadata(): ApiMetadata {
        if (this.lookupModelDisabled !== true && this._boundField?.lookupModel != null)
            return getApiMetadataFromCache(this._boundField.lookupModel);
    }

    get quickInfoLayout(): string {
        return this["_mixin-QuickInfo-quickInfoLayout"];
    }

    set quickInfoLayout(value: string) {
        this["_mixin-QuickInfo-quickInfoLayout"] = value;
    }

    validateBlur(event: BlurEvent): boolean {
        let proceedWithBlur = true;
        const relatedTarget = event.relatedTarget;
        const nonInputComponents = this._getNonInputComponents();
        for (let x = 0; x < nonInputComponents.length; x++) {
            if (relatedTarget === nonInputComponents[x]._element) {
                proceedWithBlur = false;
                break;
            }
        }
        return proceedWithBlur;
    }

    protected _getDefaultEventProp(): string {
        return "onChange";
    }

    private _getNonInputComponents(): Component[] {
        const result = [];
        if (this._overlay != null)
            result.push(this._overlay);
        if (this._dropdown != null)
            result.push(this._dropdown);
        if (this.buttonHandler.button != null)
            result.push(this.buttonHandler.button);
        if (this.buttonHandler.multilineExpandButton != null)
            result.push(this.buttonHandler.multilineExpandButton);
        return result;
    }

    public _getTooltipAnchor(): HTMLElement {
        if (this._input == null)
            return this._printableLabel._element;
        else
            return this._inputDiv;
    }

    get align(): HorizontalAlignment {
        if (this._textboxAlign === undefined && isRightAlignedDisplayType(this.displayType))
            return HorizontalAlignment.RIGHT;
        return this._textboxAlign || HorizontalAlignment.LEFT;
    }

    set align(value: HorizontalAlignment) {
        this._textboxAlign = value;
        this._syncAlign();
    }

    private _syncAlign() {
        const value = this.align;
        let result: string;
        if (value === HorizontalAlignment.LEFT)
            result = "";
        else if (value === HorizontalAlignment.RIGHT)
            result = "right";
        else
            result = "center";
        this._applyInputStyle("textAlign", result);
    }

    private _formattingFocusEvent(event: Event) {
        const textbox = event.target as Textbox;
        if (textbox.displayType === DisplayType.CURRENCY)
            textbox.text = CurrencyUtil.removeFormatting(textbox.text);
        else
            textbox.text = NumberUtil.removeFormatting(textbox.text);
    }

    private _syncFormattingFocusListener() {
        this.removeFocusListener(this._formattingFocusEvent);
        if (isDisplayTypeNumeric(this.displayType)) {
            this.insertFocusListener(this._formattingFocusEvent, 0); //formatting needs to happen before other focus listeners, like the one that selects text
        }
    }

    get displayType(): DisplayType {
        let result = this._displayType;
        if (result === undefined && this._boundField != null)
            result = this._boundField.displayType;
        return result;
    }

    set displayType(value: DisplayType) {
        this._displayType = value;
        this._syncHoverCallback();
        this._syncAlign();
        this.buttonHandler.syncButton();
        this._syncFormattingFocusListener();
        this.syncDesignerDisplayTypeWidth();
        if (value === DisplayType.COLOR)
            this._applyStringInputAttribute("type", "color");
    }

    private syncDesignerDisplayTypeWidth(): void {
        if (this._designer == null || this.width != null || this.isDeserializing())
            return;
        const displayType = this.displayType;
        if (displayType === DisplayType.PHONE)
            this.width = 176;
        else if (displayType === DisplayType.DATE)
            this.width = 128;
        else if (displayType === DisplayType.DATETIME)
            this.width = 180;
        else if (displayType === DisplayType.TIME)
            this.width = 112;
        else if (displayType === DisplayType.DATERANGE)
            this.width = 205;
    }

    override _applyEnabled(value: boolean): void {
        this._applyBooleanInputAttribute("disabled", !value);
        this._inputDiv?.classList.toggle(TextboxStyles.disabled, !value)
        this._inputDiv?.classList.toggle(TextboxStyles.disablePointerEvents, !value || !this._interactionEnabled);
    }

    get manualAddLayout(): string {
        return this._manualAddLayout;
    }

    set manualAddLayout(value: string) {
        this._manualAddLayout = value;
    }

    override getPropertyDefaultValue(prop: ComponentPropDefinition): any {
        if (prop.name === "displayType")
            return this._boundField?.displayType || DisplayType.STRING;
        else if (prop.name === "forcedCase") {
            if (this._boundField?.upshifted === true && this.lookupModel == null)
                return ForcedCase.UPPER;
            else
                return ForcedCase.NONE;
        }
        else if (prop.name === "align") {
            if (isRightAlignedDisplayType(this.displayType))
                return HorizontalAlignment.RIGHT;
            else
                return HorizontalAlignment.LEFT;
        }
        else if (prop.name === "lookupModel")
            return this._boundField?.lookupModel;
        else if (prop.name === "lookupModelResultField") {
            return this.getLookupModelApiMetadata()?.keyFields?.[0];
        }
        else if (prop.name === "lookupModelLayout") {
            return this.getLookupModelApiMetadata()?.lookupLayout;
        }
        else if (prop.name === "lookupModelDisplayField") {
            return this.getLookupModelApiMetadata()?.displayField;
        }
        else if (prop.name === "quickInfoLayout")
            return this["getQuickInfoLayoutDefaultValue"]();
        else if (prop.name === "items") {
            //recreate items from DB/Display values, and test them against the items in use
            //if they are the same, return this.items as the default value so that they are
            //not included in the serialized version of the component
            if (this._boundField?.dynamicDbDisplayValues !== true && this._boundField?.dbDisplayValues != null) {
                const dbDisplayItems = this._createDropdownItems(this._boundField.dbDisplayValues);
                if (dbDisplayItems != null && this.items != null && dbDisplayItems.length === this.items.length) {
                    let matches = true;
                    for (let x = 0; x < this.items.length; x++) {
                        const item = this.items[x] as DropdownItem;
                        const dbDisplayItem = dbDisplayItems[x] as DropdownItem;
                        if (item.caption !== dbDisplayItem.caption || item.value !== dbDisplayItem.value) {
                            matches = false;
                            break;
                        }
                    }
                    if (matches === true)
                        return this.items;
                }
            }
        }
        return super.getPropertyDefaultValue(prop);
    }

    getSearchValues(): string[] {
        const result = [];
        result.push(this.text);
        return result;
    }

    override get serializationName() {
        return "textbox";
    }

    override get properName(): string {
        return "Textbox";
    }

    /**
     * Create dropdown items from DB/Display values that are present in the metadata
     * Also remove any forcedCase value so that display values don't have their case changed (DB values will already be correct)
     */
    private _createItemsFromDbDisplayValues() {
        if (this._items != null || this.hasLookupModel())
            return;
        if (this._boundField?.dynamicDbDisplayValues !== true && this._boundField?.dbDisplayValues != null) {
            this.items = this._createDropdownItems(this._boundField.dbDisplayValues);
            if (this._items?.length > 0)
                this.forcedCase = undefined;
        }
    }

    /**
     * When a field needs dynamic DB/Display values, call the API to get them (pass the field name and ModelRow as context)
     * Also remove any forcedCase value so that display values don't have their case changed (DB values will already be correct)
     * Finally, call _internalDisplayData() after values have loaded, so that current value gets set in the dropdown
     *
     * @param data
     * @param allData
     * @param rowIndex
     */
    private _createItemsFromDynamicDbDisplayValues(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        if (this._boundField?.dynamicDbDisplayValues !== true || data == null) //data can be null when fields are cleared when a search runs
            return;
        return Api.search("dynamic-values", {
            "endpoint": data._modelPath,
            "field": this.field,
            "row": data
        }).then(response => {
            const values = response?.db_display_values;
            if (values != null) {
                this.items = this._createDropdownItems(values);
                if (this._items?.length > 0)
                    this.forcedCase = undefined;
            }
        }).catch(err => {
            log.debug("An error occurred while searching for dynamic DB/Display Values", err);
        }).finally(() => {
            this._internalDisplayData(data, allData, rowIndex);
        });
    }

    /**
     * Convert DB/Display values into DropdownItems that can be presented to the user.
     *
     * @param dbDisplayValues an array of DbDisplayValue objects
     * @returns an array of DropdownItem objects
     */
    private _createDropdownItems(dbDisplayValues: DbDisplayValue[]): DropdownItem[] {
        const result: DropdownItem[] = [];
        for (const dbDisplayValue of dbDisplayValues) {
            result.push({ value: dbDisplayValue.dbValue, caption: dbDisplayValue.displayValue });
        }
        return result;
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "change": { ..._changeListenerDef },
            "lookup": { ..._lookupListenerDef },
            "dropdownSelection": { ..._dropdownSelectionListenerDef },
            "lookupModelSelection": { ..._lookupModelSelectionListenerDef },
            "printable": { ...printableListenerDef }
        };
    }

    override getBasicValue(): any {
        return this.text;
    }

    override dataSourceModeChanged(mode: DataSourceMode) {
        super.dataSourceModeChanged(mode);
        this["_syncPrintable"]();
    }

    protected _applyPrintable(value: boolean) {
        if (value === true) {
            if (this._element.contains(this._inputDiv))
                this._element.removeChild(this._inputDiv);
            if (this._printableLabel == null) {
                //wait until now to get the text value...it may have been formatted by the formatting blur event when the inputDiv was removed from the DOM
                this._createPrintableLabel(this.text);
                if (this._designer != null)
                    this._printableLabel.caption = this.field;
                this._element.appendChild(this._printableLabel._element);
            }
            if (this._captionLabel != null)
                this._captionLabel.paddingLeft = 0;

            if (this._input != null && this._inputDiv.contains(this._input))
                this._inputDiv.removeChild(this._input);
            this._input = null;
            this._element.classList.add(TextboxStyles.unsetWidth);
        }
        else if (this._printableLabel != null) {
            const text = this.text;
            if (this._captionLabel != null)
                this._captionLabel.paddingLeft = 2;
            this._element.removeChild(this._printableLabel._element);
            this._printableLabel = null;
            this.maxHeight ??= this._preReadMoreMaxHeight;
            this._createTextElement(false, text);
        }

        this.fireListeners(printableListenerDef, new PrintableEvent(this, this._printableLabel));
    }

    private _applyStringInputAttribute(key: string, value: string) {
        if (StringUtil.isEmptyString(value) !== true)
            this._applyInputAttribute(key, value);
        else
            this._removeInputAttribute(key);
    }

    private _applyBooleanInputAttribute(key: string, value: boolean) {
        if (value === true)
            this._applyInputAttribute(key, "true");
        else
            this._removeInputAttribute(key);
    }

    private _applyInputAttribute(key: string, value: string) {
        if (this._inputAttributes == null)
            this._inputAttributes = {};
        this._inputAttributes[key] = value;
        if (this._input != null)
            this._input.setAttribute(key, value);
    }

    private _applyAllInputAttributes() {
        if (this._input == null || this._inputAttributes == null)
            return;
        for (const key of Object.keys(this._inputAttributes)) {
            this._input.setAttribute(key, this._inputAttributes[key]);
        }
    }

    private _removeInputAttribute(key: string) {
        if (this._inputAttributes == null)
            return;
        delete this._inputAttributes[key];
        if (ObjectUtil.isEmptyObject(this._inputAttributes))
            this._inputAttributes = null;
        if (this._input != null)
            this._input.removeAttribute(key);
    }

    private _applyInputClass(clazz: any) {
        if (this._inputClassList == null)
            this._inputClassList = [];
        if (!this._inputClassList.includes(clazz))
            this._inputClassList.push(clazz)
        if (this._input != null)
            this._input.classList.add(clazz);
    }

    private _applyAllInputClasses() {
        if (this._input == null || this._inputClassList == null)
            return;
        for (const clazz of this._inputClassList) {
            this._input.classList.add(clazz);
        }
    }

    private _removeInputClass(clazz: any) {
        if (this._inputClassList == null)
            return;
        ArrayUtil.removeFromArray(this._inputClassList, clazz);
        if (this._inputClassList.length === 0)
            this._inputClassList = null;
        if (this._input != null)
            this._input.classList.remove(clazz);
    }

    private _applyInputStyle(key: string, value: any) {
        if (value == null) {
            this._removeInputStyle(key);
            return;
        }
        if (this._inputStyles == null)
            this._inputStyles = {};
        this._inputStyles[key] = value;
        if (this._input != null)
            this._input.style[key] = value;
    }

    private _applyAllInputStyles() {
        if (this._input == null || this._inputStyles == null)
            return;
        for (const key of Object.keys(this._inputStyles)) {
            this._input.style[key] = this._inputStyles[key];
        }
    }

    private _removeInputStyle(key: string) {
        if (this._inputStyles == null)
            return;
        delete this._inputStyles[key];
        if (ObjectUtil.isEmptyObject(this._inputStyles))
            this._inputStyles = null;
        if (this._input != null)
            this._input.style[key] = null;
    }

    get precision(): number {
        if (isDisplayTypeNumeric(this.displayType) === true)
            return this._boundField?.precision;
        return null;
    }

    get scale(): number {
        if (isDisplayTypeNumeric(this.displayType) === true)
            return this._boundField?.scale;
        return null;
    }

    get maxValue(): number {
        return this._maxValue;
    }

    set maxValue(value: number) {
        this._maxValue = value;
    }

    get minValue(): number {
        return this._minValue;
    }

    set minValue(value: number) {
        this._minValue = value;
    }

    get timezone(): Timezone {
        return this._timezone;
    }

    set timezone(value: Timezone) {
        this._timezone = value;
    }

    set currencyColorCallback(colorCallback: (num: number) => string) {
        this._currencyColorCallback = colorCallback;
    }

    public get displayLabel(): string {
        return this._displayLabel || this.caption || this._boundField?.caption;
    }

    public set displayLabel(value: string) {
        this._displayLabel = value;
    }

    get fillHeight(): boolean {
        return super.fillHeight;
    }

    set fillHeight(value: boolean) {
        super.fillHeight = value;
        //not sure why, but a multiline textarea won't grow when its height is set to 100%
        //we do want the Textbox's parent panelRow to be affected by the setting of fillHeight though
        //so let that happen and then remove the set of height to 100%
        if (value === true && this.multiline === true)
            this._element.style.height = "";
    }

    getPermissionsTypes(): PermissionsDefinition[] {
        return [
            ...super.getPermissionsTypes(),
            {
                permsType: "E",
                description: "Edit security",
                availableToAllDescription: "Everyone can edit this item",
                availableToNoneDescription: "This item is read-only to everyone"
            }
        ];
    }
}

JSUtil.applyMixins(Textbox, [Captioned, Printable, QuickInfo]);
ComponentTypes.registerComponentType("textbox", Textbox.prototype.constructor);
