import { ArrayUtil, Collection, StringUtil } from "@mcleod/core";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { DataSource } from "../../databinding/DataSource";
import { ModelUserSavedSearch, RowUserSavedSearch } from "../../models/ModelUserSavedSearch";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { Panel } from "../panel/Panel";
import { List, ListItemType } from "../list/List";
import { ListItem } from "../list/ListItem";
import { Label } from "../label/Label";
import { Textbox } from "../textbox/Textbox";
import { SelectionEvent } from "../../events/SelectionEvent";
import { ReflectiveSnackbars } from "../../base/ReflectiveSnackbars";
import { ClickEvent } from "../../events/ClickEvent";
import { LabelProps } from "../label/LabelProps";

export class PinnedSearch {
    private static cachedSearches: Collection<RowUserSavedSearch[]> = {};
    private searchParentName: string;
    private dataSource: DataSource;
    private commonLabelProps: Partial<LabelProps>;

    constructor(searchParentName: string, dataSource: DataSource, commonLabelProps: Partial<LabelProps>) {
        this.searchParentName = searchParentName;
        this.dataSource = dataSource;
        this.commonLabelProps = commonLabelProps;
    }

    public executePinnedSearch(searchName: string) {
        this.dataSource.search({ pinned_search_name: searchName, pinned_search_path: this.searchParentName });
    }

    public async getDropdownItem(): Promise<ListItem> {
        const labelPinnedSearches = new Label({
            ...this.commonLabelProps,
            id: "labelPinnedSearches",
            caption: "Pinned Searches",
            fillRow: true,
            rowBreak: false,
            imageName: "pushPin"
        });
        const children: ListItemType[] = [this.getPinCurrentSearchPanel()];
        const pinned = await this.getPinnedSearches();
        for (const pin of pinned) {
            children.push(this.getPinnedSearchComponent(pin));
        }
        return new ListItem(labelPinnedSearches, children, (event: SelectionEvent) => {
            this.handlePinnedItemSelect(event);
        });
    }

    private handlePinnedItemSelect(event: SelectionEvent) {
        if (event.domEvent.defaultPrevented === true)
            return;
        const selectedIndex = (event.target as List).selectedIndex;
        if (selectedIndex === 0) {
            //user selected to create a new pinned search
            this.pinSearch();
        }
        else {
            const searchesForCurrentPage = PinnedSearch.cachedSearches[this.searchParentName];
            const searchName = searchesForCurrentPage[selectedIndex - 1].get("search_name");
            this.executePinnedSearch(searchName);
        }
    }

    private getPinCurrentSearchPanel(): Panel {
        const result = new Panel({ fillRow: true, padding: 0 });
        const labelPinCurrentSearch = new Label({
            ...this.commonLabelProps,
            id: "labelPinCurrentSearch",
            caption: "Create From Current Search",
            fillRow: true,
            rowBreak: false,
            imageName: "add"
        });
        const buttonPlaceholder = new Button({
            variant: ButtonVariant.round,
            color: "subtle.light",
            imageName: "delete",
            preventCollapse: true,
            visible: false
        });
        result.add(labelPinCurrentSearch, buttonPlaceholder);
        return result;
    }

    private getPinnedSearchComponent(pin: RowUserSavedSearch): Panel {
        const result = new Panel({ fillRow: true, padding: 0 });
        const searchParentName = pin.get("screen_class_name");
        const name = pin.get("search_name");
        result.add(new Label({
            ...this.commonLabelProps,
            rowBreak: false,
            fillRow: true,
            caption: "Run Search: " + name,
            imageName: "listStar"
        }));
        result.add(new Button({
            variant: ButtonVariant.round,
            color: "subtle.light",
            imageName: "delete",
            onClick: async (event) => {
                this.updateClickEvent(event);
                const message = `Are you sure you want to delete pinned search '${name}'?`;
                if (await ReflectiveDialogs.showDestructive(message, "Delete Pinned Search?")) {
                    await pin.delete();
                    ArrayUtil.removeFromArray(PinnedSearch.cachedSearches[searchParentName], pin);
                }
            }
        }));
        return result;
    }

    private updateClickEvent(event: ClickEvent) {
        //don't stop the event so that the containing panel will get the event and close the overlay
        event.shouldAutomaticallyStopPropagation = false;
        //do prevent default so that the list's selection listener can ignore the selection event that
        //clicking the button will fire
        event.preventDefault();
    }

    private async getPinnedSearches(): Promise<RowUserSavedSearch[]> {
        let result = PinnedSearch.cachedSearches[this.searchParentName];
        if (result == null) {
            const rows = await new ModelUserSavedSearch().search({ screen_class_name: this.searchParentName });
            result = rows.modelRows;
            PinnedSearch.cachedSearches[this.searchParentName] = result;
        }
        return result;
    }

    private async pinSearch(): Promise<RowUserSavedSearch> {
        if (this.dataSource.lastSearch == null)
            ReflectiveSnackbars.showWarningSnackbar("You need to perform an advanced search first.");
        else {
            const name = await this.getNewPinnedSearchName();
            if (StringUtil.isEmptyString(name) === true)
                return null;
            const rowSavedSearch = new RowUserSavedSearch();
            rowSavedSearch.set({
                screen_class_name: this.searchParentName,
                search_name: name,
                where_clause: JSON.stringify(this.dataSource.lastSearch)
            });
            await rowSavedSearch.post()
            PinnedSearch.cachedSearches[rowSavedSearch.get("screen_class_name")].push(rowSavedSearch);
            return rowSavedSearch;
        }
    }

    private async getNewPinnedSearchName(): Promise<string> {
        const content: Panel = new Panel({ margin: 0, padding: 0, minWidth: 400, rowBreakDefault: true });
        const message = new Label({ caption: "Enter a name for the this pinned search." });
        const name = new Textbox({ caption: "*Name", required: true, fillRow: true, marginTop: 7 });
        content.add(message, name);
        const dialogProps = { noButtonCaption: "Cancel", yesButtonCaption: "Save", title: "Create Pinned Search" };
        const save = await ReflectiveDialogs.showYesNo(content, null, dialogProps);
        return save === true ? name.text : null;
    }
}
