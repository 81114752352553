import { CommonDialogs } from "@mcleod/common";
import { Label, Panel, Snackbar } from "@mcleod/components";
import { Api, DateUtil, DisplayType, GeneralSettings, ModelRow, UserSettings, UserType } from "@mcleod/core";
import { FtlOrder } from "./FtlOrder";
import { LtlOrder } from "./ltl/LtlOrder";
import { PortalCustomerSettings } from "../../customer/src/settings/PortalCustomerSettings";
import { PortalCompanySettings } from "../../portal-common/src/settings/PortalCompanySettings";

const snackbarPops = { padding: 0, width: 450 };

interface Contact {
    name: string;
    phone: string;
    email: string;
    subject: string;
}

export function userCanEnterFtlOrder(): boolean {
    return isPermissionsAllowed("InternetModuleOrderEntry") && UserSettings.isUserTypeCustomer();
}

export function userCanEnterFtlQuote(): boolean {
    return isPermissionsAllowed("InternetModuleFTLQuoteEntry") && UserSettings.isUserTypeCustomer();
}

export async function userCanEnterLtlOrder(): Promise<boolean> {
    if (!PortalCompanySettings.getSingleton().isAssetLtl() || !isPermissionsAllowed("InternetModulePickupEntry"))
        return false;

    switch (UserSettings.getUserType()) {
        case UserType.CUSTOMER: return true;
        case UserType.LOCATION: {
            if (PortalCustomerSettings.get()?.allow_location_order_entry) {
                return Api.search("portal/common/location", { get_customer_id: true }).then(result => {
                    return result?.data?.[0]?.customer_id != null
                });
            }
            return false;
        }
        default: return false;
    }
}

export function userCanEnterLtlQuote(): boolean {
    return PortalCompanySettings.getSingleton().isLtl() && isPermissionsAllowed("InternetModuleQuoteEntry") && UserSettings.isUserTypeCustomer();
}

function isPermissionsAllowed(permName: string): boolean {
    return GeneralSettings.get().permissions?.denied?.InternetModule?.[permName] == null;
}

export function validateBeforeAddOrder(layout: FtlOrder | LtlOrder) {
    let disabledTooltip = null;
    if (!layout.activeRow.getBoolean("credit_status_valid", false)) {
        const creditStatus = layout.activeRow.getString("credit_status") ?? "";
        switch (creditStatus) {
            case "" : {
                disabledTooltip = "Your credit status is not on file";
                break;
            }
            case "T": {
                disabledTooltip = "Your credit status is tentative";
                break;
            }
            case "D": {
                disabledTooltip = "Your credit status is denied";
            }
        }
        showCreditSnackbar(layout.activeRow.getBoolean("credit_status_valid", null), true, creditStatus);
    }
    if (validateRevenuCode(layout.activeRow.get("revenue_code_id"), true) == false) {
        if (disabledTooltip)
            disabledTooltip += " and a revenue code is required"
        else
            disabledTooltip = "A revenue code is required";
    }
    if (disabledTooltip) {
        layout.buttonSubmitOrder.disabledTooltip = disabledTooltip + ". We're unable to accept your order.";
        layout.buttonSubmitOrder.enabled = false;
    }
}

export function validateLocationsPresent(): Promise<boolean> {
    const companySettings = PortalCompanySettings.get();
    if (companySettings?.enforce_shipper_id == true) {
        return Api.search("common/location-suggestion", {
            text_search: "*",
            web_locations_only: true
        }).then(result => {
            const locationsPresent = result?.data?.length > 0;
            if (!locationsPresent) {
                const locationErrorMessage = "Please contact " + companySettings.order_contact_name + " at " + companySettings.order_contact_phone + " to have your shippers added to your portal.";
                Snackbar.showWarningSnackbar({ ...snackbarPops, caption: locationErrorMessage }, { id: "noLocs", persist: true });
            }
            return locationsPresent;
        });
    } else {
        return Promise.resolve(true);
    }
}

function getContactInfo(name: string, phone: string, email: string, subject?: string): Contact {
    const companySettings = PortalCompanySettings.get();
    return {
        name: name ? name : (companySettings.order_contact_name || "us"),
        phone: phone ? phone : companySettings.order_contact_phone,
        email: email ? email : PortalCustomerSettings.get().order_email_list,
        subject: subject
    };
}

function getContactSnackbarPanel(contact: Contact, prefix: string, suffix: string): Panel {
    const panel = new Panel({ ...snackbarPops });
    const labelProps = { rowBreak: false, paddingTop: 0, paddingBottom: 0 };
    const labelPhone = contact.phone ? new Label({ ...labelProps, caption: contact.phone, displayType: DisplayType.PHONE, fontBold: true, color: '#535d61' }) : null;
    const labelEmail = contact.email ? new Label({ ...labelProps, caption: contact.email, displayType: DisplayType.EMAIL, fontBold: true, color: '#535d61' }) : null;
    if (labelEmail && contact.subject)
        labelEmail.link = `mailto:${contact.email}?subject=${contact.subject}`;

    if (labelPhone == null && labelEmail == null) {
        if (prefix)
            panel.add(new Label({ ...labelProps, rowBreak: true, caption: prefix }));
        return panel;
    }

    const lableMsg = new Label({ ...labelProps, rowBreak: true, caption: `Please contact ${contact.name}` });
    panel.add(lableMsg);
    if (prefix)
        lableMsg.caption = prefix + ` ${lableMsg.caption}`;
    lableMsg.caption = lableMsg.caption + " at ";

    if (labelEmail)
        panel.add(labelEmail);
    if (labelPhone) {
        if (labelEmail)
            panel.add(new Label({ ...labelProps, caption: "or" }));
        panel.add(labelPhone);
        panel.add(new Label({ ...labelProps, caption: suffix }));
    }
    return panel;
}

export function validateRevenuCode(revenueCode: any, isOrder: boolean): boolean {
    const customerSettings = PortalCustomerSettings.get();
    if (customerSettings?.enforce_revenue_code == true && revenueCode == null) {
        const assistanceContact = !customerSettings.assistance_contact ? null
            : customerSettings.assistance_contact === "S" ? customerSettings.salesperson : customerSettings.operations_user;


        let message = "A Revenue Code is required.  We can accept your quote request, but we cannot accept your order.";
        if (isOrder)
            message = "A Revenue Code is required. We're unable to accept your order.";

        const subject = `${UserSettings.getUserName()} with ${customerSettings.id} - ${customerSettings.name} needs an assigned revenue code`;
        const contact = getContactInfo(assistanceContact?.name, assistanceContact?.phone, assistanceContact?.email, subject);
        const panel = getContactSnackbarPanel(contact, message, null);
        Snackbar.showWarningSnackbar(panel, { id: "revCode", persist: true });
        return false;
    }
    return true;
}

export function validateCustomerCredit(isOrder: boolean): boolean {
    const companySettings = PortalCompanySettings.get();

    const enforceCredit = companySettings.enforce_credit;
    if (enforceCredit && (enforceCredit === "L" || enforceCredit === "S")) {
        const creditStatus = PortalCustomerSettings.get()?.credit_status;
        return showCreditSnackbar(creditStatus && creditStatus === "D", isOrder, creditStatus);
    }
    else {
        return true;
    }
}

export function showCreditSnackbar(creditValid: boolean, isOrder: boolean, creditStatus?: String): boolean {
    if (creditValid == false) {
        const collectionsContact = PortalCustomerSettings.get().collections_contact;
        const contact = getContactInfo(collectionsContact?.name, collectionsContact?.phone, collectionsContact?.email);
        let message;
        switch (creditStatus) {
            case "": {
                message = PortalCompanySettings.get().name + " does not have a credit status on file for your account.";
                break;
            }
            case "T": {
                message = "Your credit status is currently marked as tentative."
                break;
            }
            default: {
                message = "Your credit status is denied.  We can accept your quote request, but we cannot accept your order.";
                if (isOrder)
                    message = "Your credit status is denied. We're unable to accept your order.";
            }
        }
    
        if (message) {
            Snackbar.showWarningSnackbar(getContactSnackbarPanel(contact, message, "for more information."), { id: "creditCheck", persist: true });
        }
        return false;
    }
    else {
        return true;
    }
}

export function validateShipper(shipperLoc: string, quoteId: string): boolean {
    const companySettings = PortalCompanySettings.get();
    if (companySettings?.enforce_shipper_id == true && shipperLoc == null) {
        const customerSettings = PortalCustomerSettings.get();
        const assistanceContact = !customerSettings.assistance_contact ? null
            : customerSettings.assistance_contact === "S" ? customerSettings.salesperson : customerSettings.operations_user;

        const subject = `${UserSettings.getUserName()} with ${customerSettings.id} - ${customerSettings.name} needs help converting quote #${quoteId} to an order`;
        const contact = getContactInfo(assistanceContact?.name, assistanceContact?.phone, assistanceContact?.email, subject);
        const panel = getContactSnackbarPanel(contact, null, "to have this shipper added to your account and to get assistance with completing this order.");
        Snackbar.showWarningSnackbar(panel, { id: "shipper", persist: true });
        return false;
    }
    return true;
}

export async function validatePrepTime(row: ModelRow<any>, showSnackbar: boolean = true): Promise<boolean> {
    const customerSettings = PortalCustomerSettings.get();
    const prepMinutes = customerSettings.order_prep_time_minutes;
    const early = row?.get("sched_arrive_early");
    if (early == null || prepMinutes == 0) {
        return Promise.resolve(true);
    }
    const earlyDateTime = DateUtil.parseDateTime(early);
    const cityId = row.get("city_id");

    const earliestPossiblePickup = new Date();
    earliestPossiblePickup.setMinutes(earliestPossiblePickup.getMinutes() + prepMinutes);
    if (cityId != null) {
        const companySettings = PortalCompanySettings.getSingleton();
        return Api.post("portal/customer/dispatch/early-pickup-validator", { early_time: earlyDateTime, city_id: cityId }).then((isValidResponse) => {
            const isValid = isValidResponse.data[0].is_valid;
            if (isValid === false) {
                let message = "We need at least " + prepMinutes + " minutes lead time for a pickup. Please adjust your pickup time.";
                if (prepMinutes === 0)
                    message = "Shipper's early pickup time must be later than shipper's present time. Please adjust your pickup time."
                if (showSnackbar)
                    Snackbar.showWarningSnackbar({ caption: message, padding: 0, width: 450 }, { id: "prepTime", persist: false });
                return (companySettings.isAssetLtl() || companySettings.isLtl() == false) ? false : true;
            }
            else {
                return true;
            }
        }).catch(reason => {
            CommonDialogs.showError("An error occurred while validating the earliest possible pickup", reason);
            return companySettings.isAssetLtl() ? false : true;
        });
    }
    else {
        return Promise.resolve(true);
    }
}

export function validatePickupWindow(row: ModelRow<any>, showSnackbar: boolean = true): boolean {
    const early = row?.get("sched_arrive_early");
    const late = row?.get("sched_arrive_late");
    if (early == null || late == null) {
        return true;
    }
    const earlyDateTime = DateUtil.parseDateTime(early);
    const lateDateTime = DateUtil.parseDateTime(late);
    const minWindowMinutes = PortalCustomerSettings.get().order_pickup_window_minutes;

    lateDateTime.setMinutes(lateDateTime.getMinutes() - minWindowMinutes);

    if (lateDateTime < earlyDateTime) {
        const message = "We need at least a " + minWindowMinutes + " minute pickup window.  Please adjust your Early Pickup and/or Late Pickup to allow for at least a " + minWindowMinutes + " minute window.";
        if (showSnackbar)
            Snackbar.showWarningSnackbar({ caption: message, padding: 0, width: 450 }, { id: "pickupWindow", persist: false });
        return false;
    }
    return true;
}
