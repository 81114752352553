import { CompoundComponent } from "../components/compound/CompoundComponent";
import { Component } from "./Component";
import { ComponentProps } from "./ComponentProps";
import { Container } from "./Container";

interface ComponentConstructor {
    new(props: ComponentProps): Component;
}

export interface CloneComponentProps {
    component: Component,
    id?: string,
    overrideProps?: any,
    targetComponent?: Component,
    appendComponentId?: boolean,
    clonedIds?: string[],
    idCalcFunction?: ((sourceComponent: Component, clonedsId: string[]) => string)
}

export class CloneComponent {

    public static clone(props: CloneComponentProps): Component {
        const component = props.component;
        let id = props.id;
        const overrideProps = props.overrideProps;
        const targetComponent = props.targetComponent;
        const appendComponentId = props.appendComponentId;
        const idCalcFunction = props.idCalcFunction;
        const clonedIds = props.clonedIds || [];

        const sourceProps = CloneComponent.getComponentPropertyValues(component);
        if (overrideProps != null)
            for (const key in overrideProps)
                sourceProps[key] = overrideProps[key];
        delete sourceProps.id;
        sourceProps._designer = component._designer;
        let result = targetComponent;
        if (id == null && idCalcFunction != null) {
            id = idCalcFunction(component, clonedIds);
            clonedIds.push(id);
        }
        sourceProps.id = id;
        if (result == null) {
            const constr = component.constructor as ComponentConstructor;
            result = new constr(sourceProps);
        }
        if (component instanceof Container && !(component instanceof CompoundComponent))
            for (const comp of component.components) {
                let id: string;
                if (idCalcFunction == null) {
                    id = appendComponentId === false ? comp.id : result.id + "-" + comp.id;
                    clonedIds.push(id);
                }
                (result as Container).add(CloneComponent.clone({ 
                    component: comp, 
                    id: id, 
                    appendComponentId: appendComponentId, 
                    clonedIds: clonedIds,
                    idCalcFunction: idCalcFunction 
                }));
            }
        result.owner = component.owner;
        return result;
    }

    public static getComponentPropertyValues(component: Component): any {
        const result = {};
        const props = component.getPropertyDefinitions();
        for (const key in props) {
            const value = component[key];
            if (value !== undefined)
                result[key] = component[key];
        }
        return result;
    }
}
