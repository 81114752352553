import { ComponentPropDefinitions, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { PanelProps } from "../panel/PanelProps";
import { layoutSelector } from "../textbox/TextboxProps";

export interface LocationProps extends PanelProps {
    caption: string;
    captionVisible: boolean;
    cityField: string;
    stateField: string;
    zipField: string;
    cityIdField: string;
    locationIdField: string;
    printable: boolean;
    printableDuringAdd: boolean;
    printableDuringSearch: boolean;
    printableDuringUpdate: boolean;
    quickInfoLayout: string;
}

let locationPropDefs: ComponentPropDefinitions;

export class LocationPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (locationPropDefs == null) {
            locationPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                caption: { type: PropType.string, visibleInMcLeodTailor: true, description: "This controls the label that appears above the Location component." },
                captionVisible: {
                    type: PropType.bool,
                    defaultValue: true,
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether or not the caption of the Textbox will be shown.  If set to false, there will be no caption or additional whitespace where the caption would normally go."
                },
                cityField: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This field to hold the city name." },
                stateField: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This field to hold the state." },
                zipField: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This field to hold the zip code." },
                cityIdField: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This field to hold the city id." },
                locationIdField: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This field to hold the location id." },
                locationNameField: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This field to hold the location name." },
                addressField: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This field to hold the address." },
                searchWebLocation: { type: PropType.bool, visibleInMcLeodTailor: true, description: "Search the web location Lucene index" },
                filterOwner: { type: PropType.bool, visibleInMcLeodTailor: true, description: "Filter results by user logged in" },
                isForStop: { type: PropType.bool, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "Is this used in a stop table and needs to check against enforce shipper id in dispatch control." },
                printable: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Location component appears as a caption with a user-editable input box or whether it should be displayed as a caption with a non-editable Label.  This is often changed at runtime.",
                },
                printableDuringAdd: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Location component should appear in its printable state during add mode.",
                },
                printableDuringSearch: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Location component should appear in its printable state during search mode.",
                },
                printableDuringUpdate: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Location component should appear in its printable state during update mode.",
                },
                quickInfoLayout: { type: PropType.string, editor: layoutSelector, defaultValue: "lme/dispatch/LocationQuickInfo", visibleInMcLeodTailor: true, editableInMcLeodTailor: false, description: "Specifies a layout that will be opened as a tooltip when the user hovers over the Location's 'combined' field.  The mainDataSource of this layout will be searched with the value in the 'combined' field." },
                onChange: { type: PropType.event, category: "Events", addListenerMethod: "addChangeListener", removeListenerMethod: "removeChangeListener", description: "This specifies a function to be called when the value changes." },
                onPrintable: { type: PropType.event, category: "Events", addListenerMethod: "addPrintableListener", removeListenerMethod: "removePrintableListener", eventSignature: "OnPrintable(event: PrintableEvent)", description: "This specifies a function to be called when the component's printable property has changed."},
                //beforeLookupModelSearch: { type: PropType.event, category: "Events", addListenerMethod: "addBeforeLookupModelSearchListener", removeListenerMethod: "removeBeforeLookupModelSearchListener", description: "This specifies a function to be called before the lookup model is searched, to adjust the filter." },
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(locationPropDefs);
        }
        return locationPropDefs;
    }
}
