import { ClickEvent, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, Label } from "@mcleod/components";
import { AutogenLayoutOrderHistoryImages } from "./autogen/AutogenLayoutOrderHistoryImages";

export class OrderHistoryImages extends AutogenLayoutOrderHistoryImages {
    private _addedAnImage = false;

    public setup(movementId: string, orderId: string, onSave?: (movementId: string, orderId: string) => void,
        onClose?: (movementId: string, orderId: string) => void) {
        this.addLayoutDataLoadListener(event => {
            this.mainDataSource.mode = DataSourceMode.ADD;
            this.mainDataSource.addBeforeExecutionListener((event: DataSourceExecutionEvent) => {
                if (event.getAction() === DataSourceAction.ADD) {
                    event.dataSource.activeRow?.set("movement_id", movementId);
                    event.dataSource.activeRow?.set("order_id", orderId);
                }
            });
            this.setupButtons(movementId, orderId, onSave, onClose);
        });
    }

    private setupButtons(movementId: string, orderId: string, onSave?: (movementId: string, orderId: string) => void,
        onClose?: (movementId: string, orderId: string) => void) {
        this.buttonCancel.addClickListener((event: ClickEvent) => {
            if (onClose != null)
                onClose(movementId, orderId);
        });
        this.buttonSave.addClickListener((event: ClickEvent) => {
            this.mainDataSource.addAfterExecutionListener(() => {
                this._addedAnImage = true;
                this.mainDataSource.mode = DataSourceMode.ADD;
                if (onSave != null)
                    onSave(movementId, orderId);
            });
        });
        this.buttonSaveAndClose.addClickListener((event: ClickEvent) => {
            this.mainDataSource.addAfterExecutionListener(() => {
                this._addedAnImage = true;
                if (onSave != null)
                    onSave(movementId, orderId);
                if (onClose != null)
                    onClose(movementId, orderId);
            });
        });
    }

    public get addedAnImage(): boolean {
        return this._addedAnImage;
    }
}
