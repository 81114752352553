import { DataType, DateUtil, getLogger, ModelRow } from "@mcleod/core";
import { TableRowSearchUtil } from "./TableRowSearchUtil";

export type ComponentSearchResult = {
    value: string | string[];
    fieldName: string;
    alias?: string;
}

const log = getLogger("components.table.ComponentSearcher");

export class ComponentSearcher {
    searcher: (row: ModelRow) => ComponentSearchResult;
    fieldName: string;
    alias?: string;

    constructor(fieldName: string, alias?: string, searcher?: (row: ModelRow) => ComponentSearchResult) {
        this.fieldName = fieldName;
        this.alias = alias;
        if (searcher == null)
            this.searcher = this.createBasicSearcher();
        else
            this.searcher = searcher;
    }

    public search(row: ModelRow): ComponentSearchResult {
        return this.searcher(row);
    }

    private createBasicSearcher(): (row: ModelRow) => ComponentSearchResult {
        return (row: ModelRow) => this.getValuesFromRow(row, this.fieldName, this.alias);
    }

    private getValuesFromRow(row: ModelRow, fieldName: string, alias: string): ComponentSearchResult {
        let value = row.get(fieldName)?.toString();
        log.debug("Getting table search value from field %o, alias %o: %o", fieldName, alias, value);
        const fieldMetadata = row.getMetadata()?.getFieldFromOutput(fieldName);
        if (fieldMetadata?.dataType === DataType.DATE || fieldMetadata?.dataType === DataType.DATETIME && value != null) {
            value = [value];
            value.push(DateUtil.formatDateTime(DateUtil.parseDateTime(value)));
        }
        return this.createComponentSearchResult(value, fieldName, alias);
    }

    private createComponentSearchResult(value: string | string[], fieldName: string, alias: string): ComponentSearchResult {
        return {
            value: value,
            fieldName: TableRowSearchUtil.getSimpleFieldName(fieldName),
            alias: alias
        };
    }
}