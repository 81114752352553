import { ClickEvent } from "@mcleod/components";
import { Navigation } from "@mcleod/core";
import { AutogenLayoutDriverApplicationCongrats } from "./autogen/AutogenLayoutDriverApplicationCongrats";
import { PortalCompanySettings } from "../../portal-common/src/settings/PortalCompanySettings";

export class DriverApplicationCongrats extends AutogenLayoutDriverApplicationCongrats {

    override onLoad() {
        const settings = PortalCompanySettings.get();
        if (settings != null) {
            this.labelAppReceived.caption = settings.dr_response;
            this.labelContact.caption = settings.dr_contact_name;
            this.labelContactPhone.caption = settings.dr_contact_phone;
            this.labelContactEmail.caption = settings.dr_contact_email;
        }

    }

    /** This is an event handler for the onClick event of buttonBackToSignIn.  */
    buttonBackToSignInOnClick(event: ClickEvent) {
        Navigation.navigateTo("common/Login");
    }
}
