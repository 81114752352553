import { BlurEvent, ChangeEvent, DataSourceMode } from "@mcleod/components";
import { Api, DateUtil, DisplayType, FieldUpdateEvent, ModelRow } from "@mcleod/core";
import { validatePickupWindow, validatePrepTime } from "./OrderValidation";
import { AutogenLayoutAddStop } from "./autogen/AutogenLayoutAddStop";
import { getDatesBasedOnMaxDaysOut } from "./ltl/LtlMaxDaysOut";
import { LtlUtil } from "./ltl/LtlUtil";
import { PortalCompanySettings } from "../../portal-common/src/settings/PortalCompanySettings";

export class AddStop extends AutogenLayoutAddStop {

    private _hasValidPickupTimes: boolean = true;
    private _isShipper: boolean;
    public dateValidationActive = true;
    private _isFtlOrder: boolean = false;

    public get isFtlOrder(): boolean {
        return this._isFtlOrder;
    }
    public set isFtlOrder(value: boolean) {
        this._isFtlOrder = value;
    }

    override onLoad() {
        this.mainDataSource.preventFocusFirstField = true;
    }

    public get hasValidPickupTimes(): boolean {
        return this._hasValidPickupTimes;
    }

    public set hasValidPickupTimes(value: boolean) {
        this._hasValidPickupTimes = value;
    }

    initialize(row: ModelRow<any>, isShipper: boolean) {
        this.mainDataSource.setRowsAndMode(DataSourceMode.ADD, [row]);
        this.isShipper = isShipper;
    }

    public get isShipper(): boolean {
        return this._isShipper;
    }

    private set isShipper(value: boolean) {
        this._isShipper = value;
        const showDateTimes = this.isShipper || this.isFtlOrder;
        this.textPickupDate.setProps({ required: showDateTimes, visible: showDateTimes });
        this.textboxEarlyPickup.setProps({ required: showDateTimes, visible: showDateTimes });
        this.textboxLatePickup.visible = showDateTimes;
        if (this.isFtlOrder) {
            this.labelStopType.visible = this.isShipper;
            this.switchStopType.visible = !this.isShipper;
        } else {
            this.labelStopType.caption = this.isShipper ? "Pickup" : "Delivery";
        }

        if (this.isShipper && PortalCompanySettings.get().enforce_shipper_id === true) {
            this.locationStop.webLocationsOnly = true;
            this.locationStop.textCombined.manualAddLayout = null;
        }

        if (this.isShipper || this.isFtlOrder)
            this.setupDateComponents();
    }

    setupDateComponents() {
        if (this.isFtlOrder) {
            this.textPickupDate.displayType = DisplayType.DATE;
            this.textPickupDate.addChangeListener((event: ChangeEvent) => {
                if (this.textPickupDate.isDropdownVisible())
                    this.shipDateChanged();
            })

            this.textPickupDate.addBlurListener((event: BlurEvent) => {
                if (event.changedWhileFocused)
                    this.shipDateChanged();
            });
        }
        this.setupDropdownItems();
        this.activeRow.addAfterFieldUpdateListener((event: FieldUpdateEvent) => this.stopFieldUpdated(event))
    }

    setupDropdownItems() {
        if (!this.isFtlOrder) {
            this.textPickupDate.items = getDatesBasedOnMaxDaysOut();
        }
        this.textboxEarlyPickup.items = LtlUtil.getPickupDateItems(new Date());
        this.textboxLatePickup.items = [...this.textboxEarlyPickup.items];
    }

    async validatePickupDates() {
        if (this.isShipper && this.dateValidationActive == true) {
            this.hasValidPickupTimes = true;
            try {
                this.dateValidationActive = false;
                this.hasValidPickupTimes = validatePickupWindow(this.activeRow);
                this.hasValidPickupTimes = await validatePrepTime(this.activeRow) && this.hasValidPickupTimes;
            } finally {
                this.dateValidationActive = true;
            }
        }
    }

    async autoFillLocationData() {
        if (!this.activeRow.isNull("location_id")) {
            await Api.search("portal/customer/dispatch/location-auto-fill", {
                id: this.activeRow.get("location_id"),
                day_of_week: this.pickupDate?.getDay(),
                ltl: !this.isFtlOrder
            }).then(response => {
                const data = response?.data[0];
                this.activeRow.set({ "contact_name": data.name, "phone": data.phone, "appt_required": data.appt_required });

                if (this.isShipper && (data.open_time || data.close_time)) {
                    this.dateValidationActive = false;
                    this.defaultOpenCloseTimes(data.open_time, "sched_arrive_early_time");
                    this.defaultOpenCloseTimes(data.close_time, "sched_arrive_late_time");
                    this.dateValidationActive = true;
                    this.validatePickupDates();
                }

            });
        }
    }

    defaultOpenCloseTimes(openOrCloseTime: any, fieldName: string) {
        if (openOrCloseTime != null) {
            const openTime = DateUtil.parseTime(openOrCloseTime);
            const stopTime = DateUtil.parseDateTime(this.activeRow.get(fieldName)) ?? new Date();
            stopTime.setHours(openTime.getHours(), openTime.getMinutes(), 0, 0);
            this.activeRow.set(fieldName, stopTime.toString());
        }
    }

    stopFieldUpdated(event: FieldUpdateEvent): void {
        // avoid calling this listener if fields are being updated from the server's response in ModelRow.afterPost()
        const isOrderAddMode = this.owner?.mainDataSource?.mode == DataSourceMode.ADD;

        if (isOrderAddMode && event.oldValue != event.newValue) {
            if ("sched_arrive_date" == event.fieldName && !this.isFtlOrder) {
                this.shipDateChanged();
            } else if ("location_id" == event.fieldName) {
                this.activeRow.set({ "contact_name": null, "phone": null, "appt_required": "N" });
                this.autoFillLocationData();
            } else if ("sched_arrive_early_time" == event.fieldName) {
                this.textboxLatePickup.hideDropdown(true);
                this.updatePickupTimes("sched_arrive_early_time");
                this.validatePickupDates();
            } else if ("sched_arrive_late_time" == event.fieldName) {
                this.textboxLatePickup.hideDropdown(true);
                this.updatePickupTimes("sched_arrive_late_time");
                this.validatePickupDates();
            }
        }
    }

    shipDateChanged() {
        if (this.pickupDate != null) {
            this.textPickupDate.hideDropdown(true);
            this.updateLateEarlyTimes();
            if (!this.activeRow.isNull("location_id")) {
                this.autoFillLocationData();
            } else {
                this.validatePickupDates();
            }
        }
    }

    updateLateEarlyTimes() {
        this.dateValidationActive = false;
        this.updatePickupTimes("sched_arrive_early_time");
        this.updatePickupTimes("sched_arrive_late_time");
        this.dateValidationActive = true;
    }

    updatePickupTimes(pickupTimeField: string) {
        if (this.pickupDate != null) {
            const pickupTimes = this.activeRow.get(pickupTimeField);
            const field = "sched_arrive_early_time" == pickupTimeField ? "sched_arrive_early" : "sched_arrive_late";
            if (pickupTimes != null) {
                const pickupTime = DateUtil.parseDateTime(pickupTimes);
                pickupTime.setFullYear(this.pickupDate.getFullYear(), this.pickupDate.getMonth(), this.pickupDate.getDate());
                this.activeRow.set(field, pickupTime.toString());
            }
        }
    }

    private get pickupDate(): Date {
        const date = DateUtil.parseDate(this.activeRow.get("sched_arrive_date"));
        if (date == null || isNaN(date.getTime()))
            return null;
        return date;
    }

}
