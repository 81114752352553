import { HorizontalAlignment, VerticalAlignment, getLogger, getThemeColor } from "@mcleod/core";
import { Label, Stepper } from "../..";
import { ComponentTypes } from "../../base/ComponentTypes";
import { Cursor } from "../../base/Cursor";
import { Image } from "../image/Image";
import { Panel } from "../panel/Panel";
import { StepPropDefinitions } from "./StepProps";

const log = getLogger("components.stepper.Step");

export class Step extends Panel {
    private _caption: string;
    private _index: number;
    private _headingLabel: Label;
    private _stepLabel: Label;
    private _heading: Panel;
    private _headingImage: Image
    _selectedIndex: number;
    _visited: boolean;
    _selected: boolean;

    constructor(props) {
        super({ fillRow: true, rowBreak: false });
        this.createHeading();
        this.setProps(props);
    }

    private get stepper(): Stepper {
        return this.owner as Stepper;
    }

    set caption(value) {
        this._caption = value;
        this._headingLabel.caption = value;
    }

    get caption() {
        return this._caption;
    }

    get index() {
        return this._index;
    }

    set index(value) {
        this._index = value;
        this._stepLabel.caption = (value + 1).toString();
    }

    select() {
        this.stepper.selectedIndex = this.index;
    }

    get selected() {
        return this._selected;
    }

    set selected(value) {
        this._selected = value;
        if (value)
            this.visited = true;
        else
            this.renderStepLabel();
    }

    get visited() {
        return this._visited;
    }

    set visited(value) {
        this._visited = value;
        this.renderStepLabel();
    }

    get selectedIndex() {
        return this._selectedIndex;
    }

    set selectedIndex(value) {
        this._selectedIndex = value;
    }

    renderStepLabel() {
        if (this.stepper.enforceStepSequence) {
            if (this.selected || (this._designer == null && this.visited)) {
                this._headingLabel.color = "primary";
                this._stepLabel.backgroundColor = "primary";
            } else {
                this._headingLabel.color = "subtle.light";
                this._stepLabel.backgroundColor = "subtle.light";
            }
            if (this.visited && !this.selected && this._designer == null) {
                this._stepLabel.caption = null;
                this._stepLabel.imageName = "check";
                this._stepLabel.padding = 2;
            } else {
                this._stepLabel.caption = (this.index + 1).toString()
                this._stepLabel.imageName = null;
                this._stepLabel.padding = undefined;
            }
        } else {
            this._stepLabel.backgroundColor = this.selected ? "primary" : "subtle.light";
            this._headingLabel.color = this.selected ? "primary" : "subtle.light";
        }
    }

    close() {
        this.parent.remove(this);
    }

    get heading() {
        return this._heading;
    }

    createHeading() {
        this._heading = new Panel({
            rowBreak: false,
            color: getThemeColor("subtle.darker"),
            verticalAlign: VerticalAlignment.CENTER
        });
        this._stepLabel = new Label({
            backgroundColor: "subtle.light",
            borderRadius: "50%",
            rowBreak: false,
            allowSelect: false,
            caption: this.index?.toString(),
            width: 24,
            height: 24,
            marginRight: 4,
            align: HorizontalAlignment.CENTER,
            color: "subtle.reverse",
            cursor: Cursor.POINTER
        });
        this._headingLabel = new Label({
            caption: this.caption,
            rowBreak: false,
            allowSelect: false,
            fontBold: true,
            cursor: Cursor.POINTER,
        });
        this._stepLabel.addClickListener(() => this.select());
        this._headingLabel.addClickListener(() => this.select());
        this._heading.add(this._stepLabel);
        this._heading.add(this._headingLabel);
    }

    _imageUpdated(image: Image) {
        if (this._headingImage == null) {
            this._headingImage = new Image({ rowBreak: false, marginRight: 4 });
            this._heading.insert(this._headingImage, this._heading.indexOf(this._headingLabel));
        }
        if (this._headingImage._element.parentElement != null)
            this._headingImage._element.parentElement.replaceChild(image._element, this._headingImage._element);
        this._headingImage._element = image._element;
    }

    getPropertyDefinitions() {
        return StepPropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "step";
    }

    override get properName(): string {
        return "Step";
    }

    override getBasicValue(): any {
        return this.index;
    }
}

ComponentTypes.registerComponentType("step", Step.prototype.constructor);
