import { UserType } from "./UserType";

export enum AuthType {
    CUSTOMER = "CUSTOMER",
    CARRIER = "CARRIER",
    LOCATION = "LOCATION",
    FACTORING_COMPANY = "FACTORING",
    DRIVER = "DRIVER",
    LME = "LME",
    ANY = "ANY",
    UNAUTH = "UNAUTH",
    MCLEOD = "MCLEOD"
}

export class AuthTypeUtil {
    public static fromUserType(userType: UserType | string): AuthType {
        switch (userType) {
            case UserType.CUSTOMER:
                return AuthType.CUSTOMER;
            case UserType.CARRIER:
                return AuthType.CARRIER;
            case UserType.LOCATION:
                return AuthType.LOCATION;
            case UserType.FACTORING_COMPANY:
                return AuthType.FACTORING_COMPANY;
            case UserType.DRIVER:
                return AuthType.DRIVER;
            case UserType.LME:
                return AuthType.LME;
            case UserType.MCLEOD:
                return AuthType.MCLEOD;
            default:
                return AuthType.UNAUTH;
        }
    }
}
